import {
  Contacts_Contact as CustomerContact,
  PurchaseOrder,
} from "gen/customer/models_pb"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { Address } from "gen/proto/address/models_pb"
import { AppState } from "app/store"
import { Decimal } from "decimal"
import { Money } from "money"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { RestockFeeType } from "./RestockFeeSection"
import { SaleProduct } from "./types"
import { UUID } from "uuid-rd"

export interface ReturnForm {
  quantityToReturnBySaleProductId: Record<string, number>
  returnNotes: string
  address: Address | null
  po: PurchaseOrder | null
  authorizedBuyer: CustomerContact | null
  restockFeeAmount: Money | null
  restockFeePercentage: Decimal | null
  restockFeeType: RestockFeeType
}

export interface ReturnFormState {
  initialState: ReturnForm
  currentState: ReturnForm
}

export const emptyReturnForm: ReturnForm = {
  quantityToReturnBySaleProductId: {},
  returnNotes: "",
  address: null,
  po: null,
  authorizedBuyer: null,
  restockFeeAmount: null,
  restockFeePercentage: null,
  restockFeeType: "percentage", // default fee type
}

const initialState: ReturnFormState = {
  initialState: emptyReturnForm,
  currentState: emptyReturnForm,
}

export const returnsSlice = createSlice({
  name: "returns",
  initialState,
  reducers: {
    setInitialReturnNotes: (state, action: PayloadAction<string>) => {
      state.initialState.returnNotes = action.payload
      state.currentState.returnNotes = action.payload
    },
    setInitialState: (
      state,
      action: PayloadAction<{
        saleProducts?: SaleProduct[]
        returnNotes?: string
        address?: Address | undefined
        po?: PurchaseOrder | undefined
      }>
    ) => {
      const returnProductsById: Record<string, number> = {}
      for (const saleProduct of action.payload.saleProducts ?? []) {
        const idStr = getIdString(saleProduct.id)
        if (!idStr) {
          continue
        }
        returnProductsById[idStr] = 0
      }

      const initialState: ReturnForm = {
        quantityToReturnBySaleProductId: returnProductsById,
        returnNotes: action.payload.returnNotes ?? "",
        address: action.payload.address ?? null,
        po: action.payload.po ?? null,
        authorizedBuyer: null,
        restockFeeAmount: null,
        restockFeePercentage: null,
        restockFeeType: "percentage",
      }
      state.initialState = initialState
      state.currentState = initialState
    },
    resetFormToCurrent: (state) => {
      state.initialState = state.currentState
    },
    setCurrentStateToInitial: (state) => {
      state.currentState = state.initialState
    },
    setReturnNotes: (state, action: PayloadAction<string>) => {
      state.currentState.returnNotes = action.payload
    },
    setAddress: (state, action: PayloadAction<Address | null>) => {
      state.currentState.address = action.payload
    },
    setPo: (state, action: PayloadAction<PurchaseOrder | null>) => {
      state.currentState.po = action.payload
    },
    setAuthorizedBuyer: (
      state,
      action: PayloadAction<CustomerContact | null>
    ) => {
      state.currentState.authorizedBuyer = action.payload
    },
    setQuantityToReturn: (
      state,
      action: PayloadAction<{ saleProductId?: PBUUID; quantity: number }>
    ) => {
      const idString = getIdString(action.payload.saleProductId)
      if (!idString) {
        return
      }

      if (!(idString in state.currentState.quantityToReturnBySaleProductId)) {
        return
      }

      state.currentState.quantityToReturnBySaleProductId[idString] =
        action.payload.quantity
    },
    setRestockFeeAmount: (state, action: PayloadAction<Money | null>) => {
      state.currentState.restockFeeAmount = action.payload
    },
    setRestockFeePercentage: (state, action: PayloadAction<Decimal | null>) => {
      state.currentState.restockFeePercentage = action.payload
    },
    setRestockFeeType: (state, action: PayloadAction<RestockFeeType>) => {
      state.currentState.restockFeeType = action.payload
    },
  },
})

function getIdString(id?: PBUUID) {
  return id ? UUID.fromPB(id).toString() : undefined
}

export const {
  setInitialReturnNotes,
  setInitialState,
  resetFormToCurrent,
  setAddress,
  setPo,
  setAuthorizedBuyer,
  setQuantityToReturn,
  setReturnNotes,
  setRestockFeeAmount,
  setRestockFeePercentage,
  setRestockFeeType,
} = returnsSlice.actions

export const selectQuantityToReturnBySaleProductId = (state: AppState) =>
  state.returns.currentState.quantityToReturnBySaleProductId
export const selectReturnNotes = (state: AppState) =>
  state.returns.currentState.returnNotes
export const selectAddress = (state: AppState) =>
  state.returns.currentState.address

export const selectPo = (state: AppState) => state.returns.currentState.po

export const selectAuthorizedBuyer = (state: AppState) =>
  state.returns.currentState.authorizedBuyer

export const selectIsValidForm = (state: AppState) => {
  const returnNotes = selectReturnNotes(state)
  return (
    !!returnNotes &&
    Object.values(
      state.returns.currentState.quantityToReturnBySaleProductId
    ).some((quantity) => quantity > 0)
  )
}

export function getQuantityToReturn(
  quantityToReturnBySaleProductId: Record<string, number>,
  saleProductId?: PBUUID
) {
  const idString = getIdString(saleProductId)
  if (!idString) {
    return 0
  }
  if (!(idString in quantityToReturnBySaleProductId)) {
    return 0
  }
  return quantityToReturnBySaleProductId[idString]
}

export const selectRestockFeeAmount = (state: AppState) =>
  state.returns.currentState.restockFeeAmount

export const selectRestockFeePercentage = (state: AppState) =>
  state.returns.currentState.restockFeePercentage

export const selectRestockFeeType = (state: AppState) =>
  state.returns.currentState.restockFeeType
