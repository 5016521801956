import { ErrorPageTitles, RundooHead } from "components/Head"

import { ExclamationCircleIcon } from "@heroicons/react/solid"

interface ErrorPageProps {
  message?: string
}

export function ErrorPage({
  message = "Please try again, or contact Rundoo support at 1 (650) 334-3205",
}: ErrorPageProps) {
  return (
    <div className="dark:bg-gray-1000 flex h-screen w-screen flex-col items-center justify-center bg-gray-50 text-center">
      <RundooHead headTitle={ErrorPageTitles.ERROR} />
      <ExclamationCircleIcon className="h-20 w-20 text-red-400 dark:text-red-800" />
      <h2 className="mt-8 font-semibold">Something went wrong!</h2>
      <span className="mt-1 text-base text-gray-600 dark:text-gray-200">
        {message}
      </span>
    </div>
  )
}
