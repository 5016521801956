/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "analytics/models.proto" (package "rd.analytics", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Product } from "../product/models_pb";
import { Sale } from "../txn/models_pb";
import { SaleProduct } from "../txn/models_pb";
import { CustomerBalance } from "../txn/models_pb";
import { Contacts } from "../customer/models_pb";
import { ProfitAndLoss as ProfitAndLoss$ } from "../analyticsv2/models_pb";
import { Staff } from "../company/models_pb";
import { Customer } from "../customer/models_pb";
import { Location } from "../company/models_pb";
import { Company } from "../company/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { Money } from "../proto/money/models_pb";
import { TimestampRange } from "../proto/timestamprange/models_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.analytics.Report
 */
export interface Report {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: repeated rd.analytics.Summary summaries = 2;
     */
    summaries: Summary[];
    /**
     * @generated from protobuf field: repeated string headers = 3;
     */
    headers: string[];
    /**
     * @generated from protobuf field: repeated rd.analytics.Row rows = 4;
     */
    rows: Row[];
    /**
     * @generated from protobuf field: repeated rd.analytics.Configuration configurations = 5;
     */
    configurations: Configuration[];
}
/**
 * @generated from protobuf message rd.analytics.Summary
 */
export interface Summary {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message rd.analytics.Row
 */
export interface Row {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: map<string, rd.analytics.Cell> cells_by_header = 2;
     */
    cellsByHeader: {
        [key: string]: Cell;
    };
}
/**
 * @generated from protobuf message rd.analytics.Cell
 */
export interface Cell {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
    /**
     * @generated from protobuf field: rd.analytics.Cell.Width width = 2;
     */
    width: Cell_Width;
    /**
     * @generated from protobuf field: rd.analytics.Cell.Type type = 3;
     */
    type: Cell_Type;
    /**
     * @generated from protobuf field: rd.analytics.Chip chip = 4;
     */
    chip?: Chip;
}
/**
 * @generated from protobuf enum rd.analytics.Cell.Width
 */
export enum Cell_Width {
    /**
     * @generated from protobuf enum value: WIDTH_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: WIDTH_BASE = 1;
     */
    BASE = 1,
    /**
     * @generated from protobuf enum value: WIDTH_MEDIUM = 2;
     */
    MEDIUM = 2,
    /**
     * @generated from protobuf enum value: WIDTH_LONG = 3;
     */
    LONG = 3
}
/**
 * @generated from protobuf enum rd.analytics.Cell.Type
 */
export enum Cell_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_BASE = 1;
     */
    BASE = 1,
    /**
     * @generated from protobuf enum value: TYPE_CHIP = 2;
     */
    CHIP = 2,
    /**
     * @generated from protobuf enum value: TYPE_BOLD = 3;
     */
    BOLD = 3
}
/**
 * @generated from protobuf message rd.analytics.Chip
 */
export interface Chip {
    /**
     * @generated from protobuf field: rd.analytics.Chip.Type type = 1;
     */
    type: Chip_Type;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Sale sale = 2;
     */
    sale?: Chip_Sale;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Return return = 3;
     */
    return?: Chip_Return;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Customer customer = 4;
     */
    customer?: Chip_Customer;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Product product = 5;
     */
    product?: Chip_Product;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Location location = 6;
     */
    location?: Chip_Location;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Account account = 7;
     */
    account?: Chip_Account;
    /**
     * @generated from protobuf field: rd.analytics.Chip.BillPayment bill_payment = 8;
     */
    billPayment?: Chip_BillPayment;
    /**
     * @generated from protobuf field: rd.analytics.Chip.BillRefund bill_refund = 9;
     */
    billRefund?: Chip_BillRefund;
    /**
     * @generated from protobuf field: rd.analytics.Chip.CloseOut close_out = 10;
     */
    closeOut?: Chip_CloseOut;
    /**
     * @generated from protobuf field: rd.analytics.Chip.PettyCash petty_cash = 11;
     */
    pettyCash?: Chip_PettyCash;
    /**
     * @generated from protobuf field: rd.analytics.Chip.OrderShipment order_shipment = 12;
     */
    orderShipment?: Chip_OrderShipment;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Transfer transfer = 13;
     */
    transfer?: Chip_Transfer;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Vendor vendor = 14;
     */
    vendor?: Chip_Vendor;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Count count = 15;
     */
    count?: Chip_Count;
    /**
     * @generated from protobuf field: rd.analytics.Chip.Order order = 16;
     */
    order?: Chip_Order;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Sale
 */
export interface Chip_Sale {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Return
 */
export interface Chip_Return {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_from_sale_id = 1;
     */
    returnedFromSaleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 2;
     */
    returnIdentifier: string;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Customer
 */
export interface Chip_Customer {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 1;
     */
    customerId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Product
 */
export interface Chip_Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 1;
     */
    productId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Location
 */
export interface Chip_Location {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 1;
     */
    locationId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Account
 */
export interface Chip_Account {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID account_id = 1;
     */
    accountId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.BillPayment
 */
export interface Chip_BillPayment {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 1;
     */
    billPaymentId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.BillRefund
 */
export interface Chip_BillRefund {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 1;
     */
    billPaymentId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.CloseOut
 */
export interface Chip_CloseOut {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID close_out_id = 1;
     */
    closeOutId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.PettyCash
 */
export interface Chip_PettyCash {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID petty_cash_id = 1;
     */
    pettyCashId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.OrderShipment
 */
export interface Chip_OrderShipment {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_shipment_id = 1;
     */
    orderShipmentId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Transfer
 */
export interface Chip_Transfer {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transfer_id = 1;
     */
    transferId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Vendor
 */
export interface Chip_Vendor {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Count
 */
export interface Chip_Count {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID count_id = 1;
     */
    countId?: UUID;
}
/**
 * @generated from protobuf message rd.analytics.Chip.Order
 */
export interface Chip_Order {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_id = 1;
     */
    orderId?: UUID;
}
/**
 * @generated from protobuf enum rd.analytics.Chip.Type
 */
export enum Chip_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: TYPE_CUSTOMER = 3;
     */
    CUSTOMER = 3,
    /**
     * @generated from protobuf enum value: TYPE_PRODUCT = 4;
     */
    PRODUCT = 4,
    /**
     * @generated from protobuf enum value: TYPE_LOCATION = 5;
     */
    LOCATION = 5,
    /**
     * @generated from protobuf enum value: TYPE_ACCOUNT = 6;
     */
    ACCOUNT = 6,
    /**
     * @generated from protobuf enum value: TYPE_BILL_PAYMENT = 7;
     */
    BILL_PAYMENT = 7,
    /**
     * @generated from protobuf enum value: TYPE_BILL_REFUND = 8;
     */
    BILL_REFUND = 8,
    /**
     * @generated from protobuf enum value: TYPE_CLOSE_OUT = 9;
     */
    CLOSE_OUT = 9,
    /**
     * @generated from protobuf enum value: TYPE_PETTY_CASH = 10;
     */
    PETTY_CASH = 10,
    /**
     * @generated from protobuf enum value: TYPE_ORDER_SHIPMENT = 11;
     */
    ORDER_SHIPMENT = 11,
    /**
     * @generated from protobuf enum value: TYPE_TRANSFER = 12;
     */
    TRANSFER = 12,
    /**
     * @generated from protobuf enum value: TYPE_VENDOR = 13;
     */
    VENDOR = 13,
    /**
     * @generated from protobuf enum value: TYPE_COUNT = 14;
     */
    COUNT = 14,
    /**
     * @generated from protobuf enum value: TYPE_ORDER = 15;
     */
    ORDER = 15
}
/**
 * @generated from protobuf message rd.analytics.Configuration
 */
export interface Configuration {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * ProfitAndLoss represents the key closing information for a
 * given day.
 *
 * @generated from protobuf message rd.analytics.ProfitAndLoss
 */
export interface ProfitAndLoss {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 1;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange timestamp_range = 3;
     */
    timestampRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.analytics.ProfitAndLoss.ProductCategoryRevenue category_revenues = 4;
     */
    categoryRevenues: ProfitAndLoss_ProductCategoryRevenue[];
    /**
     * @generated from protobuf field: rd.analytics.ProfitAndLoss.TransactionRevenue sales_revenue = 5;
     */
    salesRevenue?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.analytics.ProfitAndLoss.TransactionRevenue returns_revenue = 6;
     */
    returnsRevenue?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.analytics.ProfitAndLoss.TransactionRevenue bill_payments_revenue = 7;
     */
    billPaymentsRevenue?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.analytics.ProfitAndLoss.TransactionRevenue totals_by_payment_method = 8;
     */
    totalsByPaymentMethod?: ProfitAndLoss_TransactionRevenue;
    /**
     * @generated from protobuf field: rd.proto.money.Money bank_deposit = 9;
     */
    bankDeposit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money revenue = 10;
     */
    revenue?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_fees = 15;
     */
    cardFees?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal gross_profit_margin = 16;
     */
    grossProfitMargin?: Decimal; // as XX% rather than .XX
    /**
     * @generated from protobuf field: string ledger_entries_hash = 17;
     */
    ledgerEntriesHash: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money write_offs = 18;
     */
    writeOffs?: Money;
    /**
     * @generated from protobuf field: int32 count_write_offs = 19;
     */
    countWriteOffs: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money petty_cash = 20;
     */
    pettyCash?: Money;
    /**
     * @generated from protobuf field: int32 count_petty_cash = 21;
     */
    countPettyCash: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money close_outs = 22;
     */
    closeOuts?: Money;
    /**
     * @generated from protobuf field: int32 count_close_outs = 23;
     */
    countCloseOuts: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 24;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 25;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 26;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID rep_staff_id = 27;
     */
    repStaffId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money petty_and_close_outs = 28;
     */
    pettyAndCloseOuts?: Money;
    /**
     * @generated from protobuf field: int32 count_petty_and_close_outs = 29;
     */
    countPettyAndCloseOuts: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_cash_total = 30;
     */
    adjustedCashTotal?: Money;
    /**
     * @generated from protobuf field: int32 count_adjusted_cash_total = 31;
     */
    countAdjustedCashTotal: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_card_total = 32;
     */
    adjustedCardTotal?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money petty_and_close_outs_minus_fees = 33;
     */
    pettyAndCloseOutsMinusFees?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_total_revenue = 34;
     */
    adjustedTotalRevenue?: Money;
    /**
     * @generated from protobuf field: int32 count_adjusted_total_revenue = 35;
     */
    countAdjustedTotalRevenue: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money cogs = 37;
     */
    cogs?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_tax = 38;
     */
    salesTax?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money additional_fees = 39;
     */
    additionalFees?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money gross_profit = 40;
     */
    grossProfit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money discounts = 41;
     */
    discounts?: Money;
    /**
     * @generated from protobuf field: int32 count_discounts = 42;
     */
    countDiscounts: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjusted_account_total = 43;
     */
    adjustedAccountTotal?: Money;
}
/**
 * @generated from protobuf message rd.analytics.ProfitAndLoss.ProductCategoryRevenue
 */
export interface ProfitAndLoss_ProductCategoryRevenue {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money revenue = 2;
     */
    revenue?: Money;
    /**
     * @generated from protobuf field: string id_string = 3;
     */
    idString: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal gross_profit_margin = 5;
     */
    grossProfitMargin?: Decimal; // as XX% rather than .XX
    /**
     * @generated from protobuf field: rd.proto.money.Money gross_profit = 8;
     */
    grossProfit?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money cogs = 9;
     */
    cogs?: Money;
}
/**
 * @generated from protobuf message rd.analytics.ProfitAndLoss.TransactionRevenue
 */
export interface ProfitAndLoss_TransactionRevenue {
    /**
     * @generated from protobuf field: rd.proto.money.Money cash = 1;
     */
    cash?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money check = 2;
     */
    check?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card = 3;
     */
    card?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money account = 4;
     */
    account?: Money;
    /**
     * @generated from protobuf field: int32 count_cash = 5;
     */
    countCash: number;
    /**
     * @generated from protobuf field: int32 count_check = 6;
     */
    countCheck: number;
    /**
     * @generated from protobuf field: int32 count_card = 7;
     */
    countCard: number;
    /**
     * @generated from protobuf field: int32 count_account = 8;
     */
    countAccount: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 9;
     */
    total?: Money;
    /**
     * @generated from protobuf field: int32 count_total = 10;
     */
    countTotal: number;
}
/**
 * ProfitAndLossPDFData represents the data required to fill the
 * html template for a profit and loss statement
 *
 * @generated from protobuf message rd.analytics.ProfitAndLossPDFData
 */
export interface ProfitAndLossPDFData {
    /**
     * @generated from protobuf field: rd.company.Company company = 1;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.company.Location location = 2;
     */
    location?: Location;
    /**
     * @generated from protobuf field: bool is_product_categories_enabled = 5;
     */
    isProductCategoriesEnabled: boolean;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 6;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.company.Staff rep_staff = 7;
     */
    repStaff?: Staff;
    /**
     * @generated from protobuf field: rd.analyticsv2.ProfitAndLoss profit_and_loss = 8;
     */
    profitAndLoss?: ProfitAndLoss$;
}
/**
 * InventoryValuation represents the value (i.e. cost * quantity on hand)
 * of a location's inventory at a particular moment in time.
 *
 * @generated from protobuf message rd.analytics.InventoryValuation
 */
export interface InventoryValuation {
    /**
     * @generated from protobuf field: rd.proto.money.Money valuation = 2;
     */
    valuation?: Money;
    /**
     * @generated from protobuf field: repeated rd.analytics.InventoryValuation.ProductCategoryValuation category_valuations = 3;
     */
    categoryValuations: InventoryValuation_ProductCategoryValuation[];
}
/**
 * @generated from protobuf message rd.analytics.InventoryValuation.ProductCategoryValuation
 */
export interface InventoryValuation_ProductCategoryValuation {
    /**
     * @generated from protobuf field: rd.proto.money.Money valuation = 2;
     */
    valuation?: Money;
    /**
     * @generated from protobuf field: string id_string = 3;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
}
/**
 * CustomerAging represents a report of customers with outstanding balances
 *
 * @generated from protobuf message rd.analytics.CustomerAging
 */
export interface CustomerAging {
    /**
     * @generated from protobuf field: repeated rd.analytics.CustomerAging.Customer customers = 1;
     */
    customers: CustomerAging_Customer[];
    /**
     * @generated from protobuf field: rd.analytics.CustomerAging.Summary summary = 2;
     */
    summary?: CustomerAging_Summary;
}
/**
 * @generated from protobuf message rd.analytics.CustomerAging.Customer
 */
export interface CustomerAging_Customer {
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.customer.Contacts contacts = 2;
     */
    contacts?: Contacts;
    /**
     * @generated from protobuf field: rd.txn.CustomerBalance balance = 3;
     */
    balance?: CustomerBalance;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_balance = 4;
     */
    totalBalance?: Money;
}
/**
 * @generated from protobuf message rd.analytics.CustomerAging.Summary
 */
export interface CustomerAging_Summary {
    /**
     * @generated from protobuf field: rd.proto.money.Money credits = 1;
     */
    credits?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money current = 2;
     */
    current?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money late_1_to_30 = 3;
     */
    late1To30?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money late_31_to_60 = 4;
     */
    late31To60?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money late_61_plus = 5;
     */
    late61Plus?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charges = 6;
     */
    financingCharges?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_balance = 7;
     */
    totalBalance?: Money;
}
/**
 * CustomerAgingPDFData represents the data required to fill the
 * html template for a customer aging report
 *
 * @generated from protobuf message rd.analytics.CustomerAgingPDFData
 */
export interface CustomerAgingPDFData {
    /**
     * @generated from protobuf field: rd.company.Company company = 1;
     */
    company?: Company;
    /**
     * @generated from protobuf field: rd.analytics.CustomerAging customer_aging = 2;
     */
    customerAging?: CustomerAging;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp at = 3;
     */
    at?: Timestamp;
}
/**
 * @generated from protobuf message rd.analytics.TaxEntries
 */
export interface TaxEntries {
    /**
     * @generated from protobuf field: repeated rd.analytics.TaxEntries.LineItem line_items = 2;
     */
    lineItems: TaxEntries_LineItem[];
    /**
     * @generated from protobuf field: rd.analytics.TaxEntries.Totals totals = 3;
     */
    totals?: TaxEntries_Totals;
}
/**
 * @generated from protobuf message rd.analytics.TaxEntries.LineItem
 */
export interface TaxEntries_LineItem {
    /**
     * @generated from protobuf field: rd.proto.money.Money taxable_revenue = 1;
     */
    taxableRevenue?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money non_taxable_revenue = 2;
     */
    nonTaxableRevenue?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money tax_owed = 3;
     */
    taxOwed?: Money;
    /**
     * @generated from protobuf field: string tax_rate = 4;
     */
    taxRate: string;
}
/**
 * @generated from protobuf message rd.analytics.TaxEntries.Totals
 */
export interface TaxEntries_Totals {
    /**
     * @generated from protobuf field: rd.proto.money.Money taxable_revenue = 1;
     */
    taxableRevenue?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money non_taxable_revenue = 2;
     */
    nonTaxableRevenue?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money tax_owed = 3;
     */
    taxOwed?: Money;
}
/**
 * PriceEdits is the summary of all price edit line items and totals for a
 * price edits report.
 *
 * @generated from protobuf message rd.analytics.PriceEdits
 */
export interface PriceEdits {
    /**
     * @generated from protobuf field: repeated rd.analytics.PriceEdits.PriceEditLineItem price_edits = 1;
     */
    priceEdits: PriceEdits_PriceEditLineItem[];
    /**
     * @generated from protobuf field: rd.analytics.PriceEdits.Totals totals = 2;
     */
    totals?: PriceEdits_Totals;
}
/**
 * PriceEditLineItem is a single price edit for a product on a sale and
 * relevant metadata (expected price, impact, and margin).
 *
 * @generated from protobuf message rd.analytics.PriceEdits.PriceEditLineItem
 */
export interface PriceEdits_PriceEditLineItem {
    /**
     * @generated from protobuf field: rd.txn.SaleProduct sale_product = 1;
     */
    saleProduct?: SaleProduct;
    /**
     * @generated from protobuf field: rd.txn.Sale sale = 2;
     */
    sale?: Sale;
    /**
     * @generated from protobuf field: rd.product.Product product = 3;
     */
    product?: Product;
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 4;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: rd.proto.money.Money expected_price = 5;
     */
    expectedPrice?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money impact = 6;
     */
    impact?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal margin = 7;
     */
    margin?: Decimal;
}
/**
 * Totals are the number of price edits and the total monetary impact for
 * a price edits report.
 *
 * @generated from protobuf message rd.analytics.PriceEdits.Totals
 */
export interface PriceEdits_Totals {
    /**
     * @generated from protobuf field: int32 edits = 1;
     */
    edits: number;
    /**
     * @generated from protobuf field: rd.proto.money.Money impact = 2;
     */
    impact?: Money;
}
/**
 * NewAccountsOpened is the summary of all new customer accounts opened for a
 * new accounts opened report.
 *
 * @generated from protobuf message rd.analytics.NewAccountsOpened
 */
export interface NewAccountsOpened {
    /**
     * @generated from protobuf field: repeated rd.analytics.NewAccountsOpened.LineItem new_accounts_opened = 1;
     */
    newAccountsOpened: NewAccountsOpened_LineItem[];
    /**
     * @generated from protobuf field: rd.analytics.NewAccountsOpened.Totals totals = 2;
     */
    totals?: NewAccountsOpened_Totals;
}
/**
 * LineItem is a single account opened with relevant metadata (pricing tier
 * name, staff member who opened the account, sales data for the customer).
 *
 * @generated from protobuf message rd.analytics.NewAccountsOpened.LineItem
 */
export interface NewAccountsOpened_LineItem {
    /**
     * @generated from protobuf field: rd.customer.Customer customer = 1;
     */
    customer?: Customer;
    /**
     * @generated from protobuf field: string rep_name = 2;
     */
    repName: string;
    /**
     * @generated from protobuf field: string tier_name = 3;
     */
    tierName: string;
    /**
     * @generated from protobuf field: string phone = 4;
     */
    phone: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money sales_last_12_mo = 5;
     */
    salesLast12Mo?: Money;
}
/**
 * Totals are the number of accounts that have been opened in the time frame.
 *
 * @generated from protobuf message rd.analytics.NewAccountsOpened.Totals
 */
export interface NewAccountsOpened_Totals {
    /**
     * @generated from protobuf field: int32 accounts_opened = 1;
     */
    accountsOpened: number;
}
/**
 * @generated from protobuf message rd.analytics.SalesDetail
 */
export interface SalesDetail {
}
/**
 * @generated from protobuf message rd.analytics.SalesDetail.Totals
 */
export interface SalesDetail_Totals {
}
/**
 * @generated from protobuf message rd.analytics.SalesDetail.LineItem
 */
export interface SalesDetail_LineItem {
}
/**
 * CustomerAgingSortBy represents the different ways the customer aging
 * report can be sorted
 *
 * @generated from protobuf enum rd.analytics.CustomerAgingSortBy
 */
export enum CustomerAgingSortBy {
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_TOTAL_BALANCE = 1;
     */
    TOTAL_BALANCE = 1,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_CURRENT_BALANCE = 2;
     */
    CURRENT_BALANCE = 2,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_LATE_1_TO_30_BALANCE = 3;
     */
    LATE_1_TO_30_BALANCE = 3,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_LATE_31_TO_60_BALANCE = 4;
     */
    LATE_31_TO_60_BALANCE = 4,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_LATE_61_PLUS_BALANCE = 5;
     */
    LATE_61_PLUS_BALANCE = 5,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_CREDITS_BALANCE = 6;
     */
    CREDITS_BALANCE = 6,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_FINANCING_CHARGES_BALANCE = 7;
     */
    FINANCING_CHARGES_BALANCE = 7,
    /**
     * @generated from protobuf enum value: CUSTOMER_AGING_SORT_BY_NAME = 8;
     */
    NAME = 8
}
/**
 * SortOrder represents the different ways a sort can be ordered, i.e.
 * ascending or descending
 *
 * @generated from protobuf enum rd.analytics.SortOrder
 */
export enum SortOrder {
    /**
     * @generated from protobuf enum value: SORT_ORDER_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: SORT_ORDER_ASC = 1;
     */
    ASC = 1,
    /**
     * @generated from protobuf enum value: SORT_ORDER_DESC = 2;
     */
    DESC = 2
}
/**
 * @generated from protobuf enum rd.analytics.PriceEditsSortBy
 */
export enum PriceEditsSortBy {
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_TIME_OF_SALE = 1;
     */
    TIME_OF_SALE = 1,
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_IMPACT = 2;
     */
    IMPACT = 2,
    /**
     * @generated from protobuf enum value: PRICE_EDITS_SORT_BY_MARGIN = 3;
     */
    MARGIN = 3
}
/**
 * @generated from protobuf enum rd.analytics.NewAccountsOpenedSortBy
 */
export enum NewAccountsOpenedSortBy {
    /**
     * @generated from protobuf enum value: NEW_ACCOUNTS_OPENED_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: NEW_ACCOUNTS_OPENED_SORT_BY_TIME_OPENED = 1;
     */
    TIME_OPENED = 1,
    /**
     * @generated from protobuf enum value: NEW_ACCOUNTS_OPENED_SORT_BY_CREDIT_LIMIT = 2;
     */
    CREDIT_LIMIT = 2
}
/**
 * @generated from protobuf enum rd.analytics.SalesDetailSortBy
 */
export enum SalesDetailSortBy {
    /**
     * @generated from protobuf enum value: SALES_DETAIL_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0
}
/**
 * @generated from protobuf enum rd.analytics.SalesByClerkSortBy
 */
export enum SalesByClerkSortBy {
    /**
     * @generated from protobuf enum value: SALES_BY_CLERK_SORT_BY_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: SALES_BY_CLERK_SORT_BY_STAFF = 1;
     */
    STAFF = 1,
    /**
     * @generated from protobuf enum value: SALES_BY_CLERK_SORT_BY_SALES_COUNT = 2;
     */
    SALES_COUNT = 2,
    /**
     * @generated from protobuf enum value: SALES_BY_CLERK_SORT_BY_SALES_TOTAL = 3;
     */
    SALES_TOTAL = 3,
    /**
     * @generated from protobuf enum value: SALES_BY_CLERK_SORT_BY_RETURNS_COUNT = 4;
     */
    RETURNS_COUNT = 4,
    /**
     * @generated from protobuf enum value: SALES_BY_CLERK_SORT_BY_RETURNS_TOTAL = 5;
     */
    RETURNS_TOTAL = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class Report$Type extends MessageType<Report> {
    constructor() {
        super("rd.analytics.Report", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "summaries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Summary },
            { no: 3, name: "headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "rows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Row },
            { no: 5, name: "configurations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Configuration }
        ]);
    }
    create(value?: PartialMessage<Report>): Report {
        const message = { title: "", summaries: [], headers: [], rows: [], configurations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Report>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Report): Report {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* repeated rd.analytics.Summary summaries */ 2:
                    message.summaries.push(Summary.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string headers */ 3:
                    message.headers.push(reader.string());
                    break;
                case /* repeated rd.analytics.Row rows */ 4:
                    message.rows.push(Row.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated rd.analytics.Configuration configurations */ 5:
                    message.configurations.push(Configuration.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Report, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* repeated rd.analytics.Summary summaries = 2; */
        for (let i = 0; i < message.summaries.length; i++)
            Summary.internalBinaryWrite(message.summaries[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string headers = 3; */
        for (let i = 0; i < message.headers.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.headers[i]);
        /* repeated rd.analytics.Row rows = 4; */
        for (let i = 0; i < message.rows.length; i++)
            Row.internalBinaryWrite(message.rows[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analytics.Configuration configurations = 5; */
        for (let i = 0; i < message.configurations.length; i++)
            Configuration.internalBinaryWrite(message.configurations[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Report
 */
export const Report = new Report$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Summary$Type extends MessageType<Summary> {
    constructor() {
        super("rd.analytics.Summary", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Summary>): Summary {
        const message = { label: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Summary>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Summary): Summary {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Summary, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Summary
 */
export const Summary = new Summary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Row$Type extends MessageType<Row> {
    constructor() {
        super("rd.analytics.Row", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cells_by_header", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Cell } }
        ]);
    }
    create(value?: PartialMessage<Row>): Row {
        const message = { key: "", cellsByHeader: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Row>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Row): Row {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* map<string, rd.analytics.Cell> cells_by_header */ 2:
                    this.binaryReadMap2(message.cellsByHeader, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: Row["cellsByHeader"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Row["cellsByHeader"] | undefined, val: Row["cellsByHeader"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Cell.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.analytics.Row.cells_by_header");
            }
        }
        map[key ?? ""] = val ?? Cell.create();
    }
    internalBinaryWrite(message: Row, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* map<string, rd.analytics.Cell> cells_by_header = 2; */
        for (let k of Object.keys(message.cellsByHeader)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Cell.internalBinaryWrite(message.cellsByHeader[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Row
 */
export const Row = new Row$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Cell$Type extends MessageType<Cell> {
    constructor() {
        super("rd.analytics.Cell", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "width", kind: "enum", T: () => ["rd.analytics.Cell.Width", Cell_Width, "WIDTH_"] },
            { no: 3, name: "type", kind: "enum", T: () => ["rd.analytics.Cell.Type", Cell_Type, "TYPE_"] },
            { no: 4, name: "chip", kind: "message", T: () => Chip }
        ]);
    }
    create(value?: PartialMessage<Cell>): Cell {
        const message = { value: "", width: 0, type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Cell>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Cell): Cell {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                case /* rd.analytics.Cell.Width width */ 2:
                    message.width = reader.int32();
                    break;
                case /* rd.analytics.Cell.Type type */ 3:
                    message.type = reader.int32();
                    break;
                case /* rd.analytics.Chip chip */ 4:
                    message.chip = Chip.internalBinaryRead(reader, reader.uint32(), options, message.chip);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Cell, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        /* rd.analytics.Cell.Width width = 2; */
        if (message.width !== 0)
            writer.tag(2, WireType.Varint).int32(message.width);
        /* rd.analytics.Cell.Type type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* rd.analytics.Chip chip = 4; */
        if (message.chip)
            Chip.internalBinaryWrite(message.chip, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Cell
 */
export const Cell = new Cell$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip$Type extends MessageType<Chip> {
    constructor() {
        super("rd.analytics.Chip", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.analytics.Chip.Type", Chip_Type, "TYPE_"] },
            { no: 2, name: "sale", kind: "message", T: () => Chip_Sale },
            { no: 3, name: "return", kind: "message", T: () => Chip_Return },
            { no: 4, name: "customer", kind: "message", T: () => Chip_Customer },
            { no: 5, name: "product", kind: "message", T: () => Chip_Product },
            { no: 6, name: "location", kind: "message", T: () => Chip_Location },
            { no: 7, name: "account", kind: "message", T: () => Chip_Account },
            { no: 8, name: "bill_payment", kind: "message", T: () => Chip_BillPayment },
            { no: 9, name: "bill_refund", kind: "message", T: () => Chip_BillRefund },
            { no: 10, name: "close_out", kind: "message", T: () => Chip_CloseOut },
            { no: 11, name: "petty_cash", kind: "message", T: () => Chip_PettyCash },
            { no: 12, name: "order_shipment", kind: "message", T: () => Chip_OrderShipment },
            { no: 13, name: "transfer", kind: "message", T: () => Chip_Transfer },
            { no: 14, name: "vendor", kind: "message", T: () => Chip_Vendor },
            { no: 15, name: "count", kind: "message", T: () => Chip_Count },
            { no: 16, name: "order", kind: "message", T: () => Chip_Order }
        ]);
    }
    create(value?: PartialMessage<Chip>): Chip {
        const message = { type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip): Chip {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analytics.Chip.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* rd.analytics.Chip.Sale sale */ 2:
                    message.sale = Chip_Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.analytics.Chip.Return return */ 3:
                    message.return = Chip_Return.internalBinaryRead(reader, reader.uint32(), options, message.return);
                    break;
                case /* rd.analytics.Chip.Customer customer */ 4:
                    message.customer = Chip_Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.analytics.Chip.Product product */ 5:
                    message.product = Chip_Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* rd.analytics.Chip.Location location */ 6:
                    message.location = Chip_Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* rd.analytics.Chip.Account account */ 7:
                    message.account = Chip_Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* rd.analytics.Chip.BillPayment bill_payment */ 8:
                    message.billPayment = Chip_BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.analytics.Chip.BillRefund bill_refund */ 9:
                    message.billRefund = Chip_BillRefund.internalBinaryRead(reader, reader.uint32(), options, message.billRefund);
                    break;
                case /* rd.analytics.Chip.CloseOut close_out */ 10:
                    message.closeOut = Chip_CloseOut.internalBinaryRead(reader, reader.uint32(), options, message.closeOut);
                    break;
                case /* rd.analytics.Chip.PettyCash petty_cash */ 11:
                    message.pettyCash = Chip_PettyCash.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* rd.analytics.Chip.OrderShipment order_shipment */ 12:
                    message.orderShipment = Chip_OrderShipment.internalBinaryRead(reader, reader.uint32(), options, message.orderShipment);
                    break;
                case /* rd.analytics.Chip.Transfer transfer */ 13:
                    message.transfer = Chip_Transfer.internalBinaryRead(reader, reader.uint32(), options, message.transfer);
                    break;
                case /* rd.analytics.Chip.Vendor vendor */ 14:
                    message.vendor = Chip_Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                case /* rd.analytics.Chip.Count count */ 15:
                    message.count = Chip_Count.internalBinaryRead(reader, reader.uint32(), options, message.count);
                    break;
                case /* rd.analytics.Chip.Order order */ 16:
                    message.order = Chip_Order.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analytics.Chip.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* rd.analytics.Chip.Sale sale = 2; */
        if (message.sale)
            Chip_Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Return return = 3; */
        if (message.return)
            Chip_Return.internalBinaryWrite(message.return, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Customer customer = 4; */
        if (message.customer)
            Chip_Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Product product = 5; */
        if (message.product)
            Chip_Product.internalBinaryWrite(message.product, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Location location = 6; */
        if (message.location)
            Chip_Location.internalBinaryWrite(message.location, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Account account = 7; */
        if (message.account)
            Chip_Account.internalBinaryWrite(message.account, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.BillPayment bill_payment = 8; */
        if (message.billPayment)
            Chip_BillPayment.internalBinaryWrite(message.billPayment, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.BillRefund bill_refund = 9; */
        if (message.billRefund)
            Chip_BillRefund.internalBinaryWrite(message.billRefund, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.CloseOut close_out = 10; */
        if (message.closeOut)
            Chip_CloseOut.internalBinaryWrite(message.closeOut, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.PettyCash petty_cash = 11; */
        if (message.pettyCash)
            Chip_PettyCash.internalBinaryWrite(message.pettyCash, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.OrderShipment order_shipment = 12; */
        if (message.orderShipment)
            Chip_OrderShipment.internalBinaryWrite(message.orderShipment, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Transfer transfer = 13; */
        if (message.transfer)
            Chip_Transfer.internalBinaryWrite(message.transfer, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Vendor vendor = 14; */
        if (message.vendor)
            Chip_Vendor.internalBinaryWrite(message.vendor, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Count count = 15; */
        if (message.count)
            Chip_Count.internalBinaryWrite(message.count, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.Chip.Order order = 16; */
        if (message.order)
            Chip_Order.internalBinaryWrite(message.order, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip
 */
export const Chip = new Chip$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Sale$Type extends MessageType<Chip_Sale> {
    constructor() {
        super("rd.analytics.Chip.Sale", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Sale>): Chip_Sale {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Sale>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Sale): Chip_Sale {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Sale, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Sale
 */
export const Chip_Sale = new Chip_Sale$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Return$Type extends MessageType<Chip_Return> {
    constructor() {
        super("rd.analytics.Chip.Return", [
            { no: 1, name: "returned_from_sale_id", kind: "message", T: () => UUID },
            { no: 2, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Chip_Return>): Chip_Return {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Return>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Return): Chip_Return {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID returned_from_sale_id */ 1:
                    message.returnedFromSaleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedFromSaleId);
                    break;
                case /* string return_identifier */ 2:
                    message.returnIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Return, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID returned_from_sale_id = 1; */
        if (message.returnedFromSaleId)
            UUID.internalBinaryWrite(message.returnedFromSaleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 2; */
        if (message.returnIdentifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.returnIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Return
 */
export const Chip_Return = new Chip_Return$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Customer$Type extends MessageType<Chip_Customer> {
    constructor() {
        super("rd.analytics.Chip.Customer", [
            { no: 1, name: "customer_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Customer>): Chip_Customer {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Customer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Customer): Chip_Customer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID customer_id */ 1:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Customer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID customer_id = 1; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Customer
 */
export const Chip_Customer = new Chip_Customer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Product$Type extends MessageType<Chip_Product> {
    constructor() {
        super("rd.analytics.Chip.Product", [
            { no: 1, name: "product_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Product>): Chip_Product {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Product): Chip_Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID product_id */ 1:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID product_id = 1; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Product
 */
export const Chip_Product = new Chip_Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Location$Type extends MessageType<Chip_Location> {
    constructor() {
        super("rd.analytics.Chip.Location", [
            { no: 1, name: "location_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Location>): Chip_Location {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Location): Chip_Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID location_id */ 1:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID location_id = 1; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Location
 */
export const Chip_Location = new Chip_Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Account$Type extends MessageType<Chip_Account> {
    constructor() {
        super("rd.analytics.Chip.Account", [
            { no: 1, name: "account_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Account>): Chip_Account {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Account): Chip_Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID account_id */ 1:
                    message.accountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.accountId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID account_id = 1; */
        if (message.accountId)
            UUID.internalBinaryWrite(message.accountId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Account
 */
export const Chip_Account = new Chip_Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_BillPayment$Type extends MessageType<Chip_BillPayment> {
    constructor() {
        super("rd.analytics.Chip.BillPayment", [
            { no: 1, name: "bill_payment_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_BillPayment>): Chip_BillPayment {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_BillPayment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_BillPayment): Chip_BillPayment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID bill_payment_id */ 1:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_BillPayment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID bill_payment_id = 1; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.BillPayment
 */
export const Chip_BillPayment = new Chip_BillPayment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_BillRefund$Type extends MessageType<Chip_BillRefund> {
    constructor() {
        super("rd.analytics.Chip.BillRefund", [
            { no: 1, name: "bill_payment_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_BillRefund>): Chip_BillRefund {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_BillRefund>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_BillRefund): Chip_BillRefund {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID bill_payment_id */ 1:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_BillRefund, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID bill_payment_id = 1; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.BillRefund
 */
export const Chip_BillRefund = new Chip_BillRefund$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_CloseOut$Type extends MessageType<Chip_CloseOut> {
    constructor() {
        super("rd.analytics.Chip.CloseOut", [
            { no: 1, name: "close_out_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_CloseOut>): Chip_CloseOut {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_CloseOut>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_CloseOut): Chip_CloseOut {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID close_out_id */ 1:
                    message.closeOutId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.closeOutId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_CloseOut, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID close_out_id = 1; */
        if (message.closeOutId)
            UUID.internalBinaryWrite(message.closeOutId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.CloseOut
 */
export const Chip_CloseOut = new Chip_CloseOut$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_PettyCash$Type extends MessageType<Chip_PettyCash> {
    constructor() {
        super("rd.analytics.Chip.PettyCash", [
            { no: 1, name: "petty_cash_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_PettyCash>): Chip_PettyCash {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_PettyCash>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_PettyCash): Chip_PettyCash {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID petty_cash_id */ 1:
                    message.pettyCashId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.pettyCashId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_PettyCash, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID petty_cash_id = 1; */
        if (message.pettyCashId)
            UUID.internalBinaryWrite(message.pettyCashId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.PettyCash
 */
export const Chip_PettyCash = new Chip_PettyCash$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_OrderShipment$Type extends MessageType<Chip_OrderShipment> {
    constructor() {
        super("rd.analytics.Chip.OrderShipment", [
            { no: 1, name: "order_shipment_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_OrderShipment>): Chip_OrderShipment {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_OrderShipment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_OrderShipment): Chip_OrderShipment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID order_shipment_id */ 1:
                    message.orderShipmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderShipmentId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_OrderShipment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID order_shipment_id = 1; */
        if (message.orderShipmentId)
            UUID.internalBinaryWrite(message.orderShipmentId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.OrderShipment
 */
export const Chip_OrderShipment = new Chip_OrderShipment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Transfer$Type extends MessageType<Chip_Transfer> {
    constructor() {
        super("rd.analytics.Chip.Transfer", [
            { no: 1, name: "transfer_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Transfer>): Chip_Transfer {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Transfer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Transfer): Chip_Transfer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID transfer_id */ 1:
                    message.transferId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transferId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Transfer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID transfer_id = 1; */
        if (message.transferId)
            UUID.internalBinaryWrite(message.transferId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Transfer
 */
export const Chip_Transfer = new Chip_Transfer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Vendor$Type extends MessageType<Chip_Vendor> {
    constructor() {
        super("rd.analytics.Chip.Vendor", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Vendor>): Chip_Vendor {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Vendor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Vendor): Chip_Vendor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Vendor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Vendor
 */
export const Chip_Vendor = new Chip_Vendor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Count$Type extends MessageType<Chip_Count> {
    constructor() {
        super("rd.analytics.Chip.Count", [
            { no: 1, name: "count_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Count>): Chip_Count {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Count>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Count): Chip_Count {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID count_id */ 1:
                    message.countId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.countId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Count, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID count_id = 1; */
        if (message.countId)
            UUID.internalBinaryWrite(message.countId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Count
 */
export const Chip_Count = new Chip_Count$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Chip_Order$Type extends MessageType<Chip_Order> {
    constructor() {
        super("rd.analytics.Chip.Order", [
            { no: 1, name: "order_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Chip_Order>): Chip_Order {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Chip_Order>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Chip_Order): Chip_Order {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID order_id */ 1:
                    message.orderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Chip_Order, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID order_id = 1; */
        if (message.orderId)
            UUID.internalBinaryWrite(message.orderId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Chip.Order
 */
export const Chip_Order = new Chip_Order$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Configuration$Type extends MessageType<Configuration> {
    constructor() {
        super("rd.analytics.Configuration", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Configuration>): Configuration {
        const message = { label: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Configuration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Configuration): Configuration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Configuration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.Configuration
 */
export const Configuration = new Configuration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfitAndLoss$Type extends MessageType<ProfitAndLoss> {
    constructor() {
        super("rd.analytics.ProfitAndLoss", [
            { no: 1, name: "company_id", kind: "message", T: () => UUID },
            { no: 2, name: "location_id", kind: "message", T: () => UUID },
            { no: 3, name: "timestamp_range", kind: "message", T: () => TimestampRange },
            { no: 4, name: "category_revenues", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProfitAndLoss_ProductCategoryRevenue },
            { no: 5, name: "sales_revenue", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 6, name: "returns_revenue", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 7, name: "bill_payments_revenue", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 8, name: "totals_by_payment_method", kind: "message", T: () => ProfitAndLoss_TransactionRevenue },
            { no: 9, name: "bank_deposit", kind: "message", T: () => Money },
            { no: 10, name: "revenue", kind: "message", T: () => Money },
            { no: 15, name: "card_fees", kind: "message", T: () => Money },
            { no: 16, name: "gross_profit_margin", kind: "message", T: () => Decimal },
            { no: 17, name: "ledger_entries_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "write_offs", kind: "message", T: () => Money },
            { no: 19, name: "count_write_offs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "petty_cash", kind: "message", T: () => Money },
            { no: 21, name: "count_petty_cash", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "close_outs", kind: "message", T: () => Money },
            { no: 23, name: "count_close_outs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "id", kind: "message", T: () => UUID },
            { no: 25, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 26, name: "customer_id", kind: "message", T: () => UUID },
            { no: 27, name: "rep_staff_id", kind: "message", T: () => UUID },
            { no: 28, name: "petty_and_close_outs", kind: "message", T: () => Money },
            { no: 29, name: "count_petty_and_close_outs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 30, name: "adjusted_cash_total", kind: "message", T: () => Money },
            { no: 31, name: "count_adjusted_cash_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 32, name: "adjusted_card_total", kind: "message", T: () => Money },
            { no: 33, name: "petty_and_close_outs_minus_fees", kind: "message", T: () => Money },
            { no: 34, name: "adjusted_total_revenue", kind: "message", T: () => Money },
            { no: 35, name: "count_adjusted_total_revenue", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 37, name: "cogs", kind: "message", T: () => Money },
            { no: 38, name: "sales_tax", kind: "message", T: () => Money },
            { no: 39, name: "additional_fees", kind: "message", T: () => Money },
            { no: 40, name: "gross_profit", kind: "message", T: () => Money },
            { no: 41, name: "discounts", kind: "message", T: () => Money },
            { no: 42, name: "count_discounts", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 43, name: "adjusted_account_total", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<ProfitAndLoss>): ProfitAndLoss {
        const message = { categoryRevenues: [], ledgerEntriesHash: "", countWriteOffs: 0, countPettyCash: 0, countCloseOuts: 0, countPettyAndCloseOuts: 0, countAdjustedCashTotal: 0, countAdjustedTotalRevenue: 0, countDiscounts: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfitAndLoss>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfitAndLoss): ProfitAndLoss {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 1:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.timestamprange.TimestampRange timestamp_range */ 3:
                    message.timestampRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.timestampRange);
                    break;
                case /* repeated rd.analytics.ProfitAndLoss.ProductCategoryRevenue category_revenues */ 4:
                    message.categoryRevenues.push(ProfitAndLoss_ProductCategoryRevenue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analytics.ProfitAndLoss.TransactionRevenue sales_revenue */ 5:
                    message.salesRevenue = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.salesRevenue);
                    break;
                case /* rd.analytics.ProfitAndLoss.TransactionRevenue returns_revenue */ 6:
                    message.returnsRevenue = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.returnsRevenue);
                    break;
                case /* rd.analytics.ProfitAndLoss.TransactionRevenue bill_payments_revenue */ 7:
                    message.billPaymentsRevenue = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentsRevenue);
                    break;
                case /* rd.analytics.ProfitAndLoss.TransactionRevenue totals_by_payment_method */ 8:
                    message.totalsByPaymentMethod = ProfitAndLoss_TransactionRevenue.internalBinaryRead(reader, reader.uint32(), options, message.totalsByPaymentMethod);
                    break;
                case /* rd.proto.money.Money bank_deposit */ 9:
                    message.bankDeposit = Money.internalBinaryRead(reader, reader.uint32(), options, message.bankDeposit);
                    break;
                case /* rd.proto.money.Money revenue */ 10:
                    message.revenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.revenue);
                    break;
                case /* rd.proto.money.Money card_fees */ 15:
                    message.cardFees = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardFees);
                    break;
                case /* rd.proto.decimal.Decimal gross_profit_margin */ 16:
                    message.grossProfitMargin = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.grossProfitMargin);
                    break;
                case /* string ledger_entries_hash */ 17:
                    message.ledgerEntriesHash = reader.string();
                    break;
                case /* rd.proto.money.Money write_offs */ 18:
                    message.writeOffs = Money.internalBinaryRead(reader, reader.uint32(), options, message.writeOffs);
                    break;
                case /* int32 count_write_offs */ 19:
                    message.countWriteOffs = reader.int32();
                    break;
                case /* rd.proto.money.Money petty_cash */ 20:
                    message.pettyCash = Money.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* int32 count_petty_cash */ 21:
                    message.countPettyCash = reader.int32();
                    break;
                case /* rd.proto.money.Money close_outs */ 22:
                    message.closeOuts = Money.internalBinaryRead(reader, reader.uint32(), options, message.closeOuts);
                    break;
                case /* int32 count_close_outs */ 23:
                    message.countCloseOuts = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID id */ 24:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 25:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 26:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID rep_staff_id */ 27:
                    message.repStaffId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.repStaffId);
                    break;
                case /* rd.proto.money.Money petty_and_close_outs */ 28:
                    message.pettyAndCloseOuts = Money.internalBinaryRead(reader, reader.uint32(), options, message.pettyAndCloseOuts);
                    break;
                case /* int32 count_petty_and_close_outs */ 29:
                    message.countPettyAndCloseOuts = reader.int32();
                    break;
                case /* rd.proto.money.Money adjusted_cash_total */ 30:
                    message.adjustedCashTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedCashTotal);
                    break;
                case /* int32 count_adjusted_cash_total */ 31:
                    message.countAdjustedCashTotal = reader.int32();
                    break;
                case /* rd.proto.money.Money adjusted_card_total */ 32:
                    message.adjustedCardTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedCardTotal);
                    break;
                case /* rd.proto.money.Money petty_and_close_outs_minus_fees */ 33:
                    message.pettyAndCloseOutsMinusFees = Money.internalBinaryRead(reader, reader.uint32(), options, message.pettyAndCloseOutsMinusFees);
                    break;
                case /* rd.proto.money.Money adjusted_total_revenue */ 34:
                    message.adjustedTotalRevenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedTotalRevenue);
                    break;
                case /* int32 count_adjusted_total_revenue */ 35:
                    message.countAdjustedTotalRevenue = reader.int32();
                    break;
                case /* rd.proto.money.Money cogs */ 37:
                    message.cogs = Money.internalBinaryRead(reader, reader.uint32(), options, message.cogs);
                    break;
                case /* rd.proto.money.Money sales_tax */ 38:
                    message.salesTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesTax);
                    break;
                case /* rd.proto.money.Money additional_fees */ 39:
                    message.additionalFees = Money.internalBinaryRead(reader, reader.uint32(), options, message.additionalFees);
                    break;
                case /* rd.proto.money.Money gross_profit */ 40:
                    message.grossProfit = Money.internalBinaryRead(reader, reader.uint32(), options, message.grossProfit);
                    break;
                case /* rd.proto.money.Money discounts */ 41:
                    message.discounts = Money.internalBinaryRead(reader, reader.uint32(), options, message.discounts);
                    break;
                case /* int32 count_discounts */ 42:
                    message.countDiscounts = reader.int32();
                    break;
                case /* rd.proto.money.Money adjusted_account_total */ 43:
                    message.adjustedAccountTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustedAccountTotal);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfitAndLoss, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 1; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.timestamprange.TimestampRange timestamp_range = 3; */
        if (message.timestampRange)
            TimestampRange.internalBinaryWrite(message.timestampRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analytics.ProfitAndLoss.ProductCategoryRevenue category_revenues = 4; */
        for (let i = 0; i < message.categoryRevenues.length; i++)
            ProfitAndLoss_ProductCategoryRevenue.internalBinaryWrite(message.categoryRevenues[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.ProfitAndLoss.TransactionRevenue sales_revenue = 5; */
        if (message.salesRevenue)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.salesRevenue, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.ProfitAndLoss.TransactionRevenue returns_revenue = 6; */
        if (message.returnsRevenue)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.returnsRevenue, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.ProfitAndLoss.TransactionRevenue bill_payments_revenue = 7; */
        if (message.billPaymentsRevenue)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.billPaymentsRevenue, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.ProfitAndLoss.TransactionRevenue totals_by_payment_method = 8; */
        if (message.totalsByPaymentMethod)
            ProfitAndLoss_TransactionRevenue.internalBinaryWrite(message.totalsByPaymentMethod, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money bank_deposit = 9; */
        if (message.bankDeposit)
            Money.internalBinaryWrite(message.bankDeposit, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money revenue = 10; */
        if (message.revenue)
            Money.internalBinaryWrite(message.revenue, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_fees = 15; */
        if (message.cardFees)
            Money.internalBinaryWrite(message.cardFees, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal gross_profit_margin = 16; */
        if (message.grossProfitMargin)
            Decimal.internalBinaryWrite(message.grossProfitMargin, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* string ledger_entries_hash = 17; */
        if (message.ledgerEntriesHash !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.ledgerEntriesHash);
        /* rd.proto.money.Money write_offs = 18; */
        if (message.writeOffs)
            Money.internalBinaryWrite(message.writeOffs, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_write_offs = 19; */
        if (message.countWriteOffs !== 0)
            writer.tag(19, WireType.Varint).int32(message.countWriteOffs);
        /* rd.proto.money.Money petty_cash = 20; */
        if (message.pettyCash)
            Money.internalBinaryWrite(message.pettyCash, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_petty_cash = 21; */
        if (message.countPettyCash !== 0)
            writer.tag(21, WireType.Varint).int32(message.countPettyCash);
        /* rd.proto.money.Money close_outs = 22; */
        if (message.closeOuts)
            Money.internalBinaryWrite(message.closeOuts, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_close_outs = 23; */
        if (message.countCloseOuts !== 0)
            writer.tag(23, WireType.Varint).int32(message.countCloseOuts);
        /* rd.proto.uuid.UUID id = 24; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 25; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 26; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID rep_staff_id = 27; */
        if (message.repStaffId)
            UUID.internalBinaryWrite(message.repStaffId, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money petty_and_close_outs = 28; */
        if (message.pettyAndCloseOuts)
            Money.internalBinaryWrite(message.pettyAndCloseOuts, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_petty_and_close_outs = 29; */
        if (message.countPettyAndCloseOuts !== 0)
            writer.tag(29, WireType.Varint).int32(message.countPettyAndCloseOuts);
        /* rd.proto.money.Money adjusted_cash_total = 30; */
        if (message.adjustedCashTotal)
            Money.internalBinaryWrite(message.adjustedCashTotal, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_adjusted_cash_total = 31; */
        if (message.countAdjustedCashTotal !== 0)
            writer.tag(31, WireType.Varint).int32(message.countAdjustedCashTotal);
        /* rd.proto.money.Money adjusted_card_total = 32; */
        if (message.adjustedCardTotal)
            Money.internalBinaryWrite(message.adjustedCardTotal, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money petty_and_close_outs_minus_fees = 33; */
        if (message.pettyAndCloseOutsMinusFees)
            Money.internalBinaryWrite(message.pettyAndCloseOutsMinusFees, writer.tag(33, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjusted_total_revenue = 34; */
        if (message.adjustedTotalRevenue)
            Money.internalBinaryWrite(message.adjustedTotalRevenue, writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_adjusted_total_revenue = 35; */
        if (message.countAdjustedTotalRevenue !== 0)
            writer.tag(35, WireType.Varint).int32(message.countAdjustedTotalRevenue);
        /* rd.proto.money.Money cogs = 37; */
        if (message.cogs)
            Money.internalBinaryWrite(message.cogs, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money sales_tax = 38; */
        if (message.salesTax)
            Money.internalBinaryWrite(message.salesTax, writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money additional_fees = 39; */
        if (message.additionalFees)
            Money.internalBinaryWrite(message.additionalFees, writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money gross_profit = 40; */
        if (message.grossProfit)
            Money.internalBinaryWrite(message.grossProfit, writer.tag(40, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money discounts = 41; */
        if (message.discounts)
            Money.internalBinaryWrite(message.discounts, writer.tag(41, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_discounts = 42; */
        if (message.countDiscounts !== 0)
            writer.tag(42, WireType.Varint).int32(message.countDiscounts);
        /* rd.proto.money.Money adjusted_account_total = 43; */
        if (message.adjustedAccountTotal)
            Money.internalBinaryWrite(message.adjustedAccountTotal, writer.tag(43, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.ProfitAndLoss
 */
export const ProfitAndLoss = new ProfitAndLoss$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfitAndLoss_ProductCategoryRevenue$Type extends MessageType<ProfitAndLoss_ProductCategoryRevenue> {
    constructor() {
        super("rd.analytics.ProfitAndLoss.ProductCategoryRevenue", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "revenue", kind: "message", T: () => Money },
            { no: 3, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "gross_profit_margin", kind: "message", T: () => Decimal },
            { no: 8, name: "gross_profit", kind: "message", T: () => Money },
            { no: 9, name: "cogs", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<ProfitAndLoss_ProductCategoryRevenue>): ProfitAndLoss_ProductCategoryRevenue {
        const message = { name: "", idString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfitAndLoss_ProductCategoryRevenue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfitAndLoss_ProductCategoryRevenue): ProfitAndLoss_ProductCategoryRevenue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* rd.proto.money.Money revenue */ 2:
                    message.revenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.revenue);
                    break;
                case /* string id_string */ 3:
                    message.idString = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal gross_profit_margin */ 5:
                    message.grossProfitMargin = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.grossProfitMargin);
                    break;
                case /* rd.proto.money.Money gross_profit */ 8:
                    message.grossProfit = Money.internalBinaryRead(reader, reader.uint32(), options, message.grossProfit);
                    break;
                case /* rd.proto.money.Money cogs */ 9:
                    message.cogs = Money.internalBinaryRead(reader, reader.uint32(), options, message.cogs);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfitAndLoss_ProductCategoryRevenue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* rd.proto.money.Money revenue = 2; */
        if (message.revenue)
            Money.internalBinaryWrite(message.revenue, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string id_string = 3; */
        if (message.idString !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idString);
        /* rd.proto.decimal.Decimal gross_profit_margin = 5; */
        if (message.grossProfitMargin)
            Decimal.internalBinaryWrite(message.grossProfitMargin, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money gross_profit = 8; */
        if (message.grossProfit)
            Money.internalBinaryWrite(message.grossProfit, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cogs = 9; */
        if (message.cogs)
            Money.internalBinaryWrite(message.cogs, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.ProfitAndLoss.ProductCategoryRevenue
 */
export const ProfitAndLoss_ProductCategoryRevenue = new ProfitAndLoss_ProductCategoryRevenue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfitAndLoss_TransactionRevenue$Type extends MessageType<ProfitAndLoss_TransactionRevenue> {
    constructor() {
        super("rd.analytics.ProfitAndLoss.TransactionRevenue", [
            { no: 1, name: "cash", kind: "message", T: () => Money },
            { no: 2, name: "check", kind: "message", T: () => Money },
            { no: 3, name: "card", kind: "message", T: () => Money },
            { no: 4, name: "account", kind: "message", T: () => Money },
            { no: 5, name: "count_cash", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "count_check", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "count_card", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "count_account", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "total", kind: "message", T: () => Money },
            { no: 10, name: "count_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ProfitAndLoss_TransactionRevenue>): ProfitAndLoss_TransactionRevenue {
        const message = { countCash: 0, countCheck: 0, countCard: 0, countAccount: 0, countTotal: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfitAndLoss_TransactionRevenue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfitAndLoss_TransactionRevenue): ProfitAndLoss_TransactionRevenue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money cash */ 1:
                    message.cash = Money.internalBinaryRead(reader, reader.uint32(), options, message.cash);
                    break;
                case /* rd.proto.money.Money check */ 2:
                    message.check = Money.internalBinaryRead(reader, reader.uint32(), options, message.check);
                    break;
                case /* rd.proto.money.Money card */ 3:
                    message.card = Money.internalBinaryRead(reader, reader.uint32(), options, message.card);
                    break;
                case /* rd.proto.money.Money account */ 4:
                    message.account = Money.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* int32 count_cash */ 5:
                    message.countCash = reader.int32();
                    break;
                case /* int32 count_check */ 6:
                    message.countCheck = reader.int32();
                    break;
                case /* int32 count_card */ 7:
                    message.countCard = reader.int32();
                    break;
                case /* int32 count_account */ 8:
                    message.countAccount = reader.int32();
                    break;
                case /* rd.proto.money.Money total */ 9:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* int32 count_total */ 10:
                    message.countTotal = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfitAndLoss_TransactionRevenue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money cash = 1; */
        if (message.cash)
            Money.internalBinaryWrite(message.cash, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money check = 2; */
        if (message.check)
            Money.internalBinaryWrite(message.check, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card = 3; */
        if (message.card)
            Money.internalBinaryWrite(message.card, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money account = 4; */
        if (message.account)
            Money.internalBinaryWrite(message.account, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_cash = 5; */
        if (message.countCash !== 0)
            writer.tag(5, WireType.Varint).int32(message.countCash);
        /* int32 count_check = 6; */
        if (message.countCheck !== 0)
            writer.tag(6, WireType.Varint).int32(message.countCheck);
        /* int32 count_card = 7; */
        if (message.countCard !== 0)
            writer.tag(7, WireType.Varint).int32(message.countCard);
        /* int32 count_account = 8; */
        if (message.countAccount !== 0)
            writer.tag(8, WireType.Varint).int32(message.countAccount);
        /* rd.proto.money.Money total = 9; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* int32 count_total = 10; */
        if (message.countTotal !== 0)
            writer.tag(10, WireType.Varint).int32(message.countTotal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.ProfitAndLoss.TransactionRevenue
 */
export const ProfitAndLoss_TransactionRevenue = new ProfitAndLoss_TransactionRevenue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfitAndLossPDFData$Type extends MessageType<ProfitAndLossPDFData> {
    constructor() {
        super("rd.analytics.ProfitAndLossPDFData", [
            { no: 1, name: "company", kind: "message", T: () => Company },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 5, name: "is_product_categories_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "customer", kind: "message", T: () => Customer },
            { no: 7, name: "rep_staff", kind: "message", T: () => Staff },
            { no: 8, name: "profit_and_loss", kind: "message", T: () => ProfitAndLoss$ }
        ]);
    }
    create(value?: PartialMessage<ProfitAndLossPDFData>): ProfitAndLossPDFData {
        const message = { isProductCategoriesEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfitAndLossPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfitAndLossPDFData): ProfitAndLossPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Company company */ 1:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.company.Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* bool is_product_categories_enabled */ 5:
                    message.isProductCategoriesEnabled = reader.bool();
                    break;
                case /* rd.customer.Customer customer */ 6:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.company.Staff rep_staff */ 7:
                    message.repStaff = Staff.internalBinaryRead(reader, reader.uint32(), options, message.repStaff);
                    break;
                case /* rd.analyticsv2.ProfitAndLoss profit_and_loss */ 8:
                    message.profitAndLoss = ProfitAndLoss$.internalBinaryRead(reader, reader.uint32(), options, message.profitAndLoss);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfitAndLossPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Company company = 1; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool is_product_categories_enabled = 5; */
        if (message.isProductCategoriesEnabled !== false)
            writer.tag(5, WireType.Varint).bool(message.isProductCategoriesEnabled);
        /* rd.customer.Customer customer = 6; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.Staff rep_staff = 7; */
        if (message.repStaff)
            Staff.internalBinaryWrite(message.repStaff, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.ProfitAndLoss profit_and_loss = 8; */
        if (message.profitAndLoss)
            ProfitAndLoss$.internalBinaryWrite(message.profitAndLoss, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.ProfitAndLossPDFData
 */
export const ProfitAndLossPDFData = new ProfitAndLossPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoryValuation$Type extends MessageType<InventoryValuation> {
    constructor() {
        super("rd.analytics.InventoryValuation", [
            { no: 2, name: "valuation", kind: "message", T: () => Money },
            { no: 3, name: "category_valuations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InventoryValuation_ProductCategoryValuation }
        ]);
    }
    create(value?: PartialMessage<InventoryValuation>): InventoryValuation {
        const message = { categoryValuations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoryValuation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoryValuation): InventoryValuation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money valuation */ 2:
                    message.valuation = Money.internalBinaryRead(reader, reader.uint32(), options, message.valuation);
                    break;
                case /* repeated rd.analytics.InventoryValuation.ProductCategoryValuation category_valuations */ 3:
                    message.categoryValuations.push(InventoryValuation_ProductCategoryValuation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InventoryValuation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money valuation = 2; */
        if (message.valuation)
            Money.internalBinaryWrite(message.valuation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analytics.InventoryValuation.ProductCategoryValuation category_valuations = 3; */
        for (let i = 0; i < message.categoryValuations.length; i++)
            InventoryValuation_ProductCategoryValuation.internalBinaryWrite(message.categoryValuations[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.InventoryValuation
 */
export const InventoryValuation = new InventoryValuation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoryValuation_ProductCategoryValuation$Type extends MessageType<InventoryValuation_ProductCategoryValuation> {
    constructor() {
        super("rd.analytics.InventoryValuation.ProductCategoryValuation", [
            { no: 2, name: "valuation", kind: "message", T: () => Money },
            { no: 3, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InventoryValuation_ProductCategoryValuation>): InventoryValuation_ProductCategoryValuation {
        const message = { idString: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoryValuation_ProductCategoryValuation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoryValuation_ProductCategoryValuation): InventoryValuation_ProductCategoryValuation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money valuation */ 2:
                    message.valuation = Money.internalBinaryRead(reader, reader.uint32(), options, message.valuation);
                    break;
                case /* string id_string */ 3:
                    message.idString = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InventoryValuation_ProductCategoryValuation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money valuation = 2; */
        if (message.valuation)
            Money.internalBinaryWrite(message.valuation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string id_string = 3; */
        if (message.idString !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idString);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.InventoryValuation.ProductCategoryValuation
 */
export const InventoryValuation_ProductCategoryValuation = new InventoryValuation_ProductCategoryValuation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAging$Type extends MessageType<CustomerAging> {
    constructor() {
        super("rd.analytics.CustomerAging", [
            { no: 1, name: "customers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CustomerAging_Customer },
            { no: 2, name: "summary", kind: "message", T: () => CustomerAging_Summary }
        ]);
    }
    create(value?: PartialMessage<CustomerAging>): CustomerAging {
        const message = { customers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerAging>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerAging): CustomerAging {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.analytics.CustomerAging.Customer customers */ 1:
                    message.customers.push(CustomerAging_Customer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analytics.CustomerAging.Summary summary */ 2:
                    message.summary = CustomerAging_Summary.internalBinaryRead(reader, reader.uint32(), options, message.summary);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerAging, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.analytics.CustomerAging.Customer customers = 1; */
        for (let i = 0; i < message.customers.length; i++)
            CustomerAging_Customer.internalBinaryWrite(message.customers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.CustomerAging.Summary summary = 2; */
        if (message.summary)
            CustomerAging_Summary.internalBinaryWrite(message.summary, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.CustomerAging
 */
export const CustomerAging = new CustomerAging$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAging_Customer$Type extends MessageType<CustomerAging_Customer> {
    constructor() {
        super("rd.analytics.CustomerAging.Customer", [
            { no: 1, name: "customer", kind: "message", T: () => Customer },
            { no: 2, name: "contacts", kind: "message", T: () => Contacts },
            { no: 3, name: "balance", kind: "message", T: () => CustomerBalance },
            { no: 4, name: "total_balance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CustomerAging_Customer>): CustomerAging_Customer {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerAging_Customer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerAging_Customer): CustomerAging_Customer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.customer.Contacts contacts */ 2:
                    message.contacts = Contacts.internalBinaryRead(reader, reader.uint32(), options, message.contacts);
                    break;
                case /* rd.txn.CustomerBalance balance */ 3:
                    message.balance = CustomerBalance.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                case /* rd.proto.money.Money total_balance */ 4:
                    message.totalBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerAging_Customer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Contacts contacts = 2; */
        if (message.contacts)
            Contacts.internalBinaryWrite(message.contacts, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.CustomerBalance balance = 3; */
        if (message.balance)
            CustomerBalance.internalBinaryWrite(message.balance, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_balance = 4; */
        if (message.totalBalance)
            Money.internalBinaryWrite(message.totalBalance, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.CustomerAging.Customer
 */
export const CustomerAging_Customer = new CustomerAging_Customer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAging_Summary$Type extends MessageType<CustomerAging_Summary> {
    constructor() {
        super("rd.analytics.CustomerAging.Summary", [
            { no: 1, name: "credits", kind: "message", T: () => Money },
            { no: 2, name: "current", kind: "message", T: () => Money },
            { no: 3, name: "late_1_to_30", kind: "message", T: () => Money },
            { no: 4, name: "late_31_to_60", kind: "message", T: () => Money },
            { no: 5, name: "late_61_plus", kind: "message", T: () => Money },
            { no: 6, name: "financing_charges", kind: "message", T: () => Money },
            { no: 7, name: "total_balance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<CustomerAging_Summary>): CustomerAging_Summary {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerAging_Summary>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerAging_Summary): CustomerAging_Summary {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money credits */ 1:
                    message.credits = Money.internalBinaryRead(reader, reader.uint32(), options, message.credits);
                    break;
                case /* rd.proto.money.Money current */ 2:
                    message.current = Money.internalBinaryRead(reader, reader.uint32(), options, message.current);
                    break;
                case /* rd.proto.money.Money late_1_to_30 */ 3:
                    message.late1To30 = Money.internalBinaryRead(reader, reader.uint32(), options, message.late1To30);
                    break;
                case /* rd.proto.money.Money late_31_to_60 */ 4:
                    message.late31To60 = Money.internalBinaryRead(reader, reader.uint32(), options, message.late31To60);
                    break;
                case /* rd.proto.money.Money late_61_plus */ 5:
                    message.late61Plus = Money.internalBinaryRead(reader, reader.uint32(), options, message.late61Plus);
                    break;
                case /* rd.proto.money.Money financing_charges */ 6:
                    message.financingCharges = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingCharges);
                    break;
                case /* rd.proto.money.Money total_balance */ 7:
                    message.totalBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalBalance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerAging_Summary, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money credits = 1; */
        if (message.credits)
            Money.internalBinaryWrite(message.credits, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money current = 2; */
        if (message.current)
            Money.internalBinaryWrite(message.current, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money late_1_to_30 = 3; */
        if (message.late1To30)
            Money.internalBinaryWrite(message.late1To30, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money late_31_to_60 = 4; */
        if (message.late31To60)
            Money.internalBinaryWrite(message.late31To60, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money late_61_plus = 5; */
        if (message.late61Plus)
            Money.internalBinaryWrite(message.late61Plus, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money financing_charges = 6; */
        if (message.financingCharges)
            Money.internalBinaryWrite(message.financingCharges, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_balance = 7; */
        if (message.totalBalance)
            Money.internalBinaryWrite(message.totalBalance, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.CustomerAging.Summary
 */
export const CustomerAging_Summary = new CustomerAging_Summary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAgingPDFData$Type extends MessageType<CustomerAgingPDFData> {
    constructor() {
        super("rd.analytics.CustomerAgingPDFData", [
            { no: 1, name: "company", kind: "message", T: () => Company },
            { no: 2, name: "customer_aging", kind: "message", T: () => CustomerAging },
            { no: 3, name: "at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<CustomerAgingPDFData>): CustomerAgingPDFData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerAgingPDFData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerAgingPDFData): CustomerAgingPDFData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.company.Company company */ 1:
                    message.company = Company.internalBinaryRead(reader, reader.uint32(), options, message.company);
                    break;
                case /* rd.analytics.CustomerAging customer_aging */ 2:
                    message.customerAging = CustomerAging.internalBinaryRead(reader, reader.uint32(), options, message.customerAging);
                    break;
                case /* google.protobuf.Timestamp at */ 3:
                    message.at = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.at);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerAgingPDFData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.company.Company company = 1; */
        if (message.company)
            Company.internalBinaryWrite(message.company, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.CustomerAging customer_aging = 2; */
        if (message.customerAging)
            CustomerAging.internalBinaryWrite(message.customerAging, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp at = 3; */
        if (message.at)
            Timestamp.internalBinaryWrite(message.at, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.CustomerAgingPDFData
 */
export const CustomerAgingPDFData = new CustomerAgingPDFData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaxEntries$Type extends MessageType<TaxEntries> {
    constructor() {
        super("rd.analytics.TaxEntries", [
            { no: 2, name: "line_items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TaxEntries_LineItem },
            { no: 3, name: "totals", kind: "message", T: () => TaxEntries_Totals }
        ]);
    }
    create(value?: PartialMessage<TaxEntries>): TaxEntries {
        const message = { lineItems: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaxEntries>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaxEntries): TaxEntries {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.analytics.TaxEntries.LineItem line_items */ 2:
                    message.lineItems.push(TaxEntries_LineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analytics.TaxEntries.Totals totals */ 3:
                    message.totals = TaxEntries_Totals.internalBinaryRead(reader, reader.uint32(), options, message.totals);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaxEntries, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.analytics.TaxEntries.LineItem line_items = 2; */
        for (let i = 0; i < message.lineItems.length; i++)
            TaxEntries_LineItem.internalBinaryWrite(message.lineItems[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.TaxEntries.Totals totals = 3; */
        if (message.totals)
            TaxEntries_Totals.internalBinaryWrite(message.totals, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.TaxEntries
 */
export const TaxEntries = new TaxEntries$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaxEntries_LineItem$Type extends MessageType<TaxEntries_LineItem> {
    constructor() {
        super("rd.analytics.TaxEntries.LineItem", [
            { no: 1, name: "taxable_revenue", kind: "message", T: () => Money },
            { no: 2, name: "non_taxable_revenue", kind: "message", T: () => Money },
            { no: 3, name: "tax_owed", kind: "message", T: () => Money },
            { no: 4, name: "tax_rate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TaxEntries_LineItem>): TaxEntries_LineItem {
        const message = { taxRate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaxEntries_LineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaxEntries_LineItem): TaxEntries_LineItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money taxable_revenue */ 1:
                    message.taxableRevenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.taxableRevenue);
                    break;
                case /* rd.proto.money.Money non_taxable_revenue */ 2:
                    message.nonTaxableRevenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.nonTaxableRevenue);
                    break;
                case /* rd.proto.money.Money tax_owed */ 3:
                    message.taxOwed = Money.internalBinaryRead(reader, reader.uint32(), options, message.taxOwed);
                    break;
                case /* string tax_rate */ 4:
                    message.taxRate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaxEntries_LineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money taxable_revenue = 1; */
        if (message.taxableRevenue)
            Money.internalBinaryWrite(message.taxableRevenue, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money non_taxable_revenue = 2; */
        if (message.nonTaxableRevenue)
            Money.internalBinaryWrite(message.nonTaxableRevenue, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money tax_owed = 3; */
        if (message.taxOwed)
            Money.internalBinaryWrite(message.taxOwed, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string tax_rate = 4; */
        if (message.taxRate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.taxRate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.TaxEntries.LineItem
 */
export const TaxEntries_LineItem = new TaxEntries_LineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaxEntries_Totals$Type extends MessageType<TaxEntries_Totals> {
    constructor() {
        super("rd.analytics.TaxEntries.Totals", [
            { no: 1, name: "taxable_revenue", kind: "message", T: () => Money },
            { no: 2, name: "non_taxable_revenue", kind: "message", T: () => Money },
            { no: 3, name: "tax_owed", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<TaxEntries_Totals>): TaxEntries_Totals {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaxEntries_Totals>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaxEntries_Totals): TaxEntries_Totals {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money taxable_revenue */ 1:
                    message.taxableRevenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.taxableRevenue);
                    break;
                case /* rd.proto.money.Money non_taxable_revenue */ 2:
                    message.nonTaxableRevenue = Money.internalBinaryRead(reader, reader.uint32(), options, message.nonTaxableRevenue);
                    break;
                case /* rd.proto.money.Money tax_owed */ 3:
                    message.taxOwed = Money.internalBinaryRead(reader, reader.uint32(), options, message.taxOwed);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaxEntries_Totals, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money taxable_revenue = 1; */
        if (message.taxableRevenue)
            Money.internalBinaryWrite(message.taxableRevenue, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money non_taxable_revenue = 2; */
        if (message.nonTaxableRevenue)
            Money.internalBinaryWrite(message.nonTaxableRevenue, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money tax_owed = 3; */
        if (message.taxOwed)
            Money.internalBinaryWrite(message.taxOwed, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.TaxEntries.Totals
 */
export const TaxEntries_Totals = new TaxEntries_Totals$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceEdits$Type extends MessageType<PriceEdits> {
    constructor() {
        super("rd.analytics.PriceEdits", [
            { no: 1, name: "price_edits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PriceEdits_PriceEditLineItem },
            { no: 2, name: "totals", kind: "message", T: () => PriceEdits_Totals }
        ]);
    }
    create(value?: PartialMessage<PriceEdits>): PriceEdits {
        const message = { priceEdits: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PriceEdits>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceEdits): PriceEdits {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.analytics.PriceEdits.PriceEditLineItem price_edits */ 1:
                    message.priceEdits.push(PriceEdits_PriceEditLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analytics.PriceEdits.Totals totals */ 2:
                    message.totals = PriceEdits_Totals.internalBinaryRead(reader, reader.uint32(), options, message.totals);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PriceEdits, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.analytics.PriceEdits.PriceEditLineItem price_edits = 1; */
        for (let i = 0; i < message.priceEdits.length; i++)
            PriceEdits_PriceEditLineItem.internalBinaryWrite(message.priceEdits[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.PriceEdits.Totals totals = 2; */
        if (message.totals)
            PriceEdits_Totals.internalBinaryWrite(message.totals, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.PriceEdits
 */
export const PriceEdits = new PriceEdits$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceEdits_PriceEditLineItem$Type extends MessageType<PriceEdits_PriceEditLineItem> {
    constructor() {
        super("rd.analytics.PriceEdits.PriceEditLineItem", [
            { no: 1, name: "sale_product", kind: "message", T: () => SaleProduct },
            { no: 2, name: "sale", kind: "message", T: () => Sale },
            { no: 3, name: "product", kind: "message", T: () => Product },
            { no: 4, name: "customer", kind: "message", T: () => Customer },
            { no: 5, name: "expected_price", kind: "message", T: () => Money },
            { no: 6, name: "impact", kind: "message", T: () => Money },
            { no: 7, name: "margin", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<PriceEdits_PriceEditLineItem>): PriceEdits_PriceEditLineItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PriceEdits_PriceEditLineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceEdits_PriceEditLineItem): PriceEdits_PriceEditLineItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.SaleProduct sale_product */ 1:
                    message.saleProduct = SaleProduct.internalBinaryRead(reader, reader.uint32(), options, message.saleProduct);
                    break;
                case /* rd.txn.Sale sale */ 2:
                    message.sale = Sale.internalBinaryRead(reader, reader.uint32(), options, message.sale);
                    break;
                case /* rd.product.Product product */ 3:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                case /* rd.customer.Customer customer */ 4:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* rd.proto.money.Money expected_price */ 5:
                    message.expectedPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.expectedPrice);
                    break;
                case /* rd.proto.money.Money impact */ 6:
                    message.impact = Money.internalBinaryRead(reader, reader.uint32(), options, message.impact);
                    break;
                case /* rd.proto.decimal.Decimal margin */ 7:
                    message.margin = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.margin);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PriceEdits_PriceEditLineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.SaleProduct sale_product = 1; */
        if (message.saleProduct)
            SaleProduct.internalBinaryWrite(message.saleProduct, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.txn.Sale sale = 2; */
        if (message.sale)
            Sale.internalBinaryWrite(message.sale, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product product = 3; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.customer.Customer customer = 4; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money expected_price = 5; */
        if (message.expectedPrice)
            Money.internalBinaryWrite(message.expectedPrice, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money impact = 6; */
        if (message.impact)
            Money.internalBinaryWrite(message.impact, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal margin = 7; */
        if (message.margin)
            Decimal.internalBinaryWrite(message.margin, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.PriceEdits.PriceEditLineItem
 */
export const PriceEdits_PriceEditLineItem = new PriceEdits_PriceEditLineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceEdits_Totals$Type extends MessageType<PriceEdits_Totals> {
    constructor() {
        super("rd.analytics.PriceEdits.Totals", [
            { no: 1, name: "edits", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "impact", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<PriceEdits_Totals>): PriceEdits_Totals {
        const message = { edits: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PriceEdits_Totals>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceEdits_Totals): PriceEdits_Totals {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 edits */ 1:
                    message.edits = reader.int32();
                    break;
                case /* rd.proto.money.Money impact */ 2:
                    message.impact = Money.internalBinaryRead(reader, reader.uint32(), options, message.impact);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PriceEdits_Totals, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 edits = 1; */
        if (message.edits !== 0)
            writer.tag(1, WireType.Varint).int32(message.edits);
        /* rd.proto.money.Money impact = 2; */
        if (message.impact)
            Money.internalBinaryWrite(message.impact, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.PriceEdits.Totals
 */
export const PriceEdits_Totals = new PriceEdits_Totals$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAccountsOpened$Type extends MessageType<NewAccountsOpened> {
    constructor() {
        super("rd.analytics.NewAccountsOpened", [
            { no: 1, name: "new_accounts_opened", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NewAccountsOpened_LineItem },
            { no: 2, name: "totals", kind: "message", T: () => NewAccountsOpened_Totals }
        ]);
    }
    create(value?: PartialMessage<NewAccountsOpened>): NewAccountsOpened {
        const message = { newAccountsOpened: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAccountsOpened>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAccountsOpened): NewAccountsOpened {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rd.analytics.NewAccountsOpened.LineItem new_accounts_opened */ 1:
                    message.newAccountsOpened.push(NewAccountsOpened_LineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analytics.NewAccountsOpened.Totals totals */ 2:
                    message.totals = NewAccountsOpened_Totals.internalBinaryRead(reader, reader.uint32(), options, message.totals);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAccountsOpened, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rd.analytics.NewAccountsOpened.LineItem new_accounts_opened = 1; */
        for (let i = 0; i < message.newAccountsOpened.length; i++)
            NewAccountsOpened_LineItem.internalBinaryWrite(message.newAccountsOpened[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.analytics.NewAccountsOpened.Totals totals = 2; */
        if (message.totals)
            NewAccountsOpened_Totals.internalBinaryWrite(message.totals, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.NewAccountsOpened
 */
export const NewAccountsOpened = new NewAccountsOpened$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAccountsOpened_LineItem$Type extends MessageType<NewAccountsOpened_LineItem> {
    constructor() {
        super("rd.analytics.NewAccountsOpened.LineItem", [
            { no: 1, name: "customer", kind: "message", T: () => Customer },
            { no: 2, name: "rep_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tier_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sales_last_12_mo", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<NewAccountsOpened_LineItem>): NewAccountsOpened_LineItem {
        const message = { repName: "", tierName: "", phone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAccountsOpened_LineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAccountsOpened_LineItem): NewAccountsOpened_LineItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.customer.Customer customer */ 1:
                    message.customer = Customer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* string rep_name */ 2:
                    message.repName = reader.string();
                    break;
                case /* string tier_name */ 3:
                    message.tierName = reader.string();
                    break;
                case /* string phone */ 4:
                    message.phone = reader.string();
                    break;
                case /* rd.proto.money.Money sales_last_12_mo */ 5:
                    message.salesLast12Mo = Money.internalBinaryRead(reader, reader.uint32(), options, message.salesLast12Mo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAccountsOpened_LineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.customer.Customer customer = 1; */
        if (message.customer)
            Customer.internalBinaryWrite(message.customer, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string rep_name = 2; */
        if (message.repName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.repName);
        /* string tier_name = 3; */
        if (message.tierName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tierName);
        /* string phone = 4; */
        if (message.phone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phone);
        /* rd.proto.money.Money sales_last_12_mo = 5; */
        if (message.salesLast12Mo)
            Money.internalBinaryWrite(message.salesLast12Mo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.NewAccountsOpened.LineItem
 */
export const NewAccountsOpened_LineItem = new NewAccountsOpened_LineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAccountsOpened_Totals$Type extends MessageType<NewAccountsOpened_Totals> {
    constructor() {
        super("rd.analytics.NewAccountsOpened.Totals", [
            { no: 1, name: "accounts_opened", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<NewAccountsOpened_Totals>): NewAccountsOpened_Totals {
        const message = { accountsOpened: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAccountsOpened_Totals>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAccountsOpened_Totals): NewAccountsOpened_Totals {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 accounts_opened */ 1:
                    message.accountsOpened = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAccountsOpened_Totals, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 accounts_opened = 1; */
        if (message.accountsOpened !== 0)
            writer.tag(1, WireType.Varint).int32(message.accountsOpened);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.NewAccountsOpened.Totals
 */
export const NewAccountsOpened_Totals = new NewAccountsOpened_Totals$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalesDetail$Type extends MessageType<SalesDetail> {
    constructor() {
        super("rd.analytics.SalesDetail", []);
    }
    create(value?: PartialMessage<SalesDetail>): SalesDetail {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SalesDetail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SalesDetail): SalesDetail {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SalesDetail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.SalesDetail
 */
export const SalesDetail = new SalesDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalesDetail_Totals$Type extends MessageType<SalesDetail_Totals> {
    constructor() {
        super("rd.analytics.SalesDetail.Totals", []);
    }
    create(value?: PartialMessage<SalesDetail_Totals>): SalesDetail_Totals {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SalesDetail_Totals>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SalesDetail_Totals): SalesDetail_Totals {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SalesDetail_Totals, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.SalesDetail.Totals
 */
export const SalesDetail_Totals = new SalesDetail_Totals$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalesDetail_LineItem$Type extends MessageType<SalesDetail_LineItem> {
    constructor() {
        super("rd.analytics.SalesDetail.LineItem", []);
    }
    create(value?: PartialMessage<SalesDetail_LineItem>): SalesDetail_LineItem {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SalesDetail_LineItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SalesDetail_LineItem): SalesDetail_LineItem {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SalesDetail_LineItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analytics.SalesDetail.LineItem
 */
export const SalesDetail_LineItem = new SalesDetail_LineItem$Type();
