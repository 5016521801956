import { getApiErrorStatusMessage, isApiError } from "features/api/apiSlice"
import rht, { ToastOptions } from "react-hot-toast"

import { Banner } from "components/Banner"
import { classNames } from "./classNames"

function success(message: string, options?: ToastOptions) {
  return rht.custom(
    (t) => (
      <Banner
        data-testid="success-toast"
        mode="success"
        className={classNames(
          t.visible ? "animate-enter" : "animate-leave",
          "w-full max-w-3xl"
        )}
        message={message}
        onClose={() => rht.dismiss(t.id)}
      />
    ),
    options
  )
}

function error(message: string, options?: ToastOptions) {
  return rht.custom(
    (t) => (
      <Banner
        data-testid="error-toast"
        mode="error"
        className={classNames(
          t.visible ? "animate-enter" : "animate-leave",
          "w-full max-w-3xl"
        )}
        message={message}
        onClose={() => rht.dismiss(t.id)}
      />
    ),
    options
  )
}

function apiError(e: unknown, defaultMessage: string, options?: ToastOptions) {
  if (isApiError(e)) {
    return error(getApiErrorStatusMessage(e, defaultMessage), options)
  }

  return error(defaultMessage, options)
}

function warning(message: string, options?: ToastOptions) {
  return rht.custom(
    (t) => (
      <Banner
        mode="warning"
        className={classNames(
          t.visible ? "animate-enter" : "animate-leave",
          "w-full max-w-3xl"
        )}
        message={message}
        onClose={() => rht.dismiss(t.id)}
      />
    ),
    options
  )
}

function info(message: string, options?: ToastOptions) {
  return rht.custom(
    (t) => (
      <Banner
        mode="information"
        className={classNames(
          t.visible ? "animate-enter" : "animate-leave",
          "w-full max-w-3xl"
        )}
        message={message}
        onClose={() => rht.dismiss(t.id)}
      />
    ),
    options
  )
}

function copy(message: string, options?: ToastOptions) {
  return rht.custom(
    (t) => (
      <Banner
        data-testid="copy-toast"
        mode="copy"
        className={classNames(
          t.visible ? "animate-enter" : "animate-leave",
          "w-1/4 max-w-xs",
          "surface-primary",
          "text-primary",
          "shadow-[0_2px_14px_0px_rgba(0,0,0,0.15)]"
        )}
        message={message}
        onClose={() => rht.dismiss(t.id)}
      />
    ),
    {
      duration: 2000,
      ...options,
    }
  )
}

export const toast = {
  success,
  error,
  warning,
  info,
  copy,
  apiError,
}
