import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Transfer_Status as TransferStatus } from "gen/txn/models_pb"
import { UUID } from "uuid-rd"
import { cleanStatus } from "./cleanStatus"

export function transfer(id?: PBUUID, status?: TransferStatus): string {
  const statusParam = transferStatusParam(status)
  if (!statusParam) {
    return `/pos/transfers/${UUID.idString(id)}`
  }
  return `/pos/transfers/${UUID.idString(id)}?status=${statusParam}`
}

export function transferStatusParam(status?: TransferStatus): string {
  if (!status) {
    return ""
  }
  return cleanStatus(TransferStatus[status])
}
