import { NextRouter } from "next/router"
import { ParsedUrlQuery } from "querystring"
import { ReportConfig } from "./types"
import { isString } from "./configuration/helpers"
import { pushParam } from "lib/params"

export const APPLIED_PREFIX = "applied_"
export const STAGED_PREFIX = "staged_"
export const PARAM_DELIMITER = ","

export const REPORT_PARAM = "report"
export const END_DATE_PARAM = "end"
export const DATE_RANGE_PARAM = "dateRange"
export const SORT_PARAM = "sort"
export const SORT_ORDER_PARAM = "sortOrder"
export const LOCATION_PARAM = "location"
export const CLERK_PARAM = "clerk"
export const REP_PARAM = "rep"
export const CUSTOMER_PARAM = "customer"
export const JOB_PARAM = "job"
export const CUSTOMER_TAG_PARAM = "customerTag"
export const PRODUCT_PARAM = "product"
export const PRODUCT_TAG_PARAM = "productTag"
export const START_DATE_PARAM = "start"
export const VENDOR_PARAM = "vendor"
export const DEPARTMENT_PARAM = "department"
export const CLASS_PARAM = "class"
export const FINELINE_PARAM = "fineline"
export const ACCOUNT_PARAM = "account"
export const TERMS_PARAM = "terms"
export const SALES_METHOD_PARAM = "salesMethod"
export const SALES_TRANSACTION_TYPE_PARAM = "salesTransactionType"
export const PAYMENT_METHOD_PARAM = "paymentMethod"
export const PAYMENT_TRANSACTION_TYPE_PARAM = "paymentTransactionType"
export const CASH_REGISTER_TRANSACTION_TYPE_PARAM =
  "cashRegisterTransactionType"
export const AGING_DETAIL_TRANSACTION_TYPE_PARAM =
  "aging-detail-transaction-type"
export const AGING_DETAIL_STATUS_PARAM = "aging-detail-status"

export function pushReportParam(
  router: NextRouter,
  paramName: string,
  paramValue: string
) {
  pushParam(router, stagedKey(paramName), paramValue)
}

export function parseParamKeys(p: ParsedUrlQuery): {
  stagedKeys: ParsedUrlQuery
  appliedKeys: ParsedUrlQuery
  areFiltersUnapplied: boolean
} {
  const stagedKeys: ParsedUrlQuery = {}
  const appliedKeys: ParsedUrlQuery = {}
  for (const key in p) {
    if (key.startsWith(STAGED_PREFIX)) {
      stagedKeys[extractStagedKey(key)] = p[key]
    } else if (key.startsWith(APPLIED_PREFIX)) {
      appliedKeys[extractAppliedKey(key)] = p[key]
    }
  }
  return {
    stagedKeys,
    appliedKeys,
    areFiltersUnapplied: Object.keys(appliedKeys).length === 0,
  }
}

export function isStagedEqualApplied(a: ParsedUrlQuery): boolean {
  const { stagedKeys, appliedKeys } = parseParamKeys(a)
  if (Object.keys(stagedKeys).length !== Object.keys(appliedKeys).length) {
    return false
  }
  for (const key in stagedKeys) {
    if (stagedKeys[key] !== appliedKeys[key]) {
      return false
    }
  }
  return true
}

export function appliedKey(paramKey: string) {
  return `${APPLIED_PREFIX}${paramKey}`
}

export function applyStagedKey(stagedKey: string) {
  return stagedKey.replace(STAGED_PREFIX, APPLIED_PREFIX)
}

export function stagedKey(paramKey: string) {
  return `${STAGED_PREFIX}${paramKey}`
}

export function extractStagedKey(stagedKey: string) {
  return stagedKey.replace(STAGED_PREFIX, "")
}

export function extractAppliedKey(appliedKey: string) {
  return appliedKey.replace(APPLIED_PREFIX, "")
}

export function joinParameters(keys: string[]): string {
  return keys
    .filter((value) => !!value && !value.includes(PARAM_DELIMITER))
    .join(PARAM_DELIMITER)
}

export function extractItemsFromParam<T>(
  paramValue: string | string[] | undefined,
  allItems: T[],
  keyExtractor: (t: T) => string
) {
  return allItems.filter((item) => {
    const key = keyExtractor(item)
    return (
      !!key &&
      isString(paramValue) &&
      paramValue.split(PARAM_DELIMITER).includes(key)
    )
  })
}

export function defaultStagedParams(rc: ReportConfig) {
  const stagedParams: { [key: string]: string | string[] } = {}
  const defaultParams = rc.defaultParams()
  for (const key in defaultParams) {
    stagedParams[stagedKey(key)] = defaultParams[key]
  }
  stagedParams[REPORT_PARAM] = rc.paramKey
  return stagedParams
}
