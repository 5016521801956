/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/product/models.proto" (package "rd.api.product", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Tier } from "../../price/models_pb";
import { FractionalMoney } from "../../proto/money/models_pb";
import { Product_Cost } from "../../product/models_pb";
import { Product_TaxStatus } from "../../product/models_pb";
import { Product_QuantityBreak } from "../../product/models_pb";
import { Product_OrderSettings } from "../../product/models_pb";
import { User } from "../../user/models_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { UUID } from "../../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.api.product.Product
 */
export interface Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 2;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 3;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * First identifier in the list is the primary identifier
     *
     * @generated from protobuf field: repeated string identifiers = 5;
     */
    identifiers: string[];
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID department_id = 6;
     */
    departmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID class_id = 7;
     */
    classId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID fineline_id = 8;
     */
    finelineId?: UUID;
    /**
     * @generated from protobuf field: string additional_fee_id_string = 9;
     */
    additionalFeeIdString: string;
    /**
     * @generated from protobuf field: bool is_commissionable = 10;
     */
    isCommissionable: boolean;
    /**
     * @generated from protobuf field: bool is_tintable = 11;
     */
    isTintable: boolean;
    /**
     * @generated from protobuf field: bool is_special_orderable = 12;
     */
    isSpecialOrderable: boolean;
    /**
     * @generated from protobuf field: bool is_active = 13;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: int32 case_quantity = 14;
     */
    caseQuantity: number;
    /**
     * @generated from protobuf field: map<string, rd.product.Product.OrderSettings> order_settings_by_location_id = 15;
     */
    orderSettingsByLocationId: {
        [key: string]: Product_OrderSettings;
    };
    /**
     * @generated from protobuf field: map<string, string> bin_locations_by_location_id = 16;
     */
    binLocationsByLocationId: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: repeated rd.product.Product.QuantityBreak quantity_breaks = 17;
     */
    quantityBreaks: Product_QuantityBreak[];
    /**
     * @generated from protobuf field: rd.product.Product.TaxStatus tax_status = 18;
     */
    taxStatus: Product_TaxStatus;
    /**
     * @generated from protobuf field: repeated rd.product.Product.Cost costs = 19;
     */
    costs: Product_Cost[];
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney average_cost = 20;
     */
    averageCost?: FractionalMoney;
    /**
     * @generated from protobuf field: repeated rd.price.Tier tier_prices = 26;
     */
    tierPrices: Tier[];
    /**
     * @generated from protobuf field: string notes = 27;
     */
    notes: string;
    /**
     * @generated from protobuf field: string sales_reminder = 28;
     */
    salesReminder: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 29;
     */
    tagIds: UUID[];
    /**
     * @generated from protobuf field: string template_sale_product_notes = 30;
     */
    templateSaleProductNotes: string;
    /**
     * @generated from protobuf field: string template_order_product_notes = 31;
     */
    templateOrderProductNotes: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Product$Type extends MessageType<Product> {
    constructor() {
        super("rd.api.product.Product", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_by", kind: "message", T: () => User },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "identifiers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "department_id", kind: "message", T: () => UUID },
            { no: 7, name: "class_id", kind: "message", T: () => UUID },
            { no: 8, name: "fineline_id", kind: "message", T: () => UUID },
            { no: 9, name: "additional_fee_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "is_commissionable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "is_tintable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "is_special_orderable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "case_quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "order_settings_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product_OrderSettings } },
            { no: 16, name: "bin_locations_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 17, name: "quantity_breaks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Product_QuantityBreak },
            { no: 18, name: "tax_status", kind: "enum", T: () => ["rd.product.Product.TaxStatus", Product_TaxStatus, "TAX_STATUS_"] },
            { no: 19, name: "costs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Product_Cost },
            { no: 20, name: "average_cost", kind: "message", T: () => FractionalMoney },
            { no: 26, name: "tier_prices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tier },
            { no: 27, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "sales_reminder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 30, name: "template_sale_product_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "template_order_product_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Product>): Product {
        const message = { name: "", identifiers: [], additionalFeeIdString: "", isCommissionable: false, isTintable: false, isSpecialOrderable: false, isActive: false, caseQuantity: 0, orderSettingsByLocationId: {}, binLocationsByLocationId: {}, quantityBreaks: [], taxStatus: 0, costs: [], tierPrices: [], notes: "", salesReminder: "", tagIds: [], templateSaleProductNotes: "", templateOrderProductNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Product): Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 2:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User updated_by */ 3:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* repeated string identifiers */ 5:
                    message.identifiers.push(reader.string());
                    break;
                case /* rd.proto.uuid.UUID department_id */ 6:
                    message.departmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.departmentId);
                    break;
                case /* rd.proto.uuid.UUID class_id */ 7:
                    message.classId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.classId);
                    break;
                case /* rd.proto.uuid.UUID fineline_id */ 8:
                    message.finelineId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.finelineId);
                    break;
                case /* string additional_fee_id_string */ 9:
                    message.additionalFeeIdString = reader.string();
                    break;
                case /* bool is_commissionable */ 10:
                    message.isCommissionable = reader.bool();
                    break;
                case /* bool is_tintable */ 11:
                    message.isTintable = reader.bool();
                    break;
                case /* bool is_special_orderable */ 12:
                    message.isSpecialOrderable = reader.bool();
                    break;
                case /* bool is_active */ 13:
                    message.isActive = reader.bool();
                    break;
                case /* int32 case_quantity */ 14:
                    message.caseQuantity = reader.int32();
                    break;
                case /* map<string, rd.product.Product.OrderSettings> order_settings_by_location_id */ 15:
                    this.binaryReadMap15(message.orderSettingsByLocationId, reader, options);
                    break;
                case /* map<string, string> bin_locations_by_location_id */ 16:
                    this.binaryReadMap16(message.binLocationsByLocationId, reader, options);
                    break;
                case /* repeated rd.product.Product.QuantityBreak quantity_breaks */ 17:
                    message.quantityBreaks.push(Product_QuantityBreak.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.product.Product.TaxStatus tax_status */ 18:
                    message.taxStatus = reader.int32();
                    break;
                case /* repeated rd.product.Product.Cost costs */ 19:
                    message.costs.push(Product_Cost.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.FractionalMoney average_cost */ 20:
                    message.averageCost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.averageCost);
                    break;
                case /* repeated rd.price.Tier tier_prices */ 26:
                    message.tierPrices.push(Tier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string notes */ 27:
                    message.notes = reader.string();
                    break;
                case /* string sales_reminder */ 28:
                    message.salesReminder = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 29:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string template_sale_product_notes */ 30:
                    message.templateSaleProductNotes = reader.string();
                    break;
                case /* string template_order_product_notes */ 31:
                    message.templateOrderProductNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap15(map: Product["orderSettingsByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Product["orderSettingsByLocationId"] | undefined, val: Product["orderSettingsByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product_OrderSettings.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.product.Product.order_settings_by_location_id");
            }
        }
        map[key ?? ""] = val ?? Product_OrderSettings.create();
    }
    private binaryReadMap16(map: Product["binLocationsByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Product["binLocationsByLocationId"] | undefined, val: Product["binLocationsByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.product.Product.bin_locations_by_location_id");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 2; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 3; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* repeated string identifiers = 5; */
        for (let i = 0; i < message.identifiers.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.identifiers[i]);
        /* rd.proto.uuid.UUID department_id = 6; */
        if (message.departmentId)
            UUID.internalBinaryWrite(message.departmentId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID class_id = 7; */
        if (message.classId)
            UUID.internalBinaryWrite(message.classId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID fineline_id = 8; */
        if (message.finelineId)
            UUID.internalBinaryWrite(message.finelineId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string additional_fee_id_string = 9; */
        if (message.additionalFeeIdString !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.additionalFeeIdString);
        /* bool is_commissionable = 10; */
        if (message.isCommissionable !== false)
            writer.tag(10, WireType.Varint).bool(message.isCommissionable);
        /* bool is_tintable = 11; */
        if (message.isTintable !== false)
            writer.tag(11, WireType.Varint).bool(message.isTintable);
        /* bool is_special_orderable = 12; */
        if (message.isSpecialOrderable !== false)
            writer.tag(12, WireType.Varint).bool(message.isSpecialOrderable);
        /* bool is_active = 13; */
        if (message.isActive !== false)
            writer.tag(13, WireType.Varint).bool(message.isActive);
        /* int32 case_quantity = 14; */
        if (message.caseQuantity !== 0)
            writer.tag(14, WireType.Varint).int32(message.caseQuantity);
        /* map<string, rd.product.Product.OrderSettings> order_settings_by_location_id = 15; */
        for (let k of Object.keys(message.orderSettingsByLocationId)) {
            writer.tag(15, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product_OrderSettings.internalBinaryWrite(message.orderSettingsByLocationId[k], writer, options);
            writer.join().join();
        }
        /* map<string, string> bin_locations_by_location_id = 16; */
        for (let k of Object.keys(message.binLocationsByLocationId))
            writer.tag(16, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.binLocationsByLocationId[k]).join();
        /* repeated rd.product.Product.QuantityBreak quantity_breaks = 17; */
        for (let i = 0; i < message.quantityBreaks.length; i++)
            Product_QuantityBreak.internalBinaryWrite(message.quantityBreaks[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product.TaxStatus tax_status = 18; */
        if (message.taxStatus !== 0)
            writer.tag(18, WireType.Varint).int32(message.taxStatus);
        /* repeated rd.product.Product.Cost costs = 19; */
        for (let i = 0; i < message.costs.length; i++)
            Product_Cost.internalBinaryWrite(message.costs[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney average_cost = 20; */
        if (message.averageCost)
            FractionalMoney.internalBinaryWrite(message.averageCost, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.price.Tier tier_prices = 26; */
        for (let i = 0; i < message.tierPrices.length; i++)
            Tier.internalBinaryWrite(message.tierPrices[i], writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 27; */
        if (message.notes !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.notes);
        /* string sales_reminder = 28; */
        if (message.salesReminder !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.salesReminder);
        /* repeated rd.proto.uuid.UUID tag_ids = 29; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* string template_sale_product_notes = 30; */
        if (message.templateSaleProductNotes !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.templateSaleProductNotes);
        /* string template_order_product_notes = 31; */
        if (message.templateOrderProductNotes !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.templateOrderProductNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.product.Product
 */
export const Product = new Product$Type();
