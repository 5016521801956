/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/billpayment/service.proto" (package "rd.api.billpayment", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "../../user/models_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { LedgerEntry } from "../../ledger/models_pb";
import { Customer } from "../../customer/models_pb";
import { Card } from "../../payments/models_pb";
import { PurchaseOrder } from "../../customer/models_pb";
import { Sale } from "../../txn/models_pb";
import { BillPayment } from "../../txn/models_pb";
import { LedgerEntry_Refund_Method } from "../../ledger/models_pb";
import { Decimal } from "../../proto/decimal/models_pb";
import { PaymentMetadata } from "../../txn/models_pb";
import { Money } from "../../proto/money/models_pb";
import { UUID } from "../../proto/uuid/models_pb";
/**
 * @generated from protobuf message rd.api.billpayment.CreateBillPaymentV3Req
 */
export interface CreateBillPaymentV3Req {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 1;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 3;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    }; // positive
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 4;
     */
    financingChargeAllocation?: Money; // positive
    /**
     * @generated from protobuf field: string notes = 5;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.txn.PaymentMetadata payment = 6;
     */
    payment?: PaymentMetadata;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 7;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal card_surcharge_rate = 8;
     */
    cardSurchargeRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal tax_rate = 9;
     */
    taxRate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 10;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: string idempotency_key = 11;
     */
    idempotencyKey: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID printer_id = 12;
     */
    printerId?: UUID;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_finance_charge_id = 13;
     */
    allocationsByFinanceChargeId: {
        [key: string]: Money;
    }; // positive
}
/**
 * @generated from protobuf message rd.api.billpayment.CreateBillPaymentV3Res
 */
export interface CreateBillPaymentV3Res {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.RefundBillPaymentReq
 */
export interface RefundBillPaymentReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: string notes = 3;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Refund.Method refund_method = 5;
     */
    refundMethod: LedgerEntry_Refund_Method;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID printer_id = 6;
     */
    printerId?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.RefundBillPaymentRes
 */
export interface RefundBillPaymentRes {
}
/**
 * @generated from protobuf message rd.api.billpayment.CreateWriteOffReq
 */
export interface CreateWriteOffReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 1;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 2;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 3;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 4;
     */
    financingChargeAllocation?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 5;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: string notes = 6;
     */
    notes: string;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_finance_charge_id = 7;
     */
    allocationsByFinanceChargeId: {
        [key: string]: Money;
    };
}
/**
 * @generated from protobuf message rd.api.billpayment.CreateWriteOffRes
 */
export interface CreateWriteOffRes {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentReq
 */
export interface GetBillPaymentReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentRes
 */
export interface GetBillPaymentRes {
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 1;
     */
    billPayment?: BillPayment;
    /**
     * @generated from protobuf field: rd.proto.money.Money amount = 4;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 5;
     */
    financingChargeAllocation?: Money;
    /**
     * @generated from protobuf field: map<string, rd.txn.Sale> sales_by_id = 6;
     */
    salesById: {
        [key: string]: Sale;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> allocations_by_sale_id = 7;
     */
    allocationsBySaleId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.customer.PurchaseOrder> purchase_orders_by_id = 9;
     */
    purchaseOrdersById: {
        [key: string]: PurchaseOrder;
    };
    /**
     * @generated from protobuf field: rd.payments.Card payment_card = 10;
     */
    paymentCard?: Card;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 11;
     */
    cardSurcharge?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 12;
     */
    cardSurchargeTax?: Money;
    /**
     * @generated from protobuf field: string payment_method = 13;
     */
    paymentMethod: string;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> discounts_by_sale_id = 14;
     */
    discountsBySaleId: {
        [key: string]: Money;
    }; // negative amounts
    /**
     * @generated from protobuf field: rd.proto.money.Money discounts_total = 15;
     */
    discountsTotal?: Money; // negative
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> reverse_discounts_by_sale_id = 16;
     */
    reverseDiscountsBySaleId: {
        [key: string]: Money;
    }; // positive amounts
    /**
     * @generated from protobuf field: rd.proto.money.Money reverse_discounts_total = 17;
     */
    reverseDiscountsTotal?: Money; // positive
    /**
     * @generated from protobuf field: map<string, rd.customer.Customer> customers_by_id = 18;
     */
    customersById: {
        [key: string]: Customer;
    };
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry payment_ledger_entry = 19;
     */
    paymentLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry write_off_ledger_entry = 20;
     */
    writeOffLedgerEntry?: LedgerEntry;
    /**
     * @generated from protobuf field: repeated rd.api.billpayment.GetBillPaymentRes.AllocatedFinanceCharge allocated_finance_charges = 21;
     */
    allocatedFinanceCharges: GetBillPaymentRes_AllocatedFinanceCharge[];
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentRes.AllocatedFinanceCharge
 */
export interface GetBillPaymentRes_AllocatedFinanceCharge {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string identifier = 2;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money allocated_amount = 3;
     */
    allocatedAmount?: Money;
    /**
     * @generated from protobuf field: string location_prefix = 4;
     */
    locationPrefix: string;
    /**
     * @generated from protobuf field: string location_name = 5;
     */
    locationName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp assessed_at = 6;
     */
    assessedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 7;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 8;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: string customer_identifier = 9;
     */
    customerIdentifier: string;
    /**
     * @generated from protobuf field: string customer_name = 10;
     */
    customerName: string;
    /**
     * @generated from protobuf field: bool is_job_finance_charge = 11;
     */
    isJobFinanceCharge: boolean;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetScannedBillPaymentReq
 */
export interface GetScannedBillPaymentReq {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetScannedBillPaymentRes
 */
export interface GetScannedBillPaymentRes {
    /**
     * @generated from protobuf field: rd.txn.BillPayment bill_payment = 1;
     */
    billPayment?: BillPayment;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentPDFReq
 */
export interface GetBillPaymentPDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetBillPaymentPDFRes
 */
export interface GetBillPaymentPDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.billpayment.EmailBillPaymentPDFReq
 */
export interface EmailBillPaymentPDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: repeated string customer_contact_id_strings = 2;
     */
    customerContactIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string additional_emails = 3;
     */
    additionalEmails: string[];
}
/**
 * @generated from protobuf message rd.api.billpayment.EmailBillPaymentPDFRes
 */
export interface EmailBillPaymentPDFRes {
}
/**
 * @generated from protobuf message rd.api.billpayment.UpdateBillPaymentDateReq
 */
export interface UpdateBillPaymentDateReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
     */
    timestamp?: Timestamp;
}
/**
 * @generated from protobuf message rd.api.billpayment.UpdateBillPaymentDateRes
 */
export interface UpdateBillPaymentDateRes {
}
/**
 * @generated from protobuf message rd.api.billpayment.GetFinanceChargeReq
 */
export interface GetFinanceChargeReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetFinanceChargeRes
 */
export interface GetFinanceChargeRes {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string;
    /**
     * @generated from protobuf field: string location_prefix = 2;
     */
    locationPrefix: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 3;
     */
    customerId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp assessed_at = 4;
     */
    assessedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money adjustment = 5;
     */
    adjustment?: Money;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal rate = 6;
     */
    rate?: Decimal;
    /**
     * @generated from protobuf field: rd.proto.money.Money total = 7;
     */
    total?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money net_allocated_amount = 8;
     */
    netAllocatedAmount?: Money;
    /**
     * @generated from protobuf field: rd.proto.money.Money total_balance = 9;
     */
    totalBalance?: Money;
    /**
     * @generated from protobuf field: repeated rd.api.billpayment.GetFinanceChargeRes.AssessedTransaction assessed_transactions = 10;
     */
    assessedTransactions: GetFinanceChargeRes_AssessedTransaction[];
}
/**
 * @generated from protobuf message rd.api.billpayment.GetFinanceChargeRes.AssessedTransaction
 */
export interface GetFinanceChargeRes_AssessedTransaction {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: string identifier = 2;
     */
    identifier: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp due_at = 4;
     */
    dueAt?: Timestamp;
    /**
     * @generated from protobuf field: string transaction_type = 5;
     */
    transactionType: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID job_id = 6;
     */
    jobId?: UUID;
    /**
     * @generated from protobuf field: string job_identifier = 7;
     */
    jobIdentifier: string;
    /**
     * @generated from protobuf field: rd.proto.money.Money balance = 8;
     */
    balance?: Money;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetFinanceChargePDFReq
 */
export interface GetFinanceChargePDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
}
/**
 * @generated from protobuf message rd.api.billpayment.GetFinanceChargePDFRes
 */
export interface GetFinanceChargePDFRes {
    /**
     * @generated from protobuf field: bytes bytes = 1;
     */
    bytes: Uint8Array;
}
/**
 * @generated from protobuf message rd.api.billpayment.EmailFinanceChargePDFReq
 */
export interface EmailFinanceChargePDFReq {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: repeated string customer_contact_id_strings = 2;
     */
    customerContactIdStrings: string[];
    /**
     * @generated from protobuf field: repeated string additional_emails = 3;
     */
    additionalEmails: string[];
}
/**
 * @generated from protobuf message rd.api.billpayment.EmailFinanceChargePDFRes
 */
export interface EmailFinanceChargePDFRes {
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateBillPaymentV3Req$Type extends MessageType<CreateBillPaymentV3Req> {
    constructor() {
        super("rd.api.billpayment.CreateBillPaymentV3Req", [
            { no: 1, name: "customer_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 4, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "payment", kind: "message", T: () => PaymentMetadata },
            { no: 7, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 8, name: "card_surcharge_rate", kind: "message", T: () => Decimal },
            { no: 9, name: "tax_rate", kind: "message", T: () => Decimal },
            { no: 10, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 11, name: "idempotency_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "printer_id", kind: "message", T: () => UUID },
            { no: 13, name: "allocations_by_finance_charge_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } }
        ]);
    }
    create(value?: PartialMessage<CreateBillPaymentV3Req>): CreateBillPaymentV3Req {
        const message = { allocationsBySaleId: {}, notes: "", idempotencyKey: "", allocationsByFinanceChargeId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateBillPaymentV3Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBillPaymentV3Req): CreateBillPaymentV3Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID customer_id */ 1:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 3:
                    this.binaryReadMap3(message.allocationsBySaleId, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 4:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* rd.txn.PaymentMetadata payment */ 6:
                    message.payment = PaymentMetadata.internalBinaryRead(reader, reader.uint32(), options, message.payment);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 7:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.decimal.Decimal card_surcharge_rate */ 8:
                    message.cardSurchargeRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeRate);
                    break;
                case /* rd.proto.decimal.Decimal tax_rate */ 9:
                    message.taxRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.taxRate);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 10:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* string idempotency_key */ 11:
                    message.idempotencyKey = reader.string();
                    break;
                case /* rd.proto.uuid.UUID printer_id */ 12:
                    message.printerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.printerId);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_finance_charge_id */ 13:
                    this.binaryReadMap13(message.allocationsByFinanceChargeId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CreateBillPaymentV3Req["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateBillPaymentV3Req["allocationsBySaleId"] | undefined, val: CreateBillPaymentV3Req["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.CreateBillPaymentV3Req.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap13(map: CreateBillPaymentV3Req["allocationsByFinanceChargeId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateBillPaymentV3Req["allocationsByFinanceChargeId"] | undefined, val: CreateBillPaymentV3Req["allocationsByFinanceChargeId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.CreateBillPaymentV3Req.allocations_by_finance_charge_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CreateBillPaymentV3Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID customer_id = 1; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 3; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 4; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* rd.txn.PaymentMetadata payment = 6; */
        if (message.payment)
            PaymentMetadata.internalBinaryWrite(message.payment, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 7; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal card_surcharge_rate = 8; */
        if (message.cardSurchargeRate)
            Decimal.internalBinaryWrite(message.cardSurchargeRate, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal tax_rate = 9; */
        if (message.taxRate)
            Decimal.internalBinaryWrite(message.taxRate, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 10; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string idempotency_key = 11; */
        if (message.idempotencyKey !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.idempotencyKey);
        /* rd.proto.uuid.UUID printer_id = 12; */
        if (message.printerId)
            UUID.internalBinaryWrite(message.printerId, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> allocations_by_finance_charge_id = 13; */
        for (let k of Object.keys(message.allocationsByFinanceChargeId)) {
            writer.tag(13, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsByFinanceChargeId[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateBillPaymentV3Req
 */
export const CreateBillPaymentV3Req = new CreateBillPaymentV3Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateBillPaymentV3Res$Type extends MessageType<CreateBillPaymentV3Res> {
    constructor() {
        super("rd.api.billpayment.CreateBillPaymentV3Res", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateBillPaymentV3Res>): CreateBillPaymentV3Res {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateBillPaymentV3Res>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBillPaymentV3Res): CreateBillPaymentV3Res {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBillPaymentV3Res, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateBillPaymentV3Res
 */
export const CreateBillPaymentV3Res = new CreateBillPaymentV3Res$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundBillPaymentReq$Type extends MessageType<RefundBillPaymentReq> {
    constructor() {
        super("rd.api.billpayment.RefundBillPaymentReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 5, name: "refund_method", kind: "enum", T: () => ["rd.ledger.LedgerEntry.Refund.Method", LedgerEntry_Refund_Method, "METHOD_"], options: { "rd.validator.require": true } },
            { no: 6, name: "printer_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<RefundBillPaymentReq>): RefundBillPaymentReq {
        const message = { notes: "", refundMethod: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefundBillPaymentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundBillPaymentReq): RefundBillPaymentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* rd.ledger.LedgerEntry.Refund.Method refund_method */ 5:
                    message.refundMethod = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID printer_id */ 6:
                    message.printerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.printerId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefundBillPaymentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* rd.ledger.LedgerEntry.Refund.Method refund_method = 5; */
        if (message.refundMethod !== 0)
            writer.tag(5, WireType.Varint).int32(message.refundMethod);
        /* rd.proto.uuid.UUID printer_id = 6; */
        if (message.printerId)
            UUID.internalBinaryWrite(message.printerId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.RefundBillPaymentReq
 */
export const RefundBillPaymentReq = new RefundBillPaymentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundBillPaymentRes$Type extends MessageType<RefundBillPaymentRes> {
    constructor() {
        super("rd.api.billpayment.RefundBillPaymentRes", []);
    }
    create(value?: PartialMessage<RefundBillPaymentRes>): RefundBillPaymentRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefundBillPaymentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundBillPaymentRes): RefundBillPaymentRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RefundBillPaymentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.RefundBillPaymentRes
 */
export const RefundBillPaymentRes = new RefundBillPaymentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWriteOffReq$Type extends MessageType<CreateWriteOffReq> {
    constructor() {
        super("rd.api.billpayment.CreateWriteOffReq", [
            { no: 1, name: "customer_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "location_id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 3, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 4, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 5, name: "amount", kind: "message", T: () => Money, options: { "rd.validator.require": true } },
            { no: 6, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } },
            { no: 7, name: "allocations_by_finance_charge_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } }
        ]);
    }
    create(value?: PartialMessage<CreateWriteOffReq>): CreateWriteOffReq {
        const message = { allocationsBySaleId: {}, notes: "", allocationsByFinanceChargeId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateWriteOffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWriteOffReq): CreateWriteOffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID customer_id */ 1:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 2:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 3:
                    this.binaryReadMap3(message.allocationsBySaleId, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 4:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* rd.proto.money.Money amount */ 5:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* string notes */ 6:
                    message.notes = reader.string();
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_finance_charge_id */ 7:
                    this.binaryReadMap7(message.allocationsByFinanceChargeId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CreateWriteOffReq["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateWriteOffReq["allocationsBySaleId"] | undefined, val: CreateWriteOffReq["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.CreateWriteOffReq.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap7(map: CreateWriteOffReq["allocationsByFinanceChargeId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateWriteOffReq["allocationsByFinanceChargeId"] | undefined, val: CreateWriteOffReq["allocationsByFinanceChargeId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.CreateWriteOffReq.allocations_by_finance_charge_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: CreateWriteOffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID customer_id = 1; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 2; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 3; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 4; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount = 5; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 6; */
        if (message.notes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.notes);
        /* map<string, rd.proto.money.Money> allocations_by_finance_charge_id = 7; */
        for (let k of Object.keys(message.allocationsByFinanceChargeId)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsByFinanceChargeId[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateWriteOffReq
 */
export const CreateWriteOffReq = new CreateWriteOffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWriteOffRes$Type extends MessageType<CreateWriteOffRes> {
    constructor() {
        super("rd.api.billpayment.CreateWriteOffRes", [
            { no: 1, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<CreateWriteOffRes>): CreateWriteOffRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateWriteOffRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWriteOffRes): CreateWriteOffRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateWriteOffRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.CreateWriteOffRes
 */
export const CreateWriteOffRes = new CreateWriteOffRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentReq$Type extends MessageType<GetBillPaymentReq> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentReq>): GetBillPaymentReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentReq): GetBillPaymentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillPaymentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentReq
 */
export const GetBillPaymentReq = new GetBillPaymentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentRes$Type extends MessageType<GetBillPaymentRes> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentRes", [
            { no: 1, name: "bill_payment", kind: "message", T: () => BillPayment },
            { no: 4, name: "amount", kind: "message", T: () => Money },
            { no: 5, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 6, name: "sales_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Sale } },
            { no: 7, name: "allocations_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 9, name: "purchase_orders_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PurchaseOrder } },
            { no: 10, name: "payment_card", kind: "message", T: () => Card },
            { no: 11, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 12, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 13, name: "payment_method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "discounts_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 15, name: "discounts_total", kind: "message", T: () => Money },
            { no: 16, name: "reverse_discounts_by_sale_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 17, name: "reverse_discounts_total", kind: "message", T: () => Money },
            { no: 18, name: "customers_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Customer } },
            { no: 19, name: "payment_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 20, name: "write_off_ledger_entry", kind: "message", T: () => LedgerEntry },
            { no: 21, name: "allocated_finance_charges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetBillPaymentRes_AllocatedFinanceCharge }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentRes>): GetBillPaymentRes {
        const message = { salesById: {}, allocationsBySaleId: {}, purchaseOrdersById: {}, paymentMethod: "", discountsBySaleId: {}, reverseDiscountsBySaleId: {}, customersById: {}, allocatedFinanceCharges: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentRes): GetBillPaymentRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.BillPayment bill_payment */ 1:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                case /* rd.proto.money.Money amount */ 4:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 5:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* map<string, rd.txn.Sale> sales_by_id */ 6:
                    this.binaryReadMap6(message.salesById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> allocations_by_sale_id */ 7:
                    this.binaryReadMap7(message.allocationsBySaleId, reader, options);
                    break;
                case /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_id */ 9:
                    this.binaryReadMap9(message.purchaseOrdersById, reader, options);
                    break;
                case /* rd.payments.Card payment_card */ 10:
                    message.paymentCard = Card.internalBinaryRead(reader, reader.uint32(), options, message.paymentCard);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 11:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 12:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* string payment_method */ 13:
                    message.paymentMethod = reader.string();
                    break;
                case /* map<string, rd.proto.money.Money> discounts_by_sale_id */ 14:
                    this.binaryReadMap14(message.discountsBySaleId, reader, options);
                    break;
                case /* rd.proto.money.Money discounts_total */ 15:
                    message.discountsTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.discountsTotal);
                    break;
                case /* map<string, rd.proto.money.Money> reverse_discounts_by_sale_id */ 16:
                    this.binaryReadMap16(message.reverseDiscountsBySaleId, reader, options);
                    break;
                case /* rd.proto.money.Money reverse_discounts_total */ 17:
                    message.reverseDiscountsTotal = Money.internalBinaryRead(reader, reader.uint32(), options, message.reverseDiscountsTotal);
                    break;
                case /* map<string, rd.customer.Customer> customers_by_id */ 18:
                    this.binaryReadMap18(message.customersById, reader, options);
                    break;
                case /* rd.ledger.LedgerEntry payment_ledger_entry */ 19:
                    message.paymentLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntry);
                    break;
                case /* rd.ledger.LedgerEntry write_off_ledger_entry */ 20:
                    message.writeOffLedgerEntry = LedgerEntry.internalBinaryRead(reader, reader.uint32(), options, message.writeOffLedgerEntry);
                    break;
                case /* repeated rd.api.billpayment.GetBillPaymentRes.AllocatedFinanceCharge allocated_finance_charges */ 21:
                    message.allocatedFinanceCharges.push(GetBillPaymentRes_AllocatedFinanceCharge.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: GetBillPaymentRes["salesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["salesById"] | undefined, val: GetBillPaymentRes["salesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Sale.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.sales_by_id");
            }
        }
        map[key ?? ""] = val ?? Sale.create();
    }
    private binaryReadMap7(map: GetBillPaymentRes["allocationsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["allocationsBySaleId"] | undefined, val: GetBillPaymentRes["allocationsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.allocations_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap9(map: GetBillPaymentRes["purchaseOrdersById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["purchaseOrdersById"] | undefined, val: GetBillPaymentRes["purchaseOrdersById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PurchaseOrder.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.purchase_orders_by_id");
            }
        }
        map[key ?? ""] = val ?? PurchaseOrder.create();
    }
    private binaryReadMap14(map: GetBillPaymentRes["discountsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["discountsBySaleId"] | undefined, val: GetBillPaymentRes["discountsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.discounts_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap16(map: GetBillPaymentRes["reverseDiscountsBySaleId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["reverseDiscountsBySaleId"] | undefined, val: GetBillPaymentRes["reverseDiscountsBySaleId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.reverse_discounts_by_sale_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap18(map: GetBillPaymentRes["customersById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetBillPaymentRes["customersById"] | undefined, val: GetBillPaymentRes["customersById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Customer.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.api.billpayment.GetBillPaymentRes.customers_by_id");
            }
        }
        map[key ?? ""] = val ?? Customer.create();
    }
    internalBinaryWrite(message: GetBillPaymentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.BillPayment bill_payment = 1; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount = 4; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money financing_charge_allocation = 5; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.txn.Sale> sales_by_id = 6; */
        for (let k of Object.keys(message.salesById)) {
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Sale.internalBinaryWrite(message.salesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> allocations_by_sale_id = 7; */
        for (let k of Object.keys(message.allocationsBySaleId)) {
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.allocationsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.customer.PurchaseOrder> purchase_orders_by_id = 9; */
        for (let k of Object.keys(message.purchaseOrdersById)) {
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PurchaseOrder.internalBinaryWrite(message.purchaseOrdersById[k], writer, options);
            writer.join().join();
        }
        /* rd.payments.Card payment_card = 10; */
        if (message.paymentCard)
            Card.internalBinaryWrite(message.paymentCard, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 11; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 12; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string payment_method = 13; */
        if (message.paymentMethod !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.paymentMethod);
        /* map<string, rd.proto.money.Money> discounts_by_sale_id = 14; */
        for (let k of Object.keys(message.discountsBySaleId)) {
            writer.tag(14, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.discountsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money discounts_total = 15; */
        if (message.discountsTotal)
            Money.internalBinaryWrite(message.discountsTotal, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> reverse_discounts_by_sale_id = 16; */
        for (let k of Object.keys(message.reverseDiscountsBySaleId)) {
            writer.tag(16, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.reverseDiscountsBySaleId[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money reverse_discounts_total = 17; */
        if (message.reverseDiscountsTotal)
            Money.internalBinaryWrite(message.reverseDiscountsTotal, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.customer.Customer> customers_by_id = 18; */
        for (let k of Object.keys(message.customersById)) {
            writer.tag(18, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Customer.internalBinaryWrite(message.customersById[k], writer, options);
            writer.join().join();
        }
        /* rd.ledger.LedgerEntry payment_ledger_entry = 19; */
        if (message.paymentLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.paymentLedgerEntry, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry write_off_ledger_entry = 20; */
        if (message.writeOffLedgerEntry)
            LedgerEntry.internalBinaryWrite(message.writeOffLedgerEntry, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.api.billpayment.GetBillPaymentRes.AllocatedFinanceCharge allocated_finance_charges = 21; */
        for (let i = 0; i < message.allocatedFinanceCharges.length; i++)
            GetBillPaymentRes_AllocatedFinanceCharge.internalBinaryWrite(message.allocatedFinanceCharges[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentRes
 */
export const GetBillPaymentRes = new GetBillPaymentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentRes_AllocatedFinanceCharge$Type extends MessageType<GetBillPaymentRes_AllocatedFinanceCharge> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentRes.AllocatedFinanceCharge", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "allocated_amount", kind: "message", T: () => Money },
            { no: 4, name: "location_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "assessed_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "created_by", kind: "message", T: () => User },
            { no: 8, name: "customer_id", kind: "message", T: () => UUID },
            { no: 9, name: "customer_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_job_finance_charge", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentRes_AllocatedFinanceCharge>): GetBillPaymentRes_AllocatedFinanceCharge {
        const message = { identifier: "", locationPrefix: "", locationName: "", customerIdentifier: "", customerName: "", isJobFinanceCharge: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentRes_AllocatedFinanceCharge>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentRes_AllocatedFinanceCharge): GetBillPaymentRes_AllocatedFinanceCharge {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string identifier */ 2:
                    message.identifier = reader.string();
                    break;
                case /* rd.proto.money.Money allocated_amount */ 3:
                    message.allocatedAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.allocatedAmount);
                    break;
                case /* string location_prefix */ 4:
                    message.locationPrefix = reader.string();
                    break;
                case /* string location_name */ 5:
                    message.locationName = reader.string();
                    break;
                case /* google.protobuf.Timestamp assessed_at */ 6:
                    message.assessedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.assessedAt);
                    break;
                case /* rd.user.User created_by */ 7:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 8:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* string customer_identifier */ 9:
                    message.customerIdentifier = reader.string();
                    break;
                case /* string customer_name */ 10:
                    message.customerName = reader.string();
                    break;
                case /* bool is_job_finance_charge */ 11:
                    message.isJobFinanceCharge = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillPaymentRes_AllocatedFinanceCharge, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 2; */
        if (message.identifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.identifier);
        /* rd.proto.money.Money allocated_amount = 3; */
        if (message.allocatedAmount)
            Money.internalBinaryWrite(message.allocatedAmount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string location_prefix = 4; */
        if (message.locationPrefix !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locationPrefix);
        /* string location_name = 5; */
        if (message.locationName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.locationName);
        /* google.protobuf.Timestamp assessed_at = 6; */
        if (message.assessedAt)
            Timestamp.internalBinaryWrite(message.assessedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 7; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 8; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string customer_identifier = 9; */
        if (message.customerIdentifier !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.customerIdentifier);
        /* string customer_name = 10; */
        if (message.customerName !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.customerName);
        /* bool is_job_finance_charge = 11; */
        if (message.isJobFinanceCharge !== false)
            writer.tag(11, WireType.Varint).bool(message.isJobFinanceCharge);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentRes.AllocatedFinanceCharge
 */
export const GetBillPaymentRes_AllocatedFinanceCharge = new GetBillPaymentRes_AllocatedFinanceCharge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedBillPaymentReq$Type extends MessageType<GetScannedBillPaymentReq> {
    constructor() {
        super("rd.api.billpayment.GetScannedBillPaymentReq", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetScannedBillPaymentReq>): GetScannedBillPaymentReq {
        const message = { identifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedBillPaymentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedBillPaymentReq): GetScannedBillPaymentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedBillPaymentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetScannedBillPaymentReq
 */
export const GetScannedBillPaymentReq = new GetScannedBillPaymentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetScannedBillPaymentRes$Type extends MessageType<GetScannedBillPaymentRes> {
    constructor() {
        super("rd.api.billpayment.GetScannedBillPaymentRes", [
            { no: 1, name: "bill_payment", kind: "message", T: () => BillPayment }
        ]);
    }
    create(value?: PartialMessage<GetScannedBillPaymentRes>): GetScannedBillPaymentRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetScannedBillPaymentRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetScannedBillPaymentRes): GetScannedBillPaymentRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.txn.BillPayment bill_payment */ 1:
                    message.billPayment = BillPayment.internalBinaryRead(reader, reader.uint32(), options, message.billPayment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetScannedBillPaymentRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.txn.BillPayment bill_payment = 1; */
        if (message.billPayment)
            BillPayment.internalBinaryWrite(message.billPayment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetScannedBillPaymentRes
 */
export const GetScannedBillPaymentRes = new GetScannedBillPaymentRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentPDFReq$Type extends MessageType<GetBillPaymentPDFReq> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentPDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentPDFReq>): GetBillPaymentPDFReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentPDFReq): GetBillPaymentPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillPaymentPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentPDFReq
 */
export const GetBillPaymentPDFReq = new GetBillPaymentPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBillPaymentPDFRes$Type extends MessageType<GetBillPaymentPDFRes> {
    constructor() {
        super("rd.api.billpayment.GetBillPaymentPDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetBillPaymentPDFRes>): GetBillPaymentPDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBillPaymentPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBillPaymentPDFRes): GetBillPaymentPDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBillPaymentPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetBillPaymentPDFRes
 */
export const GetBillPaymentPDFRes = new GetBillPaymentPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailBillPaymentPDFReq$Type extends MessageType<EmailBillPaymentPDFReq> {
    constructor() {
        super("rd.api.billpayment.EmailBillPaymentPDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "customer_contact_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "additional_emails", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EmailBillPaymentPDFReq>): EmailBillPaymentPDFReq {
        const message = { customerContactIdStrings: [], additionalEmails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailBillPaymentPDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailBillPaymentPDFReq): EmailBillPaymentPDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* repeated string customer_contact_id_strings */ 2:
                    message.customerContactIdStrings.push(reader.string());
                    break;
                case /* repeated string additional_emails */ 3:
                    message.additionalEmails.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EmailBillPaymentPDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string customer_contact_id_strings = 2; */
        for (let i = 0; i < message.customerContactIdStrings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.customerContactIdStrings[i]);
        /* repeated string additional_emails = 3; */
        for (let i = 0; i < message.additionalEmails.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.additionalEmails[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.EmailBillPaymentPDFReq
 */
export const EmailBillPaymentPDFReq = new EmailBillPaymentPDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailBillPaymentPDFRes$Type extends MessageType<EmailBillPaymentPDFRes> {
    constructor() {
        super("rd.api.billpayment.EmailBillPaymentPDFRes", []);
    }
    create(value?: PartialMessage<EmailBillPaymentPDFRes>): EmailBillPaymentPDFRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailBillPaymentPDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailBillPaymentPDFRes): EmailBillPaymentPDFRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmailBillPaymentPDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.EmailBillPaymentPDFRes
 */
export const EmailBillPaymentPDFRes = new EmailBillPaymentPDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBillPaymentDateReq$Type extends MessageType<UpdateBillPaymentDateReq> {
    constructor() {
        super("rd.api.billpayment.UpdateBillPaymentDateReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "timestamp", kind: "message", T: () => Timestamp, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<UpdateBillPaymentDateReq>): UpdateBillPaymentDateReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBillPaymentDateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBillPaymentDateReq): UpdateBillPaymentDateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp timestamp */ 2:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateBillPaymentDateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp timestamp = 2; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.UpdateBillPaymentDateReq
 */
export const UpdateBillPaymentDateReq = new UpdateBillPaymentDateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBillPaymentDateRes$Type extends MessageType<UpdateBillPaymentDateRes> {
    constructor() {
        super("rd.api.billpayment.UpdateBillPaymentDateRes", []);
    }
    create(value?: PartialMessage<UpdateBillPaymentDateRes>): UpdateBillPaymentDateRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBillPaymentDateRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBillPaymentDateRes): UpdateBillPaymentDateRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateBillPaymentDateRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.UpdateBillPaymentDateRes
 */
export const UpdateBillPaymentDateRes = new UpdateBillPaymentDateRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFinanceChargeReq$Type extends MessageType<GetFinanceChargeReq> {
    constructor() {
        super("rd.api.billpayment.GetFinanceChargeReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetFinanceChargeReq>): GetFinanceChargeReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFinanceChargeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFinanceChargeReq): GetFinanceChargeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFinanceChargeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetFinanceChargeReq
 */
export const GetFinanceChargeReq = new GetFinanceChargeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFinanceChargeRes$Type extends MessageType<GetFinanceChargeRes> {
    constructor() {
        super("rd.api.billpayment.GetFinanceChargeRes", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "message", T: () => UUID },
            { no: 4, name: "assessed_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "adjustment", kind: "message", T: () => Money },
            { no: 6, name: "rate", kind: "message", T: () => Decimal },
            { no: 7, name: "total", kind: "message", T: () => Money },
            { no: 8, name: "net_allocated_amount", kind: "message", T: () => Money },
            { no: 9, name: "total_balance", kind: "message", T: () => Money },
            { no: 10, name: "assessed_transactions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetFinanceChargeRes_AssessedTransaction }
        ]);
    }
    create(value?: PartialMessage<GetFinanceChargeRes>): GetFinanceChargeRes {
        const message = { identifier: "", locationPrefix: "", assessedTransactions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFinanceChargeRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFinanceChargeRes): GetFinanceChargeRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* string location_prefix */ 2:
                    message.locationPrefix = reader.string();
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 3:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* google.protobuf.Timestamp assessed_at */ 4:
                    message.assessedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.assessedAt);
                    break;
                case /* rd.proto.money.Money adjustment */ 5:
                    message.adjustment = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustment);
                    break;
                case /* rd.proto.decimal.Decimal rate */ 6:
                    message.rate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.rate);
                    break;
                case /* rd.proto.money.Money total */ 7:
                    message.total = Money.internalBinaryRead(reader, reader.uint32(), options, message.total);
                    break;
                case /* rd.proto.money.Money net_allocated_amount */ 8:
                    message.netAllocatedAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.netAllocatedAmount);
                    break;
                case /* rd.proto.money.Money total_balance */ 9:
                    message.totalBalance = Money.internalBinaryRead(reader, reader.uint32(), options, message.totalBalance);
                    break;
                case /* repeated rd.api.billpayment.GetFinanceChargeRes.AssessedTransaction assessed_transactions */ 10:
                    message.assessedTransactions.push(GetFinanceChargeRes_AssessedTransaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFinanceChargeRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* string location_prefix = 2; */
        if (message.locationPrefix !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locationPrefix);
        /* rd.proto.uuid.UUID customer_id = 3; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp assessed_at = 4; */
        if (message.assessedAt)
            Timestamp.internalBinaryWrite(message.assessedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjustment = 5; */
        if (message.adjustment)
            Money.internalBinaryWrite(message.adjustment, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.decimal.Decimal rate = 6; */
        if (message.rate)
            Decimal.internalBinaryWrite(message.rate, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total = 7; */
        if (message.total)
            Money.internalBinaryWrite(message.total, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money net_allocated_amount = 8; */
        if (message.netAllocatedAmount)
            Money.internalBinaryWrite(message.netAllocatedAmount, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money total_balance = 9; */
        if (message.totalBalance)
            Money.internalBinaryWrite(message.totalBalance, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.api.billpayment.GetFinanceChargeRes.AssessedTransaction assessed_transactions = 10; */
        for (let i = 0; i < message.assessedTransactions.length; i++)
            GetFinanceChargeRes_AssessedTransaction.internalBinaryWrite(message.assessedTransactions[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetFinanceChargeRes
 */
export const GetFinanceChargeRes = new GetFinanceChargeRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFinanceChargeRes_AssessedTransaction$Type extends MessageType<GetFinanceChargeRes_AssessedTransaction> {
    constructor() {
        super("rd.api.billpayment.GetFinanceChargeRes.AssessedTransaction", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "due_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "transaction_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "job_id", kind: "message", T: () => UUID },
            { no: 7, name: "job_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "balance", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<GetFinanceChargeRes_AssessedTransaction>): GetFinanceChargeRes_AssessedTransaction {
        const message = { identifier: "", transactionType: "", jobIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFinanceChargeRes_AssessedTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFinanceChargeRes_AssessedTransaction): GetFinanceChargeRes_AssessedTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string identifier */ 2:
                    message.identifier = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp due_at */ 4:
                    message.dueAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dueAt);
                    break;
                case /* string transaction_type */ 5:
                    message.transactionType = reader.string();
                    break;
                case /* rd.proto.uuid.UUID job_id */ 6:
                    message.jobId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.jobId);
                    break;
                case /* string job_identifier */ 7:
                    message.jobIdentifier = reader.string();
                    break;
                case /* rd.proto.money.Money balance */ 8:
                    message.balance = Money.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFinanceChargeRes_AssessedTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 2; */
        if (message.identifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.identifier);
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp due_at = 4; */
        if (message.dueAt)
            Timestamp.internalBinaryWrite(message.dueAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string transaction_type = 5; */
        if (message.transactionType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.transactionType);
        /* rd.proto.uuid.UUID job_id = 6; */
        if (message.jobId)
            UUID.internalBinaryWrite(message.jobId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string job_identifier = 7; */
        if (message.jobIdentifier !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.jobIdentifier);
        /* rd.proto.money.Money balance = 8; */
        if (message.balance)
            Money.internalBinaryWrite(message.balance, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetFinanceChargeRes.AssessedTransaction
 */
export const GetFinanceChargeRes_AssessedTransaction = new GetFinanceChargeRes_AssessedTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFinanceChargePDFReq$Type extends MessageType<GetFinanceChargePDFReq> {
    constructor() {
        super("rd.api.billpayment.GetFinanceChargePDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } }
        ]);
    }
    create(value?: PartialMessage<GetFinanceChargePDFReq>): GetFinanceChargePDFReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFinanceChargePDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFinanceChargePDFReq): GetFinanceChargePDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFinanceChargePDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetFinanceChargePDFReq
 */
export const GetFinanceChargePDFReq = new GetFinanceChargePDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFinanceChargePDFRes$Type extends MessageType<GetFinanceChargePDFRes> {
    constructor() {
        super("rd.api.billpayment.GetFinanceChargePDFRes", [
            { no: 1, name: "bytes", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetFinanceChargePDFRes>): GetFinanceChargePDFRes {
        const message = { bytes: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFinanceChargePDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFinanceChargePDFRes): GetFinanceChargePDFRes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes bytes */ 1:
                    message.bytes = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFinanceChargePDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes bytes = 1; */
        if (message.bytes.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.GetFinanceChargePDFRes
 */
export const GetFinanceChargePDFRes = new GetFinanceChargePDFRes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailFinanceChargePDFReq$Type extends MessageType<EmailFinanceChargePDFReq> {
    constructor() {
        super("rd.api.billpayment.EmailFinanceChargePDFReq", [
            { no: 1, name: "id", kind: "message", T: () => UUID, options: { "rd.validator.require": true } },
            { no: 2, name: "customer_contact_id_strings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "additional_emails", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EmailFinanceChargePDFReq>): EmailFinanceChargePDFReq {
        const message = { customerContactIdStrings: [], additionalEmails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailFinanceChargePDFReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailFinanceChargePDFReq): EmailFinanceChargePDFReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* repeated string customer_contact_id_strings */ 2:
                    message.customerContactIdStrings.push(reader.string());
                    break;
                case /* repeated string additional_emails */ 3:
                    message.additionalEmails.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EmailFinanceChargePDFReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string customer_contact_id_strings = 2; */
        for (let i = 0; i < message.customerContactIdStrings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.customerContactIdStrings[i]);
        /* repeated string additional_emails = 3; */
        for (let i = 0; i < message.additionalEmails.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.additionalEmails[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.EmailFinanceChargePDFReq
 */
export const EmailFinanceChargePDFReq = new EmailFinanceChargePDFReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailFinanceChargePDFRes$Type extends MessageType<EmailFinanceChargePDFRes> {
    constructor() {
        super("rd.api.billpayment.EmailFinanceChargePDFRes", []);
    }
    create(value?: PartialMessage<EmailFinanceChargePDFRes>): EmailFinanceChargePDFRes {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailFinanceChargePDFRes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailFinanceChargePDFRes): EmailFinanceChargePDFRes {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EmailFinanceChargePDFRes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.api.billpayment.EmailFinanceChargePDFRes
 */
export const EmailFinanceChargePDFRes = new EmailFinanceChargePDFRes$Type();
/**
 * @generated ServiceType for protobuf service rd.api.billpayment.BillPaymentService
 */
export const BillPaymentService = new ServiceType("rd.api.billpayment.BillPaymentService", [
    { name: "CreateBillPaymentV3", options: {}, I: CreateBillPaymentV3Req, O: CreateBillPaymentV3Res },
    { name: "RefundBillPayment", options: {}, I: RefundBillPaymentReq, O: RefundBillPaymentRes },
    { name: "CreateWriteOff", options: {}, I: CreateWriteOffReq, O: CreateWriteOffRes },
    { name: "GetBillPayment", options: {}, I: GetBillPaymentReq, O: GetBillPaymentRes },
    { name: "GetScannedBillPayment", options: {}, I: GetScannedBillPaymentReq, O: GetScannedBillPaymentRes },
    { name: "GetBillPaymentPDF", options: {}, I: GetBillPaymentPDFReq, O: GetBillPaymentPDFRes },
    { name: "EmailBillPaymentPDF", options: {}, I: EmailBillPaymentPDFReq, O: EmailBillPaymentPDFRes },
    { name: "UpdateBillPaymentDate", options: {}, I: UpdateBillPaymentDateReq, O: UpdateBillPaymentDateRes },
    { name: "GetFinanceCharge", options: {}, I: GetFinanceChargeReq, O: GetFinanceChargeRes },
    { name: "GetFinanceChargePDF", options: {}, I: GetFinanceChargePDFReq, O: GetFinanceChargePDFRes },
    { name: "EmailFinanceChargePDF", options: {}, I: EmailFinanceChargePDFReq, O: EmailFinanceChargePDFRes }
]);
