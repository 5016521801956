import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Sale_Status as SaleStatus } from "gen/txn/models_pb"
import { UUID } from "uuid-rd"
import { cleanStatus } from "./cleanStatus"

export function sale(id?: PBUUID, status?: SaleStatus): string {
  const statusParam = saleStatusParam(status)
  if (!statusParam) {
    return `/pos/sales/${UUID.idString(id)}`
  }
  return `/pos/sales/${UUID.idString(id)}?status=${statusParam}`
}

export function saleStatusParam(status?: SaleStatus): string {
  if (!status) {
    return ""
  }
  if (status === SaleStatus.PAID) {
    return cleanStatus(SaleStatus[SaleStatus.SOLD])
  }
  return cleanStatus(SaleStatus[status])
}
