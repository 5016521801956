import { Card, ManuallyEnteredCard } from "gen/payments/models_pb"
import { Format, Timestamp as TimestampRD } from "lib/timestamp"
import {
  PurchaseMethod_Method as Method,
  SaleProduct as PBSaleProduct,
  PaymentMetadata,
  PaymentMetadata_Cash as PaymentMetadataCash,
  PaymentMetadata_Check as PaymentMetadataCheck,
  PaymentMetadata_PresentCard as PaymentMetadataPresentCard,
  PaymentMetadata_SavedCard as PaymentMetadataSavedCard,
  PurchaseMethod,
  SaleProductView,
  Sale_Status as SaleStatus,
} from "gen/txn/models_pb"
import { SalePayment, SaleProduct } from "../types"

import { SyncCartPricesReq_CartProduct as CartProduct } from "gen/api/sale/service_pb"
import { Decimal } from "decimal"
import { Inventories } from "gen/product/models_pb"
import { Money } from "money"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { LedgerEntry_Payment_Method as PaymentMethod } from "gen/ledger/models_pb"
import { TimestampRange as TimestampRangeRD } from "gen/proto/timestamprange/models_pb"
import { UUID } from "uuid-rd"
import { capitalize } from "../../../../lib/capitalize"
import { uniqueTintIdentifier } from "../salesSlice"

export function findIdxInSaleProducts(
  saleProducts: SaleProduct[],
  id: PBUUID
): number {
  // find the index of the product in the array
  return saleProducts.findIndex((product) => {
    return UUID.eqFromPB(id, product.id)
  })
}

export function getIdxFromSaleProductsForUniqueTint(
  saleProducts: SaleProduct[],
  { productId, customTintColor, tintColorId }: uniqueTintIdentifier
) {
  if (!productId || !saleProducts || saleProducts.length === 0) {
    return undefined
  }

  const productIndex = saleProducts.findIndex(
    (product) =>
      UUID.eqFromPB(product.productId, productId) &&
      UUID.eqFromPB(product.tintColorId, tintColorId) &&
      product.customTintColor === customTintColor
  )

  if (productIndex === -1) {
    return undefined
  }

  return productIndex
}

export function makeCartProductsList(
  saleProducts?: SaleProduct[]
): CartProduct[] {
  if (!saleProducts || saleProducts.length === 0) {
    return []
  }

  const cartProducts: CartProduct[] = saleProducts.map((product) => ({
    productId: product.productId,
    id: product.id,
    quantity: product.quantity,
    productEditedPrice: product.isPriceEdited ? product.price : undefined,
    quantityReturned: product.quantityReturned,
    deposit: product.deposit,
    isSpecialOrder: product.isSpecialOrder,
  }))
  return cartProducts
}

export const SaleStatusFormatted = new Map<SaleStatus, string>([
  [SaleStatus.CREATED, "Created"],
  [SaleStatus.QUOTED, "Quoted"],
  [SaleStatus.WILL_CALLED, "Will called"],
  [SaleStatus.SOLD, "Sold"],
  [SaleStatus.PAID, "Sold"],
  [SaleStatus.VOIDED, "Voided"],
])

export function getPreviousRoute(status?: SaleStatus) {
  switch (status) {
    case SaleStatus.CREATED:
      return "drafts"
    case SaleStatus.QUOTED:
      return "quotes"
    case SaleStatus.WILL_CALLED:
      return "will-call"
    case SaleStatus.SOLD:
      return "sold"
    case SaleStatus.PAID:
      return "sold"
    case SaleStatus.VOIDED:
      return "voided"
    default:
      return "new"
  }
}

export function inventoriesFromSaleProduct(
  inventoriesByProductId: { [id: string]: Inventories },
  saleProduct: SaleProduct
): Inventories {
  return inventoriesByProductId[UUID.fromPB(saleProduct.productId!).toString()]
}

export const getPurchaseMethodsListString = (pms: PurchaseMethod[]) => {
  return [...new Set(pms.map((pm) => pm.name))].sort().join(", ")
}

export function sumPayments(payments?: PaymentMetadata[]) {
  let total = Money.zero()
  for (const payment of payments || []) {
    if (!payment.amount) {
      return Money.zero()
    }

    if (Money.fromPB(payment.amount).lte(Money.zero())) {
      return Money.zero()
    }

    total = Money.fromPB(payment.amount).add(total)
  }
  return total
}

export function makePaymentMetadata(
  payment?: SalePayment,
  method?: PaymentMethod,
  amount?: Money,
  cardReaderId?: string,
  cardSurcharge?: Money,
  cardSurchargeTax?: Money
): PaymentMetadata | null {
  if (!payment || payment.drawer === "charge_account" || !method || !amount) {
    return null
  }

  let cash: PaymentMetadataCash | undefined = undefined
  let check: PaymentMetadataCheck | undefined = undefined
  let presentCard: PaymentMetadataPresentCard | undefined = undefined
  let savedCard: PaymentMetadataSavedCard | undefined = undefined
  let manuallyEnteredCard: ManuallyEnteredCard | undefined = undefined
  let shouldSaveCard = false
  let cardNickname = ""
  switch (method) {
    case PaymentMethod.CASH:
      if (payment.drawer !== "cash") {
        return null
      }
      cash = {
        cashReceived: payment.cashReceived.toPB(),
      }
      break
    case PaymentMethod.CHECK:
      if (payment.drawer !== "check") {
        return null
      }
      check = {
        checkNumber: payment.checkNumber ?? "",
      }
      break
    case PaymentMethod.CARD_PRESENT:
      if (payment.drawer !== "card" || !cardReaderId) {
        return null
      }
      presentCard = {
        stripeReaderId: cardReaderId,
        zipCode: "", // todo(homie): follow-up frontend PRs will populate this field
      }
      shouldSaveCard = !!payment.shouldSaveCardPresent
      cardNickname = payment.cardPresentCardNickname
      break
    case PaymentMethod.CARD_SAVED:
      if (payment.drawer !== "card" || !payment.card?.id) {
        return null
      }
      savedCard = {
        stripePaymentMethodId: payment.card.id,
      }
      break
    case PaymentMethod.CARD_MANUALLY_ENTERED:
      if (
        payment.drawer !== "card" ||
        !payment.number ||
        payment.exp.length < 3 ||
        !payment.cvc ||
        !payment.zip
      ) {
        return null
      }
      manuallyEnteredCard = {
        cardNumber: payment.number,
        expirationMonth: Number(payment.exp.substring(0, 2)),
        expirationYear: Number(payment.exp.substring(3)),
        cvc: payment.cvc,
        zipCode: payment.zip,
      }
      shouldSaveCard = !!payment.shouldSaveCardManuallyEntered
      cardNickname = payment.manuallyEnteredCardNickname
      break
    default:
      return null
  }

  return {
    paymentMethod: method,
    amount: amount.toPB(),
    stripePaymentIntentId: "",
    shouldSaveCard: shouldSaveCard,
    cash,
    check,
    presentCard,
    savedCard,
    manuallyEnteredCard,
    cardSurcharge: cardSurcharge?.toPB(),
    cardSurchargeTax: cardSurchargeTax?.toPB(),
    cardNickname,
  }
}

export function paymentMetadataToText(
  paymentMetadata: PaymentMetadata,
  isPaymentComplete: boolean,
  cards: Card[]
): string {
  const cardChargeText = isPaymentComplete ? "was charged" : "will be charged"
  const amount = Money.fromPB(paymentMetadata?.amount)

  switch (paymentMetadata.paymentMethod) {
    case PaymentMethod.CASH: {
      const cashPaid = Money.fromPB(paymentMetadata.cash?.cashReceived)
      return `${amount.format()} was accepted via cash. ${cashPaid.format()} received, ${cashPaid
        .sub(amount as Money)
        .format()} change.`
    }
    case PaymentMethod.CHECK:
      return `${amount.format()} was accepted via check`
    case PaymentMethod.CARD_SAVED: {
      const card = cards.find(
        (card) => card.id === paymentMetadata.savedCard?.stripePaymentMethodId
      )
      if (!card) {
        return `${amount.format()} ${cardChargeText} to card`
      }
      return `${amount.format()} ${cardChargeText} to ${capitalize(
        card?.brand
      )} •••• •••• •••• ${card?.last4}`
    }
    case PaymentMethod.CARD_MANUALLY_ENTERED: {
      const last4 = paymentMetadata.manuallyEnteredCard?.cardNumber?.slice(-4)
      if (!last4) {
        return `${amount.format()} ${cardChargeText} to card`
      }
      return `${amount.format()} ${cardChargeText} to •••• •••• •••• ${last4}`
    }
    case PaymentMethod.CARD_PRESENT:
      return `${amount.format()} ${cardChargeText} to card`
    default:
      return ""
  }
}

export function getPurchaseType(purchaseMethods?: PurchaseMethod[]) {
  if (!purchaseMethods || purchaseMethods.length === 0) {
    return "none"
  }

  if (purchaseMethods.length > 1) {
    return "split_payment"
  }

  switch (purchaseMethods[0].method) {
    case Method.CHECK:
      return "check"
    case Method.ACCOUNT:
      return "account"
    case Method.CARD:
      return "card"
    default:
      return "cash"
  }
}

export function createPaymentCardDefaults() {
  return {
    drawer: "card" as const,
    card: null,
    shouldSaveCardPresent: false,
    cardPresentCardNickname: "",
    number: "",
    exp: "",
    cvc: "",
    zip: "",
    shouldSaveCardManuallyEntered: false,
    manuallyEnteredCardNickname: "",
  }
}

export function calculateSurchargeFromPayments(payments: PaymentMetadata[]) {
  let totalSurcharge = Money.zero()
  for (const payment of payments) {
    totalSurcharge = totalSurcharge
      .add(Money.fromPB(payment.cardSurcharge))
      .add(Money.fromPB(payment.cardSurchargeTax))
  }

  return totalSurcharge
}

export function calculateSurcharge(
  paymentAmount: Money,
  surchargeRate: Decimal,
  taxRate: Decimal
) {
  const surcharge = paymentAmount.mult(surchargeRate)
  const tax = surcharge.mult(taxRate)

  return {
    tax,
    amount: surcharge,
    total: surcharge.add(tax),
  }
}

export interface TestSetupParams {
  cardsResCards?: Card[]
  customerId?: string
}

export const formatCSVFilename = ({
  subdomain,
  timestampRange,
}: {
  subdomain: string
  timestampRange: TimestampRangeRD
}) => {
  const formattedCompanySubdomain = subdomain
    .toLocaleLowerCase()
    .replace(/\s/g, "-")
  const timestampRangeStart = TimestampRD.format(
    Format.DATE_YEAR_MONTH_DAY,
    timestampRange.start
  )
  const timestampRangeEnd = TimestampRD.format(
    Format.DATE_YEAR_MONTH_DAY,
    timestampRange.end
  )
  return { formattedCompanySubdomain, timestampRangeStart, timestampRangeEnd }
}

export const convertToSaleProductV2 = (sp: SaleProductView): PBSaleProduct => {
  const saleProduct: PBSaleProduct = PBSaleProduct.create({
    id: sp.id,
    productId: sp.productId,
    tintColorId: sp.tintColorId,
    customTintColor: sp.customTintColor ?? "",
    quantity: sp.quantity ?? 0,
    quantityReturned: sp.quantityReturned ?? 0,
    isSpecialOrder: sp.isSpecialOrder ?? false,
    isPriceEdited: sp.isPriceEdited ?? false,
    isCostEdited: sp.isCostEdited ?? false,
    notes: sp.notes ?? "",
    position: sp.position ?? 0,
    isTaxed: sp.isTaxed ?? false,
    isFeeTaxed: sp.isFeeTaxed ?? false,
    price: sp.price,
    expectedPrice: sp.expectedPrice,
    tier1Price: sp.tier1Price,
    costV2: sp.costV2,
    additionalFeeV2: sp.additionalFeeV2,
    deposit: sp.deposit,
    additionalFeeName: sp.additionalFeeName ?? "",
    colorxTintedProductId: sp.colorxTintedProductId,
    isActive: true,
  })

  return saleProduct
}
