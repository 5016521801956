/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "analyticsv2/reportbuilder/models.proto" (package "rd.analyticsv2.reportbuilder", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { UUID } from "../../proto/uuid/models_pb";
import { TimestampRange } from "../../proto/timestamprange/models_pb";
/**
 * @generated from protobuf message rd.analyticsv2.reportbuilder.Report
 */
export interface Report {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string subtitle = 2;
     */
    subtitle: string;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.reportbuilder.Report.Series series = 3;
     */
    series: Report_Series[];
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.Report.Table table = 4;
     */
    table?: Report_Table;
}
/**
 * @generated from protobuf message rd.analyticsv2.reportbuilder.Report.Series
 */
export interface Report_Series {
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.Report.Series.Type type = 1;
     */
    type: Report_Series_Type;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.reportbuilder.Report.Series.Point points = 3;
     */
    points: Report_Series_Point[];
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.Report.Series.Unit unit = 4;
     */
    unit: Report_Series_Unit;
    /**
     * note that groups separate points and subgroups separate series
     *
     * @generated from protobuf field: repeated string subgroups = 5;
     */
    subgroups: string[];
}
/**
 * @generated from protobuf message rd.analyticsv2.reportbuilder.Report.Series.Point
 */
export interface Report_Series_Point {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string; // categorical string or rfc3339 timestamp: "2024-01-02T03:45:56Z"
    /**
     * @generated from protobuf field: float value = 2;
     */
    value: number;
    /**
     * note that groups separate points and subgroups separate series
     *
     * @generated from protobuf field: repeated string groups = 3;
     */
    groups: string[];
}
/**
 * @generated from protobuf enum rd.analyticsv2.reportbuilder.Report.Series.Type
 */
export enum Report_Series_Type {
    /**
     * @generated from protobuf enum value: TYPE_SCALAR = 0;
     */
    SCALAR = 0,
    /**
     * @generated from protobuf enum value: TYPE_TIMESERIES = 1;
     */
    TIMESERIES = 1
}
/**
 * @generated from protobuf enum rd.analyticsv2.reportbuilder.Report.Series.Unit
 */
export enum Report_Series_Unit {
    /**
     * @generated from protobuf enum value: UNIT_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: UNIT_SCALAR = 1;
     */
    SCALAR = 1,
    /**
     * @generated from protobuf enum value: UNIT_MONEY = 2;
     */
    MONEY = 2
}
/**
 * @generated from protobuf message rd.analyticsv2.reportbuilder.Report.Table
 */
export interface Report_Table {
    /**
     * @generated from protobuf field: repeated string headers = 1;
     */
    headers: string[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.reportbuilder.Report.Table.Row rows = 2;
     */
    rows: Report_Table_Row[];
}
/**
 * @generated from protobuf message rd.analyticsv2.reportbuilder.Report.Table.Row
 */
export interface Report_Table_Row {
    /**
     * @generated from protobuf field: repeated string cells = 1;
     */
    cells: string[];
}
/**
 * ReportParams define the parameters for a report builder request. They are
 * stored on the frontend to save report configurations, so we should avoid
 * modifying them non-additively.
 *
 * @generated from protobuf message rd.analyticsv2.reportbuilder.ReportParams
 */
export interface ReportParams {
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.DataSource data_source = 1;
     */
    dataSource: DataSource;
    /**
     * @generated from protobuf field: rd.proto.timestamprange.TimestampRange data_source_range = 2;
     */
    dataSourceRange?: TimestampRange;
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.reportbuilder.Group groups = 3;
     */
    groups: Group[];
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.reportbuilder.Group subgroups = 4;
     */
    subgroups: Group[];
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.ReportParams.CompareUnit compare_unit = 5;
     */
    compareUnit: ReportParams_CompareUnit;
    /**
     * @generated from protobuf field: int32 compare_amount = 6;
     */
    compareAmount: number;
    /**
     * @generated from protobuf field: int32 sort_column_index = 7;
     */
    sortColumnIndex: number;
    /**
     * @generated from protobuf field: int32 sort_order = 8;
     */
    sortOrder: number; // 0:no sort, 1:desc, 2:asc
    /**
     * @generated from protobuf field: repeated rd.analyticsv2.reportbuilder.ReportParams.Filter filters = 9;
     */
    filters: ReportParams_Filter[];
    /**
     * @generated from protobuf field: bool gen_xy_table = 10;
     */
    genXyTable: boolean;
}
/**
 * @generated from protobuf message rd.analyticsv2.reportbuilder.ReportParams.Filter
 */
export interface ReportParams_Filter {
    /**
     * @generated from protobuf field: rd.analyticsv2.reportbuilder.ReportParams.Filter.Attribute attribute = 1;
     */
    attribute: ReportParams_Filter_Attribute;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 2;
     */
    id?: UUID;
}
/**
 * @generated from protobuf enum rd.analyticsv2.reportbuilder.ReportParams.Filter.Attribute
 */
export enum ReportParams_Filter_Attribute {
    /**
     * @generated from protobuf enum value: ATTRIBUTE_LOCATION_ID = 0;
     */
    LOCATION_ID = 0,
    /**
     * @generated from protobuf enum value: ATTRIBUTE_CUSTOMER_ID = 1;
     */
    CUSTOMER_ID = 1,
    /**
     * @generated from protobuf enum value: ATTRIBUTE_PRODUCT_ID = 2;
     */
    PRODUCT_ID = 2,
    /**
     * @generated from protobuf enum value: ATTRIBUTE_PRODUCT_CATEGORY_ID = 3;
     */
    PRODUCT_CATEGORY_ID = 3,
    /**
     * @generated from protobuf enum value: ATTRIBUTE_CLERK_ID = 4;
     */
    CLERK_ID = 4,
    /**
     * @generated from protobuf enum value: ATTRIBUTE_REP_ID = 5;
     */
    REP_ID = 5,
    /**
     * @generated from protobuf enum value: ATTRIBUTE_TAG_ID = 6;
     */
    TAG_ID = 6,
    /**
     * @generated from protobuf enum value: ATTRIBUTE_VENDOR_ID = 7;
     */
    VENDOR_ID = 7
}
/**
 * @generated from protobuf enum rd.analyticsv2.reportbuilder.ReportParams.CompareUnit
 */
export enum ReportParams_CompareUnit {
    /**
     * @generated from protobuf enum value: COMPARE_UNIT_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: COMPARE_UNIT_DAY = 1;
     */
    DAY = 1,
    /**
     * @generated from protobuf enum value: COMPARE_UNIT_MONTH = 2;
     */
    MONTH = 2,
    /**
     * @generated from protobuf enum value: COMPARE_UNIT_YEAR = 3;
     */
    YEAR = 3
}
/**
 * @generated from protobuf enum rd.analyticsv2.reportbuilder.DataSource
 */
export enum DataSource {
    /**
     * @generated from protobuf enum value: DATA_SOURCE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: DATA_SOURCE_SALES_REVENUE = 1;
     */
    SALES_REVENUE = 1,
    /**
     * @generated from protobuf enum value: DATA_SOURCE_SALES_PROFIT = 2;
     */
    SALES_PROFIT = 2,
    /**
     * @generated from protobuf enum value: DATA_SOURCE_SALES_MARGIN = 3;
     */
    SALES_MARGIN = 3,
    /**
     * @generated from protobuf enum value: DATA_SOURCE_SALES_QUANTITY = 4;
     */
    SALES_QUANTITY = 4
}
/**
 * @generated from protobuf enum rd.analyticsv2.reportbuilder.Group
 */
export enum Group {
    /**
     * @generated from protobuf enum value: GROUP_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: GROUP_PRODUCT = 1;
     */
    PRODUCT = 1,
    /**
     * @generated from protobuf enum value: GROUP_DEPARTMENT = 2;
     */
    DEPARTMENT = 2,
    /**
     * @generated from protobuf enum value: GROUP_DAY = 3;
     */
    DAY = 3,
    /**
     * @generated from protobuf enum value: GROUP_MONTH = 4;
     */
    MONTH = 4,
    /**
     * @generated from protobuf enum value: GROUP_YEAR = 5;
     */
    YEAR = 5,
    /**
     * @generated from protobuf enum value: GROUP_CUSTOMER = 6;
     */
    CUSTOMER = 6,
    /**
     * @generated from protobuf enum value: GROUP_PRODUCT_TAG = 7;
     */
    PRODUCT_TAG = 7,
    /**
     * @generated from protobuf enum value: GROUP_CUSTOMER_TAG = 8;
     */
    CUSTOMER_TAG = 8,
    /**
     * @generated from protobuf enum value: GROUP_HOUR_OF_DAY = 9;
     */
    HOUR_OF_DAY = 9,
    /**
     * @generated from protobuf enum value: GROUP_DAY_OF_WEEK = 10;
     */
    DAY_OF_WEEK = 10,
    /**
     * @generated from protobuf enum value: GROUP_MONTH_OF_YEAR = 11;
     */
    MONTH_OF_YEAR = 11
}
// @generated message type with reflection information, may provide speed optimized methods
class Report$Type extends MessageType<Report> {
    constructor() {
        super("rd.analyticsv2.reportbuilder.Report", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "series", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Report_Series },
            { no: 4, name: "table", kind: "message", T: () => Report_Table }
        ]);
    }
    create(value?: PartialMessage<Report>): Report {
        const message = { title: "", subtitle: "", series: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Report>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Report): Report {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 2:
                    message.subtitle = reader.string();
                    break;
                case /* repeated rd.analyticsv2.reportbuilder.Report.Series series */ 3:
                    message.series.push(Report_Series.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analyticsv2.reportbuilder.Report.Table table */ 4:
                    message.table = Report_Table.internalBinaryRead(reader, reader.uint32(), options, message.table);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Report, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 2; */
        if (message.subtitle !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subtitle);
        /* repeated rd.analyticsv2.reportbuilder.Report.Series series = 3; */
        for (let i = 0; i < message.series.length; i++)
            Report_Series.internalBinaryWrite(message.series[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.reportbuilder.Report.Table table = 4; */
        if (message.table)
            Report_Table.internalBinaryWrite(message.table, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.reportbuilder.Report
 */
export const Report = new Report$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Report_Series$Type extends MessageType<Report_Series> {
    constructor() {
        super("rd.analyticsv2.reportbuilder.Report.Series", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.analyticsv2.reportbuilder.Report.Series.Type", Report_Series_Type, "TYPE_"] },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "points", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Report_Series_Point },
            { no: 4, name: "unit", kind: "enum", T: () => ["rd.analyticsv2.reportbuilder.Report.Series.Unit", Report_Series_Unit, "UNIT_"] },
            { no: 5, name: "subgroups", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Report_Series>): Report_Series {
        const message = { type: 0, label: "", points: [], unit: 0, subgroups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Report_Series>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Report_Series): Report_Series {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.reportbuilder.Report.Series.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* repeated rd.analyticsv2.reportbuilder.Report.Series.Point points */ 3:
                    message.points.push(Report_Series_Point.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.analyticsv2.reportbuilder.Report.Series.Unit unit */ 4:
                    message.unit = reader.int32();
                    break;
                case /* repeated string subgroups */ 5:
                    message.subgroups.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Report_Series, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.reportbuilder.Report.Series.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* repeated rd.analyticsv2.reportbuilder.Report.Series.Point points = 3; */
        for (let i = 0; i < message.points.length; i++)
            Report_Series_Point.internalBinaryWrite(message.points[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.analyticsv2.reportbuilder.Report.Series.Unit unit = 4; */
        if (message.unit !== 0)
            writer.tag(4, WireType.Varint).int32(message.unit);
        /* repeated string subgroups = 5; */
        for (let i = 0; i < message.subgroups.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.subgroups[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.reportbuilder.Report.Series
 */
export const Report_Series = new Report_Series$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Report_Series_Point$Type extends MessageType<Report_Series_Point> {
    constructor() {
        super("rd.analyticsv2.reportbuilder.Report.Series.Point", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "groups", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Report_Series_Point>): Report_Series_Point {
        const message = { label: "", value: 0, groups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Report_Series_Point>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Report_Series_Point): Report_Series_Point {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* float value */ 2:
                    message.value = reader.float();
                    break;
                case /* repeated string groups */ 3:
                    message.groups.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Report_Series_Point, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* float value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit32).float(message.value);
        /* repeated string groups = 3; */
        for (let i = 0; i < message.groups.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.groups[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.reportbuilder.Report.Series.Point
 */
export const Report_Series_Point = new Report_Series_Point$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Report_Table$Type extends MessageType<Report_Table> {
    constructor() {
        super("rd.analyticsv2.reportbuilder.Report.Table", [
            { no: 1, name: "headers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Report_Table_Row }
        ]);
    }
    create(value?: PartialMessage<Report_Table>): Report_Table {
        const message = { headers: [], rows: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Report_Table>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Report_Table): Report_Table {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string headers */ 1:
                    message.headers.push(reader.string());
                    break;
                case /* repeated rd.analyticsv2.reportbuilder.Report.Table.Row rows */ 2:
                    message.rows.push(Report_Table_Row.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Report_Table, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string headers = 1; */
        for (let i = 0; i < message.headers.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.headers[i]);
        /* repeated rd.analyticsv2.reportbuilder.Report.Table.Row rows = 2; */
        for (let i = 0; i < message.rows.length; i++)
            Report_Table_Row.internalBinaryWrite(message.rows[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.reportbuilder.Report.Table
 */
export const Report_Table = new Report_Table$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Report_Table_Row$Type extends MessageType<Report_Table_Row> {
    constructor() {
        super("rd.analyticsv2.reportbuilder.Report.Table.Row", [
            { no: 1, name: "cells", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Report_Table_Row>): Report_Table_Row {
        const message = { cells: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Report_Table_Row>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Report_Table_Row): Report_Table_Row {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string cells */ 1:
                    message.cells.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Report_Table_Row, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string cells = 1; */
        for (let i = 0; i < message.cells.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.cells[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.reportbuilder.Report.Table.Row
 */
export const Report_Table_Row = new Report_Table_Row$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportParams$Type extends MessageType<ReportParams> {
    constructor() {
        super("rd.analyticsv2.reportbuilder.ReportParams", [
            { no: 1, name: "data_source", kind: "enum", T: () => ["rd.analyticsv2.reportbuilder.DataSource", DataSource, "DATA_SOURCE_"] },
            { no: 2, name: "data_source_range", kind: "message", T: () => TimestampRange },
            { no: 3, name: "groups", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.reportbuilder.Group", Group, "GROUP_"] },
            { no: 4, name: "subgroups", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["rd.analyticsv2.reportbuilder.Group", Group, "GROUP_"] },
            { no: 5, name: "compare_unit", kind: "enum", T: () => ["rd.analyticsv2.reportbuilder.ReportParams.CompareUnit", ReportParams_CompareUnit, "COMPARE_UNIT_"] },
            { no: 6, name: "compare_amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "sort_column_index", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "sort_order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "filters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReportParams_Filter },
            { no: 10, name: "gen_xy_table", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ReportParams>): ReportParams {
        const message = { dataSource: 0, groups: [], subgroups: [], compareUnit: 0, compareAmount: 0, sortColumnIndex: 0, sortOrder: 0, filters: [], genXyTable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportParams): ReportParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.reportbuilder.DataSource data_source */ 1:
                    message.dataSource = reader.int32();
                    break;
                case /* rd.proto.timestamprange.TimestampRange data_source_range */ 2:
                    message.dataSourceRange = TimestampRange.internalBinaryRead(reader, reader.uint32(), options, message.dataSourceRange);
                    break;
                case /* repeated rd.analyticsv2.reportbuilder.Group groups */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.groups.push(reader.int32());
                    else
                        message.groups.push(reader.int32());
                    break;
                case /* repeated rd.analyticsv2.reportbuilder.Group subgroups */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.subgroups.push(reader.int32());
                    else
                        message.subgroups.push(reader.int32());
                    break;
                case /* rd.analyticsv2.reportbuilder.ReportParams.CompareUnit compare_unit */ 5:
                    message.compareUnit = reader.int32();
                    break;
                case /* int32 compare_amount */ 6:
                    message.compareAmount = reader.int32();
                    break;
                case /* int32 sort_column_index */ 7:
                    message.sortColumnIndex = reader.int32();
                    break;
                case /* int32 sort_order */ 8:
                    message.sortOrder = reader.int32();
                    break;
                case /* repeated rd.analyticsv2.reportbuilder.ReportParams.Filter filters */ 9:
                    message.filters.push(ReportParams_Filter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool gen_xy_table */ 10:
                    message.genXyTable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.reportbuilder.DataSource data_source = 1; */
        if (message.dataSource !== 0)
            writer.tag(1, WireType.Varint).int32(message.dataSource);
        /* rd.proto.timestamprange.TimestampRange data_source_range = 2; */
        if (message.dataSourceRange)
            TimestampRange.internalBinaryWrite(message.dataSourceRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.analyticsv2.reportbuilder.Group groups = 3; */
        if (message.groups.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.groups.length; i++)
                writer.int32(message.groups[i]);
            writer.join();
        }
        /* repeated rd.analyticsv2.reportbuilder.Group subgroups = 4; */
        if (message.subgroups.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.subgroups.length; i++)
                writer.int32(message.subgroups[i]);
            writer.join();
        }
        /* rd.analyticsv2.reportbuilder.ReportParams.CompareUnit compare_unit = 5; */
        if (message.compareUnit !== 0)
            writer.tag(5, WireType.Varint).int32(message.compareUnit);
        /* int32 compare_amount = 6; */
        if (message.compareAmount !== 0)
            writer.tag(6, WireType.Varint).int32(message.compareAmount);
        /* int32 sort_column_index = 7; */
        if (message.sortColumnIndex !== 0)
            writer.tag(7, WireType.Varint).int32(message.sortColumnIndex);
        /* int32 sort_order = 8; */
        if (message.sortOrder !== 0)
            writer.tag(8, WireType.Varint).int32(message.sortOrder);
        /* repeated rd.analyticsv2.reportbuilder.ReportParams.Filter filters = 9; */
        for (let i = 0; i < message.filters.length; i++)
            ReportParams_Filter.internalBinaryWrite(message.filters[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bool gen_xy_table = 10; */
        if (message.genXyTable !== false)
            writer.tag(10, WireType.Varint).bool(message.genXyTable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.reportbuilder.ReportParams
 */
export const ReportParams = new ReportParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportParams_Filter$Type extends MessageType<ReportParams_Filter> {
    constructor() {
        super("rd.analyticsv2.reportbuilder.ReportParams.Filter", [
            { no: 1, name: "attribute", kind: "enum", T: () => ["rd.analyticsv2.reportbuilder.ReportParams.Filter.Attribute", ReportParams_Filter_Attribute, "ATTRIBUTE_"] },
            { no: 2, name: "id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<ReportParams_Filter>): ReportParams_Filter {
        const message = { attribute: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportParams_Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportParams_Filter): ReportParams_Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.analyticsv2.reportbuilder.ReportParams.Filter.Attribute attribute */ 1:
                    message.attribute = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID id */ 2:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportParams_Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.analyticsv2.reportbuilder.ReportParams.Filter.Attribute attribute = 1; */
        if (message.attribute !== 0)
            writer.tag(1, WireType.Varint).int32(message.attribute);
        /* rd.proto.uuid.UUID id = 2; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.analyticsv2.reportbuilder.ReportParams.Filter
 */
export const ReportParams_Filter = new ReportParams_Filter$Type();
