// @ts-strict-ignore
import {
  CreateBillPaymentV3Req,
  CreateWriteOffReq,
} from "gen/api/billpayment/service_pb"
import {
  PayloadAction,
  createDraftSafeSelector,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
import {
  createPaymentCardDefaults,
  makePaymentMetadata,
} from "../sales/helpers/helpers"

import { AppState } from "app/store"
import { Card } from "gen/payments/models_pb"
import { CustomerBalance } from "gen/txn/models_pb"
import { CustomerState } from "../transactions/CustomerSidebar"
import { Decimal } from "decimal"
import { Location } from "gen/company/models_pb"
import { Money } from "money"
import { Decimal as PBDecimal } from "gen/proto/decimal/models_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { LedgerEntry_Payment_Method as PaymentMethod } from "gen/ledger/models_pb"
import { SearchOutstandingSalesRes_Result as SearchOutstandingSalesResult } from "gen/search/service/service_pb"
import { Timestamp } from "gen/google/protobuf/timestamp_pb"
import { UUID } from "uuid-rd"
import { companyApiSlice } from "features/api/company"
import { compareAsc } from "date-fns"
import { isEmptyObject } from "lib/isEmptyObject"
import { selectLocation } from "features/auth/authSlice"

export type BillsPayment =
  | {
      drawer: "check"
      checkNumber?: string
    }
  | {
      drawer: "card"
      card: Card | null
      shouldSaveCardPresent: boolean
      cardPresentCardNickname: string
      number: string
      exp: string
      cvc: string
      zip: string
      shouldSaveCardManuallyEntered: boolean
      manuallyEnteredCardNickname: string
    }
  | {
      drawer: "cash"
      cashReceived: Money
    }
  | {
      drawer: "write_off"
    }

export type BillsState = {
  customerId?: UUID
  customerCards?: Card[]
  customerBalance?: CustomerBalance
  paymentAmount: Money
  shouldUseAccountCredit: boolean
  shouldPayFinancingCharges: boolean
  allocationsBySaleId: { [key: string]: Money }
  notes: string
  saleTotals: Money
  financingCharges: Money
  credit: Money
  payment?: BillsPayment
  paymentMethod?: PaymentMethod
  cardSurchargeRate?: Decimal
  cardSurcharge?: Money
  cardSurchargeTax?: Money
  isCardSurchargeTaxExempt?: boolean
  taxRate?: Decimal
  billPaymentTotal: Money
  earlyPaymentDiscountTotal?: Money
  earlyPaymentDiscountBySaleId?: { [key: string]: Money }
}

export function createBillPaymentRequest(
  state: BillsState,
  signedInLocation?: Location,
  cardReaderId?: string,
  printerId?: PBUUID
): CreateBillPaymentV3Req | null {
  if (!state.paymentMethod) {
    return null
  }
  if (state.payment?.drawer === "write_off") {
    return null
  }
  if (!state.customerId) {
    return null
  }
  if (!signedInLocation?.id) {
    return null
  }
  if (state.paymentMethod === PaymentMethod.CARD_PRESENT && !cardReaderId) {
    return null
  }

  const payment = makePaymentMetadata(
    state.payment,
    state.paymentMethod,
    state.billPaymentTotal,
    cardReaderId
  )

  if (!payment) {
    return null
  }

  let taxRate: PBDecimal | undefined
  let cardSurchargeRate: PBDecimal | undefined
  if (state.payment.drawer === "card") {
    taxRate = state.cardSurchargeTax.isNonZero()
      ? state.taxRate?.toPB()
      : undefined
    cardSurchargeRate = state.cardSurchargeRate?.toPB()
  }

  return CreateBillPaymentV3Req.create({
    customerId: state.customerId?.toPB(),
    locationId: signedInLocation.id,
    allocationsBySaleId: Object.fromEntries(
      Object.entries(state.allocationsBySaleId).map((v) => [v[0], v[1].toPB()])
    ),
    financingChargeAllocation: state.shouldPayFinancingCharges
      ? state.financingCharges.toPB()
      : undefined,
    notes: state.notes,
    payment,
    cardSurchargeTax: state.cardSurchargeTax.isNonZero()
      ? state.cardSurchargeTax.toPB()
      : undefined,
    cardSurcharge: state.cardSurcharge.isNonZero()
      ? state.cardSurcharge.toPB()
      : undefined,
    cardSurchargeRate,
    taxRate,
    printerId,
  })
}

export function createWriteOffRequest(
  state: BillsState,
  signedInLocation?: Location
): CreateWriteOffReq | null {
  if (state.payment?.drawer !== "write_off") {
    return null
  }

  if (!state.customerId || !signedInLocation?.id || !state.notes) {
    return null
  }

  return CreateWriteOffReq.create({
    customerId: state.customerId?.toPB(),
    locationId: signedInLocation.id,
    amount: state.paymentAmount.toPB(),
    allocationsBySaleId: Object.fromEntries(
      Object.entries(state.allocationsBySaleId).map(([id, allocation]) => [
        id,
        allocation.toPB(),
      ])
    ),
    financingChargeAllocation: state.shouldPayFinancingCharges
      ? state.financingCharges.toPB()
      : undefined,
    notes: state.notes,
  })
}

export const initialState: BillsState = {
  paymentAmount: Money.zero(),
  shouldUseAccountCredit: false,
  shouldPayFinancingCharges: false,
  allocationsBySaleId: {},
  notes: "",
  saleTotals: Money.zero(),
  financingCharges: Money.zero(),
  credit: Money.zero(),
  payment: createPaymentCardDefaults(),
  paymentMethod: PaymentMethod.CARD_PRESENT,
  cardSurchargeRate: undefined,
  cardSurcharge: Money.zero(),
  cardSurchargeTax: Money.zero(),
  isCardSurchargeTaxExempt: false,
  taxRate: undefined,
  billPaymentTotal: Money.zero(),
  earlyPaymentDiscountTotal: Money.zero(),
  earlyPaymentDiscountBySaleId: {},
}

// checkIsEdited returns whether the state is edited from its initial state.
// Unlike with sales, orders, & transfers, with bill payments we do not have
// to worry about editing an existing one as they have only two states: new
// & paid (where paid is terminal).
export function checkIsEdited(state: BillsState): boolean {
  if (state.customerId !== undefined) {
    return true
  }

  if (state.customerCards !== undefined) {
    return true
  }

  if (state.customerBalance !== undefined) {
    return true
  }

  if (!state.paymentAmount.eq(initialState.paymentAmount)) {
    return true
  }

  if (state.shouldUseAccountCredit !== initialState.shouldUseAccountCredit) {
    return true
  }

  if (
    state.shouldPayFinancingCharges !== initialState.shouldPayFinancingCharges
  ) {
    return true
  }

  if (!isEmptyObject(state.allocationsBySaleId)) {
    return true
  }

  if (state.notes !== initialState.notes) {
    return true
  }

  if (state.payment?.drawer !== "card") {
    return true
  }

  if (state.payment?.card) {
    return true
  }

  if (state.payment?.shouldSaveCardPresent) {
    return true
  }

  if (state.payment?.number) {
    return true
  }

  if (state.payment?.exp) {
    return true
  }

  if (state.payment?.cvc) {
    return true
  }

  if (state.payment?.zip) {
    return true
  }

  if (state.payment?.shouldSaveCardManuallyEntered) {
    return true
  }

  if (state.paymentMethod != PaymentMethod.CARD_PRESENT) {
    return true
  }

  return false
}

// amountToAllocate returns the total amount available to allocate based on the
// entered payment amount and whether credit is being used.
function amountToAllocate(state: BillsState): Money {
  let res = state.paymentAmount

  if (state.shouldUseAccountCredit && state.customerBalance?.credits) {
    res = res.sub(Money.fromPB(state.customerBalance.credits)) // sub because credits are negative
  }

  return res
}

// allocationsForOldestSales returns the allocations (i.e. the money allocated to
// financing charges & sales) optimizing for paying off the oldest sales first.
function allocationsForOldestSales(
  state: BillsState,
  salesSearchResults: SearchOutstandingSalesResult[]
): Pick<
  BillsState,
  | "shouldPayFinancingCharges"
  | "allocationsBySaleId"
  | "earlyPaymentDiscountBySaleId"
> {
  let toAllocate = amountToAllocate(state as BillsState)
  if (toAllocate.isZero()) {
    return {
      shouldPayFinancingCharges: false,
      allocationsBySaleId: {},
      earlyPaymentDiscountBySaleId: {},
    }
  }

  let financingCharges = Money.zero()
  let shouldPayFinancingCharges = false
  if (state.customerBalance?.financingCharges) {
    const customerFinancingCharges = Money.fromPB(
      state.customerBalance.financingCharges
    )
    shouldPayFinancingCharges = customerFinancingCharges.isNonZero()
    financingCharges = toAllocate.lt(customerFinancingCharges)
      ? toAllocate
      : customerFinancingCharges
  }
  toAllocate = toAllocate.sub(financingCharges)

  if (toAllocate.isZero()) {
    return {
      shouldPayFinancingCharges,
      allocationsBySaleId: {},
      earlyPaymentDiscountBySaleId: {},
    }
  }

  const oldestFirst = [...salesSearchResults].sort((a, b) => {
    if (!a.sale?.soldAt || !b.sale?.soldAt) {
      return 0
    }

    return compareAsc(
      Timestamp.toDate(a.sale.soldAt),
      Timestamp.toDate(b.sale.soldAt)
    )
  })

  const allocationsBySaleId: Record<string, Money> = {}
  const earlyPaymentDiscountBySaleId: Record<string, Money> = {}
  for (const searchResult of oldestFirst) {
    if (!searchResult.sale?.id) {
      continue
    }
    const saleIdString = UUID.fromPB(searchResult.sale.id).toString()

    const { saleBalance: saleBalancePB, discountAmount: discountAmountPB } =
      searchResult

    const saleBalance = Money.fromPB(saleBalancePB)
    // negative
    const discountAmount = Money.fromPB(discountAmountPB)
    const remainingSaleBalance = Money.fromPB(saleBalance).add(discountAmount)

    const allocation = toAllocate.gt(remainingSaleBalance)
      ? remainingSaleBalance
      : toAllocate

    allocationsBySaleId[saleIdString] = allocation
    // for early discount to apply, the allocation + discountAmount = sale balance
    // sub as discountAmount is negative
    if (allocation.sub(discountAmount).eq(saleBalance)) {
      earlyPaymentDiscountBySaleId[saleIdString] = discountAmount
    }

    toAllocate = toAllocate.sub(allocation)

    if (toAllocate.isZero()) {
      break
    }
  }

  return {
    shouldPayFinancingCharges: true,
    allocationsBySaleId,
    earlyPaymentDiscountBySaleId,
  }
}

// checkIsOldestSalesSelected returns whether the current allocations are for the
// oldest sales.
export function checkIsOldestSalesSelected(
  state: BillsState,
  salesSearchResults: SearchOutstandingSalesResult[]
): boolean {
  const {
    shouldPayFinancingCharges,
    allocationsBySaleId,
    earlyPaymentDiscountBySaleId,
  } = allocationsForOldestSales(state, salesSearchResults)

  if (state.shouldPayFinancingCharges !== shouldPayFinancingCharges) {
    return false
  }

  if (
    Object.keys(allocationsBySaleId).length !==
    Object.keys(state.allocationsBySaleId).length
  ) {
    return false
  }

  if (
    Object.keys(earlyPaymentDiscountBySaleId).length !==
    Object.keys(state.earlyPaymentDiscountBySaleId).length
  ) {
    return false
  }

  for (const [saleIdString, allocation] of Object.entries(
    state.allocationsBySaleId
  )) {
    if (!(saleIdString in allocationsBySaleId)) {
      return false
    }

    if (!allocation.eq(allocationsBySaleId[saleIdString])) {
      return false
    }
  }

  for (const [saleIdString, discountAmount] of Object.entries(
    state.earlyPaymentDiscountBySaleId
  )) {
    if (!discountAmount.eq(earlyPaymentDiscountBySaleId[saleIdString])) {
      return false
    }
  }

  return true
}

// calculateTotals calculates the relevant totals for a bill payment (i.e. sale totals,
// financing charges, credit, card surcharge, and the overall total). This should be used to streamline
// updating state in the following scenarios:
//   1. the payment amount changes
//   2. using credit is toggled on/off
//   3. paying financing charges is toggled on/off
//   4. the allocation of the payment changes
//   5. drawer is changed to card and there's a card surcharge
export function calculateTotals(
  state: BillsState
): Pick<
  BillsState,
  | "saleTotals"
  | "financingCharges"
  | "credit"
  | "cardSurcharge"
  | "billPaymentTotal"
  | "cardSurchargeTax"
  | "earlyPaymentDiscountTotal"
> {
  let saleTotals = Money.zero()
  for (const allocation of Object.values(state.allocationsBySaleId)) {
    saleTotals = saleTotals.add(allocation)
  }

  let financingCharges = Money.zero()
  if (
    state.shouldPayFinancingCharges &&
    !!state.customerBalance?.financingCharges
  ) {
    const customerFinancingCharges = Money.fromPB(
      state.customerBalance.financingCharges
    )
    const remainingToAllocate = amountToAllocate(state).sub(saleTotals)
    financingCharges = remainingToAllocate.lt(customerFinancingCharges)
      ? remainingToAllocate
      : customerFinancingCharges
  }

  let cardSurcharge = Money.zero()
  let cardSurchargeTax = Money.zero()
  if (
    state.payment.drawer === "card" &&
    state.cardSurchargeRate &&
    state.taxRate
  ) {
    cardSurcharge = state.paymentAmount.mult(state.cardSurchargeRate)
    if (!state.isCardSurchargeTaxExempt) {
      cardSurchargeTax = cardSurcharge.mult(state.taxRate)
    }
  }

  // negative
  let earlyPaymentDiscountTotal = Money.zero()
  for (const earlyPaymentDiscount of Object.values(
    state.earlyPaymentDiscountBySaleId
  )) {
    earlyPaymentDiscountTotal =
      earlyPaymentDiscountTotal.add(earlyPaymentDiscount)
  }

  const credit = state.paymentAmount.sub(saleTotals).sub(financingCharges)

  const billPaymentTotal = state.paymentAmount
    .add(cardSurcharge)
    .add(cardSurchargeTax)

  return {
    saleTotals,
    financingCharges,
    cardSurcharge,
    credit,
    billPaymentTotal,
    cardSurchargeTax,
    earlyPaymentDiscountTotal,
  }
}

export const billsSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    resetBillForm: (state) => {
      const taxRate = state.taxRate
      const cardSurchargeRate = state.cardSurchargeRate
      const isCardSurchargeTaxExempt = state.isCardSurchargeTaxExempt

      for (const key of Object.keys(state)) {
        delete state[key]
      }

      Object.assign(state, {
        ...initialState,
        taxRate,
        cardSurchargeRate,
        isCardSurchargeTaxExempt,
      })
    },
    customerSelected(state, action: PayloadAction<CustomerState>) {
      state.customerId = action.payload?.customerId
        ? UUID.fromPB(action.payload?.customerId)
        : undefined
      state.customerBalance = action.payload?.customerBalance
      // Avoid overwriting drawer if manually changed already
      if (state.payment?.drawer === "card") {
        state.payment = createPaymentCardDefaults()
        state.paymentMethod = PaymentMethod.CARD_PRESENT
      }
    },
    customerCardsSelected(state, action: PayloadAction<Card[]>) {
      state.customerCards = action.payload
      if (state.payment?.drawer === "card" && state.customerCards?.length > 0) {
        state.payment.card = state.customerCards[0]
        state.paymentMethod = PaymentMethod.CARD_SAVED
      }
    },
    customerCleared(state) {
      const taxRate = state.taxRate
      const cardSurchargeRate = state.cardSurchargeRate
      const isCardSurchargeTaxExempt = state.isCardSurchargeTaxExempt

      for (const key of Object.keys(state)) {
        delete state[key]
      }

      Object.assign(state, {
        ...initialState,
        taxRate,
        cardSurchargeRate,
        isCardSurchargeTaxExempt,
      })
    },
    setPaymentAmount(state, action: PayloadAction<Money>) {
      state.paymentAmount = action.payload
      state.allocationsBySaleId = {}
      state.earlyPaymentDiscountBySaleId = {}
      Object.assign(state, calculateTotals(state as BillsState))
    },
    setBillPaymentTotal(state, action: PayloadAction<Money>) {
      state.billPaymentTotal = action.payload
    },
    toggleShouldUseAccountCredit(state) {
      state.shouldUseAccountCredit = !state.shouldUseAccountCredit
      state.allocationsBySaleId = {}
      state.earlyPaymentDiscountBySaleId = {}
      Object.assign(state, calculateTotals(state as BillsState))
    },
    toggleShouldPayFinancingCharges(state) {
      state.shouldPayFinancingCharges = !state.shouldPayFinancingCharges
      Object.assign(state, calculateTotals(state as BillsState))
    },
    saleSelected(state, action: PayloadAction<SearchOutstandingSalesResult>) {
      const {
        sale,
        saleBalance: saleBalancePB,
        discountAmount: discountAmountPB,
      } = action.payload

      if (!sale?.id) {
        return
      }

      const saleIdString = UUID.fromPB(sale.id).toString()

      const discountAmount = Money.fromPB(discountAmountPB)

      if (saleIdString in state.earlyPaymentDiscountBySaleId) {
        delete state.earlyPaymentDiscountBySaleId[saleIdString]
        Object.assign(state, calculateTotals(state as BillsState))
      }

      if (saleIdString in state.allocationsBySaleId) {
        delete state.allocationsBySaleId[saleIdString]
        Object.assign(state, calculateTotals(state as BillsState))
        return
      }

      const remainingToAllocate = amountToAllocate(state as BillsState)
        .sub(state.saleTotals as Money)
        .sub(state.financingCharges as Money)

      if (remainingToAllocate.isZero()) {
        return
      }

      const saleBalance = Money.fromPB(saleBalancePB)
      const remainingSaleBalance = saleBalance.add(discountAmount)
      const allocation = remainingToAllocate.gt(remainingSaleBalance)
        ? remainingSaleBalance
        : remainingToAllocate

      state.allocationsBySaleId[saleIdString] = allocation
      // for early discount to apply, the allocation + discountAmount = sale balance
      // sub as discountAmount is negative
      if (allocation.sub(discountAmount).eq(saleBalance)) {
        state.earlyPaymentDiscountBySaleId[saleIdString] = discountAmount
      }

      Object.assign(state, calculateTotals(state as BillsState))
    },
    oldestSalesSelected(
      state,
      action: PayloadAction<SearchOutstandingSalesResult[]>
    ) {
      Object.assign(
        state,
        allocationsForOldestSales(state as BillsState, action.payload)
      )
      Object.assign(state, calculateTotals(state as BillsState))
    },
    setNotes(state, action: PayloadAction<string>) {
      state.notes = action.payload
    },
    paymentSelected(
      state,
      action: PayloadAction<"check" | "card" | "cash" | "write_off">
    ) {
      switch (action.payload) {
        case "cash": {
          state.payment = {
            drawer: "cash",
            cashReceived: Money.zero(),
          }
          state.paymentMethod = PaymentMethod.CASH
          break
        }
        case "check": {
          state.payment = {
            drawer: "check",
            checkNumber: "",
          }
          state.paymentMethod = PaymentMethod.CHECK
          break
        }
        case "card": {
          state.payment = createPaymentCardDefaults()
          if (state.customerCards && state.customerCards.length > 0) {
            state.payment.card = state.customerCards[0]
            state.paymentMethod = PaymentMethod.CARD_SAVED
            break
          }
          state.paymentMethod = PaymentMethod.CARD_PRESENT
          break
        }
        case "write_off": {
          state.payment = {
            drawer: "write_off",
          }
          state.paymentMethod = undefined
          break
        }
        default: {
          throw new Error("unexpected payment method")
        }
      }
      Object.assign(state, calculateTotals(state as BillsState))
    },
    setCheckNumber(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "check") {
        return
      }
      state.paymentMethod = PaymentMethod.CHECK
      state.payment.checkNumber = action.payload
    },
    savedCardSelected(state, action: PayloadAction<Card>) {
      if (state.payment?.drawer !== "card") {
        return
      }
      state.paymentMethod = PaymentMethod.CARD_SAVED
      state.payment = {
        ...state.payment,
        card: action.payload,
      }
    },
    cardPresentSelected(state) {
      if (state.payment?.drawer !== "card") {
        return
      }
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment = {
        ...state.payment,
      }
    },
    toggleShouldSaveReadCard(state) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment.shouldSaveCardPresent = !state.payment.shouldSaveCardPresent
    },
    setCardPresentCardNickname(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment.cardPresentCardNickname = action.payload
    },
    manuallyEnterCardSelected(state) {
      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
    },
    setManuallyEnteredCardNumber(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.number = action.payload
    },
    setManuallyEnteredCardExp(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.exp = action.payload
    },
    setManuallyEnteredCardCVC(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.cvc = action.payload
    },
    setManuallyEnteredCardZIP(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.zip = action.payload
    },
    toggleShouldSaveManuallyEnteredCard(state) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.shouldSaveCardManuallyEntered =
        !state.payment.shouldSaveCardManuallyEntered
    },
    setManuallyEnteredCardNickname(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.manuallyEnteredCardNickname = action.payload
    },
    setCashReceived(state, action: PayloadAction<Money>) {
      if (state.payment?.drawer !== "cash") {
        return
      }

      state.payment.cashReceived = action.payload
    },
    setCardSurchargeRate(state, action: PayloadAction<Decimal>) {
      state.cardSurchargeRate = action.payload
    },
    setBillTaxRate(state, action: PayloadAction<Decimal>) {
      state.taxRate = action.payload
    },
  },
  extraReducers: (builder) => {
    // cache whether the card surcharge is tax exempt for use in calculateTotals
    builder.addMatcher(
      companyApiSlice.endpoints.getCompany.matchFulfilled,
      (state, { payload }) => {
        state.isCardSurchargeTaxExempt =
          !!payload?.company?.isCardSurchargeTaxExempt
      }
    )
  },
})

export const {
  resetBillForm,
  customerSelected,
  customerCardsSelected,
  customerCleared,
  setPaymentAmount,
  setBillPaymentTotal,
  toggleShouldUseAccountCredit,
  toggleShouldPayFinancingCharges,
  saleSelected,
  oldestSalesSelected,
  setNotes,
  paymentSelected,
  setCheckNumber,
  savedCardSelected,
  cardPresentSelected,
  toggleShouldSaveReadCard,
  setCardPresentCardNickname,
  manuallyEnterCardSelected,
  setManuallyEnteredCardNumber,
  setManuallyEnteredCardExp,
  setManuallyEnteredCardCVC,
  setManuallyEnteredCardZIP,
  toggleShouldSaveManuallyEnteredCard,
  setManuallyEnteredCardNickname,
  setCashReceived,
  setCardSurchargeRate,
  setBillTaxRate,
} = billsSlice.actions

export const selectCustomerId = (state: AppState) => state.bills.customerId
export const selectCustomerFinancingCharges = createSelector(
  (state: AppState) => state.bills.customerBalance,
  (customerBalance) => Money.fromPB(customerBalance?.financingCharges)
)
export const selectPaymentAmount = (state: AppState) =>
  state.bills.paymentAmount
export const selectAccountCredit = createSelector(
  (state: AppState) => state.bills.customerBalance,
  (customerBalance) => Money.fromPB(customerBalance?.credits)
)
export const selectRemainingToAllocate = createSelector(
  (state: AppState) => state.bills,
  (bills) =>
    amountToAllocate(bills as BillsState)
      .sub(bills.saleTotals as Money)
      .sub(bills.financingCharges as Money)
)
export const selectShouldUseAccountCredit = (state: AppState) =>
  state.bills.shouldUseAccountCredit
export const selectShouldPayFinancingCharges = (state: AppState) =>
  state.bills.shouldPayFinancingCharges
export const selectAllocationsBySaleId = (state: AppState) =>
  state.bills.allocationsBySaleId
export const selectIsOldestSalesSelected = createSelector(
  (state: AppState) => state.bills,
  (_: AppState, salesSearchResults: SearchOutstandingSalesResult[]) =>
    salesSearchResults,
  (bills, salesSearchResults) =>
    checkIsOldestSalesSelected(bills as BillsState, salesSearchResults)
)
export const selectNotes = (state: AppState) => state.bills.notes
export const selectSaleTotals = (state: AppState) => state.bills.saleTotals
export const selectFinancingCharges = (state: AppState) =>
  state.bills.financingCharges
export const selectCredit = (state: AppState) => state.bills.credit
export const selectCardSurcharge = (state: AppState) =>
  state.bills.cardSurcharge
export const selectCardSurchargeRate = (state: AppState) =>
  state.bills.cardSurchargeRate
export const selectBillPaymentTotal = (state: AppState) =>
  state.bills.billPaymentTotal
export const selectCardSurchargeTax = (state: AppState) =>
  state.bills.cardSurchargeTax
export const selectTaxRate = (state: AppState) => state.bills.taxRate

export const selectIsEdited = (state: AppState) =>
  checkIsEdited(state.bills as BillsState)
export const selectPaymentMethod = (state: AppState) =>
  state.bills.paymentMethod
export const selectCheckNumber = (state: AppState) =>
  state.bills.payment?.drawer === "check" ? state.bills.payment.checkNumber : ""
export const selectCashReceived = createSelector(
  (state: AppState) => state.bills.payment,
  (payment) =>
    payment?.drawer === "cash" ? payment.cashReceived : Money.zero()
)
export const selectSavedCard = (state: AppState) =>
  state.bills.payment?.drawer === "card" ? state.bills.payment.card : null
export const selectCards = createSelector(
  (state: AppState) => state.bills.customerCards,
  (customerCards) => customerCards ?? []
)
export const selectManuallyEnteredCardNumber = (state: AppState) =>
  state.bills.payment?.drawer === "card" ? state.bills.payment.number : ""
export const selectManuallyEnteredCardExp = (state: AppState) =>
  state.bills.payment?.drawer === "card" ? state.bills.payment.exp : ""
export const selectManuallyEnteredCardCVC = (state: AppState) =>
  state.bills.payment?.drawer === "card" ? state.bills.payment.cvc : ""
export const selectManuallyEnteredCardZIP = (state: AppState) =>
  state.bills.payment?.drawer === "card" ? state.bills.payment.zip : ""
export const selectShouldSaveCardManuallyEntered = (state: AppState) =>
  state.bills.payment?.drawer === "card"
    ? state.bills.payment.shouldSaveCardManuallyEntered
    : false
export const selectManuallyEnteredCardNickname = (state: AppState) =>
  state.bills.payment?.drawer === "card"
    ? state.bills.payment.manuallyEnteredCardNickname
    : ""
export const selectShouldSaveCardPresent = (state: AppState) =>
  state.bills.payment?.drawer === "card"
    ? state.bills.payment.shouldSaveCardPresent
    : false
export const selectCardPresentCardNickname = (state: AppState) =>
  state.bills.payment?.drawer === "card"
    ? state.bills.payment.cardPresentCardNickname
    : ""
export const selectPayment = (state: AppState) => state.bills.payment
export const selectCreateBillPaymentRequest = createDraftSafeSelector(
  (state: AppState) => state.bills,
  selectLocation,
  (state: AppState) => state.cardReaders.selectedCardReader?.id,
  (state: AppState) => state.printers.selectedPrinter?.id,
  (bills, location, cardReaderId, printerId) =>
    createBillPaymentRequest(
      bills as BillsState,
      location ?? undefined,
      cardReaderId ?? undefined,
      printerId ?? undefined
    )
)
export const selectCreateWriteOffRequest = createDraftSafeSelector(
  (state: AppState) => state.bills,
  selectLocation,
  (bills, location) =>
    createWriteOffRequest(bills as BillsState, location ?? undefined)
)
export const selectEarlyPaymentDiscountTotal = (state: AppState) =>
  state.bills.earlyPaymentDiscountTotal
export const selectEarlyPaymentDiscountBySaleId = (state: AppState) =>
  state.bills.earlyPaymentDiscountBySaleId
export const selectIsCardSurchargeTaxExempt = (state: AppState) =>
  state.bills.isCardSurchargeTaxExempt
