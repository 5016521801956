/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "vendors/models.proto" (package "rd.vendors", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Address } from "../proto/address/models_pb";
import { User } from "../user/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * Vendor represents a seller that a Company buys from.
 *
 * @generated from protobuf message rd.vendors.Vendor
 */
export interface Vendor {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.user.User created_by = 5;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 6;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * @generated from protobuf field: string orders_email = 8;
     */
    ordersEmail: string;
    /**
     * @generated from protobuf field: string phone = 9;
     */
    phone: string;
    /**
     * @generated from protobuf field: string notes = 10;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.proto.address.Address address = 12;
     */
    address?: Address;
    /**
     * @generated from protobuf field: bool is_active = 13;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: bool is_edi_enabled = 14;
     */
    isEdiEnabled: boolean;
    /**
     * @generated from protobuf field: int32 edi_control_number = 15;
     */
    ediControlNumber: number; // Control number for EDI form that increments upon EDI generation
    /**
     * @generated from protobuf field: repeated string upc_prefixes = 16;
     */
    upcPrefixes: string[];
    /**
     * @generated from protobuf field: string stripe_customer_id = 17;
     */
    stripeCustomerId: string;
    /**
     * @generated from protobuf field: string isa_id = 18;
     */
    isaId: string; // Interchange ID for EDI ordering
    /**
     * @generated from protobuf field: map<string, string> accounts_by_location_id = 19;
     */
    accountsByLocationId: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string pay_to = 20;
     */
    payTo: string;
    /**
     * @generated from protobuf field: bool disable_order_pdf_attachments = 21;
     */
    disableOrderPdfAttachments: boolean;
    /**
     * @generated from protobuf field: bool disable_order_excel_attachments = 22;
     */
    disableOrderExcelAttachments: boolean;
}
/**
 * VendorLog represents a change of a Vendor.
 *
 * @generated from protobuf message rd.vendors.VendorLog
 */
export interface VendorLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.vendors.Vendor vendor = 4;
     */
    vendor?: Vendor;
}
/**
 * Contacts represents the contact details for a vendor
 *
 * @generated from protobuf message rd.vendors.Contacts
 */
export interface Contacts {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // vendor id
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: map<string, rd.vendors.Contacts.Contact> contacts_by_id = 4;
     */
    contactsById: {
        [key: string]: Contacts_Contact;
    };
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 5;
     */
    companyId?: UUID;
}
/**
 * @generated from protobuf message rd.vendors.Contacts.Contact
 */
export interface Contacts_Contact {
    /**
     * @generated from protobuf field: string id_string = 1;
     */
    idString: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: string phone = 4;
     */
    phone: string;
    /**
     * @generated from protobuf field: string notes = 5;
     */
    notes: string;
    /**
     * @generated from protobuf field: bool is_active = 6;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: int32 position = 7;
     */
    position: number;
}
/**
 * Credentials represents all sets of credentials for a vendor.
 *
 * @generated from protobuf message rd.vendors.Credentials
 */
export interface Credentials {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // vendor id
    /**
     * @generated from protobuf field: string edi_username = 2;
     */
    ediUsername: string;
    /**
     * @generated from protobuf field: bytes edi_password = 3;
     */
    ediPassword: Uint8Array;
    /**
     * @generated from protobuf field: string edi_address = 4;
     */
    ediAddress: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 5;
     */
    companyId?: UUID;
}
// @generated message type with reflection information, may provide speed optimized methods
class Vendor$Type extends MessageType<Vendor> {
    constructor() {
        super("rd.vendors.Vendor", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "created_by", kind: "message", T: () => User },
            { no: 6, name: "updated_by", kind: "message", T: () => User },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "orders_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "address", kind: "message", T: () => Address },
            { no: 13, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "is_edi_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "edi_control_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "upc_prefixes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "stripe_customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "isa_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "accounts_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 20, name: "pay_to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "disable_order_pdf_attachments", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "disable_order_excel_attachments", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Vendor>): Vendor {
        const message = { name: "", ordersEmail: "", phone: "", notes: "", isActive: false, isEdiEnabled: false, ediControlNumber: 0, upcPrefixes: [], stripeCustomerId: "", isaId: "", accountsByLocationId: {}, payTo: "", disableOrderPdfAttachments: false, disableOrderExcelAttachments: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Vendor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Vendor): Vendor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.user.User created_by */ 5:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User updated_by */ 6:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* string orders_email */ 8:
                    message.ordersEmail = reader.string();
                    break;
                case /* string phone */ 9:
                    message.phone = reader.string();
                    break;
                case /* string notes */ 10:
                    message.notes = reader.string();
                    break;
                case /* rd.proto.address.Address address */ 12:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* bool is_active */ 13:
                    message.isActive = reader.bool();
                    break;
                case /* bool is_edi_enabled */ 14:
                    message.isEdiEnabled = reader.bool();
                    break;
                case /* int32 edi_control_number */ 15:
                    message.ediControlNumber = reader.int32();
                    break;
                case /* repeated string upc_prefixes */ 16:
                    message.upcPrefixes.push(reader.string());
                    break;
                case /* string stripe_customer_id */ 17:
                    message.stripeCustomerId = reader.string();
                    break;
                case /* string isa_id */ 18:
                    message.isaId = reader.string();
                    break;
                case /* map<string, string> accounts_by_location_id */ 19:
                    this.binaryReadMap19(message.accountsByLocationId, reader, options);
                    break;
                case /* string pay_to */ 20:
                    message.payTo = reader.string();
                    break;
                case /* bool disable_order_pdf_attachments */ 21:
                    message.disableOrderPdfAttachments = reader.bool();
                    break;
                case /* bool disable_order_excel_attachments */ 22:
                    message.disableOrderExcelAttachments = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap19(map: Vendor["accountsByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Vendor["accountsByLocationId"] | undefined, val: Vendor["accountsByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.vendors.Vendor.accounts_by_location_id");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Vendor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 5; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 6; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* string orders_email = 8; */
        if (message.ordersEmail !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.ordersEmail);
        /* string phone = 9; */
        if (message.phone !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.phone);
        /* string notes = 10; */
        if (message.notes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.notes);
        /* rd.proto.address.Address address = 12; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool is_active = 13; */
        if (message.isActive !== false)
            writer.tag(13, WireType.Varint).bool(message.isActive);
        /* bool is_edi_enabled = 14; */
        if (message.isEdiEnabled !== false)
            writer.tag(14, WireType.Varint).bool(message.isEdiEnabled);
        /* int32 edi_control_number = 15; */
        if (message.ediControlNumber !== 0)
            writer.tag(15, WireType.Varint).int32(message.ediControlNumber);
        /* repeated string upc_prefixes = 16; */
        for (let i = 0; i < message.upcPrefixes.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.upcPrefixes[i]);
        /* string stripe_customer_id = 17; */
        if (message.stripeCustomerId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.stripeCustomerId);
        /* string isa_id = 18; */
        if (message.isaId !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.isaId);
        /* map<string, string> accounts_by_location_id = 19; */
        for (let k of Object.keys(message.accountsByLocationId))
            writer.tag(19, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.accountsByLocationId[k]).join();
        /* string pay_to = 20; */
        if (message.payTo !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.payTo);
        /* bool disable_order_pdf_attachments = 21; */
        if (message.disableOrderPdfAttachments !== false)
            writer.tag(21, WireType.Varint).bool(message.disableOrderPdfAttachments);
        /* bool disable_order_excel_attachments = 22; */
        if (message.disableOrderExcelAttachments !== false)
            writer.tag(22, WireType.Varint).bool(message.disableOrderExcelAttachments);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.vendors.Vendor
 */
export const Vendor = new Vendor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VendorLog$Type extends MessageType<VendorLog> {
    constructor() {
        super("rd.vendors.VendorLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "vendor", kind: "message", T: () => Vendor }
        ]);
    }
    create(value?: PartialMessage<VendorLog>): VendorLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VendorLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VendorLog): VendorLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.vendors.Vendor vendor */ 4:
                    message.vendor = Vendor.internalBinaryRead(reader, reader.uint32(), options, message.vendor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VendorLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.vendors.Vendor vendor = 4; */
        if (message.vendor)
            Vendor.internalBinaryWrite(message.vendor, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.vendors.VendorLog
 */
export const VendorLog = new VendorLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contacts$Type extends MessageType<Contacts> {
    constructor() {
        super("rd.vendors.Contacts", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "contacts_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Contacts_Contact } },
            { no: 5, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Contacts>): Contacts {
        const message = { contactsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Contacts>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Contacts): Contacts {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* map<string, rd.vendors.Contacts.Contact> contacts_by_id */ 4:
                    this.binaryReadMap4(message.contactsById, reader, options);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 5:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: Contacts["contactsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Contacts["contactsById"] | undefined, val: Contacts["contactsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Contacts_Contact.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.vendors.Contacts.contacts_by_id");
            }
        }
        map[key ?? ""] = val ?? Contacts_Contact.create();
    }
    internalBinaryWrite(message: Contacts, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.vendors.Contacts.Contact> contacts_by_id = 4; */
        for (let k of Object.keys(message.contactsById)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Contacts_Contact.internalBinaryWrite(message.contactsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.uuid.UUID company_id = 5; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.vendors.Contacts
 */
export const Contacts = new Contacts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Contacts_Contact$Type extends MessageType<Contacts_Contact> {
    constructor() {
        super("rd.vendors.Contacts.Contact", [
            { no: 1, name: "id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Contacts_Contact>): Contacts_Contact {
        const message = { idString: "", name: "", email: "", phone: "", notes: "", isActive: false, position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Contacts_Contact>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Contacts_Contact): Contacts_Contact {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_string */ 1:
                    message.idString = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string phone */ 4:
                    message.phone = reader.string();
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                case /* int32 position */ 7:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Contacts_Contact, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_string = 1; */
        if (message.idString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idString);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string phone = 4; */
        if (message.phone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phone);
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        /* int32 position = 7; */
        if (message.position !== 0)
            writer.tag(7, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.vendors.Contacts.Contact
 */
export const Contacts_Contact = new Contacts_Contact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Credentials$Type extends MessageType<Credentials> {
    constructor() {
        super("rd.vendors.Credentials", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "edi_username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "edi_password", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "edi_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Credentials>): Credentials {
        const message = { ediUsername: "", ediPassword: new Uint8Array(0), ediAddress: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Credentials>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Credentials): Credentials {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* string edi_username */ 2:
                    message.ediUsername = reader.string();
                    break;
                case /* bytes edi_password */ 3:
                    message.ediPassword = reader.bytes();
                    break;
                case /* string edi_address */ 4:
                    message.ediAddress = reader.string();
                    break;
                case /* rd.proto.uuid.UUID company_id */ 5:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Credentials, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string edi_username = 2; */
        if (message.ediUsername !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ediUsername);
        /* bytes edi_password = 3; */
        if (message.ediPassword.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.ediPassword);
        /* string edi_address = 4; */
        if (message.ediAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.ediAddress);
        /* rd.proto.uuid.UUID company_id = 5; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.vendors.Credentials
 */
export const Credentials = new Credentials$Type();
