import { OrderShipment_Status as OrderShipmentStatus } from "gen/txn/models_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { cleanStatus } from "./cleanStatus"

export function orderShipment(id?: PBUUID, status?: OrderShipmentStatus) {
  const statusParam = orderShipmentStatusParam(status)
  if (!statusParam) {
    return `/pos/purchase-orders/${UUID.idString(id)}`
  }
  return `/pos/purchase-orders/${UUID.idString(id)}?status=${statusParam}`
}

export function orderShipmentStatusParam(status?: OrderShipmentStatus): string {
  if (!status) {
    return ""
  }
  return cleanStatus(OrderShipmentStatus[status])
}
