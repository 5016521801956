/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "ledger/models.proto" (package "rd.ledger", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FractionalMoney } from "../proto/money/models_pb";
import { Card } from "../payments/models_pb";
import { CashRegister } from "../company/models_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { Money } from "../proto/money/models_pb";
import { User } from "../user/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * LedgerEntry represents a monetary transaction on a set of sales, either
 * payments or refunds. Total is non-duplicative because it may be greater than
 * the sum of values of allocations by sale, resulting in an account credit.
 *
 * @generated from protobuf message rd.ledger.LedgerEntry
 */
export interface LedgerEntry {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID customer_id = 5;
     */
    customerId?: UUID; // for job accounts, this is the parent customer id
    /**
     * @generated from protobuf field: rd.user.User created_by = 6;
     */
    createdBy?: User;
    /**
     * Amount is the dollar amount being transacted.
     * the sign is from the customer's balance perspective, i.e. how much they
     * owe:
     * - Purchases, Refunds, Reverse Discounts & Financing Charges are positive (increasing balance).
     * - Write Offs, Returns, Discounts & Payments are negative (decreasing balance).
     * - Allocations have zero amount and only record the allocation of payments to charges.
     * - A discount ledger entry will be recorded when a discount action is performed.
     * - A reverse ledger entry will be recorded when a discount action is reversed
     *
     * @generated from protobuf field: rd.proto.money.Money amount = 7;
     */
    amount?: Money;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Type type = 8;
     */
    type: LedgerEntry_Type;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Purchase purchase = 9;
     */
    purchase?: LedgerEntry_Purchase;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Return return = 10;
     */
    return?: LedgerEntry_Return;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Payment payment = 11;
     */
    payment?: LedgerEntry_Payment;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.WriteOff write_off = 12;
     */
    writeOff?: LedgerEntry_WriteOff;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Refund refund = 13;
     */
    refund?: LedgerEntry_Refund;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 14;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.PettyCash petty_cash = 15;
     */
    pettyCash?: LedgerEntry_PettyCash;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.CloseOut close_out = 16;
     */
    closeOut?: LedgerEntry_CloseOut;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Allocation allocation = 17;
     */
    allocation?: LedgerEntry_Allocation;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Discount discount = 18;
     */
    discount?: LedgerEntry_Discount;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.ReverseDiscount reverse_discount = 19;
     */
    reverseDiscount?: LedgerEntry_ReverseDiscount;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 20;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID job_ids = 21;
     */
    jobIds: UUID[]; // customer id for job accounts
    /**
     * @generated from protobuf field: repeated string debug_notes = 39;
     */
    debugNotes: string[];
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryHold inventory_hold = 40;
     */
    inventoryHold?: LedgerEntry_InventoryHold;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryOrder inventory_order = 41;
     */
    inventoryOrder?: LedgerEntry_InventoryOrder;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryReceipt inventory_receipt = 42;
     */
    inventoryReceipt?: LedgerEntry_InventoryReceipt;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryRelease inventory_release = 43;
     */
    inventoryRelease?: LedgerEntry_InventoryRelease;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryCount inventory_count = 44;
     */
    inventoryCount?: LedgerEntry_InventoryCount;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryCostEdit inventory_cost_edit = 45;
     */
    inventoryCostEdit?: LedgerEntry_InventoryCostEdit;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryMetadata inventory_metadata = 46;
     */
    inventoryMetadata?: LedgerEntry_InventoryMetadata;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.DepositPurchase deposit_purchase = 48;
     */
    depositPurchase?: LedgerEntry_DepositPurchase;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.DepositRedemption deposit_redemption = 49;
     */
    depositRedemption?: LedgerEntry_DepositRedemption;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.DepositForfeiture deposit_forfeiture = 50;
     */
    depositForfeiture?: LedgerEntry_DepositForfeiture;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.FinancingCharge financing_charge = 51;
     */
    financingCharge?: LedgerEntry_FinancingCharge;
}
/**
 * a potential discount is used to record one type of discount that can be potentially applied to
 * the payment of a purchase.
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.PotentialDiscount
 */
export interface LedgerEntry_PotentialDiscount {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.PotentialDiscount.Type type = 1;
     */
    type: LedgerEntry_PotentialDiscount_Type; // used to specify a discount type
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal rate = 3;
     */
    rate?: Decimal; // indicate the discount rate
    /**
     * @generated from protobuf field: string term_name = 4;
     */
    termName: string; // the name of a discount term
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_at = 5;
     */
    expiresAt?: Timestamp; // specify when the discount expires
}
/**
 * for discount type
 *
 * @generated from protobuf enum rd.ledger.LedgerEntry.PotentialDiscount.Type
 */
export enum LedgerEntry_PotentialDiscount_Type {
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_UNDEFINED = 0;
     */
    DISCOUNT_TYPE_UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: DISCOUNT_TYPE_EARLY_FULL_PAYMENT = 1;
     */
    DISCOUNT_TYPE_EARLY_FULL_PAYMENT = 1
}
/**
 * Purchases are giving inventory to customers
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.Purchase
 */
export interface LedgerEntry_Purchase {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp due_at = 2;
     */
    dueAt?: Timestamp;
    /**
     * @generated from protobuf field: repeated rd.ledger.LedgerEntry.PotentialDiscount potential_discounts = 3;
     */
    potentialDiscounts: LedgerEntry_PotentialDiscount[]; // the discounts that could be applied to payments
}
/**
 * Returns are customers giving inventory back
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.Return
 */
export interface LedgerEntry_Return {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 1;
     */
    purchaseLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_from_sale_id = 2;
     */
    returnedFromSaleId?: UUID;
    /**
     * @generated from protobuf field: int64 return_index = 3;
     */
    returnIndex: bigint; // index of return in Sale.Returns
}
/**
 * Payments are monetary payments from customers
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.Payment
 */
export interface LedgerEntry_Payment {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Payment.Method method = 1;
     */
    method: LedgerEntry_Payment_Method;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> purchase_allocations_by_id = 2;
     */
    purchaseAllocationsById: {
        [key: string]: Money;
    }; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 3;
     */
    financingChargeAllocation?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money cash_received = 4;
     */
    cashReceived?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: string stripe_payment_intent_id = 5;
     */
    stripePaymentIntentId: string;
    /**
     * @generated from protobuf field: string check_number = 6;
     */
    checkNumber: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 7;
     */
    billPaymentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_fee = 8;
     */
    cardFee?: Money;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 9;
     */
    cashRegister?: CashRegister;
    /**
     * @generated from protobuf field: rd.payments.Card card = 10;
     */
    card?: Card;
    /**
     * Sale payment: surcharge (and tax) are counted towards LE amount via purchase allocation
     * Bill payment: surcharge (and tax) are counted towards LE amount directly
     *
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 11;
     */
    cardSurcharge?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 12;
     */
    cardSurchargeTax?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> finance_charge_allocations_by_job_id = 13;
     */
    financeChargeAllocationsByJobId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> finance_charge_allocations_by_id = 14;
     */
    financeChargeAllocationsById: {
        [key: string]: Money;
    }; // payment allocations by the finance charge IDs
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> deposit_purchase_allocations_by_id = 15;
     */
    depositPurchaseAllocationsById: {
        [key: string]: Money;
    };
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.Payment.Method
 */
export enum LedgerEntry_Payment_Method {
    /**
     * @generated from protobuf enum value: METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: METHOD_CASH = 1;
     */
    CASH = 1,
    /**
     * @generated from protobuf enum value: METHOD_CHECK = 2;
     */
    CHECK = 2,
    /**
     * @generated from protobuf enum value: METHOD_CARD_MANUALLY_ENTERED = 3;
     */
    CARD_MANUALLY_ENTERED = 3,
    /**
     * @generated from protobuf enum value: METHOD_CARD_SAVED = 4;
     */
    CARD_SAVED = 4,
    /**
     * @generated from protobuf enum value: METHOD_CARD_PRESENT = 5;
     */
    CARD_PRESENT = 5,
    /**
     * @generated from protobuf enum value: METHOD_CARD_CUSTOMER_ENTERED = 6;
     */
    CARD_CUSTOMER_ENTERED = 6
}
/**
 * WriteOffs are stores forgiving a portion of a customer's balance
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.WriteOff
 */
export interface LedgerEntry_WriteOff {
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> purchase_allocations_by_id = 1;
     */
    purchaseAllocationsById: {
        [key: string]: Money;
    }; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.money.Money financing_charge_allocation = 2;
     */
    financingChargeAllocation?: Money; // same sign convention as amt
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 3;
     */
    billPaymentId?: UUID;
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> finance_charge_allocations_by_job_id = 4;
     */
    financeChargeAllocationsByJobId: {
        [key: string]: Money;
    };
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> finance_charge_allocations_by_id = 5;
     */
    financeChargeAllocationsById: {
        [key: string]: Money;
    }; // payment allocations by the finance charge IDs
}
/**
 * Refunds are monetary payments to customers
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.Refund
 */
export interface LedgerEntry_Refund {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID payment_ledger_entry_id = 1;
     */
    paymentLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID return_ledger_entry_id = 2;
     */
    returnLedgerEntryId?: UUID; // nil if bill payment refund
    /**
     * @generated from protobuf field: string stripe_refund_id = 3;
     */
    stripeRefundId: string;
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.Refund.Method method = 4;
     */
    method: LedgerEntry_Refund_Method;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 5;
     */
    cashRegister?: CashRegister;
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge = 6;
     */
    cardSurcharge?: Money; // positive
    /**
     * @generated from protobuf field: rd.proto.money.Money card_surcharge_tax = 7;
     */
    cardSurchargeTax?: Money; // positive
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID deposit_redemption_ledger_entry_id = 8;
     */
    depositRedemptionLedgerEntryId?: UUID;
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.Refund.Method
 */
export enum LedgerEntry_Refund_Method {
    /**
     * @generated from protobuf enum value: METHOD_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: METHOD_CASH = 1;
     */
    CASH = 1,
    /**
     * @generated from protobuf enum value: METHOD_CARD = 2;
     */
    CARD = 2,
    /**
     * @generated from protobuf enum value: METHOD_CHECK = 3;
     */
    CHECK = 3
}
/**
 * Petty cash represents a deposit or withdrawal of cash to the register for company use.
 * Amount in Petty cash represents the monetary value of a withdrawal or a deposit.
 * +ve indicates a deposit -ve; -ve indicates a withdrawal
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.PettyCash
 */
export interface LedgerEntry_PettyCash {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID petty_cash_id = 1;
     */
    pettyCashId?: UUID;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 2;
     */
    cashRegister?: CashRegister;
}
/**
 * Close out represents a close out of the register in which total cash is counted.
 * Amount in Close out represents the amount over or short after a close out.
 * +ve indicates an over while -ve indicates a short
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.CloseOut
 */
export interface LedgerEntry_CloseOut {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID close_out_id = 1;
     */
    closeOutId?: UUID;
    /**
     * @generated from protobuf field: rd.company.CashRegister cash_register = 2;
     */
    cashRegister?: CashRegister;
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.Allocation
 */
export interface LedgerEntry_Allocation {
    /**
     * @generated from protobuf field: rd.proto.money.Money allocation_amount = 1;
     */
    allocationAmount?: Money;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID payment_ledger_entry_id = 2;
     */
    paymentLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 3;
     */
    purchaseLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID write_off_ledger_entry_id = 4;
     */
    writeOffLedgerEntryId?: UUID;
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.Discount
 */
export interface LedgerEntry_Discount {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 1;
     */
    purchaseLedgerEntryId?: UUID; // the associated purchase ledger entry id
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_location_id = 3;
     */
    purchaseLocationId?: UUID; // the location where the related purchase occurs.
    /**
     * This is used for facilitating accounting.
     *
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 4;
     */
    billPaymentId?: UUID; // the bill payment that created the discount
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_from_sale_id = 5;
     */
    returnedFromSaleId?: UUID;
    /**
     * @generated from protobuf field: string return_identifier = 6;
     */
    returnIdentifier: string; // identifier of return in Sale.Returns
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.ReverseDiscount
 */
export interface LedgerEntry_ReverseDiscount {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 1;
     */
    purchaseLedgerEntryId?: UUID; // the associated purchase ledger entry id
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID discount_ledger_entry_id = 3;
     */
    discountLedgerEntryId?: UUID; // refer to the discount that need to be reversed
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_location_id = 5;
     */
    purchaseLocationId?: UUID; // the location where the related purchase occurs.
    /**
     * This is used for facilitating accounting.
     *
     * @generated from protobuf field: rd.proto.uuid.UUID bill_payment_id = 6;
     */
    billPaymentId?: UUID; // the bill payment refund that created the reverse discount
}
/**
 * The following ledger entries amounts are set based on if the primary account is being debited
 * or credited. The primary account is commented in each message declaration. The quantity MUST be
 * possitive.
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.InventoryHold
 */
export interface LedgerEntry_InventoryHold {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryHold.Subtype subtype = 1;
     */
    subtype: LedgerEntry_InventoryHold_Subtype;
    /**
     * @generated from protobuf field: int32 quantity_committed = 2;
     */
    quantityCommitted: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost = 3;
     */
    cost?: FractionalMoney;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 4;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 5;
     */
    saleProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transfer_id = 6;
     */
    transferId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_id = 7;
     */
    orderId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID reversed_id = 8;
     */
    reversedId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_product_id = 9;
     */
    orderProductId?: UUID;
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.InventoryHold.Subtype
 */
export enum LedgerEntry_InventoryHold_Subtype {
    /**
     * @generated from protobuf enum value: SUBTYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * primary: assets committed
     * normal:  debit committed, credit on hand
     * reverse: credit committed, debit on hand
     *
     * @generated from protobuf enum value: SUBTYPE_WILL_CALL = 1;
     */
    WILL_CALL = 1,
    /**
     * primary: assets committed
     * normal:  debit committed, credit on hand
     * reverse: credit committed, debit on hand
     *
     * @generated from protobuf enum value: SUBTYPE_TRANSFER_REQUEST = 2;
     */
    TRANSFER_REQUEST = 2,
    /**
     * primary: assets commited
     * normal:  debit committed, credit on hand
     * reverse: credit committed, debit on hand
     *
     * @generated from protobuf enum value: SUBTYPE_TRANSFER_DISPATCH = 3;
     */
    TRANSFER_DISPATCH = 3,
    /**
     * primary: assets commited
     * normal:  debit committed, credit on hand
     * reverse: credit committed, debit on hand
     *
     * @generated from protobuf enum value: SUBTYPE_ORDER_RETURN = 4;
     */
    ORDER_RETURN = 4
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.InventoryOrder
 */
export interface LedgerEntry_InventoryOrder {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryOrder.Subtype subtype = 1;
     */
    subtype: LedgerEntry_InventoryOrder_Subtype;
    /**
     * @generated from protobuf field: int32 quantity_ordered = 2;
     */
    quantityOrdered: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost = 3;
     */
    cost?: FractionalMoney;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transfer_id = 6;
     */
    transferId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_id = 7;
     */
    orderId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID reversed_id = 8;
     */
    reversedId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_product_id = 9;
     */
    orderProductId?: UUID;
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.InventoryOrder.Subtype
 */
export enum LedgerEntry_InventoryOrder_Subtype {
    /**
     * @generated from protobuf enum value: SUBTYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * primary: assets on order
     * normal:  debit assets on order, credit liability pending order
     * reverse: credit assets on order, debit liability pending order
     *
     * @generated from protobuf enum value: SUBTYPE_TRANSFER_REQUEST = 1;
     */
    TRANSFER_REQUEST = 1,
    /**
     * primary: assets on order
     * normal:  debit assets on order, credit liability pending order
     * reverse: credit assets on order, debit liability pending order
     *
     * @generated from protobuf enum value: SUBTYPE_TRANSFER_DISPATCH = 2;
     */
    TRANSFER_DISPATCH = 2,
    /**
     * primary: assets on order
     * normal:  debit assets on order, credit liability pending order
     * reverse: credit assets on order, debit liability pending order
     *
     * @generated from protobuf enum value: SUBTYPE_ORDER = 3;
     */
    ORDER = 3
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.InventoryReceipt
 */
export interface LedgerEntry_InventoryReceipt {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryReceipt.Subtype subtype = 1;
     */
    subtype: LedgerEntry_InventoryReceipt_Subtype;
    /**
     * @generated from protobuf field: int32 quantity_received = 2;
     */
    quantityReceived: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost = 3;
     */
    cost?: FractionalMoney;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_from_sale_id = 4;
     */
    returnedFromSaleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_from_sale_product_id = 5;
     */
    returnedFromSaleProductId?: UUID;
    /**
     * @generated from protobuf field: string sale_return_identifier = 6;
     */
    saleReturnIdentifier: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transfer_id = 7;
     */
    transferId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_shipment_id = 8;
     */
    orderShipmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_product_id = 9;
     */
    orderProductId?: UUID;
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.InventoryReceipt.Subtype
 */
export enum LedgerEntry_InventoryReceipt_Subtype {
    /**
     * @generated from protobuf enum value: SUBTYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * primary: on hand
     * normal:  debit on hand, credit expense transfers
     * reverse: N/A
     *
     * @generated from protobuf enum value: SUBTYPE_TRANSFER = 1;
     */
    TRANSFER = 1,
    /**
     * primary: on hand
     * normal:  debit on hand, credit liability accounts payable
     * reverse: N/A
     *
     * @generated from protobuf enum value: SUBTYPE_ORDER_SHIPMENT = 2;
     */
    ORDER_SHIPMENT = 2,
    /**
     * primary: on hand
     * normal:  debit on hand, credit expense transfers
     * reverse: N/A
     *
     * @generated from protobuf enum value: SUBTYPE_SALE_RETURN = 3;
     */
    SALE_RETURN = 3,
    /**
     * primary: cogs
     * normal:  debit expense transfer, credit cogs
     * reverse: N/A
     *
     * @generated from protobuf enum value: SUBTYPE_SALE_RETURN_ACK = 4;
     */
    SALE_RETURN_ACK = 4
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.InventoryRelease
 */
export interface LedgerEntry_InventoryRelease {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryRelease.Subtype subtype = 1;
     */
    subtype: LedgerEntry_InventoryRelease_Subtype;
    /**
     * @generated from protobuf field: int32 quantity_released = 2;
     */
    quantityReleased: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost = 3;
     */
    cost?: FractionalMoney;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 4;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 5;
     */
    saleProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transfer_id = 6;
     */
    transferId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_shipment_id = 7;
     */
    orderShipmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_product_id = 8;
     */
    orderProductId?: UUID;
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.InventoryRelease.Subtype
 */
export enum LedgerEntry_InventoryRelease_Subtype {
    /**
     * @generated from protobuf enum value: SUBTYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * primary:  on hand
     * normal:   credit on hand, debit expense transfers
     * reverse:  N/A
     *
     * @generated from protobuf enum value: SUBTYPE_TRANSFER = 1;
     */
    TRANSFER = 1,
    /**
     * primary:  on hand
     * normal:   credit on hand, debit liability accounts payable
     * reverse:  N/A
     *
     * @generated from protobuf enum value: SUBTYPE_ORDER_SHIPMENT_RETURN = 2;
     */
    ORDER_SHIPMENT_RETURN = 2,
    /**
     * primary:  on hand
     * normal:   credit on hand, debit cogs
     * reverse:  N/A
     *
     * @generated from protobuf enum value: SUBTYPE_SALE = 3;
     */
    SALE = 3
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.InventoryCount
 */
export interface LedgerEntry_InventoryCount {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryCount.Subtype subtype = 1;
     */
    subtype: LedgerEntry_InventoryCount_Subtype;
    /**
     * @generated from protobuf field: int32 new_quantity = 2;
     */
    newQuantity: number;
    /**
     * @generated from protobuf field: int32 old_quantity = 3;
     */
    oldQuantity: number;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost = 4;
     */
    cost?: FractionalMoney;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 5;
     */
    inventoryCountId?: UUID;
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.InventoryCount.Subtype
 */
export enum LedgerEntry_InventoryCount_Subtype {
    /**
     * @generated from protobuf enum value: SUBTYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * primary: on hand
     * new_quantity>old_quantity:  debit on hand, credit expense
     * else:                       credit on hand, debit expense
     *
     * @generated from protobuf enum value: SUBTYPE_COUNT = 1;
     */
    COUNT = 1,
    /**
     * primary: on hand
     * new_quantity>old_quantity:  debit on hand, credit expense
     * else:                       credit on hand, debit expense
     *
     * @generated from protobuf enum value: SUBTYPE_MANUAL_COUNT = 2;
     */
    MANUAL_COUNT = 2,
    /**
     * primary: on hand
     * new_quantity>old_quantity:  debit on hand, credit expense
     * else:                       credit on hand, debit expense
     *
     * @generated from protobuf enum value: SUBTYPE_MANUAL_INITIALIZATION = 3;
     */
    MANUAL_INITIALIZATION = 3
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.InventoryCostEdit
 */
export interface LedgerEntry_InventoryCostEdit {
    /**
     * @generated from protobuf field: rd.ledger.LedgerEntry.InventoryCostEdit.Subtype subtype = 1;
     */
    subtype: LedgerEntry_InventoryCostEdit_Subtype;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney new_cost = 2;
     */
    newCost?: FractionalMoney;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney old_cost = 3;
     */
    oldCost?: FractionalMoney;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 4;
     */
    saleId?: UUID; // temporarily also holds returned_from_sale_id
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 5;
     */
    saleProductId?: UUID; // temporarily also holds returned_from_sale_product_id
    /**
     * @generated from protobuf field: string sale_return_identifier = 6;
     */
    saleReturnIdentifier: string;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_shipment_id = 7;
     */
    orderShipmentId?: UUID;
    /**
     * @generated from protobuf field: int32 quantity = 8;
     */
    quantity: number;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_product_id = 9;
     */
    orderProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_from_sale_product_id = 10;
     */
    returnedFromSaleProductId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID returned_from_sale_id = 11;
     */
    returnedFromSaleId?: UUID;
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.InventoryCostEdit.Subtype
 */
export enum LedgerEntry_InventoryCostEdit_Subtype {
    /**
     * @generated from protobuf enum value: SUBTYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * primary: on hand
     * new_cost>old_cost: credit on hand, debit cogs
     * else:              debit on hand, credit cogs
     *
     * @generated from protobuf enum value: SUBTYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * primary: on hand
     * new_cost>old_cost: debit on hand, credit expense transfers
     * else:              credit on hand, debit expense transfers
     *
     * @generated from protobuf enum value: SUBTYPE_SALE_RETURN = 2;
     */
    SALE_RETURN = 2,
    /**
     * primary: cogs
     * new_cost>old_cost:  debit expense transfers, credit cogs
     * else:               credit expense transfers, debit cogs
     *
     * @generated from protobuf enum value: SUBTYPE_SALE_RETURN_ACK = 3;
     */
    SALE_RETURN_ACK = 3,
    /**
     * primary: on hand
     * new_cost>old_cost:  debit on hand, credit liability accounts payable
     * else:               credit on hand, debit liability accounts payable
     *
     * @generated from protobuf enum value: SUBTYPE_SHIPMENT = 4;
     */
    SHIPMENT = 4,
    /**
     * primary: on hand
     * new_cost>old_cost:  credit on hand, debit liability accounts payable
     * else:               debit on hand, credit liability accounts payable
     *
     * @generated from protobuf enum value: SUBTYPE_SHIPMENT_RETURN = 5;
     */
    SHIPMENT_RETURN = 5
}
/**
 * @generated from protobuf message rd.ledger.LedgerEntry.InventoryMetadata
 */
export interface LedgerEntry_InventoryMetadata {
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney average_cost = 1;
     */
    averageCost?: FractionalMoney;
    /**
     * @generated from protobuf field: map<string, int32> on_hand_inventories_by_location_id = 2;
     */
    onHandInventoriesByLocationId: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: map<string, int32> on_order_inventories_by_location_id = 3;
     */
    onOrderInventoriesByLocationId: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: map<string, int32> committed_inventories_by_location_id = 4;
     */
    committedInventoriesByLocationId: {
        [key: string]: number;
    };
}
/**
 * DepositPurchases are a claim on a special order product.
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.DepositPurchase
 */
export interface LedgerEntry_DepositPurchase {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 1;
     */
    saleId?: UUID;
}
/**
 * DepositRedemptions are a forfeited claim on a special order product.
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.DepositRedemption
 */
export interface LedgerEntry_DepositRedemption {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID deposit_purchase_ledger_entry_id = 1;
     */
    depositPurchaseLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 2;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID purchase_ledger_entry_id = 3;
     */
    purchaseLedgerEntryId?: UUID;
}
/**
 * DepositForfeiture purely represents the movement of money from Customer
 * Deposits to Forfeited Deposits.
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.DepositForfeiture
 */
export interface LedgerEntry_DepositForfeiture {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID deposit_redemption_ledger_entry_id = 1;
     */
    depositRedemptionLedgerEntryId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 2;
     */
    saleId?: UUID;
}
/**
 * FinancingCharge represents a finance charge that was assessed on a customer's outstanding
 * balance. The charge is calculated based on the assessment rate applied to the outstanding
 * balance, with possible adjustments for minimum charge fees. It is recorded as a ledger entry
 * with a positive amount as it increases the customer's balance.
 *
 * @generated from protobuf message rd.ledger.LedgerEntry.FinancingCharge
 */
export interface LedgerEntry_FinancingCharge {
    /**
     * @generated from protobuf field: string identifier = 1;
     */
    identifier: string; // user-friendly identifier
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> assessed_purchases_by_id = 2;
     */
    assessedPurchasesById: {
        [key: string]: Money;
    }; // map of outstanding purchases that were assessed
    /**
     * @generated from protobuf field: map<string, rd.proto.money.Money> assessed_finance_charges_by_id = 3;
     */
    assessedFinanceChargesById: {
        [key: string]: Money;
    }; // map of oustanding finance charges that were assessed
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal assessment_rate = 4;
     */
    assessmentRate?: Decimal; // prevailing finance charge rate at time of assessment
    /**
     * @generated from protobuf field: rd.proto.money.Money adjustment_amount = 5;
     */
    adjustmentAmount?: Money; // adjustment made to the finance charge due to prevailing minimum charge fee
}
/**
 * @generated from protobuf enum rd.ledger.LedgerEntry.Type
 */
export enum LedgerEntry_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_PURCHASE = 1;
     */
    PURCHASE = 1,
    /**
     * @generated from protobuf enum value: TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: TYPE_PAYMENT = 3;
     */
    PAYMENT = 3,
    /**
     * @generated from protobuf enum value: TYPE_WRITE_OFF = 4;
     */
    WRITE_OFF = 4,
    /**
     * @generated from protobuf enum value: TYPE_REFUND = 5;
     */
    REFUND = 5,
    /**
     * @generated from protobuf enum value: TYPE_FINANCING_CHARGE = 6;
     */
    FINANCING_CHARGE = 6,
    /**
     * @generated from protobuf enum value: TYPE_PETTY_CASH = 7;
     */
    PETTY_CASH = 7,
    /**
     * @generated from protobuf enum value: TYPE_CLOSE_OUT = 8;
     */
    CLOSE_OUT = 8,
    /**
     * @generated from protobuf enum value: TYPE_DISCOUNT = 10;
     */
    DISCOUNT = 10,
    /**
     * @generated from protobuf enum value: TYPE_REVERSE_DISCOUNT = 11;
     */
    REVERSE_DISCOUNT = 11,
    /**
     * New inventory ledger entries
     *
     * @generated from protobuf enum value: TYPE_INVENTORY_HOLD = 30;
     */
    INVENTORY_HOLD = 30,
    /**
     * @generated from protobuf enum value: TYPE_INVENTORY_ORDER = 31;
     */
    INVENTORY_ORDER = 31,
    /**
     * @generated from protobuf enum value: TYPE_INVENTORY_RECEIPT = 32;
     */
    INVENTORY_RECEIPT = 32,
    /**
     * @generated from protobuf enum value: TYPE_INVENTORY_RELEASE = 33;
     */
    INVENTORY_RELEASE = 33,
    /**
     * @generated from protobuf enum value: TYPE_INVENTORY_COUNT = 34;
     */
    INVENTORY_COUNT = 34,
    /**
     * @generated from protobuf enum value: TYPE_INVENTORY_COST_EDIT = 35;
     */
    INVENTORY_COST_EDIT = 35,
    /**
     * Special-order related ledger entries
     *
     * @generated from protobuf enum value: TYPE_DEPOSIT_PURCHASE = 36;
     */
    DEPOSIT_PURCHASE = 36,
    /**
     * @generated from protobuf enum value: TYPE_DEPOSIT_REDEMPTION = 37;
     */
    DEPOSIT_REDEMPTION = 37,
    /**
     * @generated from protobuf enum value: TYPE_DEPOSIT_FORFEITURE = 38;
     */
    DEPOSIT_FORFEITURE = 38
}
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry$Type extends MessageType<LedgerEntry> {
    constructor() {
        super("rd.ledger.LedgerEntry", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "customer_id", kind: "message", T: () => UUID },
            { no: 6, name: "created_by", kind: "message", T: () => User },
            { no: 7, name: "amount", kind: "message", T: () => Money },
            { no: 8, name: "type", kind: "enum", T: () => ["rd.ledger.LedgerEntry.Type", LedgerEntry_Type, "TYPE_"] },
            { no: 9, name: "purchase", kind: "message", T: () => LedgerEntry_Purchase },
            { no: 10, name: "return", kind: "message", T: () => LedgerEntry_Return },
            { no: 11, name: "payment", kind: "message", T: () => LedgerEntry_Payment },
            { no: 12, name: "write_off", kind: "message", T: () => LedgerEntry_WriteOff },
            { no: 13, name: "refund", kind: "message", T: () => LedgerEntry_Refund },
            { no: 14, name: "location_id", kind: "message", T: () => UUID },
            { no: 15, name: "petty_cash", kind: "message", T: () => LedgerEntry_PettyCash },
            { no: 16, name: "close_out", kind: "message", T: () => LedgerEntry_CloseOut },
            { no: 17, name: "allocation", kind: "message", T: () => LedgerEntry_Allocation },
            { no: 18, name: "discount", kind: "message", T: () => LedgerEntry_Discount },
            { no: 19, name: "reverse_discount", kind: "message", T: () => LedgerEntry_ReverseDiscount },
            { no: 20, name: "product_id", kind: "message", T: () => UUID },
            { no: 21, name: "job_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID },
            { no: 39, name: "debug_notes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "inventory_hold", kind: "message", T: () => LedgerEntry_InventoryHold },
            { no: 41, name: "inventory_order", kind: "message", T: () => LedgerEntry_InventoryOrder },
            { no: 42, name: "inventory_receipt", kind: "message", T: () => LedgerEntry_InventoryReceipt },
            { no: 43, name: "inventory_release", kind: "message", T: () => LedgerEntry_InventoryRelease },
            { no: 44, name: "inventory_count", kind: "message", T: () => LedgerEntry_InventoryCount },
            { no: 45, name: "inventory_cost_edit", kind: "message", T: () => LedgerEntry_InventoryCostEdit },
            { no: 46, name: "inventory_metadata", kind: "message", T: () => LedgerEntry_InventoryMetadata },
            { no: 48, name: "deposit_purchase", kind: "message", T: () => LedgerEntry_DepositPurchase },
            { no: 49, name: "deposit_redemption", kind: "message", T: () => LedgerEntry_DepositRedemption },
            { no: 50, name: "deposit_forfeiture", kind: "message", T: () => LedgerEntry_DepositForfeiture },
            { no: 51, name: "financing_charge", kind: "message", T: () => LedgerEntry_FinancingCharge }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry>): LedgerEntry {
        const message = { type: 0, jobIds: [], debugNotes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry): LedgerEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID customer_id */ 5:
                    message.customerId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.customerId);
                    break;
                case /* rd.user.User created_by */ 6:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.proto.money.Money amount */ 7:
                    message.amount = Money.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* rd.ledger.LedgerEntry.Type type */ 8:
                    message.type = reader.int32();
                    break;
                case /* rd.ledger.LedgerEntry.Purchase purchase */ 9:
                    message.purchase = LedgerEntry_Purchase.internalBinaryRead(reader, reader.uint32(), options, message.purchase);
                    break;
                case /* rd.ledger.LedgerEntry.Return return */ 10:
                    message.return = LedgerEntry_Return.internalBinaryRead(reader, reader.uint32(), options, message.return);
                    break;
                case /* rd.ledger.LedgerEntry.Payment payment */ 11:
                    message.payment = LedgerEntry_Payment.internalBinaryRead(reader, reader.uint32(), options, message.payment);
                    break;
                case /* rd.ledger.LedgerEntry.WriteOff write_off */ 12:
                    message.writeOff = LedgerEntry_WriteOff.internalBinaryRead(reader, reader.uint32(), options, message.writeOff);
                    break;
                case /* rd.ledger.LedgerEntry.Refund refund */ 13:
                    message.refund = LedgerEntry_Refund.internalBinaryRead(reader, reader.uint32(), options, message.refund);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 14:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.ledger.LedgerEntry.PettyCash petty_cash */ 15:
                    message.pettyCash = LedgerEntry_PettyCash.internalBinaryRead(reader, reader.uint32(), options, message.pettyCash);
                    break;
                case /* rd.ledger.LedgerEntry.CloseOut close_out */ 16:
                    message.closeOut = LedgerEntry_CloseOut.internalBinaryRead(reader, reader.uint32(), options, message.closeOut);
                    break;
                case /* rd.ledger.LedgerEntry.Allocation allocation */ 17:
                    message.allocation = LedgerEntry_Allocation.internalBinaryRead(reader, reader.uint32(), options, message.allocation);
                    break;
                case /* rd.ledger.LedgerEntry.Discount discount */ 18:
                    message.discount = LedgerEntry_Discount.internalBinaryRead(reader, reader.uint32(), options, message.discount);
                    break;
                case /* rd.ledger.LedgerEntry.ReverseDiscount reverse_discount */ 19:
                    message.reverseDiscount = LedgerEntry_ReverseDiscount.internalBinaryRead(reader, reader.uint32(), options, message.reverseDiscount);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 20:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* repeated rd.proto.uuid.UUID job_ids */ 21:
                    message.jobIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string debug_notes */ 39:
                    message.debugNotes.push(reader.string());
                    break;
                case /* rd.ledger.LedgerEntry.InventoryHold inventory_hold */ 40:
                    message.inventoryHold = LedgerEntry_InventoryHold.internalBinaryRead(reader, reader.uint32(), options, message.inventoryHold);
                    break;
                case /* rd.ledger.LedgerEntry.InventoryOrder inventory_order */ 41:
                    message.inventoryOrder = LedgerEntry_InventoryOrder.internalBinaryRead(reader, reader.uint32(), options, message.inventoryOrder);
                    break;
                case /* rd.ledger.LedgerEntry.InventoryReceipt inventory_receipt */ 42:
                    message.inventoryReceipt = LedgerEntry_InventoryReceipt.internalBinaryRead(reader, reader.uint32(), options, message.inventoryReceipt);
                    break;
                case /* rd.ledger.LedgerEntry.InventoryRelease inventory_release */ 43:
                    message.inventoryRelease = LedgerEntry_InventoryRelease.internalBinaryRead(reader, reader.uint32(), options, message.inventoryRelease);
                    break;
                case /* rd.ledger.LedgerEntry.InventoryCount inventory_count */ 44:
                    message.inventoryCount = LedgerEntry_InventoryCount.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCount);
                    break;
                case /* rd.ledger.LedgerEntry.InventoryCostEdit inventory_cost_edit */ 45:
                    message.inventoryCostEdit = LedgerEntry_InventoryCostEdit.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCostEdit);
                    break;
                case /* rd.ledger.LedgerEntry.InventoryMetadata inventory_metadata */ 46:
                    message.inventoryMetadata = LedgerEntry_InventoryMetadata.internalBinaryRead(reader, reader.uint32(), options, message.inventoryMetadata);
                    break;
                case /* rd.ledger.LedgerEntry.DepositPurchase deposit_purchase */ 48:
                    message.depositPurchase = LedgerEntry_DepositPurchase.internalBinaryRead(reader, reader.uint32(), options, message.depositPurchase);
                    break;
                case /* rd.ledger.LedgerEntry.DepositRedemption deposit_redemption */ 49:
                    message.depositRedemption = LedgerEntry_DepositRedemption.internalBinaryRead(reader, reader.uint32(), options, message.depositRedemption);
                    break;
                case /* rd.ledger.LedgerEntry.DepositForfeiture deposit_forfeiture */ 50:
                    message.depositForfeiture = LedgerEntry_DepositForfeiture.internalBinaryRead(reader, reader.uint32(), options, message.depositForfeiture);
                    break;
                case /* rd.ledger.LedgerEntry.FinancingCharge financing_charge */ 51:
                    message.financingCharge = LedgerEntry_FinancingCharge.internalBinaryRead(reader, reader.uint32(), options, message.financingCharge);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID customer_id = 5; */
        if (message.customerId)
            UUID.internalBinaryWrite(message.customerId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 6; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money amount = 7; */
        if (message.amount)
            Money.internalBinaryWrite(message.amount, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.Type type = 8; */
        if (message.type !== 0)
            writer.tag(8, WireType.Varint).int32(message.type);
        /* rd.ledger.LedgerEntry.Purchase purchase = 9; */
        if (message.purchase)
            LedgerEntry_Purchase.internalBinaryWrite(message.purchase, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.Return return = 10; */
        if (message.return)
            LedgerEntry_Return.internalBinaryWrite(message.return, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.Payment payment = 11; */
        if (message.payment)
            LedgerEntry_Payment.internalBinaryWrite(message.payment, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.WriteOff write_off = 12; */
        if (message.writeOff)
            LedgerEntry_WriteOff.internalBinaryWrite(message.writeOff, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.Refund refund = 13; */
        if (message.refund)
            LedgerEntry_Refund.internalBinaryWrite(message.refund, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 14; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.PettyCash petty_cash = 15; */
        if (message.pettyCash)
            LedgerEntry_PettyCash.internalBinaryWrite(message.pettyCash, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.CloseOut close_out = 16; */
        if (message.closeOut)
            LedgerEntry_CloseOut.internalBinaryWrite(message.closeOut, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.Allocation allocation = 17; */
        if (message.allocation)
            LedgerEntry_Allocation.internalBinaryWrite(message.allocation, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.Discount discount = 18; */
        if (message.discount)
            LedgerEntry_Discount.internalBinaryWrite(message.discount, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.ReverseDiscount reverse_discount = 19; */
        if (message.reverseDiscount)
            LedgerEntry_ReverseDiscount.internalBinaryWrite(message.reverseDiscount, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 20; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.proto.uuid.UUID job_ids = 21; */
        for (let i = 0; i < message.jobIds.length; i++)
            UUID.internalBinaryWrite(message.jobIds[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* repeated string debug_notes = 39; */
        for (let i = 0; i < message.debugNotes.length; i++)
            writer.tag(39, WireType.LengthDelimited).string(message.debugNotes[i]);
        /* rd.ledger.LedgerEntry.InventoryHold inventory_hold = 40; */
        if (message.inventoryHold)
            LedgerEntry_InventoryHold.internalBinaryWrite(message.inventoryHold, writer.tag(40, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.InventoryOrder inventory_order = 41; */
        if (message.inventoryOrder)
            LedgerEntry_InventoryOrder.internalBinaryWrite(message.inventoryOrder, writer.tag(41, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.InventoryReceipt inventory_receipt = 42; */
        if (message.inventoryReceipt)
            LedgerEntry_InventoryReceipt.internalBinaryWrite(message.inventoryReceipt, writer.tag(42, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.InventoryRelease inventory_release = 43; */
        if (message.inventoryRelease)
            LedgerEntry_InventoryRelease.internalBinaryWrite(message.inventoryRelease, writer.tag(43, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.InventoryCount inventory_count = 44; */
        if (message.inventoryCount)
            LedgerEntry_InventoryCount.internalBinaryWrite(message.inventoryCount, writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.InventoryCostEdit inventory_cost_edit = 45; */
        if (message.inventoryCostEdit)
            LedgerEntry_InventoryCostEdit.internalBinaryWrite(message.inventoryCostEdit, writer.tag(45, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.InventoryMetadata inventory_metadata = 46; */
        if (message.inventoryMetadata)
            LedgerEntry_InventoryMetadata.internalBinaryWrite(message.inventoryMetadata, writer.tag(46, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.DepositPurchase deposit_purchase = 48; */
        if (message.depositPurchase)
            LedgerEntry_DepositPurchase.internalBinaryWrite(message.depositPurchase, writer.tag(48, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.DepositRedemption deposit_redemption = 49; */
        if (message.depositRedemption)
            LedgerEntry_DepositRedemption.internalBinaryWrite(message.depositRedemption, writer.tag(49, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.DepositForfeiture deposit_forfeiture = 50; */
        if (message.depositForfeiture)
            LedgerEntry_DepositForfeiture.internalBinaryWrite(message.depositForfeiture, writer.tag(50, WireType.LengthDelimited).fork(), options).join();
        /* rd.ledger.LedgerEntry.FinancingCharge financing_charge = 51; */
        if (message.financingCharge)
            LedgerEntry_FinancingCharge.internalBinaryWrite(message.financingCharge, writer.tag(51, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry
 */
export const LedgerEntry = new LedgerEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_PotentialDiscount$Type extends MessageType<LedgerEntry_PotentialDiscount> {
    constructor() {
        super("rd.ledger.LedgerEntry.PotentialDiscount", [
            { no: 1, name: "type", kind: "enum", T: () => ["rd.ledger.LedgerEntry.PotentialDiscount.Type", LedgerEntry_PotentialDiscount_Type] },
            { no: 3, name: "rate", kind: "message", T: () => Decimal },
            { no: 4, name: "term_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "expires_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_PotentialDiscount>): LedgerEntry_PotentialDiscount {
        const message = { type: 0, termName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_PotentialDiscount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_PotentialDiscount): LedgerEntry_PotentialDiscount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.PotentialDiscount.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* rd.proto.decimal.Decimal rate */ 3:
                    message.rate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.rate);
                    break;
                case /* string term_name */ 4:
                    message.termName = reader.string();
                    break;
                case /* google.protobuf.Timestamp expires_at */ 5:
                    message.expiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiresAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_PotentialDiscount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.PotentialDiscount.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* rd.proto.decimal.Decimal rate = 3; */
        if (message.rate)
            Decimal.internalBinaryWrite(message.rate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string term_name = 4; */
        if (message.termName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.termName);
        /* google.protobuf.Timestamp expires_at = 5; */
        if (message.expiresAt)
            Timestamp.internalBinaryWrite(message.expiresAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.PotentialDiscount
 */
export const LedgerEntry_PotentialDiscount = new LedgerEntry_PotentialDiscount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Purchase$Type extends MessageType<LedgerEntry_Purchase> {
    constructor() {
        super("rd.ledger.LedgerEntry.Purchase", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID },
            { no: 2, name: "due_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "potential_discounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LedgerEntry_PotentialDiscount }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Purchase>): LedgerEntry_Purchase {
        const message = { potentialDiscounts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Purchase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Purchase): LedgerEntry_Purchase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* google.protobuf.Timestamp due_at */ 2:
                    message.dueAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dueAt);
                    break;
                case /* repeated rd.ledger.LedgerEntry.PotentialDiscount potential_discounts */ 3:
                    message.potentialDiscounts.push(LedgerEntry_PotentialDiscount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Purchase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp due_at = 2; */
        if (message.dueAt)
            Timestamp.internalBinaryWrite(message.dueAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.ledger.LedgerEntry.PotentialDiscount potential_discounts = 3; */
        for (let i = 0; i < message.potentialDiscounts.length; i++)
            LedgerEntry_PotentialDiscount.internalBinaryWrite(message.potentialDiscounts[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.Purchase
 */
export const LedgerEntry_Purchase = new LedgerEntry_Purchase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Return$Type extends MessageType<LedgerEntry_Return> {
    constructor() {
        super("rd.ledger.LedgerEntry.Return", [
            { no: 1, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "returned_from_sale_id", kind: "message", T: () => UUID },
            { no: 3, name: "return_index", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Return>): LedgerEntry_Return {
        const message = { returnIndex: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Return>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Return): LedgerEntry_Return {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 1:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID returned_from_sale_id */ 2:
                    message.returnedFromSaleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedFromSaleId);
                    break;
                case /* int64 return_index */ 3:
                    message.returnIndex = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Return, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 1; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID returned_from_sale_id = 2; */
        if (message.returnedFromSaleId)
            UUID.internalBinaryWrite(message.returnedFromSaleId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 return_index = 3; */
        if (message.returnIndex !== 0n)
            writer.tag(3, WireType.Varint).int64(message.returnIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.Return
 */
export const LedgerEntry_Return = new LedgerEntry_Return$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Payment$Type extends MessageType<LedgerEntry_Payment> {
    constructor() {
        super("rd.ledger.LedgerEntry.Payment", [
            { no: 1, name: "method", kind: "enum", T: () => ["rd.ledger.LedgerEntry.Payment.Method", LedgerEntry_Payment_Method, "METHOD_"] },
            { no: 2, name: "purchase_allocations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 3, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 4, name: "cash_received", kind: "message", T: () => Money },
            { no: 5, name: "stripe_payment_intent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "check_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "bill_payment_id", kind: "message", T: () => UUID },
            { no: 8, name: "card_fee", kind: "message", T: () => Money },
            { no: 9, name: "cash_register", kind: "message", T: () => CashRegister },
            { no: 10, name: "card", kind: "message", T: () => Card },
            { no: 11, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 12, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 13, name: "finance_charge_allocations_by_job_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 14, name: "finance_charge_allocations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 15, name: "deposit_purchase_allocations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Payment>): LedgerEntry_Payment {
        const message = { method: 0, purchaseAllocationsById: {}, stripePaymentIntentId: "", checkNumber: "", financeChargeAllocationsByJobId: {}, financeChargeAllocationsById: {}, depositPurchaseAllocationsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Payment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Payment): LedgerEntry_Payment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.Payment.Method method */ 1:
                    message.method = reader.int32();
                    break;
                case /* map<string, rd.proto.money.Money> purchase_allocations_by_id */ 2:
                    this.binaryReadMap2(message.purchaseAllocationsById, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 3:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* rd.proto.money.Money cash_received */ 4:
                    message.cashReceived = Money.internalBinaryRead(reader, reader.uint32(), options, message.cashReceived);
                    break;
                case /* string stripe_payment_intent_id */ 5:
                    message.stripePaymentIntentId = reader.string();
                    break;
                case /* string check_number */ 6:
                    message.checkNumber = reader.string();
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 7:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                case /* rd.proto.money.Money card_fee */ 8:
                    message.cardFee = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardFee);
                    break;
                case /* rd.company.CashRegister cash_register */ 9:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                case /* rd.payments.Card card */ 10:
                    message.card = Card.internalBinaryRead(reader, reader.uint32(), options, message.card);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 11:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 12:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* map<string, rd.proto.money.Money> finance_charge_allocations_by_job_id */ 13:
                    this.binaryReadMap13(message.financeChargeAllocationsByJobId, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> finance_charge_allocations_by_id */ 14:
                    this.binaryReadMap14(message.financeChargeAllocationsById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> deposit_purchase_allocations_by_id */ 15:
                    this.binaryReadMap15(message.depositPurchaseAllocationsById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: LedgerEntry_Payment["purchaseAllocationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_Payment["purchaseAllocationsById"] | undefined, val: LedgerEntry_Payment["purchaseAllocationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.Payment.purchase_allocations_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap13(map: LedgerEntry_Payment["financeChargeAllocationsByJobId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_Payment["financeChargeAllocationsByJobId"] | undefined, val: LedgerEntry_Payment["financeChargeAllocationsByJobId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.Payment.finance_charge_allocations_by_job_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap14(map: LedgerEntry_Payment["financeChargeAllocationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_Payment["financeChargeAllocationsById"] | undefined, val: LedgerEntry_Payment["financeChargeAllocationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.Payment.finance_charge_allocations_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap15(map: LedgerEntry_Payment["depositPurchaseAllocationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_Payment["depositPurchaseAllocationsById"] | undefined, val: LedgerEntry_Payment["depositPurchaseAllocationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.Payment.deposit_purchase_allocations_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: LedgerEntry_Payment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.Payment.Method method = 1; */
        if (message.method !== 0)
            writer.tag(1, WireType.Varint).int32(message.method);
        /* map<string, rd.proto.money.Money> purchase_allocations_by_id = 2; */
        for (let k of Object.keys(message.purchaseAllocationsById)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.purchaseAllocationsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 3; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money cash_received = 4; */
        if (message.cashReceived)
            Money.internalBinaryWrite(message.cashReceived, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_payment_intent_id = 5; */
        if (message.stripePaymentIntentId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.stripePaymentIntentId);
        /* string check_number = 6; */
        if (message.checkNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.checkNumber);
        /* rd.proto.uuid.UUID bill_payment_id = 7; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_fee = 8; */
        if (message.cardFee)
            Money.internalBinaryWrite(message.cardFee, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.CashRegister cash_register = 9; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.payments.Card card = 10; */
        if (message.card)
            Card.internalBinaryWrite(message.card, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 11; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 12; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> finance_charge_allocations_by_job_id = 13; */
        for (let k of Object.keys(message.financeChargeAllocationsByJobId)) {
            writer.tag(13, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.financeChargeAllocationsByJobId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> finance_charge_allocations_by_id = 14; */
        for (let k of Object.keys(message.financeChargeAllocationsById)) {
            writer.tag(14, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.financeChargeAllocationsById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> deposit_purchase_allocations_by_id = 15; */
        for (let k of Object.keys(message.depositPurchaseAllocationsById)) {
            writer.tag(15, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.depositPurchaseAllocationsById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.Payment
 */
export const LedgerEntry_Payment = new LedgerEntry_Payment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_WriteOff$Type extends MessageType<LedgerEntry_WriteOff> {
    constructor() {
        super("rd.ledger.LedgerEntry.WriteOff", [
            { no: 1, name: "purchase_allocations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 2, name: "financing_charge_allocation", kind: "message", T: () => Money },
            { no: 3, name: "bill_payment_id", kind: "message", T: () => UUID },
            { no: 4, name: "finance_charge_allocations_by_job_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 5, name: "finance_charge_allocations_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_WriteOff>): LedgerEntry_WriteOff {
        const message = { purchaseAllocationsById: {}, financeChargeAllocationsByJobId: {}, financeChargeAllocationsById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_WriteOff>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_WriteOff): LedgerEntry_WriteOff {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, rd.proto.money.Money> purchase_allocations_by_id */ 1:
                    this.binaryReadMap1(message.purchaseAllocationsById, reader, options);
                    break;
                case /* rd.proto.money.Money financing_charge_allocation */ 2:
                    message.financingChargeAllocation = Money.internalBinaryRead(reader, reader.uint32(), options, message.financingChargeAllocation);
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 3:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                case /* map<string, rd.proto.money.Money> finance_charge_allocations_by_job_id */ 4:
                    this.binaryReadMap4(message.financeChargeAllocationsByJobId, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> finance_charge_allocations_by_id */ 5:
                    this.binaryReadMap5(message.financeChargeAllocationsById, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LedgerEntry_WriteOff["purchaseAllocationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_WriteOff["purchaseAllocationsById"] | undefined, val: LedgerEntry_WriteOff["purchaseAllocationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.WriteOff.purchase_allocations_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap4(map: LedgerEntry_WriteOff["financeChargeAllocationsByJobId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_WriteOff["financeChargeAllocationsByJobId"] | undefined, val: LedgerEntry_WriteOff["financeChargeAllocationsByJobId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.WriteOff.finance_charge_allocations_by_job_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap5(map: LedgerEntry_WriteOff["financeChargeAllocationsById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_WriteOff["financeChargeAllocationsById"] | undefined, val: LedgerEntry_WriteOff["financeChargeAllocationsById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.WriteOff.finance_charge_allocations_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: LedgerEntry_WriteOff, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, rd.proto.money.Money> purchase_allocations_by_id = 1; */
        for (let k of Object.keys(message.purchaseAllocationsById)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.purchaseAllocationsById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.money.Money financing_charge_allocation = 2; */
        if (message.financingChargeAllocation)
            Money.internalBinaryWrite(message.financingChargeAllocation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID bill_payment_id = 3; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, rd.proto.money.Money> finance_charge_allocations_by_job_id = 4; */
        for (let k of Object.keys(message.financeChargeAllocationsByJobId)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.financeChargeAllocationsByJobId[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> finance_charge_allocations_by_id = 5; */
        for (let k of Object.keys(message.financeChargeAllocationsById)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.financeChargeAllocationsById[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.WriteOff
 */
export const LedgerEntry_WriteOff = new LedgerEntry_WriteOff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Refund$Type extends MessageType<LedgerEntry_Refund> {
    constructor() {
        super("rd.ledger.LedgerEntry.Refund", [
            { no: 1, name: "payment_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "return_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "stripe_refund_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "method", kind: "enum", T: () => ["rd.ledger.LedgerEntry.Refund.Method", LedgerEntry_Refund_Method, "METHOD_"] },
            { no: 5, name: "cash_register", kind: "message", T: () => CashRegister },
            { no: 6, name: "card_surcharge", kind: "message", T: () => Money },
            { no: 7, name: "card_surcharge_tax", kind: "message", T: () => Money },
            { no: 8, name: "deposit_redemption_ledger_entry_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Refund>): LedgerEntry_Refund {
        const message = { stripeRefundId: "", method: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Refund>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Refund): LedgerEntry_Refund {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID payment_ledger_entry_id */ 1:
                    message.paymentLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID return_ledger_entry_id */ 2:
                    message.returnLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnLedgerEntryId);
                    break;
                case /* string stripe_refund_id */ 3:
                    message.stripeRefundId = reader.string();
                    break;
                case /* rd.ledger.LedgerEntry.Refund.Method method */ 4:
                    message.method = reader.int32();
                    break;
                case /* rd.company.CashRegister cash_register */ 5:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                case /* rd.proto.money.Money card_surcharge */ 6:
                    message.cardSurcharge = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurcharge);
                    break;
                case /* rd.proto.money.Money card_surcharge_tax */ 7:
                    message.cardSurchargeTax = Money.internalBinaryRead(reader, reader.uint32(), options, message.cardSurchargeTax);
                    break;
                case /* rd.proto.uuid.UUID deposit_redemption_ledger_entry_id */ 8:
                    message.depositRedemptionLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.depositRedemptionLedgerEntryId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Refund, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID payment_ledger_entry_id = 1; */
        if (message.paymentLedgerEntryId)
            UUID.internalBinaryWrite(message.paymentLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID return_ledger_entry_id = 2; */
        if (message.returnLedgerEntryId)
            UUID.internalBinaryWrite(message.returnLedgerEntryId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string stripe_refund_id = 3; */
        if (message.stripeRefundId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.stripeRefundId);
        /* rd.ledger.LedgerEntry.Refund.Method method = 4; */
        if (message.method !== 0)
            writer.tag(4, WireType.Varint).int32(message.method);
        /* rd.company.CashRegister cash_register = 5; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge = 6; */
        if (message.cardSurcharge)
            Money.internalBinaryWrite(message.cardSurcharge, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money card_surcharge_tax = 7; */
        if (message.cardSurchargeTax)
            Money.internalBinaryWrite(message.cardSurchargeTax, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID deposit_redemption_ledger_entry_id = 8; */
        if (message.depositRedemptionLedgerEntryId)
            UUID.internalBinaryWrite(message.depositRedemptionLedgerEntryId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.Refund
 */
export const LedgerEntry_Refund = new LedgerEntry_Refund$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_PettyCash$Type extends MessageType<LedgerEntry_PettyCash> {
    constructor() {
        super("rd.ledger.LedgerEntry.PettyCash", [
            { no: 1, name: "petty_cash_id", kind: "message", T: () => UUID },
            { no: 2, name: "cash_register", kind: "message", T: () => CashRegister }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_PettyCash>): LedgerEntry_PettyCash {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_PettyCash>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_PettyCash): LedgerEntry_PettyCash {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID petty_cash_id */ 1:
                    message.pettyCashId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.pettyCashId);
                    break;
                case /* rd.company.CashRegister cash_register */ 2:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_PettyCash, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID petty_cash_id = 1; */
        if (message.pettyCashId)
            UUID.internalBinaryWrite(message.pettyCashId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.CashRegister cash_register = 2; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.PettyCash
 */
export const LedgerEntry_PettyCash = new LedgerEntry_PettyCash$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_CloseOut$Type extends MessageType<LedgerEntry_CloseOut> {
    constructor() {
        super("rd.ledger.LedgerEntry.CloseOut", [
            { no: 1, name: "close_out_id", kind: "message", T: () => UUID },
            { no: 2, name: "cash_register", kind: "message", T: () => CashRegister }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_CloseOut>): LedgerEntry_CloseOut {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_CloseOut>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_CloseOut): LedgerEntry_CloseOut {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID close_out_id */ 1:
                    message.closeOutId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.closeOutId);
                    break;
                case /* rd.company.CashRegister cash_register */ 2:
                    message.cashRegister = CashRegister.internalBinaryRead(reader, reader.uint32(), options, message.cashRegister);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_CloseOut, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID close_out_id = 1; */
        if (message.closeOutId)
            UUID.internalBinaryWrite(message.closeOutId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.company.CashRegister cash_register = 2; */
        if (message.cashRegister)
            CashRegister.internalBinaryWrite(message.cashRegister, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.CloseOut
 */
export const LedgerEntry_CloseOut = new LedgerEntry_CloseOut$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Allocation$Type extends MessageType<LedgerEntry_Allocation> {
    constructor() {
        super("rd.ledger.LedgerEntry.Allocation", [
            { no: 1, name: "allocation_amount", kind: "message", T: () => Money },
            { no: 2, name: "payment_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 4, name: "write_off_ledger_entry_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Allocation>): LedgerEntry_Allocation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Allocation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Allocation): LedgerEntry_Allocation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.Money allocation_amount */ 1:
                    message.allocationAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.allocationAmount);
                    break;
                case /* rd.proto.uuid.UUID payment_ledger_entry_id */ 2:
                    message.paymentLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.paymentLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 3:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID write_off_ledger_entry_id */ 4:
                    message.writeOffLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.writeOffLedgerEntryId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Allocation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.Money allocation_amount = 1; */
        if (message.allocationAmount)
            Money.internalBinaryWrite(message.allocationAmount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID payment_ledger_entry_id = 2; */
        if (message.paymentLedgerEntryId)
            UUID.internalBinaryWrite(message.paymentLedgerEntryId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 3; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID write_off_ledger_entry_id = 4; */
        if (message.writeOffLedgerEntryId)
            UUID.internalBinaryWrite(message.writeOffLedgerEntryId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.Allocation
 */
export const LedgerEntry_Allocation = new LedgerEntry_Allocation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_Discount$Type extends MessageType<LedgerEntry_Discount> {
    constructor() {
        super("rd.ledger.LedgerEntry.Discount", [
            { no: 1, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "purchase_location_id", kind: "message", T: () => UUID },
            { no: 4, name: "bill_payment_id", kind: "message", T: () => UUID },
            { no: 5, name: "returned_from_sale_id", kind: "message", T: () => UUID },
            { no: 6, name: "return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_Discount>): LedgerEntry_Discount {
        const message = { returnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_Discount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_Discount): LedgerEntry_Discount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 1:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID purchase_location_id */ 3:
                    message.purchaseLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLocationId);
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 4:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                case /* rd.proto.uuid.UUID returned_from_sale_id */ 5:
                    message.returnedFromSaleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedFromSaleId);
                    break;
                case /* string return_identifier */ 6:
                    message.returnIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_Discount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 1; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID purchase_location_id = 3; */
        if (message.purchaseLocationId)
            UUID.internalBinaryWrite(message.purchaseLocationId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID bill_payment_id = 4; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID returned_from_sale_id = 5; */
        if (message.returnedFromSaleId)
            UUID.internalBinaryWrite(message.returnedFromSaleId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string return_identifier = 6; */
        if (message.returnIdentifier !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.returnIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.Discount
 */
export const LedgerEntry_Discount = new LedgerEntry_Discount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_ReverseDiscount$Type extends MessageType<LedgerEntry_ReverseDiscount> {
    constructor() {
        super("rd.ledger.LedgerEntry.ReverseDiscount", [
            { no: 1, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 3, name: "discount_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 5, name: "purchase_location_id", kind: "message", T: () => UUID },
            { no: 6, name: "bill_payment_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_ReverseDiscount>): LedgerEntry_ReverseDiscount {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_ReverseDiscount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_ReverseDiscount): LedgerEntry_ReverseDiscount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 1:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID discount_ledger_entry_id */ 3:
                    message.discountLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.discountLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID purchase_location_id */ 5:
                    message.purchaseLocationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLocationId);
                    break;
                case /* rd.proto.uuid.UUID bill_payment_id */ 6:
                    message.billPaymentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.billPaymentId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_ReverseDiscount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 1; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID discount_ledger_entry_id = 3; */
        if (message.discountLedgerEntryId)
            UUID.internalBinaryWrite(message.discountLedgerEntryId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID purchase_location_id = 5; */
        if (message.purchaseLocationId)
            UUID.internalBinaryWrite(message.purchaseLocationId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID bill_payment_id = 6; */
        if (message.billPaymentId)
            UUID.internalBinaryWrite(message.billPaymentId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.ReverseDiscount
 */
export const LedgerEntry_ReverseDiscount = new LedgerEntry_ReverseDiscount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_InventoryHold$Type extends MessageType<LedgerEntry_InventoryHold> {
    constructor() {
        super("rd.ledger.LedgerEntry.InventoryHold", [
            { no: 1, name: "subtype", kind: "enum", T: () => ["rd.ledger.LedgerEntry.InventoryHold.Subtype", LedgerEntry_InventoryHold_Subtype, "SUBTYPE_"] },
            { no: 2, name: "quantity_committed", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "cost", kind: "message", T: () => FractionalMoney },
            { no: 4, name: "sale_id", kind: "message", T: () => UUID },
            { no: 5, name: "sale_product_id", kind: "message", T: () => UUID },
            { no: 6, name: "transfer_id", kind: "message", T: () => UUID },
            { no: 7, name: "order_id", kind: "message", T: () => UUID },
            { no: 8, name: "reversed_id", kind: "message", T: () => UUID },
            { no: 9, name: "order_product_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_InventoryHold>): LedgerEntry_InventoryHold {
        const message = { subtype: 0, quantityCommitted: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_InventoryHold>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_InventoryHold): LedgerEntry_InventoryHold {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.InventoryHold.Subtype subtype */ 1:
                    message.subtype = reader.int32();
                    break;
                case /* int32 quantity_committed */ 2:
                    message.quantityCommitted = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost */ 3:
                    message.cost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.cost);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 4:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID sale_product_id */ 5:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                case /* rd.proto.uuid.UUID transfer_id */ 6:
                    message.transferId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transferId);
                    break;
                case /* rd.proto.uuid.UUID order_id */ 7:
                    message.orderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderId);
                    break;
                case /* rd.proto.uuid.UUID reversed_id */ 8:
                    message.reversedId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.reversedId);
                    break;
                case /* rd.proto.uuid.UUID order_product_id */ 9:
                    message.orderProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderProductId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_InventoryHold, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.InventoryHold.Subtype subtype = 1; */
        if (message.subtype !== 0)
            writer.tag(1, WireType.Varint).int32(message.subtype);
        /* int32 quantity_committed = 2; */
        if (message.quantityCommitted !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityCommitted);
        /* rd.proto.money.FractionalMoney cost = 3; */
        if (message.cost)
            FractionalMoney.internalBinaryWrite(message.cost, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 4; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_product_id = 5; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID transfer_id = 6; */
        if (message.transferId)
            UUID.internalBinaryWrite(message.transferId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_id = 7; */
        if (message.orderId)
            UUID.internalBinaryWrite(message.orderId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID reversed_id = 8; */
        if (message.reversedId)
            UUID.internalBinaryWrite(message.reversedId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_product_id = 9; */
        if (message.orderProductId)
            UUID.internalBinaryWrite(message.orderProductId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.InventoryHold
 */
export const LedgerEntry_InventoryHold = new LedgerEntry_InventoryHold$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_InventoryOrder$Type extends MessageType<LedgerEntry_InventoryOrder> {
    constructor() {
        super("rd.ledger.LedgerEntry.InventoryOrder", [
            { no: 1, name: "subtype", kind: "enum", T: () => ["rd.ledger.LedgerEntry.InventoryOrder.Subtype", LedgerEntry_InventoryOrder_Subtype, "SUBTYPE_"] },
            { no: 2, name: "quantity_ordered", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "cost", kind: "message", T: () => FractionalMoney },
            { no: 6, name: "transfer_id", kind: "message", T: () => UUID },
            { no: 7, name: "order_id", kind: "message", T: () => UUID },
            { no: 8, name: "reversed_id", kind: "message", T: () => UUID },
            { no: 9, name: "order_product_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_InventoryOrder>): LedgerEntry_InventoryOrder {
        const message = { subtype: 0, quantityOrdered: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_InventoryOrder>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_InventoryOrder): LedgerEntry_InventoryOrder {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.InventoryOrder.Subtype subtype */ 1:
                    message.subtype = reader.int32();
                    break;
                case /* int32 quantity_ordered */ 2:
                    message.quantityOrdered = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost */ 3:
                    message.cost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.cost);
                    break;
                case /* rd.proto.uuid.UUID transfer_id */ 6:
                    message.transferId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transferId);
                    break;
                case /* rd.proto.uuid.UUID order_id */ 7:
                    message.orderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderId);
                    break;
                case /* rd.proto.uuid.UUID reversed_id */ 8:
                    message.reversedId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.reversedId);
                    break;
                case /* rd.proto.uuid.UUID order_product_id */ 9:
                    message.orderProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderProductId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_InventoryOrder, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.InventoryOrder.Subtype subtype = 1; */
        if (message.subtype !== 0)
            writer.tag(1, WireType.Varint).int32(message.subtype);
        /* int32 quantity_ordered = 2; */
        if (message.quantityOrdered !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityOrdered);
        /* rd.proto.money.FractionalMoney cost = 3; */
        if (message.cost)
            FractionalMoney.internalBinaryWrite(message.cost, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID transfer_id = 6; */
        if (message.transferId)
            UUID.internalBinaryWrite(message.transferId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_id = 7; */
        if (message.orderId)
            UUID.internalBinaryWrite(message.orderId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID reversed_id = 8; */
        if (message.reversedId)
            UUID.internalBinaryWrite(message.reversedId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_product_id = 9; */
        if (message.orderProductId)
            UUID.internalBinaryWrite(message.orderProductId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.InventoryOrder
 */
export const LedgerEntry_InventoryOrder = new LedgerEntry_InventoryOrder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_InventoryReceipt$Type extends MessageType<LedgerEntry_InventoryReceipt> {
    constructor() {
        super("rd.ledger.LedgerEntry.InventoryReceipt", [
            { no: 1, name: "subtype", kind: "enum", T: () => ["rd.ledger.LedgerEntry.InventoryReceipt.Subtype", LedgerEntry_InventoryReceipt_Subtype, "SUBTYPE_"] },
            { no: 2, name: "quantity_received", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "cost", kind: "message", T: () => FractionalMoney },
            { no: 4, name: "returned_from_sale_id", kind: "message", T: () => UUID },
            { no: 5, name: "returned_from_sale_product_id", kind: "message", T: () => UUID },
            { no: 6, name: "sale_return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "transfer_id", kind: "message", T: () => UUID },
            { no: 8, name: "order_shipment_id", kind: "message", T: () => UUID },
            { no: 9, name: "order_product_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_InventoryReceipt>): LedgerEntry_InventoryReceipt {
        const message = { subtype: 0, quantityReceived: 0, saleReturnIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_InventoryReceipt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_InventoryReceipt): LedgerEntry_InventoryReceipt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.InventoryReceipt.Subtype subtype */ 1:
                    message.subtype = reader.int32();
                    break;
                case /* int32 quantity_received */ 2:
                    message.quantityReceived = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost */ 3:
                    message.cost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.cost);
                    break;
                case /* rd.proto.uuid.UUID returned_from_sale_id */ 4:
                    message.returnedFromSaleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedFromSaleId);
                    break;
                case /* rd.proto.uuid.UUID returned_from_sale_product_id */ 5:
                    message.returnedFromSaleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedFromSaleProductId);
                    break;
                case /* string sale_return_identifier */ 6:
                    message.saleReturnIdentifier = reader.string();
                    break;
                case /* rd.proto.uuid.UUID transfer_id */ 7:
                    message.transferId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transferId);
                    break;
                case /* rd.proto.uuid.UUID order_shipment_id */ 8:
                    message.orderShipmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderShipmentId);
                    break;
                case /* rd.proto.uuid.UUID order_product_id */ 9:
                    message.orderProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderProductId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_InventoryReceipt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.InventoryReceipt.Subtype subtype = 1; */
        if (message.subtype !== 0)
            writer.tag(1, WireType.Varint).int32(message.subtype);
        /* int32 quantity_received = 2; */
        if (message.quantityReceived !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityReceived);
        /* rd.proto.money.FractionalMoney cost = 3; */
        if (message.cost)
            FractionalMoney.internalBinaryWrite(message.cost, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID returned_from_sale_id = 4; */
        if (message.returnedFromSaleId)
            UUID.internalBinaryWrite(message.returnedFromSaleId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID returned_from_sale_product_id = 5; */
        if (message.returnedFromSaleProductId)
            UUID.internalBinaryWrite(message.returnedFromSaleProductId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string sale_return_identifier = 6; */
        if (message.saleReturnIdentifier !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.saleReturnIdentifier);
        /* rd.proto.uuid.UUID transfer_id = 7; */
        if (message.transferId)
            UUID.internalBinaryWrite(message.transferId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_shipment_id = 8; */
        if (message.orderShipmentId)
            UUID.internalBinaryWrite(message.orderShipmentId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_product_id = 9; */
        if (message.orderProductId)
            UUID.internalBinaryWrite(message.orderProductId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.InventoryReceipt
 */
export const LedgerEntry_InventoryReceipt = new LedgerEntry_InventoryReceipt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_InventoryRelease$Type extends MessageType<LedgerEntry_InventoryRelease> {
    constructor() {
        super("rd.ledger.LedgerEntry.InventoryRelease", [
            { no: 1, name: "subtype", kind: "enum", T: () => ["rd.ledger.LedgerEntry.InventoryRelease.Subtype", LedgerEntry_InventoryRelease_Subtype, "SUBTYPE_"] },
            { no: 2, name: "quantity_released", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "cost", kind: "message", T: () => FractionalMoney },
            { no: 4, name: "sale_id", kind: "message", T: () => UUID },
            { no: 5, name: "sale_product_id", kind: "message", T: () => UUID },
            { no: 6, name: "transfer_id", kind: "message", T: () => UUID },
            { no: 7, name: "order_shipment_id", kind: "message", T: () => UUID },
            { no: 8, name: "order_product_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_InventoryRelease>): LedgerEntry_InventoryRelease {
        const message = { subtype: 0, quantityReleased: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_InventoryRelease>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_InventoryRelease): LedgerEntry_InventoryRelease {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.InventoryRelease.Subtype subtype */ 1:
                    message.subtype = reader.int32();
                    break;
                case /* int32 quantity_released */ 2:
                    message.quantityReleased = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost */ 3:
                    message.cost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.cost);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 4:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID sale_product_id */ 5:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                case /* rd.proto.uuid.UUID transfer_id */ 6:
                    message.transferId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transferId);
                    break;
                case /* rd.proto.uuid.UUID order_shipment_id */ 7:
                    message.orderShipmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderShipmentId);
                    break;
                case /* rd.proto.uuid.UUID order_product_id */ 8:
                    message.orderProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderProductId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_InventoryRelease, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.InventoryRelease.Subtype subtype = 1; */
        if (message.subtype !== 0)
            writer.tag(1, WireType.Varint).int32(message.subtype);
        /* int32 quantity_released = 2; */
        if (message.quantityReleased !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantityReleased);
        /* rd.proto.money.FractionalMoney cost = 3; */
        if (message.cost)
            FractionalMoney.internalBinaryWrite(message.cost, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 4; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_product_id = 5; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID transfer_id = 6; */
        if (message.transferId)
            UUID.internalBinaryWrite(message.transferId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_shipment_id = 7; */
        if (message.orderShipmentId)
            UUID.internalBinaryWrite(message.orderShipmentId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_product_id = 8; */
        if (message.orderProductId)
            UUID.internalBinaryWrite(message.orderProductId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.InventoryRelease
 */
export const LedgerEntry_InventoryRelease = new LedgerEntry_InventoryRelease$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_InventoryCount$Type extends MessageType<LedgerEntry_InventoryCount> {
    constructor() {
        super("rd.ledger.LedgerEntry.InventoryCount", [
            { no: 1, name: "subtype", kind: "enum", T: () => ["rd.ledger.LedgerEntry.InventoryCount.Subtype", LedgerEntry_InventoryCount_Subtype, "SUBTYPE_"] },
            { no: 2, name: "new_quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "old_quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "cost", kind: "message", T: () => FractionalMoney },
            { no: 5, name: "inventory_count_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_InventoryCount>): LedgerEntry_InventoryCount {
        const message = { subtype: 0, newQuantity: 0, oldQuantity: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_InventoryCount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_InventoryCount): LedgerEntry_InventoryCount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.InventoryCount.Subtype subtype */ 1:
                    message.subtype = reader.int32();
                    break;
                case /* int32 new_quantity */ 2:
                    message.newQuantity = reader.int32();
                    break;
                case /* int32 old_quantity */ 3:
                    message.oldQuantity = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney cost */ 4:
                    message.cost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.cost);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 5:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_InventoryCount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.InventoryCount.Subtype subtype = 1; */
        if (message.subtype !== 0)
            writer.tag(1, WireType.Varint).int32(message.subtype);
        /* int32 new_quantity = 2; */
        if (message.newQuantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.newQuantity);
        /* int32 old_quantity = 3; */
        if (message.oldQuantity !== 0)
            writer.tag(3, WireType.Varint).int32(message.oldQuantity);
        /* rd.proto.money.FractionalMoney cost = 4; */
        if (message.cost)
            FractionalMoney.internalBinaryWrite(message.cost, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 5; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.InventoryCount
 */
export const LedgerEntry_InventoryCount = new LedgerEntry_InventoryCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_InventoryCostEdit$Type extends MessageType<LedgerEntry_InventoryCostEdit> {
    constructor() {
        super("rd.ledger.LedgerEntry.InventoryCostEdit", [
            { no: 1, name: "subtype", kind: "enum", T: () => ["rd.ledger.LedgerEntry.InventoryCostEdit.Subtype", LedgerEntry_InventoryCostEdit_Subtype, "SUBTYPE_"] },
            { no: 2, name: "new_cost", kind: "message", T: () => FractionalMoney },
            { no: 3, name: "old_cost", kind: "message", T: () => FractionalMoney },
            { no: 4, name: "sale_id", kind: "message", T: () => UUID },
            { no: 5, name: "sale_product_id", kind: "message", T: () => UUID },
            { no: 6, name: "sale_return_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "order_shipment_id", kind: "message", T: () => UUID },
            { no: 8, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "order_product_id", kind: "message", T: () => UUID },
            { no: 10, name: "returned_from_sale_product_id", kind: "message", T: () => UUID },
            { no: 11, name: "returned_from_sale_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_InventoryCostEdit>): LedgerEntry_InventoryCostEdit {
        const message = { subtype: 0, saleReturnIdentifier: "", quantity: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_InventoryCostEdit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_InventoryCostEdit): LedgerEntry_InventoryCostEdit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.ledger.LedgerEntry.InventoryCostEdit.Subtype subtype */ 1:
                    message.subtype = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney new_cost */ 2:
                    message.newCost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.newCost);
                    break;
                case /* rd.proto.money.FractionalMoney old_cost */ 3:
                    message.oldCost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.oldCost);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 4:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID sale_product_id */ 5:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                case /* string sale_return_identifier */ 6:
                    message.saleReturnIdentifier = reader.string();
                    break;
                case /* rd.proto.uuid.UUID order_shipment_id */ 7:
                    message.orderShipmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderShipmentId);
                    break;
                case /* int32 quantity */ 8:
                    message.quantity = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID order_product_id */ 9:
                    message.orderProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderProductId);
                    break;
                case /* rd.proto.uuid.UUID returned_from_sale_product_id */ 10:
                    message.returnedFromSaleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedFromSaleProductId);
                    break;
                case /* rd.proto.uuid.UUID returned_from_sale_id */ 11:
                    message.returnedFromSaleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.returnedFromSaleId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_InventoryCostEdit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.ledger.LedgerEntry.InventoryCostEdit.Subtype subtype = 1; */
        if (message.subtype !== 0)
            writer.tag(1, WireType.Varint).int32(message.subtype);
        /* rd.proto.money.FractionalMoney new_cost = 2; */
        if (message.newCost)
            FractionalMoney.internalBinaryWrite(message.newCost, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney old_cost = 3; */
        if (message.oldCost)
            FractionalMoney.internalBinaryWrite(message.oldCost, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 4; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_product_id = 5; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string sale_return_identifier = 6; */
        if (message.saleReturnIdentifier !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.saleReturnIdentifier);
        /* rd.proto.uuid.UUID order_shipment_id = 7; */
        if (message.orderShipmentId)
            UUID.internalBinaryWrite(message.orderShipmentId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 8; */
        if (message.quantity !== 0)
            writer.tag(8, WireType.Varint).int32(message.quantity);
        /* rd.proto.uuid.UUID order_product_id = 9; */
        if (message.orderProductId)
            UUID.internalBinaryWrite(message.orderProductId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID returned_from_sale_product_id = 10; */
        if (message.returnedFromSaleProductId)
            UUID.internalBinaryWrite(message.returnedFromSaleProductId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID returned_from_sale_id = 11; */
        if (message.returnedFromSaleId)
            UUID.internalBinaryWrite(message.returnedFromSaleId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.InventoryCostEdit
 */
export const LedgerEntry_InventoryCostEdit = new LedgerEntry_InventoryCostEdit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_InventoryMetadata$Type extends MessageType<LedgerEntry_InventoryMetadata> {
    constructor() {
        super("rd.ledger.LedgerEntry.InventoryMetadata", [
            { no: 1, name: "average_cost", kind: "message", T: () => FractionalMoney },
            { no: 2, name: "on_hand_inventories_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 3, name: "on_order_inventories_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 4, name: "committed_inventories_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_InventoryMetadata>): LedgerEntry_InventoryMetadata {
        const message = { onHandInventoriesByLocationId: {}, onOrderInventoriesByLocationId: {}, committedInventoriesByLocationId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_InventoryMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_InventoryMetadata): LedgerEntry_InventoryMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.money.FractionalMoney average_cost */ 1:
                    message.averageCost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.averageCost);
                    break;
                case /* map<string, int32> on_hand_inventories_by_location_id */ 2:
                    this.binaryReadMap2(message.onHandInventoriesByLocationId, reader, options);
                    break;
                case /* map<string, int32> on_order_inventories_by_location_id */ 3:
                    this.binaryReadMap3(message.onOrderInventoriesByLocationId, reader, options);
                    break;
                case /* map<string, int32> committed_inventories_by_location_id */ 4:
                    this.binaryReadMap4(message.committedInventoriesByLocationId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: LedgerEntry_InventoryMetadata["onHandInventoriesByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_InventoryMetadata["onHandInventoriesByLocationId"] | undefined, val: LedgerEntry_InventoryMetadata["onHandInventoriesByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.InventoryMetadata.on_hand_inventories_by_location_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap3(map: LedgerEntry_InventoryMetadata["onOrderInventoriesByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_InventoryMetadata["onOrderInventoriesByLocationId"] | undefined, val: LedgerEntry_InventoryMetadata["onOrderInventoriesByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.InventoryMetadata.on_order_inventories_by_location_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap4(map: LedgerEntry_InventoryMetadata["committedInventoriesByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_InventoryMetadata["committedInventoriesByLocationId"] | undefined, val: LedgerEntry_InventoryMetadata["committedInventoriesByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.InventoryMetadata.committed_inventories_by_location_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: LedgerEntry_InventoryMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.money.FractionalMoney average_cost = 1; */
        if (message.averageCost)
            FractionalMoney.internalBinaryWrite(message.averageCost, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* map<string, int32> on_hand_inventories_by_location_id = 2; */
        for (let k of Object.keys(message.onHandInventoriesByLocationId))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.onHandInventoriesByLocationId[k]).join();
        /* map<string, int32> on_order_inventories_by_location_id = 3; */
        for (let k of Object.keys(message.onOrderInventoriesByLocationId))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.onOrderInventoriesByLocationId[k]).join();
        /* map<string, int32> committed_inventories_by_location_id = 4; */
        for (let k of Object.keys(message.committedInventoriesByLocationId))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.committedInventoriesByLocationId[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.InventoryMetadata
 */
export const LedgerEntry_InventoryMetadata = new LedgerEntry_InventoryMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_DepositPurchase$Type extends MessageType<LedgerEntry_DepositPurchase> {
    constructor() {
        super("rd.ledger.LedgerEntry.DepositPurchase", [
            { no: 1, name: "sale_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_DepositPurchase>): LedgerEntry_DepositPurchase {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_DepositPurchase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_DepositPurchase): LedgerEntry_DepositPurchase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID sale_id */ 1:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_DepositPurchase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID sale_id = 1; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.DepositPurchase
 */
export const LedgerEntry_DepositPurchase = new LedgerEntry_DepositPurchase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_DepositRedemption$Type extends MessageType<LedgerEntry_DepositRedemption> {
    constructor() {
        super("rd.ledger.LedgerEntry.DepositRedemption", [
            { no: 1, name: "deposit_purchase_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "sale_id", kind: "message", T: () => UUID },
            { no: 3, name: "purchase_ledger_entry_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_DepositRedemption>): LedgerEntry_DepositRedemption {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_DepositRedemption>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_DepositRedemption): LedgerEntry_DepositRedemption {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID deposit_purchase_ledger_entry_id */ 1:
                    message.depositPurchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.depositPurchaseLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 2:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID purchase_ledger_entry_id */ 3:
                    message.purchaseLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.purchaseLedgerEntryId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_DepositRedemption, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID deposit_purchase_ledger_entry_id = 1; */
        if (message.depositPurchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.depositPurchaseLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 2; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID purchase_ledger_entry_id = 3; */
        if (message.purchaseLedgerEntryId)
            UUID.internalBinaryWrite(message.purchaseLedgerEntryId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.DepositRedemption
 */
export const LedgerEntry_DepositRedemption = new LedgerEntry_DepositRedemption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_DepositForfeiture$Type extends MessageType<LedgerEntry_DepositForfeiture> {
    constructor() {
        super("rd.ledger.LedgerEntry.DepositForfeiture", [
            { no: 1, name: "deposit_redemption_ledger_entry_id", kind: "message", T: () => UUID },
            { no: 2, name: "sale_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_DepositForfeiture>): LedgerEntry_DepositForfeiture {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_DepositForfeiture>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_DepositForfeiture): LedgerEntry_DepositForfeiture {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID deposit_redemption_ledger_entry_id */ 1:
                    message.depositRedemptionLedgerEntryId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.depositRedemptionLedgerEntryId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 2:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LedgerEntry_DepositForfeiture, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID deposit_redemption_ledger_entry_id = 1; */
        if (message.depositRedemptionLedgerEntryId)
            UUID.internalBinaryWrite(message.depositRedemptionLedgerEntryId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 2; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.DepositForfeiture
 */
export const LedgerEntry_DepositForfeiture = new LedgerEntry_DepositForfeiture$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LedgerEntry_FinancingCharge$Type extends MessageType<LedgerEntry_FinancingCharge> {
    constructor() {
        super("rd.ledger.LedgerEntry.FinancingCharge", [
            { no: 1, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "assessed_purchases_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 3, name: "assessed_finance_charges_by_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Money } },
            { no: 4, name: "assessment_rate", kind: "message", T: () => Decimal },
            { no: 5, name: "adjustment_amount", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<LedgerEntry_FinancingCharge>): LedgerEntry_FinancingCharge {
        const message = { identifier: "", assessedPurchasesById: {}, assessedFinanceChargesById: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LedgerEntry_FinancingCharge>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LedgerEntry_FinancingCharge): LedgerEntry_FinancingCharge {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string identifier */ 1:
                    message.identifier = reader.string();
                    break;
                case /* map<string, rd.proto.money.Money> assessed_purchases_by_id */ 2:
                    this.binaryReadMap2(message.assessedPurchasesById, reader, options);
                    break;
                case /* map<string, rd.proto.money.Money> assessed_finance_charges_by_id */ 3:
                    this.binaryReadMap3(message.assessedFinanceChargesById, reader, options);
                    break;
                case /* rd.proto.decimal.Decimal assessment_rate */ 4:
                    message.assessmentRate = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.assessmentRate);
                    break;
                case /* rd.proto.money.Money adjustment_amount */ 5:
                    message.adjustmentAmount = Money.internalBinaryRead(reader, reader.uint32(), options, message.adjustmentAmount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: LedgerEntry_FinancingCharge["assessedPurchasesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_FinancingCharge["assessedPurchasesById"] | undefined, val: LedgerEntry_FinancingCharge["assessedPurchasesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.FinancingCharge.assessed_purchases_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    private binaryReadMap3(map: LedgerEntry_FinancingCharge["assessedFinanceChargesById"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LedgerEntry_FinancingCharge["assessedFinanceChargesById"] | undefined, val: LedgerEntry_FinancingCharge["assessedFinanceChargesById"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Money.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.ledger.LedgerEntry.FinancingCharge.assessed_finance_charges_by_id");
            }
        }
        map[key ?? ""] = val ?? Money.create();
    }
    internalBinaryWrite(message: LedgerEntry_FinancingCharge, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string identifier = 1; */
        if (message.identifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.identifier);
        /* map<string, rd.proto.money.Money> assessed_purchases_by_id = 2; */
        for (let k of Object.keys(message.assessedPurchasesById)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.assessedPurchasesById[k], writer, options);
            writer.join().join();
        }
        /* map<string, rd.proto.money.Money> assessed_finance_charges_by_id = 3; */
        for (let k of Object.keys(message.assessedFinanceChargesById)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Money.internalBinaryWrite(message.assessedFinanceChargesById[k], writer, options);
            writer.join().join();
        }
        /* rd.proto.decimal.Decimal assessment_rate = 4; */
        if (message.assessmentRate)
            Decimal.internalBinaryWrite(message.assessmentRate, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money adjustment_amount = 5; */
        if (message.adjustmentAmount)
            Money.internalBinaryWrite(message.adjustmentAmount, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.ledger.LedgerEntry.FinancingCharge
 */
export const LedgerEntry_FinancingCharge = new LedgerEntry_FinancingCharge$Type();
