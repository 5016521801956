/*
  Page Title Patterns
  ------------------
  Base pages (index.tsx):    "{Base}" (e.g., "Staff", "Cash Management")
  New transaction pages:     "New {Base}" (e.g., "New Sale") 
  Detail pages ([id].tsx):   "{Base} details" (e.g., "Product details")
  Tab/filtered pages:        "{Base} › {Tab}" (e.g., "Sales › Drafts")

  Notes:
    - Use title case for base and new transaction pages
    - Use sentence case for detail and tab/filtered pages
    - After the › symbol, capitalize the next word as if starting a new sentence
    - Use › (U+203A), not > (U+003E) for navigation breadcrumbs
*/

export type HeadTitle = (typeof PageTitles)[keyof typeof PageTitles]

export const POSPageTitles = {
  /// Sales
  NEW_SALE: "New Sale",
  SALE_DETAILS: "Sale details",
  SALE_RETURN_DETAILS: "Return details",
  SALES_DRAFTS: "Sales › Drafts",
  SALES_QUOTES: "Sales › Quotes",
  SALES_WILL_CALL: "Sales › Will call",
  SALES_SPECIAL_ORDERS: "Sales › Special orders",
  SALES_SOLD: "Sales › Sold",
  SALES_RETURNS: "Sales › Returns",
  SALES_VOIDED: "Sales › Voided",

  /// Receivables
  RECEIVABLES_PAYMENT: "New Bill Payment", // special
  RECEIVABLES_DETAILS: "Receivable details",
  RECEIVABLES_PAID: "Receivables › Paid",
  RECEIVABLES_REFUNDED: "Receivables › Refunded",
  RECEIVABLES_FINANCE_CHARGES: "Receivables › Finance charges",
  RECEIVABLES_FINANCE_CHARGE_DETAILS: "Finance charge details",

  /// Cash Management
  CASH_MANAGEMENT: "Cash Management",

  /// Purchase Orders
  PURCHASE_ORDERS_NEW: "New Order",
  PURCHASE_ORDERS_DETAILS: "Order details",
  PURCHASE_ORDERS_DRAFTS: "Orders › Drafts",
  PURCHASE_ORDERS_ORDERED: "Orders › Ordered",
  PURCHASE_ORDERS_RECEIVED: "Orders › Received",
  PURCHASE_ORDERS_VOUCHERED: "Orders › Vouchered",
  PURCHASE_ORDERS_PAID: "Orders › Paid",
  PURCHASE_ORDERS_VOIDED: "Orders › Voided",
  PURCHASE_ORDERS_SPECIAL_ORDER_PRODUCTS: "Orders › Special order products",

  /// Transfers
  TRANSFERS_NEW: "New Transfer",
  TRANSFERS_DETAILS: "Transfer details",
  TRANSFERS_DRAFTS: "Transfers › Drafts",
  TRANSFERS_REQUESTED: "Transfers › Requested",
  TRANSFERS_SENT: "Transfers › Sent",
  TRANSFERS_RECEIVED: "Transfers › Received",
  TRANSFERS_VOIDED: "Transfers › Voided",

  /// Counts
  COUNTS_DETAILS: "Count details",
  COUNTS_IN_PROGRESS: "Counts › In progress",
  COUNTS_COMPLETED: "Counts › Completed",
  COUNTS_VOIDED: "Counts › Voided",

  /// Products
  PRODUCTS: "Products",
  PRODUCT_DETAILS: "Product details",
  PRODUCTS_CUSTOM_PRICES: "Products › Custom prices",
  PRODUCTS_PROMOTIONS: "Products › Promotions",

  /// Customers
  CUSTOMERS: "Customers",
  CUSTOMER_DETAILS: "Customer details",
  CUSTOMERS_CONTACTS: "Customers › Contacts",
  CUSTOMERS_JOBS: "Customers › Jobs",
  CUSTOMER_JOB_DETAILS: "Job details",

  /// Vendors
  VENDORS: "Vendors",
  VENDOR_DETAILS: "Vendor details",

  /// Analytics
  ANALYTICS_SUMMARY: "Summary",
  ANALYTICS_REPORTS: "Reports",
  ANALYTICS_AI: "Rundoo AI",
  ANALYTICS_BUILD_REPORT: "Report Builder",
} as const

export const AdminPageTitles = {
  IDEAS: "Ideas",
  QUICKBOOKS: "QuickBooks",
  CHART_OF_ACCOUNTS: "Chart of accounts",

  /// Pricing Tiers
  PRICING_TIER_DETAILS: "Pricing tier details",

  /// Finances
  FINANCES_INTERNAL: "Finances › Internal",
  FINANCES_EXTERNAL: "Finances › External",
  FINANCES_EXPRESS: "Finances › Express",

  /// Reporting
  REPORTING_PROFIT_AND_LOSS: "Reporting › Profit & loss",
  REPORTING_BALANCE_SHEET: "Reporting › Balance sheet",
  REPORTING_PURCHASE_JOURNAL: "Reporting › Purchase journal",
  REPORTING_JOURNAL_ENTRIES: "Reporting › Journal entries",
  REPORTING_PAYOUTS: "Reporting › Payouts",
  REPORTING_GENERAL_LEDGER: "Reporting › General ledger",

  /// General
  COMPANY: "Company",
  LOCATIONS: "Locations",
  LOCATION_DETAILS: "Location details",
  STAFF: "Staff",
  STAFF_DETAILS: "Staff details",

  /// Blocks
  CUSTOMER: "Customer",
  PRODUCT: "Product",
  TAGS: "Tags",

  /// Settings
  COMMUNICATIONS: "Communications",
  SECURITY: "Security",
} as const

export const AuthPageTitles = {
  SIGN_IN: "Sign in",
} as const

export const ErrorPageTitles = {
  ERROR: "Error",
} as const

const PageTitles = {
  ...POSPageTitles,
  ...AdminPageTitles,
  ...AuthPageTitles,
  ...ErrorPageTitles,
} as const
