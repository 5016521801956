/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "search/service/service.proto" (package "rd.search.service", syntax proto3)
// tslint:disable
// @ts-nocheck
import { CxaSearchService } from "./service_pb";
import type { CxaSearchJobsRes } from "./service_pb";
import type { CxaSearchJobsReq } from "./service_pb";
import type { CxaSearchReturnsRes } from "./service_pb";
import type { CxaSearchReturnsReq } from "./service_pb";
import type { CxaSearchOutstandingSalesRes } from "./service_pb";
import type { CxaSearchOutstandingSalesReq } from "./service_pb";
import type { CxaSearchSalesRes } from "./service_pb";
import type { CxaSearchSalesReq } from "./service_pb";
import { SearchalyticsService } from "./service_pb";
import type { GetReportBuilderRes } from "./service_pb";
import type { GetReportBuilderReq } from "./service_pb";
import type { GetInteractionsCSVRes } from "./service_pb";
import type { GetInteractionsCSVReq } from "./service_pb";
import type { GetInteractionsPDFRes } from "./service_pb";
import type { GetInteractionsPDFReq } from "./service_pb";
import type { GetInteractionsRes } from "./service_pb";
import type { GetInteractionsReq } from "./service_pb";
import type { GetInteractionsByStaffCSVRes } from "./service_pb";
import type { GetInteractionsByStaffCSVReq } from "./service_pb";
import type { GetInteractionsByStaffPDFRes } from "./service_pb";
import type { GetInteractionsByStaffPDFReq } from "./service_pb";
import type { GetInteractionsByStaffRes } from "./service_pb";
import type { GetInteractionsByStaffReq } from "./service_pb";
import type { GetTransferDiscrepanciesCSVRes } from "./service_pb";
import type { GetTransferDiscrepanciesCSVReq } from "./service_pb";
import type { GetTransferDiscrepanciesPDFRes } from "./service_pb";
import type { GetTransferDiscrepanciesPDFReq } from "./service_pb";
import type { GetTransferDiscrepanciesRes } from "./service_pb";
import type { GetTransferDiscrepanciesReq } from "./service_pb";
import type { GetInventoryByProductCSVRes } from "./service_pb";
import type { GetInventoryByProductCSVReq } from "./service_pb";
import type { GetInventoryByProductPDFRes } from "./service_pb";
import type { GetInventoryByProductPDFReq } from "./service_pb";
import type { GetInventoryByProductRes } from "./service_pb";
import type { GetInventoryByProductReq } from "./service_pb";
import type { GetInventoryByDepartmentCSVRes } from "./service_pb";
import type { GetInventoryByDepartmentCSVReq } from "./service_pb";
import type { GetInventoryByDepartmentPDFRes } from "./service_pb";
import type { GetInventoryByDepartmentPDFReq } from "./service_pb";
import type { GetInventoryByDepartmentRes } from "./service_pb";
import type { GetInventoryByDepartmentReq } from "./service_pb";
import type { GetAdditionalFeesCSVRes } from "./service_pb";
import type { GetAdditionalFeesCSVReq } from "./service_pb";
import type { GetAdditionalFeesPDFRes } from "./service_pb";
import type { GetAdditionalFeesPDFReq } from "./service_pb";
import type { GetAdditionalFeesRes } from "./service_pb";
import type { GetAdditionalFeesReq } from "./service_pb";
import type { GetAccountPaymentAdjustmentsCSVRes } from "./service_pb";
import type { GetAccountPaymentAdjustmentsCSVReq } from "./service_pb";
import type { GetAccountPaymentAdjustmentsPDFRes } from "./service_pb";
import type { GetAccountPaymentAdjustmentsPDFReq } from "./service_pb";
import type { GetAccountPaymentAdjustmentsRes } from "./service_pb";
import type { GetAccountPaymentAdjustmentsReq } from "./service_pb";
import type { GetPurchaseJournalCSVRes } from "./service_pb";
import type { GetPurchaseJournalCSVReq } from "./service_pb";
import type { GetPurchaseJournalPDFRes } from "./service_pb";
import type { GetPurchaseJournalPDFReq } from "./service_pb";
import type { GetPurchaseJournalRes } from "./service_pb";
import type { GetPurchaseJournalReq } from "./service_pb";
import type { GetPriceEditsCSVRes } from "./service_pb";
import type { GetPriceEditsCSVReq } from "./service_pb";
import type { GetPriceEditsPDFRes } from "./service_pb";
import type { GetPriceEditsPDFReq } from "./service_pb";
import type { GetPriceEditsRes } from "./service_pb";
import type { GetPriceEditsReq } from "./service_pb";
import type { GetAgingDetailCSVRes } from "./service_pb";
import type { GetAgingDetailCSVReq } from "./service_pb";
import type { GetAgingDetailPDFRes } from "./service_pb";
import type { GetAgingDetailPDFReq } from "./service_pb";
import type { GetAgingDetailRes } from "./service_pb";
import type { GetAgingDetailReq } from "./service_pb";
import type { GetNewAccountsOpenedCSVRes } from "./service_pb";
import type { GetNewAccountsOpenedCSVReq } from "./service_pb";
import type { GetNewAccountsOpenedPDFRes } from "./service_pb";
import type { GetNewAccountsOpenedPDFReq } from "./service_pb";
import type { GetNewAccountsOpenedRes } from "./service_pb";
import type { GetNewAccountsOpenedReq } from "./service_pb";
import type { GetProfitAndLossPDFRes } from "./service_pb";
import type { GetProfitAndLossPDFReq } from "./service_pb";
import type { GetProfitAndLossRes } from "./service_pb";
import type { GetProfitAndLossReq } from "./service_pb";
import type { GetAgingByJobCSVRes } from "./service_pb";
import type { GetAgingByJobCSVReq } from "./service_pb";
import type { GetAgingByJobPDFRes } from "./service_pb";
import type { GetAgingByJobPDFReq } from "./service_pb";
import type { GetAgingByJobRes } from "./service_pb";
import type { GetAgingByJobReq } from "./service_pb";
import type { GetCustomerAgingCSVRes } from "../../api/analytics/service_pb";
import type { GetCustomerAgingCSVReq } from "../../api/analytics/service_pb";
import type { GetCustomerAgingPDFRes } from "../../api/analytics/service_pb";
import type { GetCustomerAgingPDFReq } from "../../api/analytics/service_pb";
import type { GetCustomerAgingRes } from "../../api/analytics/service_pb";
import type { GetCustomerAgingReq } from "../../api/analytics/service_pb";
import type { GetSalesByRepCSVRes } from "./service_pb";
import type { GetSalesByRepCSVReq } from "./service_pb";
import type { GetSalesByRepPDFRes } from "./service_pb";
import type { GetSalesByRepPDFReq } from "./service_pb";
import type { GetSalesByRepRes } from "./service_pb";
import type { GetSalesByRepReq } from "./service_pb";
import type { GetSalesByLocationCSVRes } from "./service_pb";
import type { GetSalesByLocationCSVReq } from "./service_pb";
import type { GetSalesByLocationPDFRes } from "./service_pb";
import type { GetSalesByLocationPDFReq } from "./service_pb";
import type { GetSalesByLocationRes } from "./service_pb";
import type { GetSalesByLocationReq } from "./service_pb";
import type { GetSalesByProductCSVRes } from "./service_pb";
import type { GetSalesByProductCSVReq } from "./service_pb";
import type { GetSalesByProductPDFRes } from "./service_pb";
import type { GetSalesByProductPDFReq } from "./service_pb";
import type { GetSalesByProductRes } from "./service_pb";
import type { GetSalesByProductReq } from "./service_pb";
import type { GetSalesByJobCSVRes } from "./service_pb";
import type { GetSalesByJobCSVReq } from "./service_pb";
import type { GetSalesByJobPDFRes } from "./service_pb";
import type { GetSalesByJobPDFReq } from "./service_pb";
import type { GetSalesByJobRes } from "./service_pb";
import type { GetSalesByJobReq } from "./service_pb";
import type { GetSalesByCustomerCSVRes } from "./service_pb";
import type { GetSalesByCustomerCSVReq } from "./service_pb";
import type { GetSalesByCustomerPDFRes } from "./service_pb";
import type { GetSalesByCustomerPDFReq } from "./service_pb";
import type { GetSalesByCustomerRes } from "./service_pb";
import type { GetSalesByCustomerReq } from "./service_pb";
import type { GetSoldProductsCSVRes } from "./service_pb";
import type { GetSoldProductsCSVReq } from "./service_pb";
import type { GetSoldProductsPDFRes } from "./service_pb";
import type { GetSoldProductsPDFReq } from "./service_pb";
import type { GetSoldProductsRes } from "./service_pb";
import type { GetSoldProductsReq } from "./service_pb";
import type { GetPaymentsCSVRes } from "./service_pb";
import type { GetPaymentsCSVReq } from "./service_pb";
import type { GetPaymentsPDFRes } from "./service_pb";
import type { GetPaymentsPDFReq } from "./service_pb";
import type { GetPaymentsRes } from "./service_pb";
import type { GetPaymentsReq } from "./service_pb";
import type { GetSalesCSVRes } from "./service_pb";
import type { GetSalesCSVReq } from "./service_pb";
import type { GetSalesPDFRes } from "./service_pb";
import type { GetSalesPDFReq } from "./service_pb";
import type { GetSalesRes } from "./service_pb";
import type { GetSalesReq } from "./service_pb";
import type { GetCashRegisterCSVRes } from "./service_pb";
import type { GetCashRegisterCSVReq } from "./service_pb";
import type { GetCashRegisterPDFRes } from "./service_pb";
import type { GetCashRegisterPDFReq } from "./service_pb";
import type { GetCashRegisterRes } from "./service_pb";
import type { GetCashRegisterReq } from "./service_pb";
import type { GetSalesByDayCSVRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayCSVReq } from "../../api/analytics/service_pb";
import type { GetSalesByDayPDFRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayPDFReq } from "../../api/analytics/service_pb";
import type { GetSalesByDayRes } from "../../api/analytics/service_pb";
import type { GetSalesByDayReq } from "../../api/analytics/service_pb";
import { BulkService } from "./service_pb";
import type { BulkExportCustomPriceRulesRes } from "./service_pb";
import type { BulkExportCustomPriceRulesReq } from "./service_pb";
import type { BulkExportInventoryCountProductsRes } from "./service_pb";
import type { BulkExportInventoryCountProductsReq } from "./service_pb";
import type { BulkExportVendorsRes } from "./service_pb";
import type { BulkExportVendorsReq } from "./service_pb";
import type { BulkExportProductLabelsRes } from "./service_pb";
import type { BulkExportProductLabelsReq } from "./service_pb";
import type { BulkExportProductsRes } from "./service_pb";
import type { BulkExportProductsReq } from "./service_pb";
import type { BulkExportCustomerContactsRes } from "./service_pb";
import type { BulkExportCustomerContactsReq } from "./service_pb";
import type { BulkExportCustomersRes } from "./service_pb";
import type { BulkExportCustomersReq } from "./service_pb";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SearchService } from "./service_pb";
import type { SearchFinanceChargesRes } from "./service_pb";
import type { SearchFinanceChargesReq } from "./service_pb";
import type { SearchStagedProductsToOrderRes } from "./service_pb";
import type { SearchStagedProductsToOrderReq } from "./service_pb";
import type { SearchTagsRes } from "./service_pb";
import type { SearchTagsReq } from "./service_pb";
import type { SearchJobsRes } from "./service_pb";
import type { SearchJobsReq } from "./service_pb";
import type { SearchPriceTierRulesRes } from "./service_pb";
import type { SearchPriceTierRulesReq } from "./service_pb";
import type { SearchCustomPriceRulesRes } from "./service_pb";
import type { SearchCustomPriceRulesReq } from "./service_pb";
import type { SearchInventoryChangesRes } from "./service_pb";
import type { SearchInventoryChangesReq } from "./service_pb";
import type { SearchOrderShipmentsForReconciliationRes } from "./service_pb";
import type { SearchOrderShipmentsForReconciliationReq } from "./service_pb";
import type { ReloadRes } from "./service_pb";
import type { ReloadReq } from "./service_pb";
import type { UpdateRecordRes } from "./service_pb";
import type { UpdateRecordReq } from "./service_pb";
import type { SearchFinancialTransactionsRes } from "./service_pb";
import type { SearchFinancialTransactionsReq } from "./service_pb";
import type { SearchCashTransactionsRes } from "./service_pb";
import type { SearchCashTransactionsReq } from "./service_pb";
import type { SearchVendorsRes } from "./service_pb";
import type { SearchVendorsReq } from "./service_pb";
import type { SearchTransfersRes } from "./service_pb";
import type { SearchTransfersReq } from "./service_pb";
import type { SearchTintColorsRes } from "./service_pb";
import type { SearchTintColorsReq } from "./service_pb";
import type { SearchOutstandingSalesRes } from "./service_pb";
import type { SearchOutstandingSalesReq } from "./service_pb";
import type { SearchReturnsRes } from "./service_pb";
import type { SearchReturnsReq } from "./service_pb";
import type { SearchStaffsRes } from "./service_pb";
import type { SearchStaffsReq } from "./service_pb";
import type { SearchSalesV2Res } from "./service_pb";
import type { SearchSalesV2Req } from "./service_pb";
import type { SearchProductCategoriesRes } from "./service_pb";
import type { SearchProductCategoriesReq } from "./service_pb";
import type { SearchProductsV2Res } from "./service_pb";
import type { SearchProductsV2Req } from "./service_pb";
import type { SearchOrderShipmentsRes } from "./service_pb";
import type { SearchOrderShipmentsReq } from "./service_pb";
import type { SearchOrdersRes } from "./service_pb";
import type { SearchOrdersReq } from "./service_pb";
import type { SearchLocationsRes } from "./service_pb";
import type { SearchLocationsReq } from "./service_pb";
import type { SearchExcludedInventoryCountProductsRes } from "./service_pb";
import type { SearchExcludedInventoryCountProductsReq } from "./service_pb";
import type { SearchInventoryCountProductsRes } from "./service_pb";
import type { SearchInventoryCountProductsReq } from "./service_pb";
import type { SearchExternalTransactionsRes } from "./service_pb";
import type { SearchExternalTransactionsReq } from "./service_pb";
import type { SearchCustomerContactsRes } from "./service_pb";
import type { SearchCustomerContactsReq } from "./service_pb";
import type { SearchCustomersV2Res } from "./service_pb";
import type { SearchCustomersV2Req } from "./service_pb";
import type { SearchRefundedBillPaymentsRes } from "./service_pb";
import type { SearchRefundedBillPaymentsReq } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SearchBillPaymentsRes } from "./service_pb";
import type { SearchBillPaymentsReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.search.service.SearchService
 */
export interface ISearchServiceClient {
    /**
     * @generated from protobuf rpc: SearchBillPayments(rd.search.service.SearchBillPaymentsReq) returns (rd.search.service.SearchBillPaymentsRes);
     */
    searchBillPayments(input: SearchBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchBillPaymentsReq, SearchBillPaymentsRes>;
    /**
     * @generated from protobuf rpc: SearchRefundedBillPayments(rd.search.service.SearchRefundedBillPaymentsReq) returns (rd.search.service.SearchRefundedBillPaymentsRes);
     */
    searchRefundedBillPayments(input: SearchRefundedBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes>;
    /**
     * @generated from protobuf rpc: SearchCustomersV2(rd.search.service.SearchCustomersV2Req) returns (rd.search.service.SearchCustomersV2Res);
     */
    searchCustomersV2(input: SearchCustomersV2Req, options?: RpcOptions): UnaryCall<SearchCustomersV2Req, SearchCustomersV2Res>;
    /**
     * @generated from protobuf rpc: SearchCustomerContacts(rd.search.service.SearchCustomerContactsReq) returns (rd.search.service.SearchCustomerContactsRes);
     */
    searchCustomerContacts(input: SearchCustomerContactsReq, options?: RpcOptions): UnaryCall<SearchCustomerContactsReq, SearchCustomerContactsRes>;
    /**
     * @generated from protobuf rpc: SearchExternalTransactions(rd.search.service.SearchExternalTransactionsReq) returns (rd.search.service.SearchExternalTransactionsRes);
     */
    searchExternalTransactions(input: SearchExternalTransactionsReq, options?: RpcOptions): UnaryCall<SearchExternalTransactionsReq, SearchExternalTransactionsRes>;
    /**
     * @generated from protobuf rpc: SearchInventoryCountProducts(rd.search.service.SearchInventoryCountProductsReq) returns (rd.search.service.SearchInventoryCountProductsRes);
     */
    searchInventoryCountProducts(input: SearchInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes>;
    /**
     * @generated from protobuf rpc: SearchExcludedInventoryCountProducts(rd.search.service.SearchExcludedInventoryCountProductsReq) returns (rd.search.service.SearchExcludedInventoryCountProductsRes);
     */
    searchExcludedInventoryCountProducts(input: SearchExcludedInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes>;
    /**
     * @generated from protobuf rpc: SearchLocations(rd.search.service.SearchLocationsReq) returns (rd.search.service.SearchLocationsRes);
     */
    searchLocations(input: SearchLocationsReq, options?: RpcOptions): UnaryCall<SearchLocationsReq, SearchLocationsRes>;
    /**
     * @generated from protobuf rpc: SearchOrders(rd.search.service.SearchOrdersReq) returns (rd.search.service.SearchOrdersRes);
     */
    searchOrders(input: SearchOrdersReq, options?: RpcOptions): UnaryCall<SearchOrdersReq, SearchOrdersRes>;
    /**
     * @generated from protobuf rpc: SearchOrderShipments(rd.search.service.SearchOrderShipmentsReq) returns (rd.search.service.SearchOrderShipmentsRes);
     */
    searchOrderShipments(input: SearchOrderShipmentsReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsReq, SearchOrderShipmentsRes>;
    /**
     * @generated from protobuf rpc: SearchProductsV2(rd.search.service.SearchProductsV2Req) returns (rd.search.service.SearchProductsV2Res);
     */
    searchProductsV2(input: SearchProductsV2Req, options?: RpcOptions): UnaryCall<SearchProductsV2Req, SearchProductsV2Res>;
    /**
     * @generated from protobuf rpc: SearchProductCategories(rd.search.service.SearchProductCategoriesReq) returns (rd.search.service.SearchProductCategoriesRes);
     */
    searchProductCategories(input: SearchProductCategoriesReq, options?: RpcOptions): UnaryCall<SearchProductCategoriesReq, SearchProductCategoriesRes>;
    /**
     * @generated from protobuf rpc: SearchSalesV2(rd.search.service.SearchSalesV2Req) returns (rd.search.service.SearchSalesV2Res);
     */
    searchSalesV2(input: SearchSalesV2Req, options?: RpcOptions): UnaryCall<SearchSalesV2Req, SearchSalesV2Res>;
    /**
     * @generated from protobuf rpc: SearchStaffs(rd.search.service.SearchStaffsReq) returns (rd.search.service.SearchStaffsRes);
     */
    searchStaffs(input: SearchStaffsReq, options?: RpcOptions): UnaryCall<SearchStaffsReq, SearchStaffsRes>;
    /**
     * @generated from protobuf rpc: SearchReturns(rd.search.service.SearchReturnsReq) returns (rd.search.service.SearchReturnsRes);
     */
    searchReturns(input: SearchReturnsReq, options?: RpcOptions): UnaryCall<SearchReturnsReq, SearchReturnsRes>;
    /**
     * @generated from protobuf rpc: SearchOutstandingSales(rd.search.service.SearchOutstandingSalesReq) returns (rd.search.service.SearchOutstandingSalesRes);
     */
    searchOutstandingSales(input: SearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<SearchOutstandingSalesReq, SearchOutstandingSalesRes>;
    /**
     * @generated from protobuf rpc: SearchTintColors(rd.search.service.SearchTintColorsReq) returns (rd.search.service.SearchTintColorsRes);
     */
    searchTintColors(input: SearchTintColorsReq, options?: RpcOptions): UnaryCall<SearchTintColorsReq, SearchTintColorsRes>;
    /**
     * @generated from protobuf rpc: SearchTransfers(rd.search.service.SearchTransfersReq) returns (rd.search.service.SearchTransfersRes);
     */
    searchTransfers(input: SearchTransfersReq, options?: RpcOptions): UnaryCall<SearchTransfersReq, SearchTransfersRes>;
    /**
     * @generated from protobuf rpc: SearchVendors(rd.search.service.SearchVendorsReq) returns (rd.search.service.SearchVendorsRes);
     */
    searchVendors(input: SearchVendorsReq, options?: RpcOptions): UnaryCall<SearchVendorsReq, SearchVendorsRes>;
    /**
     * @generated from protobuf rpc: SearchCashTransactions(rd.search.service.SearchCashTransactionsReq) returns (rd.search.service.SearchCashTransactionsRes);
     */
    searchCashTransactions(input: SearchCashTransactionsReq, options?: RpcOptions): UnaryCall<SearchCashTransactionsReq, SearchCashTransactionsRes>;
    /**
     * @generated from protobuf rpc: SearchFinancialTransactions(rd.search.service.SearchFinancialTransactionsReq) returns (rd.search.service.SearchFinancialTransactionsRes);
     */
    searchFinancialTransactions(input: SearchFinancialTransactionsReq, options?: RpcOptions): UnaryCall<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes>;
    /**
     * @generated from protobuf rpc: UpdateRecord(rd.search.service.UpdateRecordReq) returns (rd.search.service.UpdateRecordRes);
     */
    updateRecord(input: UpdateRecordReq, options?: RpcOptions): UnaryCall<UpdateRecordReq, UpdateRecordRes>;
    /**
     * @generated from protobuf rpc: Reload(rd.search.service.ReloadReq) returns (rd.search.service.ReloadRes);
     */
    reload(input: ReloadReq, options?: RpcOptions): UnaryCall<ReloadReq, ReloadRes>;
    /**
     * @generated from protobuf rpc: SearchOrderShipmentsForReconciliation(rd.search.service.SearchOrderShipmentsForReconciliationReq) returns (rd.search.service.SearchOrderShipmentsForReconciliationRes);
     */
    searchOrderShipmentsForReconciliation(input: SearchOrderShipmentsForReconciliationReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes>;
    /**
     * @generated from protobuf rpc: SearchInventoryChanges(rd.search.service.SearchInventoryChangesReq) returns (rd.search.service.SearchInventoryChangesRes);
     */
    searchInventoryChanges(input: SearchInventoryChangesReq, options?: RpcOptions): UnaryCall<SearchInventoryChangesReq, SearchInventoryChangesRes>;
    /**
     * @generated from protobuf rpc: SearchCustomPriceRules(rd.search.service.SearchCustomPriceRulesReq) returns (rd.search.service.SearchCustomPriceRulesRes);
     */
    searchCustomPriceRules(input: SearchCustomPriceRulesReq, options?: RpcOptions): UnaryCall<SearchCustomPriceRulesReq, SearchCustomPriceRulesRes>;
    /**
     * @generated from protobuf rpc: SearchPriceTierRules(rd.search.service.SearchPriceTierRulesReq) returns (rd.search.service.SearchPriceTierRulesRes);
     */
    searchPriceTierRules(input: SearchPriceTierRulesReq, options?: RpcOptions): UnaryCall<SearchPriceTierRulesReq, SearchPriceTierRulesRes>;
    /**
     * @generated from protobuf rpc: SearchJobs(rd.search.service.SearchJobsReq) returns (rd.search.service.SearchJobsRes);
     */
    searchJobs(input: SearchJobsReq, options?: RpcOptions): UnaryCall<SearchJobsReq, SearchJobsRes>;
    /**
     * @generated from protobuf rpc: SearchTags(rd.search.service.SearchTagsReq) returns (rd.search.service.SearchTagsRes);
     */
    searchTags(input: SearchTagsReq, options?: RpcOptions): UnaryCall<SearchTagsReq, SearchTagsRes>;
    /**
     * @generated from protobuf rpc: SearchStagedProductsToOrder(rd.search.service.SearchStagedProductsToOrderReq) returns (rd.search.service.SearchStagedProductsToOrderRes);
     */
    searchStagedProductsToOrder(input: SearchStagedProductsToOrderReq, options?: RpcOptions): UnaryCall<SearchStagedProductsToOrderReq, SearchStagedProductsToOrderRes>;
    /**
     * @generated from protobuf rpc: SearchFinanceCharges(rd.search.service.SearchFinanceChargesReq) returns (rd.search.service.SearchFinanceChargesRes);
     */
    searchFinanceCharges(input: SearchFinanceChargesReq, options?: RpcOptions): UnaryCall<SearchFinanceChargesReq, SearchFinanceChargesRes>;
}
/**
 * @generated from protobuf service rd.search.service.SearchService
 */
export class SearchServiceClient implements ISearchServiceClient, ServiceInfo {
    typeName = SearchService.typeName;
    methods = SearchService.methods;
    options = SearchService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SearchBillPayments(rd.search.service.SearchBillPaymentsReq) returns (rd.search.service.SearchBillPaymentsRes);
     */
    searchBillPayments(input: SearchBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchBillPaymentsReq, SearchBillPaymentsRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchBillPaymentsReq, SearchBillPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchRefundedBillPayments(rd.search.service.SearchRefundedBillPaymentsReq) returns (rd.search.service.SearchRefundedBillPaymentsRes);
     */
    searchRefundedBillPayments(input: SearchRefundedBillPaymentsReq, options?: RpcOptions): UnaryCall<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchRefundedBillPaymentsReq, SearchRefundedBillPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomersV2(rd.search.service.SearchCustomersV2Req) returns (rd.search.service.SearchCustomersV2Res);
     */
    searchCustomersV2(input: SearchCustomersV2Req, options?: RpcOptions): UnaryCall<SearchCustomersV2Req, SearchCustomersV2Res> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomersV2Req, SearchCustomersV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomerContacts(rd.search.service.SearchCustomerContactsReq) returns (rd.search.service.SearchCustomerContactsRes);
     */
    searchCustomerContacts(input: SearchCustomerContactsReq, options?: RpcOptions): UnaryCall<SearchCustomerContactsReq, SearchCustomerContactsRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomerContactsReq, SearchCustomerContactsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchExternalTransactions(rd.search.service.SearchExternalTransactionsReq) returns (rd.search.service.SearchExternalTransactionsRes);
     */
    searchExternalTransactions(input: SearchExternalTransactionsReq, options?: RpcOptions): UnaryCall<SearchExternalTransactionsReq, SearchExternalTransactionsRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchExternalTransactionsReq, SearchExternalTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchInventoryCountProducts(rd.search.service.SearchInventoryCountProductsReq) returns (rd.search.service.SearchInventoryCountProductsRes);
     */
    searchInventoryCountProducts(input: SearchInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchInventoryCountProductsReq, SearchInventoryCountProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchExcludedInventoryCountProducts(rd.search.service.SearchExcludedInventoryCountProductsReq) returns (rd.search.service.SearchExcludedInventoryCountProductsRes);
     */
    searchExcludedInventoryCountProducts(input: SearchExcludedInventoryCountProductsReq, options?: RpcOptions): UnaryCall<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchExcludedInventoryCountProductsReq, SearchExcludedInventoryCountProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchLocations(rd.search.service.SearchLocationsReq) returns (rd.search.service.SearchLocationsRes);
     */
    searchLocations(input: SearchLocationsReq, options?: RpcOptions): UnaryCall<SearchLocationsReq, SearchLocationsRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchLocationsReq, SearchLocationsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrders(rd.search.service.SearchOrdersReq) returns (rd.search.service.SearchOrdersRes);
     */
    searchOrders(input: SearchOrdersReq, options?: RpcOptions): UnaryCall<SearchOrdersReq, SearchOrdersRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrdersReq, SearchOrdersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrderShipments(rd.search.service.SearchOrderShipmentsReq) returns (rd.search.service.SearchOrderShipmentsRes);
     */
    searchOrderShipments(input: SearchOrderShipmentsReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsReq, SearchOrderShipmentsRes> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrderShipmentsReq, SearchOrderShipmentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchProductsV2(rd.search.service.SearchProductsV2Req) returns (rd.search.service.SearchProductsV2Res);
     */
    searchProductsV2(input: SearchProductsV2Req, options?: RpcOptions): UnaryCall<SearchProductsV2Req, SearchProductsV2Res> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchProductsV2Req, SearchProductsV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchProductCategories(rd.search.service.SearchProductCategoriesReq) returns (rd.search.service.SearchProductCategoriesRes);
     */
    searchProductCategories(input: SearchProductCategoriesReq, options?: RpcOptions): UnaryCall<SearchProductCategoriesReq, SearchProductCategoriesRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchProductCategoriesReq, SearchProductCategoriesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchSalesV2(rd.search.service.SearchSalesV2Req) returns (rd.search.service.SearchSalesV2Res);
     */
    searchSalesV2(input: SearchSalesV2Req, options?: RpcOptions): UnaryCall<SearchSalesV2Req, SearchSalesV2Res> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchSalesV2Req, SearchSalesV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchStaffs(rd.search.service.SearchStaffsReq) returns (rd.search.service.SearchStaffsRes);
     */
    searchStaffs(input: SearchStaffsReq, options?: RpcOptions): UnaryCall<SearchStaffsReq, SearchStaffsRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchStaffsReq, SearchStaffsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchReturns(rd.search.service.SearchReturnsReq) returns (rd.search.service.SearchReturnsRes);
     */
    searchReturns(input: SearchReturnsReq, options?: RpcOptions): UnaryCall<SearchReturnsReq, SearchReturnsRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchReturnsReq, SearchReturnsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOutstandingSales(rd.search.service.SearchOutstandingSalesReq) returns (rd.search.service.SearchOutstandingSalesRes);
     */
    searchOutstandingSales(input: SearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<SearchOutstandingSalesReq, SearchOutstandingSalesRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOutstandingSalesReq, SearchOutstandingSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchTintColors(rd.search.service.SearchTintColorsReq) returns (rd.search.service.SearchTintColorsRes);
     */
    searchTintColors(input: SearchTintColorsReq, options?: RpcOptions): UnaryCall<SearchTintColorsReq, SearchTintColorsRes> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchTintColorsReq, SearchTintColorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchTransfers(rd.search.service.SearchTransfersReq) returns (rd.search.service.SearchTransfersRes);
     */
    searchTransfers(input: SearchTransfersReq, options?: RpcOptions): UnaryCall<SearchTransfersReq, SearchTransfersRes> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchTransfersReq, SearchTransfersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchVendors(rd.search.service.SearchVendorsReq) returns (rd.search.service.SearchVendorsRes);
     */
    searchVendors(input: SearchVendorsReq, options?: RpcOptions): UnaryCall<SearchVendorsReq, SearchVendorsRes> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchVendorsReq, SearchVendorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCashTransactions(rd.search.service.SearchCashTransactionsReq) returns (rd.search.service.SearchCashTransactionsRes);
     */
    searchCashTransactions(input: SearchCashTransactionsReq, options?: RpcOptions): UnaryCall<SearchCashTransactionsReq, SearchCashTransactionsRes> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCashTransactionsReq, SearchCashTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchFinancialTransactions(rd.search.service.SearchFinancialTransactionsReq) returns (rd.search.service.SearchFinancialTransactionsRes);
     */
    searchFinancialTransactions(input: SearchFinancialTransactionsReq, options?: RpcOptions): UnaryCall<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchFinancialTransactionsReq, SearchFinancialTransactionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRecord(rd.search.service.UpdateRecordReq) returns (rd.search.service.UpdateRecordRes);
     */
    updateRecord(input: UpdateRecordReq, options?: RpcOptions): UnaryCall<UpdateRecordReq, UpdateRecordRes> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRecordReq, UpdateRecordRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Reload(rd.search.service.ReloadReq) returns (rd.search.service.ReloadRes);
     */
    reload(input: ReloadReq, options?: RpcOptions): UnaryCall<ReloadReq, ReloadRes> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReloadReq, ReloadRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchOrderShipmentsForReconciliation(rd.search.service.SearchOrderShipmentsForReconciliationReq) returns (rd.search.service.SearchOrderShipmentsForReconciliationRes);
     */
    searchOrderShipmentsForReconciliation(input: SearchOrderShipmentsForReconciliationReq, options?: RpcOptions): UnaryCall<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchOrderShipmentsForReconciliationReq, SearchOrderShipmentsForReconciliationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchInventoryChanges(rd.search.service.SearchInventoryChangesReq) returns (rd.search.service.SearchInventoryChangesRes);
     */
    searchInventoryChanges(input: SearchInventoryChangesReq, options?: RpcOptions): UnaryCall<SearchInventoryChangesReq, SearchInventoryChangesRes> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchInventoryChangesReq, SearchInventoryChangesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchCustomPriceRules(rd.search.service.SearchCustomPriceRulesReq) returns (rd.search.service.SearchCustomPriceRulesRes);
     */
    searchCustomPriceRules(input: SearchCustomPriceRulesReq, options?: RpcOptions): UnaryCall<SearchCustomPriceRulesReq, SearchCustomPriceRulesRes> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchCustomPriceRulesReq, SearchCustomPriceRulesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchPriceTierRules(rd.search.service.SearchPriceTierRulesReq) returns (rd.search.service.SearchPriceTierRulesRes);
     */
    searchPriceTierRules(input: SearchPriceTierRulesReq, options?: RpcOptions): UnaryCall<SearchPriceTierRulesReq, SearchPriceTierRulesRes> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchPriceTierRulesReq, SearchPriceTierRulesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchJobs(rd.search.service.SearchJobsReq) returns (rd.search.service.SearchJobsRes);
     */
    searchJobs(input: SearchJobsReq, options?: RpcOptions): UnaryCall<SearchJobsReq, SearchJobsRes> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchJobsReq, SearchJobsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchTags(rd.search.service.SearchTagsReq) returns (rd.search.service.SearchTagsRes);
     */
    searchTags(input: SearchTagsReq, options?: RpcOptions): UnaryCall<SearchTagsReq, SearchTagsRes> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchTagsReq, SearchTagsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchStagedProductsToOrder(rd.search.service.SearchStagedProductsToOrderReq) returns (rd.search.service.SearchStagedProductsToOrderRes);
     */
    searchStagedProductsToOrder(input: SearchStagedProductsToOrderReq, options?: RpcOptions): UnaryCall<SearchStagedProductsToOrderReq, SearchStagedProductsToOrderRes> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchStagedProductsToOrderReq, SearchStagedProductsToOrderRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SearchFinanceCharges(rd.search.service.SearchFinanceChargesReq) returns (rd.search.service.SearchFinanceChargesRes);
     */
    searchFinanceCharges(input: SearchFinanceChargesReq, options?: RpcOptions): UnaryCall<SearchFinanceChargesReq, SearchFinanceChargesRes> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<SearchFinanceChargesReq, SearchFinanceChargesRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service rd.search.service.BulkService
 */
export interface IBulkServiceClient {
    /**
     * @generated from protobuf rpc: BulkExportCustomers(rd.search.service.BulkExportCustomersReq) returns (rd.search.service.BulkExportCustomersRes);
     */
    bulkExportCustomers(input: BulkExportCustomersReq, options?: RpcOptions): UnaryCall<BulkExportCustomersReq, BulkExportCustomersRes>;
    /**
     * @generated from protobuf rpc: BulkExportCustomerContacts(rd.search.service.BulkExportCustomerContactsReq) returns (rd.search.service.BulkExportCustomerContactsRes);
     */
    bulkExportCustomerContacts(input: BulkExportCustomerContactsReq, options?: RpcOptions): UnaryCall<BulkExportCustomerContactsReq, BulkExportCustomerContactsRes>;
    /**
     * @generated from protobuf rpc: BulkExportProducts(rd.search.service.BulkExportProductsReq) returns (rd.search.service.BulkExportProductsRes);
     */
    bulkExportProducts(input: BulkExportProductsReq, options?: RpcOptions): UnaryCall<BulkExportProductsReq, BulkExportProductsRes>;
    /**
     * @generated from protobuf rpc: BulkExportProductLabels(rd.search.service.BulkExportProductLabelsReq) returns (rd.search.service.BulkExportProductLabelsRes);
     */
    bulkExportProductLabels(input: BulkExportProductLabelsReq, options?: RpcOptions): UnaryCall<BulkExportProductLabelsReq, BulkExportProductLabelsRes>;
    /**
     * @generated from protobuf rpc: BulkExportVendors(rd.search.service.BulkExportVendorsReq) returns (rd.search.service.BulkExportVendorsRes);
     */
    bulkExportVendors(input: BulkExportVendorsReq, options?: RpcOptions): UnaryCall<BulkExportVendorsReq, BulkExportVendorsRes>;
    /**
     * @generated from protobuf rpc: BulkExportInventoryCountProducts(rd.search.service.BulkExportInventoryCountProductsReq) returns (rd.search.service.BulkExportInventoryCountProductsRes);
     */
    bulkExportInventoryCountProducts(input: BulkExportInventoryCountProductsReq, options?: RpcOptions): UnaryCall<BulkExportInventoryCountProductsReq, BulkExportInventoryCountProductsRes>;
    /**
     * @generated from protobuf rpc: BulkExportCustomPriceRules(rd.search.service.BulkExportCustomPriceRulesReq) returns (rd.search.service.BulkExportCustomPriceRulesRes);
     */
    bulkExportCustomPriceRules(input: BulkExportCustomPriceRulesReq, options?: RpcOptions): UnaryCall<BulkExportCustomPriceRulesReq, BulkExportCustomPriceRulesRes>;
}
/**
 * @generated from protobuf service rd.search.service.BulkService
 */
export class BulkServiceClient implements IBulkServiceClient, ServiceInfo {
    typeName = BulkService.typeName;
    methods = BulkService.methods;
    options = BulkService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: BulkExportCustomers(rd.search.service.BulkExportCustomersReq) returns (rd.search.service.BulkExportCustomersRes);
     */
    bulkExportCustomers(input: BulkExportCustomersReq, options?: RpcOptions): UnaryCall<BulkExportCustomersReq, BulkExportCustomersRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportCustomersReq, BulkExportCustomersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportCustomerContacts(rd.search.service.BulkExportCustomerContactsReq) returns (rd.search.service.BulkExportCustomerContactsRes);
     */
    bulkExportCustomerContacts(input: BulkExportCustomerContactsReq, options?: RpcOptions): UnaryCall<BulkExportCustomerContactsReq, BulkExportCustomerContactsRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportCustomerContactsReq, BulkExportCustomerContactsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportProducts(rd.search.service.BulkExportProductsReq) returns (rd.search.service.BulkExportProductsRes);
     */
    bulkExportProducts(input: BulkExportProductsReq, options?: RpcOptions): UnaryCall<BulkExportProductsReq, BulkExportProductsRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportProductsReq, BulkExportProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportProductLabels(rd.search.service.BulkExportProductLabelsReq) returns (rd.search.service.BulkExportProductLabelsRes);
     */
    bulkExportProductLabels(input: BulkExportProductLabelsReq, options?: RpcOptions): UnaryCall<BulkExportProductLabelsReq, BulkExportProductLabelsRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportProductLabelsReq, BulkExportProductLabelsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportVendors(rd.search.service.BulkExportVendorsReq) returns (rd.search.service.BulkExportVendorsRes);
     */
    bulkExportVendors(input: BulkExportVendorsReq, options?: RpcOptions): UnaryCall<BulkExportVendorsReq, BulkExportVendorsRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportVendorsReq, BulkExportVendorsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportInventoryCountProducts(rd.search.service.BulkExportInventoryCountProductsReq) returns (rd.search.service.BulkExportInventoryCountProductsRes);
     */
    bulkExportInventoryCountProducts(input: BulkExportInventoryCountProductsReq, options?: RpcOptions): UnaryCall<BulkExportInventoryCountProductsReq, BulkExportInventoryCountProductsRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportInventoryCountProductsReq, BulkExportInventoryCountProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BulkExportCustomPriceRules(rd.search.service.BulkExportCustomPriceRulesReq) returns (rd.search.service.BulkExportCustomPriceRulesRes);
     */
    bulkExportCustomPriceRules(input: BulkExportCustomPriceRulesReq, options?: RpcOptions): UnaryCall<BulkExportCustomPriceRulesReq, BulkExportCustomPriceRulesRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkExportCustomPriceRulesReq, BulkExportCustomPriceRulesRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service rd.search.service.SearchalyticsService
 */
export interface ISearchalyticsServiceClient {
    /**
     * @generated from protobuf rpc: GetSalesByDay(rd.api.analytics.GetSalesByDayReq) returns (rd.api.analytics.GetSalesByDayRes);
     */
    getSalesByDay(input: GetSalesByDayReq, options?: RpcOptions): UnaryCall<GetSalesByDayReq, GetSalesByDayRes>;
    /**
     * @generated from protobuf rpc: GetSalesByDayPDF(rd.api.analytics.GetSalesByDayPDFReq) returns (rd.api.analytics.GetSalesByDayPDFRes);
     */
    getSalesByDayPDF(input: GetSalesByDayPDFReq, options?: RpcOptions): UnaryCall<GetSalesByDayPDFReq, GetSalesByDayPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByDayCSV(rd.api.analytics.GetSalesByDayCSVReq) returns (rd.api.analytics.GetSalesByDayCSVRes);
     */
    getSalesByDayCSV(input: GetSalesByDayCSVReq, options?: RpcOptions): UnaryCall<GetSalesByDayCSVReq, GetSalesByDayCSVRes>;
    /**
     * Cash Register Report
     *
     * @generated from protobuf rpc: GetCashRegister(rd.search.service.GetCashRegisterReq) returns (rd.search.service.GetCashRegisterRes);
     */
    getCashRegister(input: GetCashRegisterReq, options?: RpcOptions): UnaryCall<GetCashRegisterReq, GetCashRegisterRes>;
    /**
     * @generated from protobuf rpc: GetCashRegisterPDF(rd.search.service.GetCashRegisterPDFReq) returns (rd.search.service.GetCashRegisterPDFRes);
     */
    getCashRegisterPDF(input: GetCashRegisterPDFReq, options?: RpcOptions): UnaryCall<GetCashRegisterPDFReq, GetCashRegisterPDFRes>;
    /**
     * @generated from protobuf rpc: GetCashRegisterCSV(rd.search.service.GetCashRegisterCSVReq) returns (rd.search.service.GetCashRegisterCSVRes);
     */
    getCashRegisterCSV(input: GetCashRegisterCSVReq, options?: RpcOptions): UnaryCall<GetCashRegisterCSVReq, GetCashRegisterCSVRes>;
    /**
     * @generated from protobuf rpc: GetSales(rd.search.service.GetSalesReq) returns (rd.search.service.GetSalesRes);
     */
    getSales(input: GetSalesReq, options?: RpcOptions): UnaryCall<GetSalesReq, GetSalesRes>;
    /**
     * @generated from protobuf rpc: GetSalesPDF(rd.search.service.GetSalesPDFReq) returns (rd.search.service.GetSalesPDFRes);
     */
    getSalesPDF(input: GetSalesPDFReq, options?: RpcOptions): UnaryCall<GetSalesPDFReq, GetSalesPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesCSV(rd.search.service.GetSalesCSVReq) returns (rd.search.service.GetSalesCSVRes);
     */
    getSalesCSV(input: GetSalesCSVReq, options?: RpcOptions): UnaryCall<GetSalesCSVReq, GetSalesCSVRes>;
    /**
     * Payments
     *
     * @generated from protobuf rpc: GetPayments(rd.search.service.GetPaymentsReq) returns (rd.search.service.GetPaymentsRes);
     */
    getPayments(input: GetPaymentsReq, options?: RpcOptions): UnaryCall<GetPaymentsReq, GetPaymentsRes>;
    /**
     * @generated from protobuf rpc: GetPaymentsPDF(rd.search.service.GetPaymentsPDFReq) returns (rd.search.service.GetPaymentsPDFRes);
     */
    getPaymentsPDF(input: GetPaymentsPDFReq, options?: RpcOptions): UnaryCall<GetPaymentsPDFReq, GetPaymentsPDFRes>;
    /**
     * @generated from protobuf rpc: GetPaymentsCSV(rd.search.service.GetPaymentsCSVReq) returns (rd.search.service.GetPaymentsCSVRes);
     */
    getPaymentsCSV(input: GetPaymentsCSVReq, options?: RpcOptions): UnaryCall<GetPaymentsCSVReq, GetPaymentsCSVRes>;
    /**
     * Sold Products
     *
     * @generated from protobuf rpc: GetSoldProducts(rd.search.service.GetSoldProductsReq) returns (rd.search.service.GetSoldProductsRes);
     */
    getSoldProducts(input: GetSoldProductsReq, options?: RpcOptions): UnaryCall<GetSoldProductsReq, GetSoldProductsRes>;
    /**
     * @generated from protobuf rpc: GetSoldProductsPDF(rd.search.service.GetSoldProductsPDFReq) returns (rd.search.service.GetSoldProductsPDFRes);
     */
    getSoldProductsPDF(input: GetSoldProductsPDFReq, options?: RpcOptions): UnaryCall<GetSoldProductsPDFReq, GetSoldProductsPDFRes>;
    /**
     * @generated from protobuf rpc: GetSoldProductsCSV(rd.search.service.GetSoldProductsCSVReq) returns (rd.search.service.GetSoldProductsCSVRes);
     */
    getSoldProductsCSV(input: GetSoldProductsCSVReq, options?: RpcOptions): UnaryCall<GetSoldProductsCSVReq, GetSoldProductsCSVRes>;
    /**
     * Sales by Customer
     *
     * @generated from protobuf rpc: GetSalesByCustomer(rd.search.service.GetSalesByCustomerReq) returns (rd.search.service.GetSalesByCustomerRes);
     */
    getSalesByCustomer(input: GetSalesByCustomerReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerReq, GetSalesByCustomerRes>;
    /**
     * @generated from protobuf rpc: GetSalesByCustomerPDF(rd.search.service.GetSalesByCustomerPDFReq) returns (rd.search.service.GetSalesByCustomerPDFRes);
     */
    getSalesByCustomerPDF(input: GetSalesByCustomerPDFReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerPDFReq, GetSalesByCustomerPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByCustomerCSV(rd.search.service.GetSalesByCustomerCSVReq) returns (rd.search.service.GetSalesByCustomerCSVRes);
     */
    getSalesByCustomerCSV(input: GetSalesByCustomerCSVReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerCSVReq, GetSalesByCustomerCSVRes>;
    /**
     * Sales by Job
     *
     * @generated from protobuf rpc: GetSalesByJob(rd.search.service.GetSalesByJobReq) returns (rd.search.service.GetSalesByJobRes);
     */
    getSalesByJob(input: GetSalesByJobReq, options?: RpcOptions): UnaryCall<GetSalesByJobReq, GetSalesByJobRes>;
    /**
     * @generated from protobuf rpc: GetSalesByJobPDF(rd.search.service.GetSalesByJobPDFReq) returns (rd.search.service.GetSalesByJobPDFRes);
     */
    getSalesByJobPDF(input: GetSalesByJobPDFReq, options?: RpcOptions): UnaryCall<GetSalesByJobPDFReq, GetSalesByJobPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByJobCSV(rd.search.service.GetSalesByJobCSVReq) returns (rd.search.service.GetSalesByJobCSVRes);
     */
    getSalesByJobCSV(input: GetSalesByJobCSVReq, options?: RpcOptions): UnaryCall<GetSalesByJobCSVReq, GetSalesByJobCSVRes>;
    /**
     * Sales by Product
     *
     * @generated from protobuf rpc: GetSalesByProduct(rd.search.service.GetSalesByProductReq) returns (rd.search.service.GetSalesByProductRes);
     */
    getSalesByProduct(input: GetSalesByProductReq, options?: RpcOptions): UnaryCall<GetSalesByProductReq, GetSalesByProductRes>;
    /**
     * @generated from protobuf rpc: GetSalesByProductPDF(rd.search.service.GetSalesByProductPDFReq) returns (rd.search.service.GetSalesByProductPDFRes);
     */
    getSalesByProductPDF(input: GetSalesByProductPDFReq, options?: RpcOptions): UnaryCall<GetSalesByProductPDFReq, GetSalesByProductPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByProductCSV(rd.search.service.GetSalesByProductCSVReq) returns (rd.search.service.GetSalesByProductCSVRes);
     */
    getSalesByProductCSV(input: GetSalesByProductCSVReq, options?: RpcOptions): UnaryCall<GetSalesByProductCSVReq, GetSalesByProductCSVRes>;
    /**
     * Sales by Location
     *
     * @generated from protobuf rpc: GetSalesByLocation(rd.search.service.GetSalesByLocationReq) returns (rd.search.service.GetSalesByLocationRes);
     */
    getSalesByLocation(input: GetSalesByLocationReq, options?: RpcOptions): UnaryCall<GetSalesByLocationReq, GetSalesByLocationRes>;
    /**
     * @generated from protobuf rpc: GetSalesByLocationPDF(rd.search.service.GetSalesByLocationPDFReq) returns (rd.search.service.GetSalesByLocationPDFRes);
     */
    getSalesByLocationPDF(input: GetSalesByLocationPDFReq, options?: RpcOptions): UnaryCall<GetSalesByLocationPDFReq, GetSalesByLocationPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByLocationCSV(rd.search.service.GetSalesByLocationCSVReq) returns (rd.search.service.GetSalesByLocationCSVRes);
     */
    getSalesByLocationCSV(input: GetSalesByLocationCSVReq, options?: RpcOptions): UnaryCall<GetSalesByLocationCSVReq, GetSalesByLocationCSVRes>;
    /**
     * Sales by Rep
     *
     * @generated from protobuf rpc: GetSalesByRep(rd.search.service.GetSalesByRepReq) returns (rd.search.service.GetSalesByRepRes);
     */
    getSalesByRep(input: GetSalesByRepReq, options?: RpcOptions): UnaryCall<GetSalesByRepReq, GetSalesByRepRes>;
    /**
     * @generated from protobuf rpc: GetSalesByRepPDF(rd.search.service.GetSalesByRepPDFReq) returns (rd.search.service.GetSalesByRepPDFRes);
     */
    getSalesByRepPDF(input: GetSalesByRepPDFReq, options?: RpcOptions): UnaryCall<GetSalesByRepPDFReq, GetSalesByRepPDFRes>;
    /**
     * @generated from protobuf rpc: GetSalesByRepCSV(rd.search.service.GetSalesByRepCSVReq) returns (rd.search.service.GetSalesByRepCSVRes);
     */
    getSalesByRepCSV(input: GetSalesByRepCSVReq, options?: RpcOptions): UnaryCall<GetSalesByRepCSVReq, GetSalesByRepCSVRes>;
    /**
     * Customer Aging
     *
     * @generated from protobuf rpc: GetCustomerAging(rd.api.analytics.GetCustomerAgingReq) returns (rd.api.analytics.GetCustomerAgingRes);
     */
    getCustomerAging(input: GetCustomerAgingReq, options?: RpcOptions): UnaryCall<GetCustomerAgingReq, GetCustomerAgingRes>;
    /**
     * @generated from protobuf rpc: GetCustomerAgingPDF(rd.api.analytics.GetCustomerAgingPDFReq) returns (rd.api.analytics.GetCustomerAgingPDFRes);
     */
    getCustomerAgingPDF(input: GetCustomerAgingPDFReq, options?: RpcOptions): UnaryCall<GetCustomerAgingPDFReq, GetCustomerAgingPDFRes>;
    /**
     * @generated from protobuf rpc: GetCustomerAgingCSV(rd.api.analytics.GetCustomerAgingCSVReq) returns (rd.api.analytics.GetCustomerAgingCSVRes);
     */
    getCustomerAgingCSV(input: GetCustomerAgingCSVReq, options?: RpcOptions): UnaryCall<GetCustomerAgingCSVReq, GetCustomerAgingCSVRes>;
    /**
     * Aging by Job
     *
     * @generated from protobuf rpc: GetAgingByJob(rd.search.service.GetAgingByJobReq) returns (rd.search.service.GetAgingByJobRes);
     */
    getAgingByJob(input: GetAgingByJobReq, options?: RpcOptions): UnaryCall<GetAgingByJobReq, GetAgingByJobRes>;
    /**
     * @generated from protobuf rpc: GetAgingByJobPDF(rd.search.service.GetAgingByJobPDFReq) returns (rd.search.service.GetAgingByJobPDFRes);
     */
    getAgingByJobPDF(input: GetAgingByJobPDFReq, options?: RpcOptions): UnaryCall<GetAgingByJobPDFReq, GetAgingByJobPDFRes>;
    /**
     * @generated from protobuf rpc: GetAgingByJobCSV(rd.search.service.GetAgingByJobCSVReq) returns (rd.search.service.GetAgingByJobCSVRes);
     */
    getAgingByJobCSV(input: GetAgingByJobCSVReq, options?: RpcOptions): UnaryCall<GetAgingByJobCSVReq, GetAgingByJobCSVRes>;
    /**
     * Profit and Loss
     *
     * @generated from protobuf rpc: GetProfitAndLoss(rd.search.service.GetProfitAndLossReq) returns (rd.search.service.GetProfitAndLossRes);
     */
    getProfitAndLoss(input: GetProfitAndLossReq, options?: RpcOptions): UnaryCall<GetProfitAndLossReq, GetProfitAndLossRes>;
    /**
     * @generated from protobuf rpc: GetProfitAndLossPDF(rd.search.service.GetProfitAndLossPDFReq) returns (rd.search.service.GetProfitAndLossPDFRes);
     */
    getProfitAndLossPDF(input: GetProfitAndLossPDFReq, options?: RpcOptions): UnaryCall<GetProfitAndLossPDFReq, GetProfitAndLossPDFRes>;
    /**
     * New Accounts Opened
     *
     * @generated from protobuf rpc: GetNewAccountsOpened(rd.search.service.GetNewAccountsOpenedReq) returns (rd.search.service.GetNewAccountsOpenedRes);
     */
    getNewAccountsOpened(input: GetNewAccountsOpenedReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedReq, GetNewAccountsOpenedRes>;
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedPDF(rd.search.service.GetNewAccountsOpenedPDFReq) returns (rd.search.service.GetNewAccountsOpenedPDFRes);
     */
    getNewAccountsOpenedPDF(input: GetNewAccountsOpenedPDFReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedPDFReq, GetNewAccountsOpenedPDFRes>;
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedCSV(rd.search.service.GetNewAccountsOpenedCSVReq) returns (rd.search.service.GetNewAccountsOpenedCSVRes);
     */
    getNewAccountsOpenedCSV(input: GetNewAccountsOpenedCSVReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedCSVReq, GetNewAccountsOpenedCSVRes>;
    /**
     * Aging Detail
     *
     * @generated from protobuf rpc: GetAgingDetail(rd.search.service.GetAgingDetailReq) returns (rd.search.service.GetAgingDetailRes);
     */
    getAgingDetail(input: GetAgingDetailReq, options?: RpcOptions): UnaryCall<GetAgingDetailReq, GetAgingDetailRes>;
    /**
     * @generated from protobuf rpc: GetAgingDetailPDF(rd.search.service.GetAgingDetailPDFReq) returns (rd.search.service.GetAgingDetailPDFRes);
     */
    getAgingDetailPDF(input: GetAgingDetailPDFReq, options?: RpcOptions): UnaryCall<GetAgingDetailPDFReq, GetAgingDetailPDFRes>;
    /**
     * @generated from protobuf rpc: GetAgingDetailCSV(rd.search.service.GetAgingDetailCSVReq) returns (rd.search.service.GetAgingDetailCSVRes);
     */
    getAgingDetailCSV(input: GetAgingDetailCSVReq, options?: RpcOptions): UnaryCall<GetAgingDetailCSVReq, GetAgingDetailCSVRes>;
    /**
     * Price Edits
     *
     * @generated from protobuf rpc: GetPriceEdits(rd.search.service.GetPriceEditsReq) returns (rd.search.service.GetPriceEditsRes);
     */
    getPriceEdits(input: GetPriceEditsReq, options?: RpcOptions): UnaryCall<GetPriceEditsReq, GetPriceEditsRes>;
    /**
     * @generated from protobuf rpc: GetPriceEditsPDF(rd.search.service.GetPriceEditsPDFReq) returns (rd.search.service.GetPriceEditsPDFRes);
     */
    getPriceEditsPDF(input: GetPriceEditsPDFReq, options?: RpcOptions): UnaryCall<GetPriceEditsPDFReq, GetPriceEditsPDFRes>;
    /**
     * @generated from protobuf rpc: GetPriceEditsCSV(rd.search.service.GetPriceEditsCSVReq) returns (rd.search.service.GetPriceEditsCSVRes);
     */
    getPriceEditsCSV(input: GetPriceEditsCSVReq, options?: RpcOptions): UnaryCall<GetPriceEditsCSVReq, GetPriceEditsCSVRes>;
    /**
     * Purchase Journal
     *
     * @generated from protobuf rpc: GetPurchaseJournal(rd.search.service.GetPurchaseJournalReq) returns (rd.search.service.GetPurchaseJournalRes);
     */
    getPurchaseJournal(input: GetPurchaseJournalReq, options?: RpcOptions): UnaryCall<GetPurchaseJournalReq, GetPurchaseJournalRes>;
    /**
     * @generated from protobuf rpc: GetPurchaseJournalPDF(rd.search.service.GetPurchaseJournalPDFReq) returns (rd.search.service.GetPurchaseJournalPDFRes);
     */
    getPurchaseJournalPDF(input: GetPurchaseJournalPDFReq, options?: RpcOptions): UnaryCall<GetPurchaseJournalPDFReq, GetPurchaseJournalPDFRes>;
    /**
     * @generated from protobuf rpc: GetPurchaseJournalCSV(rd.search.service.GetPurchaseJournalCSVReq) returns (rd.search.service.GetPurchaseJournalCSVRes);
     */
    getPurchaseJournalCSV(input: GetPurchaseJournalCSVReq, options?: RpcOptions): UnaryCall<GetPurchaseJournalCSVReq, GetPurchaseJournalCSVRes>;
    /**
     * @generated from protobuf rpc: GetAccountPaymentAdjustments(rd.search.service.GetAccountPaymentAdjustmentsReq) returns (rd.search.service.GetAccountPaymentAdjustmentsRes);
     */
    getAccountPaymentAdjustments(input: GetAccountPaymentAdjustmentsReq, options?: RpcOptions): UnaryCall<GetAccountPaymentAdjustmentsReq, GetAccountPaymentAdjustmentsRes>;
    /**
     * @generated from protobuf rpc: GetAccountPaymentAdjustmentsPDF(rd.search.service.GetAccountPaymentAdjustmentsPDFReq) returns (rd.search.service.GetAccountPaymentAdjustmentsPDFRes);
     */
    getAccountPaymentAdjustmentsPDF(input: GetAccountPaymentAdjustmentsPDFReq, options?: RpcOptions): UnaryCall<GetAccountPaymentAdjustmentsPDFReq, GetAccountPaymentAdjustmentsPDFRes>;
    /**
     * @generated from protobuf rpc: GetAccountPaymentAdjustmentsCSV(rd.search.service.GetAccountPaymentAdjustmentsCSVReq) returns (rd.search.service.GetAccountPaymentAdjustmentsCSVRes);
     */
    getAccountPaymentAdjustmentsCSV(input: GetAccountPaymentAdjustmentsCSVReq, options?: RpcOptions): UnaryCall<GetAccountPaymentAdjustmentsCSVReq, GetAccountPaymentAdjustmentsCSVRes>;
    /**
     * Additional Fees
     *
     * @generated from protobuf rpc: GetAdditionalFees(rd.search.service.GetAdditionalFeesReq) returns (rd.search.service.GetAdditionalFeesRes);
     */
    getAdditionalFees(input: GetAdditionalFeesReq, options?: RpcOptions): UnaryCall<GetAdditionalFeesReq, GetAdditionalFeesRes>;
    /**
     * @generated from protobuf rpc: GetAdditionalFeesPDF(rd.search.service.GetAdditionalFeesPDFReq) returns (rd.search.service.GetAdditionalFeesPDFRes);
     */
    getAdditionalFeesPDF(input: GetAdditionalFeesPDFReq, options?: RpcOptions): UnaryCall<GetAdditionalFeesPDFReq, GetAdditionalFeesPDFRes>;
    /**
     * @generated from protobuf rpc: GetAdditionalFeesCSV(rd.search.service.GetAdditionalFeesCSVReq) returns (rd.search.service.GetAdditionalFeesCSVRes);
     */
    getAdditionalFeesCSV(input: GetAdditionalFeesCSVReq, options?: RpcOptions): UnaryCall<GetAdditionalFeesCSVReq, GetAdditionalFeesCSVRes>;
    /**
     * Inventory By Department
     *
     * @generated from protobuf rpc: GetInventoryByDepartment(rd.search.service.GetInventoryByDepartmentReq) returns (rd.search.service.GetInventoryByDepartmentRes);
     */
    getInventoryByDepartment(input: GetInventoryByDepartmentReq, options?: RpcOptions): UnaryCall<GetInventoryByDepartmentReq, GetInventoryByDepartmentRes>;
    /**
     * @generated from protobuf rpc: GetInventoryByDepartmentPDF(rd.search.service.GetInventoryByDepartmentPDFReq) returns (rd.search.service.GetInventoryByDepartmentPDFRes);
     */
    getInventoryByDepartmentPDF(input: GetInventoryByDepartmentPDFReq, options?: RpcOptions): UnaryCall<GetInventoryByDepartmentPDFReq, GetInventoryByDepartmentPDFRes>;
    /**
     * @generated from protobuf rpc: GetInventoryByDepartmentCSV(rd.search.service.GetInventoryByDepartmentCSVReq) returns (rd.search.service.GetInventoryByDepartmentCSVRes);
     */
    getInventoryByDepartmentCSV(input: GetInventoryByDepartmentCSVReq, options?: RpcOptions): UnaryCall<GetInventoryByDepartmentCSVReq, GetInventoryByDepartmentCSVRes>;
    /**
     * Inventory By Product
     *
     * @generated from protobuf rpc: GetInventoryByProduct(rd.search.service.GetInventoryByProductReq) returns (rd.search.service.GetInventoryByProductRes);
     */
    getInventoryByProduct(input: GetInventoryByProductReq, options?: RpcOptions): UnaryCall<GetInventoryByProductReq, GetInventoryByProductRes>;
    /**
     * @generated from protobuf rpc: GetInventoryByProductPDF(rd.search.service.GetInventoryByProductPDFReq) returns (rd.search.service.GetInventoryByProductPDFRes);
     */
    getInventoryByProductPDF(input: GetInventoryByProductPDFReq, options?: RpcOptions): UnaryCall<GetInventoryByProductPDFReq, GetInventoryByProductPDFRes>;
    /**
     * @generated from protobuf rpc: GetInventoryByProductCSV(rd.search.service.GetInventoryByProductCSVReq) returns (rd.search.service.GetInventoryByProductCSVRes);
     */
    getInventoryByProductCSV(input: GetInventoryByProductCSVReq, options?: RpcOptions): UnaryCall<GetInventoryByProductCSVReq, GetInventoryByProductCSVRes>;
    /**
     * Transfer Discrepancies
     *
     * @generated from protobuf rpc: GetTransferDiscrepancies(rd.search.service.GetTransferDiscrepanciesReq) returns (rd.search.service.GetTransferDiscrepanciesRes);
     */
    getTransferDiscrepancies(input: GetTransferDiscrepanciesReq, options?: RpcOptions): UnaryCall<GetTransferDiscrepanciesReq, GetTransferDiscrepanciesRes>;
    /**
     * @generated from protobuf rpc: GetTransferDiscrepanciesPDF(rd.search.service.GetTransferDiscrepanciesPDFReq) returns (rd.search.service.GetTransferDiscrepanciesPDFRes);
     */
    getTransferDiscrepanciesPDF(input: GetTransferDiscrepanciesPDFReq, options?: RpcOptions): UnaryCall<GetTransferDiscrepanciesPDFReq, GetTransferDiscrepanciesPDFRes>;
    /**
     * @generated from protobuf rpc: GetTransferDiscrepanciesCSV(rd.search.service.GetTransferDiscrepanciesCSVReq) returns (rd.search.service.GetTransferDiscrepanciesCSVRes);
     */
    getTransferDiscrepanciesCSV(input: GetTransferDiscrepanciesCSVReq, options?: RpcOptions): UnaryCall<GetTransferDiscrepanciesCSVReq, GetTransferDiscrepanciesCSVRes>;
    /**
     * Interactions By Staff
     *
     * @generated from protobuf rpc: GetInteractionsByStaff(rd.search.service.GetInteractionsByStaffReq) returns (rd.search.service.GetInteractionsByStaffRes);
     */
    getInteractionsByStaff(input: GetInteractionsByStaffReq, options?: RpcOptions): UnaryCall<GetInteractionsByStaffReq, GetInteractionsByStaffRes>;
    /**
     * @generated from protobuf rpc: GetInteractionsByStaffPDF(rd.search.service.GetInteractionsByStaffPDFReq) returns (rd.search.service.GetInteractionsByStaffPDFRes);
     */
    getInteractionsByStaffPDF(input: GetInteractionsByStaffPDFReq, options?: RpcOptions): UnaryCall<GetInteractionsByStaffPDFReq, GetInteractionsByStaffPDFRes>;
    /**
     * @generated from protobuf rpc: GetInteractionsByStaffCSV(rd.search.service.GetInteractionsByStaffCSVReq) returns (rd.search.service.GetInteractionsByStaffCSVRes);
     */
    getInteractionsByStaffCSV(input: GetInteractionsByStaffCSVReq, options?: RpcOptions): UnaryCall<GetInteractionsByStaffCSVReq, GetInteractionsByStaffCSVRes>;
    /**
     * Interactions
     *
     * @generated from protobuf rpc: GetInteractions(rd.search.service.GetInteractionsReq) returns (rd.search.service.GetInteractionsRes);
     */
    getInteractions(input: GetInteractionsReq, options?: RpcOptions): UnaryCall<GetInteractionsReq, GetInteractionsRes>;
    /**
     * @generated from protobuf rpc: GetInteractionsPDF(rd.search.service.GetInteractionsPDFReq) returns (rd.search.service.GetInteractionsPDFRes);
     */
    getInteractionsPDF(input: GetInteractionsPDFReq, options?: RpcOptions): UnaryCall<GetInteractionsPDFReq, GetInteractionsPDFRes>;
    /**
     * @generated from protobuf rpc: GetInteractionsCSV(rd.search.service.GetInteractionsCSVReq) returns (rd.search.service.GetInteractionsCSVRes);
     */
    getInteractionsCSV(input: GetInteractionsCSVReq, options?: RpcOptions): UnaryCall<GetInteractionsCSVReq, GetInteractionsCSVRes>;
    /**
     * @generated from protobuf rpc: GetReportBuilder(rd.search.service.GetReportBuilderReq) returns (rd.search.service.GetReportBuilderRes);
     */
    getReportBuilder(input: GetReportBuilderReq, options?: RpcOptions): UnaryCall<GetReportBuilderReq, GetReportBuilderRes>;
}
/**
 * @generated from protobuf service rd.search.service.SearchalyticsService
 */
export class SearchalyticsServiceClient implements ISearchalyticsServiceClient, ServiceInfo {
    typeName = SearchalyticsService.typeName;
    methods = SearchalyticsService.methods;
    options = SearchalyticsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetSalesByDay(rd.api.analytics.GetSalesByDayReq) returns (rd.api.analytics.GetSalesByDayRes);
     */
    getSalesByDay(input: GetSalesByDayReq, options?: RpcOptions): UnaryCall<GetSalesByDayReq, GetSalesByDayRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayReq, GetSalesByDayRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByDayPDF(rd.api.analytics.GetSalesByDayPDFReq) returns (rd.api.analytics.GetSalesByDayPDFRes);
     */
    getSalesByDayPDF(input: GetSalesByDayPDFReq, options?: RpcOptions): UnaryCall<GetSalesByDayPDFReq, GetSalesByDayPDFRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayPDFReq, GetSalesByDayPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByDayCSV(rd.api.analytics.GetSalesByDayCSVReq) returns (rd.api.analytics.GetSalesByDayCSVRes);
     */
    getSalesByDayCSV(input: GetSalesByDayCSVReq, options?: RpcOptions): UnaryCall<GetSalesByDayCSVReq, GetSalesByDayCSVRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByDayCSVReq, GetSalesByDayCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Cash Register Report
     *
     * @generated from protobuf rpc: GetCashRegister(rd.search.service.GetCashRegisterReq) returns (rd.search.service.GetCashRegisterRes);
     */
    getCashRegister(input: GetCashRegisterReq, options?: RpcOptions): UnaryCall<GetCashRegisterReq, GetCashRegisterRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCashRegisterReq, GetCashRegisterRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCashRegisterPDF(rd.search.service.GetCashRegisterPDFReq) returns (rd.search.service.GetCashRegisterPDFRes);
     */
    getCashRegisterPDF(input: GetCashRegisterPDFReq, options?: RpcOptions): UnaryCall<GetCashRegisterPDFReq, GetCashRegisterPDFRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCashRegisterPDFReq, GetCashRegisterPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCashRegisterCSV(rd.search.service.GetCashRegisterCSVReq) returns (rd.search.service.GetCashRegisterCSVRes);
     */
    getCashRegisterCSV(input: GetCashRegisterCSVReq, options?: RpcOptions): UnaryCall<GetCashRegisterCSVReq, GetCashRegisterCSVRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCashRegisterCSVReq, GetCashRegisterCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSales(rd.search.service.GetSalesReq) returns (rd.search.service.GetSalesRes);
     */
    getSales(input: GetSalesReq, options?: RpcOptions): UnaryCall<GetSalesReq, GetSalesRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesReq, GetSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesPDF(rd.search.service.GetSalesPDFReq) returns (rd.search.service.GetSalesPDFRes);
     */
    getSalesPDF(input: GetSalesPDFReq, options?: RpcOptions): UnaryCall<GetSalesPDFReq, GetSalesPDFRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesPDFReq, GetSalesPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesCSV(rd.search.service.GetSalesCSVReq) returns (rd.search.service.GetSalesCSVRes);
     */
    getSalesCSV(input: GetSalesCSVReq, options?: RpcOptions): UnaryCall<GetSalesCSVReq, GetSalesCSVRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesCSVReq, GetSalesCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Payments
     *
     * @generated from protobuf rpc: GetPayments(rd.search.service.GetPaymentsReq) returns (rd.search.service.GetPaymentsRes);
     */
    getPayments(input: GetPaymentsReq, options?: RpcOptions): UnaryCall<GetPaymentsReq, GetPaymentsRes> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPaymentsReq, GetPaymentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPaymentsPDF(rd.search.service.GetPaymentsPDFReq) returns (rd.search.service.GetPaymentsPDFRes);
     */
    getPaymentsPDF(input: GetPaymentsPDFReq, options?: RpcOptions): UnaryCall<GetPaymentsPDFReq, GetPaymentsPDFRes> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPaymentsPDFReq, GetPaymentsPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPaymentsCSV(rd.search.service.GetPaymentsCSVReq) returns (rd.search.service.GetPaymentsCSVRes);
     */
    getPaymentsCSV(input: GetPaymentsCSVReq, options?: RpcOptions): UnaryCall<GetPaymentsCSVReq, GetPaymentsCSVRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPaymentsCSVReq, GetPaymentsCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sold Products
     *
     * @generated from protobuf rpc: GetSoldProducts(rd.search.service.GetSoldProductsReq) returns (rd.search.service.GetSoldProductsRes);
     */
    getSoldProducts(input: GetSoldProductsReq, options?: RpcOptions): UnaryCall<GetSoldProductsReq, GetSoldProductsRes> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSoldProductsReq, GetSoldProductsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSoldProductsPDF(rd.search.service.GetSoldProductsPDFReq) returns (rd.search.service.GetSoldProductsPDFRes);
     */
    getSoldProductsPDF(input: GetSoldProductsPDFReq, options?: RpcOptions): UnaryCall<GetSoldProductsPDFReq, GetSoldProductsPDFRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSoldProductsPDFReq, GetSoldProductsPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSoldProductsCSV(rd.search.service.GetSoldProductsCSVReq) returns (rd.search.service.GetSoldProductsCSVRes);
     */
    getSoldProductsCSV(input: GetSoldProductsCSVReq, options?: RpcOptions): UnaryCall<GetSoldProductsCSVReq, GetSoldProductsCSVRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSoldProductsCSVReq, GetSoldProductsCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sales by Customer
     *
     * @generated from protobuf rpc: GetSalesByCustomer(rd.search.service.GetSalesByCustomerReq) returns (rd.search.service.GetSalesByCustomerRes);
     */
    getSalesByCustomer(input: GetSalesByCustomerReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerReq, GetSalesByCustomerRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByCustomerReq, GetSalesByCustomerRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByCustomerPDF(rd.search.service.GetSalesByCustomerPDFReq) returns (rd.search.service.GetSalesByCustomerPDFRes);
     */
    getSalesByCustomerPDF(input: GetSalesByCustomerPDFReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerPDFReq, GetSalesByCustomerPDFRes> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByCustomerPDFReq, GetSalesByCustomerPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByCustomerCSV(rd.search.service.GetSalesByCustomerCSVReq) returns (rd.search.service.GetSalesByCustomerCSVRes);
     */
    getSalesByCustomerCSV(input: GetSalesByCustomerCSVReq, options?: RpcOptions): UnaryCall<GetSalesByCustomerCSVReq, GetSalesByCustomerCSVRes> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByCustomerCSVReq, GetSalesByCustomerCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sales by Job
     *
     * @generated from protobuf rpc: GetSalesByJob(rd.search.service.GetSalesByJobReq) returns (rd.search.service.GetSalesByJobRes);
     */
    getSalesByJob(input: GetSalesByJobReq, options?: RpcOptions): UnaryCall<GetSalesByJobReq, GetSalesByJobRes> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByJobReq, GetSalesByJobRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByJobPDF(rd.search.service.GetSalesByJobPDFReq) returns (rd.search.service.GetSalesByJobPDFRes);
     */
    getSalesByJobPDF(input: GetSalesByJobPDFReq, options?: RpcOptions): UnaryCall<GetSalesByJobPDFReq, GetSalesByJobPDFRes> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByJobPDFReq, GetSalesByJobPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByJobCSV(rd.search.service.GetSalesByJobCSVReq) returns (rd.search.service.GetSalesByJobCSVRes);
     */
    getSalesByJobCSV(input: GetSalesByJobCSVReq, options?: RpcOptions): UnaryCall<GetSalesByJobCSVReq, GetSalesByJobCSVRes> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByJobCSVReq, GetSalesByJobCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sales by Product
     *
     * @generated from protobuf rpc: GetSalesByProduct(rd.search.service.GetSalesByProductReq) returns (rd.search.service.GetSalesByProductRes);
     */
    getSalesByProduct(input: GetSalesByProductReq, options?: RpcOptions): UnaryCall<GetSalesByProductReq, GetSalesByProductRes> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByProductReq, GetSalesByProductRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByProductPDF(rd.search.service.GetSalesByProductPDFReq) returns (rd.search.service.GetSalesByProductPDFRes);
     */
    getSalesByProductPDF(input: GetSalesByProductPDFReq, options?: RpcOptions): UnaryCall<GetSalesByProductPDFReq, GetSalesByProductPDFRes> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByProductPDFReq, GetSalesByProductPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByProductCSV(rd.search.service.GetSalesByProductCSVReq) returns (rd.search.service.GetSalesByProductCSVRes);
     */
    getSalesByProductCSV(input: GetSalesByProductCSVReq, options?: RpcOptions): UnaryCall<GetSalesByProductCSVReq, GetSalesByProductCSVRes> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByProductCSVReq, GetSalesByProductCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sales by Location
     *
     * @generated from protobuf rpc: GetSalesByLocation(rd.search.service.GetSalesByLocationReq) returns (rd.search.service.GetSalesByLocationRes);
     */
    getSalesByLocation(input: GetSalesByLocationReq, options?: RpcOptions): UnaryCall<GetSalesByLocationReq, GetSalesByLocationRes> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByLocationReq, GetSalesByLocationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByLocationPDF(rd.search.service.GetSalesByLocationPDFReq) returns (rd.search.service.GetSalesByLocationPDFRes);
     */
    getSalesByLocationPDF(input: GetSalesByLocationPDFReq, options?: RpcOptions): UnaryCall<GetSalesByLocationPDFReq, GetSalesByLocationPDFRes> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByLocationPDFReq, GetSalesByLocationPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByLocationCSV(rd.search.service.GetSalesByLocationCSVReq) returns (rd.search.service.GetSalesByLocationCSVRes);
     */
    getSalesByLocationCSV(input: GetSalesByLocationCSVReq, options?: RpcOptions): UnaryCall<GetSalesByLocationCSVReq, GetSalesByLocationCSVRes> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByLocationCSVReq, GetSalesByLocationCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Sales by Rep
     *
     * @generated from protobuf rpc: GetSalesByRep(rd.search.service.GetSalesByRepReq) returns (rd.search.service.GetSalesByRepRes);
     */
    getSalesByRep(input: GetSalesByRepReq, options?: RpcOptions): UnaryCall<GetSalesByRepReq, GetSalesByRepRes> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByRepReq, GetSalesByRepRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByRepPDF(rd.search.service.GetSalesByRepPDFReq) returns (rd.search.service.GetSalesByRepPDFRes);
     */
    getSalesByRepPDF(input: GetSalesByRepPDFReq, options?: RpcOptions): UnaryCall<GetSalesByRepPDFReq, GetSalesByRepPDFRes> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByRepPDFReq, GetSalesByRepPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSalesByRepCSV(rd.search.service.GetSalesByRepCSVReq) returns (rd.search.service.GetSalesByRepCSVRes);
     */
    getSalesByRepCSV(input: GetSalesByRepCSVReq, options?: RpcOptions): UnaryCall<GetSalesByRepCSVReq, GetSalesByRepCSVRes> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSalesByRepCSVReq, GetSalesByRepCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Customer Aging
     *
     * @generated from protobuf rpc: GetCustomerAging(rd.api.analytics.GetCustomerAgingReq) returns (rd.api.analytics.GetCustomerAgingRes);
     */
    getCustomerAging(input: GetCustomerAgingReq, options?: RpcOptions): UnaryCall<GetCustomerAgingReq, GetCustomerAgingRes> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerAgingReq, GetCustomerAgingRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomerAgingPDF(rd.api.analytics.GetCustomerAgingPDFReq) returns (rd.api.analytics.GetCustomerAgingPDFRes);
     */
    getCustomerAgingPDF(input: GetCustomerAgingPDFReq, options?: RpcOptions): UnaryCall<GetCustomerAgingPDFReq, GetCustomerAgingPDFRes> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerAgingPDFReq, GetCustomerAgingPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomerAgingCSV(rd.api.analytics.GetCustomerAgingCSVReq) returns (rd.api.analytics.GetCustomerAgingCSVRes);
     */
    getCustomerAgingCSV(input: GetCustomerAgingCSVReq, options?: RpcOptions): UnaryCall<GetCustomerAgingCSVReq, GetCustomerAgingCSVRes> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerAgingCSVReq, GetCustomerAgingCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Aging by Job
     *
     * @generated from protobuf rpc: GetAgingByJob(rd.search.service.GetAgingByJobReq) returns (rd.search.service.GetAgingByJobRes);
     */
    getAgingByJob(input: GetAgingByJobReq, options?: RpcOptions): UnaryCall<GetAgingByJobReq, GetAgingByJobRes> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingByJobReq, GetAgingByJobRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgingByJobPDF(rd.search.service.GetAgingByJobPDFReq) returns (rd.search.service.GetAgingByJobPDFRes);
     */
    getAgingByJobPDF(input: GetAgingByJobPDFReq, options?: RpcOptions): UnaryCall<GetAgingByJobPDFReq, GetAgingByJobPDFRes> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingByJobPDFReq, GetAgingByJobPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgingByJobCSV(rd.search.service.GetAgingByJobCSVReq) returns (rd.search.service.GetAgingByJobCSVRes);
     */
    getAgingByJobCSV(input: GetAgingByJobCSVReq, options?: RpcOptions): UnaryCall<GetAgingByJobCSVReq, GetAgingByJobCSVRes> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingByJobCSVReq, GetAgingByJobCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Profit and Loss
     *
     * @generated from protobuf rpc: GetProfitAndLoss(rd.search.service.GetProfitAndLossReq) returns (rd.search.service.GetProfitAndLossRes);
     */
    getProfitAndLoss(input: GetProfitAndLossReq, options?: RpcOptions): UnaryCall<GetProfitAndLossReq, GetProfitAndLossRes> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProfitAndLossReq, GetProfitAndLossRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProfitAndLossPDF(rd.search.service.GetProfitAndLossPDFReq) returns (rd.search.service.GetProfitAndLossPDFRes);
     */
    getProfitAndLossPDF(input: GetProfitAndLossPDFReq, options?: RpcOptions): UnaryCall<GetProfitAndLossPDFReq, GetProfitAndLossPDFRes> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProfitAndLossPDFReq, GetProfitAndLossPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * New Accounts Opened
     *
     * @generated from protobuf rpc: GetNewAccountsOpened(rd.search.service.GetNewAccountsOpenedReq) returns (rd.search.service.GetNewAccountsOpenedRes);
     */
    getNewAccountsOpened(input: GetNewAccountsOpenedReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedReq, GetNewAccountsOpenedRes> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewAccountsOpenedReq, GetNewAccountsOpenedRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedPDF(rd.search.service.GetNewAccountsOpenedPDFReq) returns (rd.search.service.GetNewAccountsOpenedPDFRes);
     */
    getNewAccountsOpenedPDF(input: GetNewAccountsOpenedPDFReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedPDFReq, GetNewAccountsOpenedPDFRes> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewAccountsOpenedPDFReq, GetNewAccountsOpenedPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNewAccountsOpenedCSV(rd.search.service.GetNewAccountsOpenedCSVReq) returns (rd.search.service.GetNewAccountsOpenedCSVRes);
     */
    getNewAccountsOpenedCSV(input: GetNewAccountsOpenedCSVReq, options?: RpcOptions): UnaryCall<GetNewAccountsOpenedCSVReq, GetNewAccountsOpenedCSVRes> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewAccountsOpenedCSVReq, GetNewAccountsOpenedCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Aging Detail
     *
     * @generated from protobuf rpc: GetAgingDetail(rd.search.service.GetAgingDetailReq) returns (rd.search.service.GetAgingDetailRes);
     */
    getAgingDetail(input: GetAgingDetailReq, options?: RpcOptions): UnaryCall<GetAgingDetailReq, GetAgingDetailRes> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingDetailReq, GetAgingDetailRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgingDetailPDF(rd.search.service.GetAgingDetailPDFReq) returns (rd.search.service.GetAgingDetailPDFRes);
     */
    getAgingDetailPDF(input: GetAgingDetailPDFReq, options?: RpcOptions): UnaryCall<GetAgingDetailPDFReq, GetAgingDetailPDFRes> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingDetailPDFReq, GetAgingDetailPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgingDetailCSV(rd.search.service.GetAgingDetailCSVReq) returns (rd.search.service.GetAgingDetailCSVRes);
     */
    getAgingDetailCSV(input: GetAgingDetailCSVReq, options?: RpcOptions): UnaryCall<GetAgingDetailCSVReq, GetAgingDetailCSVRes> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgingDetailCSVReq, GetAgingDetailCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Price Edits
     *
     * @generated from protobuf rpc: GetPriceEdits(rd.search.service.GetPriceEditsReq) returns (rd.search.service.GetPriceEditsRes);
     */
    getPriceEdits(input: GetPriceEditsReq, options?: RpcOptions): UnaryCall<GetPriceEditsReq, GetPriceEditsRes> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPriceEditsReq, GetPriceEditsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPriceEditsPDF(rd.search.service.GetPriceEditsPDFReq) returns (rd.search.service.GetPriceEditsPDFRes);
     */
    getPriceEditsPDF(input: GetPriceEditsPDFReq, options?: RpcOptions): UnaryCall<GetPriceEditsPDFReq, GetPriceEditsPDFRes> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPriceEditsPDFReq, GetPriceEditsPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPriceEditsCSV(rd.search.service.GetPriceEditsCSVReq) returns (rd.search.service.GetPriceEditsCSVRes);
     */
    getPriceEditsCSV(input: GetPriceEditsCSVReq, options?: RpcOptions): UnaryCall<GetPriceEditsCSVReq, GetPriceEditsCSVRes> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPriceEditsCSVReq, GetPriceEditsCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Purchase Journal
     *
     * @generated from protobuf rpc: GetPurchaseJournal(rd.search.service.GetPurchaseJournalReq) returns (rd.search.service.GetPurchaseJournalRes);
     */
    getPurchaseJournal(input: GetPurchaseJournalReq, options?: RpcOptions): UnaryCall<GetPurchaseJournalReq, GetPurchaseJournalRes> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPurchaseJournalReq, GetPurchaseJournalRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPurchaseJournalPDF(rd.search.service.GetPurchaseJournalPDFReq) returns (rd.search.service.GetPurchaseJournalPDFRes);
     */
    getPurchaseJournalPDF(input: GetPurchaseJournalPDFReq, options?: RpcOptions): UnaryCall<GetPurchaseJournalPDFReq, GetPurchaseJournalPDFRes> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPurchaseJournalPDFReq, GetPurchaseJournalPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPurchaseJournalCSV(rd.search.service.GetPurchaseJournalCSVReq) returns (rd.search.service.GetPurchaseJournalCSVRes);
     */
    getPurchaseJournalCSV(input: GetPurchaseJournalCSVReq, options?: RpcOptions): UnaryCall<GetPurchaseJournalCSVReq, GetPurchaseJournalCSVRes> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPurchaseJournalCSVReq, GetPurchaseJournalCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccountPaymentAdjustments(rd.search.service.GetAccountPaymentAdjustmentsReq) returns (rd.search.service.GetAccountPaymentAdjustmentsRes);
     */
    getAccountPaymentAdjustments(input: GetAccountPaymentAdjustmentsReq, options?: RpcOptions): UnaryCall<GetAccountPaymentAdjustmentsReq, GetAccountPaymentAdjustmentsRes> {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAccountPaymentAdjustmentsReq, GetAccountPaymentAdjustmentsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccountPaymentAdjustmentsPDF(rd.search.service.GetAccountPaymentAdjustmentsPDFReq) returns (rd.search.service.GetAccountPaymentAdjustmentsPDFRes);
     */
    getAccountPaymentAdjustmentsPDF(input: GetAccountPaymentAdjustmentsPDFReq, options?: RpcOptions): UnaryCall<GetAccountPaymentAdjustmentsPDFReq, GetAccountPaymentAdjustmentsPDFRes> {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAccountPaymentAdjustmentsPDFReq, GetAccountPaymentAdjustmentsPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccountPaymentAdjustmentsCSV(rd.search.service.GetAccountPaymentAdjustmentsCSVReq) returns (rd.search.service.GetAccountPaymentAdjustmentsCSVRes);
     */
    getAccountPaymentAdjustmentsCSV(input: GetAccountPaymentAdjustmentsCSVReq, options?: RpcOptions): UnaryCall<GetAccountPaymentAdjustmentsCSVReq, GetAccountPaymentAdjustmentsCSVRes> {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAccountPaymentAdjustmentsCSVReq, GetAccountPaymentAdjustmentsCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Additional Fees
     *
     * @generated from protobuf rpc: GetAdditionalFees(rd.search.service.GetAdditionalFeesReq) returns (rd.search.service.GetAdditionalFeesRes);
     */
    getAdditionalFees(input: GetAdditionalFeesReq, options?: RpcOptions): UnaryCall<GetAdditionalFeesReq, GetAdditionalFeesRes> {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAdditionalFeesReq, GetAdditionalFeesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAdditionalFeesPDF(rd.search.service.GetAdditionalFeesPDFReq) returns (rd.search.service.GetAdditionalFeesPDFRes);
     */
    getAdditionalFeesPDF(input: GetAdditionalFeesPDFReq, options?: RpcOptions): UnaryCall<GetAdditionalFeesPDFReq, GetAdditionalFeesPDFRes> {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAdditionalFeesPDFReq, GetAdditionalFeesPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAdditionalFeesCSV(rd.search.service.GetAdditionalFeesCSVReq) returns (rd.search.service.GetAdditionalFeesCSVRes);
     */
    getAdditionalFeesCSV(input: GetAdditionalFeesCSVReq, options?: RpcOptions): UnaryCall<GetAdditionalFeesCSVReq, GetAdditionalFeesCSVRes> {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAdditionalFeesCSVReq, GetAdditionalFeesCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Inventory By Department
     *
     * @generated from protobuf rpc: GetInventoryByDepartment(rd.search.service.GetInventoryByDepartmentReq) returns (rd.search.service.GetInventoryByDepartmentRes);
     */
    getInventoryByDepartment(input: GetInventoryByDepartmentReq, options?: RpcOptions): UnaryCall<GetInventoryByDepartmentReq, GetInventoryByDepartmentRes> {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInventoryByDepartmentReq, GetInventoryByDepartmentRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInventoryByDepartmentPDF(rd.search.service.GetInventoryByDepartmentPDFReq) returns (rd.search.service.GetInventoryByDepartmentPDFRes);
     */
    getInventoryByDepartmentPDF(input: GetInventoryByDepartmentPDFReq, options?: RpcOptions): UnaryCall<GetInventoryByDepartmentPDFReq, GetInventoryByDepartmentPDFRes> {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInventoryByDepartmentPDFReq, GetInventoryByDepartmentPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInventoryByDepartmentCSV(rd.search.service.GetInventoryByDepartmentCSVReq) returns (rd.search.service.GetInventoryByDepartmentCSVRes);
     */
    getInventoryByDepartmentCSV(input: GetInventoryByDepartmentCSVReq, options?: RpcOptions): UnaryCall<GetInventoryByDepartmentCSVReq, GetInventoryByDepartmentCSVRes> {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInventoryByDepartmentCSVReq, GetInventoryByDepartmentCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Inventory By Product
     *
     * @generated from protobuf rpc: GetInventoryByProduct(rd.search.service.GetInventoryByProductReq) returns (rd.search.service.GetInventoryByProductRes);
     */
    getInventoryByProduct(input: GetInventoryByProductReq, options?: RpcOptions): UnaryCall<GetInventoryByProductReq, GetInventoryByProductRes> {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInventoryByProductReq, GetInventoryByProductRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInventoryByProductPDF(rd.search.service.GetInventoryByProductPDFReq) returns (rd.search.service.GetInventoryByProductPDFRes);
     */
    getInventoryByProductPDF(input: GetInventoryByProductPDFReq, options?: RpcOptions): UnaryCall<GetInventoryByProductPDFReq, GetInventoryByProductPDFRes> {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInventoryByProductPDFReq, GetInventoryByProductPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInventoryByProductCSV(rd.search.service.GetInventoryByProductCSVReq) returns (rd.search.service.GetInventoryByProductCSVRes);
     */
    getInventoryByProductCSV(input: GetInventoryByProductCSVReq, options?: RpcOptions): UnaryCall<GetInventoryByProductCSVReq, GetInventoryByProductCSVRes> {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInventoryByProductCSVReq, GetInventoryByProductCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Transfer Discrepancies
     *
     * @generated from protobuf rpc: GetTransferDiscrepancies(rd.search.service.GetTransferDiscrepanciesReq) returns (rd.search.service.GetTransferDiscrepanciesRes);
     */
    getTransferDiscrepancies(input: GetTransferDiscrepanciesReq, options?: RpcOptions): UnaryCall<GetTransferDiscrepanciesReq, GetTransferDiscrepanciesRes> {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTransferDiscrepanciesReq, GetTransferDiscrepanciesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransferDiscrepanciesPDF(rd.search.service.GetTransferDiscrepanciesPDFReq) returns (rd.search.service.GetTransferDiscrepanciesPDFRes);
     */
    getTransferDiscrepanciesPDF(input: GetTransferDiscrepanciesPDFReq, options?: RpcOptions): UnaryCall<GetTransferDiscrepanciesPDFReq, GetTransferDiscrepanciesPDFRes> {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTransferDiscrepanciesPDFReq, GetTransferDiscrepanciesPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransferDiscrepanciesCSV(rd.search.service.GetTransferDiscrepanciesCSVReq) returns (rd.search.service.GetTransferDiscrepanciesCSVRes);
     */
    getTransferDiscrepanciesCSV(input: GetTransferDiscrepanciesCSVReq, options?: RpcOptions): UnaryCall<GetTransferDiscrepanciesCSVReq, GetTransferDiscrepanciesCSVRes> {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTransferDiscrepanciesCSVReq, GetTransferDiscrepanciesCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Interactions By Staff
     *
     * @generated from protobuf rpc: GetInteractionsByStaff(rd.search.service.GetInteractionsByStaffReq) returns (rd.search.service.GetInteractionsByStaffRes);
     */
    getInteractionsByStaff(input: GetInteractionsByStaffReq, options?: RpcOptions): UnaryCall<GetInteractionsByStaffReq, GetInteractionsByStaffRes> {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInteractionsByStaffReq, GetInteractionsByStaffRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInteractionsByStaffPDF(rd.search.service.GetInteractionsByStaffPDFReq) returns (rd.search.service.GetInteractionsByStaffPDFRes);
     */
    getInteractionsByStaffPDF(input: GetInteractionsByStaffPDFReq, options?: RpcOptions): UnaryCall<GetInteractionsByStaffPDFReq, GetInteractionsByStaffPDFRes> {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInteractionsByStaffPDFReq, GetInteractionsByStaffPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInteractionsByStaffCSV(rd.search.service.GetInteractionsByStaffCSVReq) returns (rd.search.service.GetInteractionsByStaffCSVRes);
     */
    getInteractionsByStaffCSV(input: GetInteractionsByStaffCSVReq, options?: RpcOptions): UnaryCall<GetInteractionsByStaffCSVReq, GetInteractionsByStaffCSVRes> {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInteractionsByStaffCSVReq, GetInteractionsByStaffCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Interactions
     *
     * @generated from protobuf rpc: GetInteractions(rd.search.service.GetInteractionsReq) returns (rd.search.service.GetInteractionsRes);
     */
    getInteractions(input: GetInteractionsReq, options?: RpcOptions): UnaryCall<GetInteractionsReq, GetInteractionsRes> {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInteractionsReq, GetInteractionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInteractionsPDF(rd.search.service.GetInteractionsPDFReq) returns (rd.search.service.GetInteractionsPDFRes);
     */
    getInteractionsPDF(input: GetInteractionsPDFReq, options?: RpcOptions): UnaryCall<GetInteractionsPDFReq, GetInteractionsPDFRes> {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInteractionsPDFReq, GetInteractionsPDFRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInteractionsCSV(rd.search.service.GetInteractionsCSVReq) returns (rd.search.service.GetInteractionsCSVRes);
     */
    getInteractionsCSV(input: GetInteractionsCSVReq, options?: RpcOptions): UnaryCall<GetInteractionsCSVReq, GetInteractionsCSVRes> {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInteractionsCSVReq, GetInteractionsCSVRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReportBuilder(rd.search.service.GetReportBuilderReq) returns (rd.search.service.GetReportBuilderRes);
     */
    getReportBuilder(input: GetReportBuilderReq, options?: RpcOptions): UnaryCall<GetReportBuilderReq, GetReportBuilderRes> {
        const method = this.methods[71], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetReportBuilderReq, GetReportBuilderRes>("unary", this._transport, method, opt, input);
    }
}
/**
 * CxaSearchService is for cxa specific search endpoints. The search service is
 * seperate because the system needs to be cleanly isolated from the pos.
 *
 * @generated from protobuf service rd.search.service.CxaSearchService
 */
export interface ICxaSearchServiceClient {
    /**
     * @generated from protobuf rpc: CxaSearchSales(rd.search.service.CxaSearchSalesReq) returns (rd.search.service.CxaSearchSalesRes);
     */
    cxaSearchSales(input: CxaSearchSalesReq, options?: RpcOptions): UnaryCall<CxaSearchSalesReq, CxaSearchSalesRes>;
    /**
     * @generated from protobuf rpc: CxaSearchOutstandingSales(rd.search.service.CxaSearchOutstandingSalesReq) returns (rd.search.service.CxaSearchOutstandingSalesRes);
     */
    cxaSearchOutstandingSales(input: CxaSearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes>;
    /**
     * @generated from protobuf rpc: CxaSearchReturns(rd.search.service.CxaSearchReturnsReq) returns (rd.search.service.CxaSearchReturnsRes);
     */
    cxaSearchReturns(input: CxaSearchReturnsReq, options?: RpcOptions): UnaryCall<CxaSearchReturnsReq, CxaSearchReturnsRes>;
    /**
     * @generated from protobuf rpc: CxaSearchJobs(rd.search.service.CxaSearchJobsReq) returns (rd.search.service.CxaSearchJobsRes);
     */
    cxaSearchJobs(input: CxaSearchJobsReq, options?: RpcOptions): UnaryCall<CxaSearchJobsReq, CxaSearchJobsRes>;
}
/**
 * CxaSearchService is for cxa specific search endpoints. The search service is
 * seperate because the system needs to be cleanly isolated from the pos.
 *
 * @generated from protobuf service rd.search.service.CxaSearchService
 */
export class CxaSearchServiceClient implements ICxaSearchServiceClient, ServiceInfo {
    typeName = CxaSearchService.typeName;
    methods = CxaSearchService.methods;
    options = CxaSearchService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CxaSearchSales(rd.search.service.CxaSearchSalesReq) returns (rd.search.service.CxaSearchSalesRes);
     */
    cxaSearchSales(input: CxaSearchSalesReq, options?: RpcOptions): UnaryCall<CxaSearchSalesReq, CxaSearchSalesRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchSalesReq, CxaSearchSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CxaSearchOutstandingSales(rd.search.service.CxaSearchOutstandingSalesReq) returns (rd.search.service.CxaSearchOutstandingSalesRes);
     */
    cxaSearchOutstandingSales(input: CxaSearchOutstandingSalesReq, options?: RpcOptions): UnaryCall<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchOutstandingSalesReq, CxaSearchOutstandingSalesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CxaSearchReturns(rd.search.service.CxaSearchReturnsReq) returns (rd.search.service.CxaSearchReturnsRes);
     */
    cxaSearchReturns(input: CxaSearchReturnsReq, options?: RpcOptions): UnaryCall<CxaSearchReturnsReq, CxaSearchReturnsRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchReturnsReq, CxaSearchReturnsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CxaSearchJobs(rd.search.service.CxaSearchJobsReq) returns (rd.search.service.CxaSearchJobsRes);
     */
    cxaSearchJobs(input: CxaSearchJobsReq, options?: RpcOptions): UnaryCall<CxaSearchJobsReq, CxaSearchJobsRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CxaSearchJobsReq, CxaSearchJobsRes>("unary", this._transport, method, opt, input);
    }
}
