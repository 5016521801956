import {
  Customer,
  Contacts_Contact as CustomerContact,
  PurchaseOrder,
} from "gen/customer/models_pb"
import { FractionalMoney, Money } from "money"
import { GetSaleRes, SyncCartPricesRes } from "gen/api/sale/service_pb"
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { PaymentMetadata, SaleProductView } from "gen/txn/models_pb"
import { Product, Product_TaxStatus } from "gen/product/models_pb"
import SaleForm, { SaleProduct } from "./types/SaleForm"
import {
  calculateSurchargeFromPayments,
  findIdxInSaleProducts,
  getIdxFromSaleProductsForUniqueTint,
  sumPayments,
} from "./helpers/helpers"

import { AdditionalFees_Fee as AdditionalFee } from "gen/company/models_pb"
import { Address } from "lib/address"
import { AppState } from "app/store"
import { Card } from "gen/payments/models_pb"
import { ListColorxTintedProductsRes_Result as ColorxTintedProductsResult } from "gen/api/colorx/service_pb"
import { CustomerState } from "../transactions/CustomerSidebar"
import { Decimal } from "decimal"
import { Address as PBAddress } from "gen/proto/address/models_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { LedgerEntry_Payment_Method as PaymentMethod } from "gen/ledger/models_pb"
import { SpecialOrderProductSaleFormData } from "./SpecialOrderProductFieldsModal"
import { Timestamp } from "gen/google/protobuf/timestamp_pb"
import { TintColor } from "gen/tintcolor/models_pb"
import { TransactionProduct } from "../transactions/transactionProduct"
import { UUID } from "uuid-rd"
import { checkProductArrayEquality } from "../transactions/helpers/helpers"
import { createPaymentCardDefaults } from "./helpers"
import { salesApiSlice } from "features/api/sale"

export interface productForReturn extends uniqueProductIdentifier {
  quantityToReturn: number
}

export type uniqueProductIdentifier = {
  id?: PBUUID // sale product id
  productId?: PBUUID
}

export type uniqueTintIdentifier = {
  productId?: PBUUID
  tintColorId?: PBUUID
  customTintColor: string
}

type ProductPayloadAction<T> = PayloadAction<uniqueProductIdentifier & T>

export interface SaleFormState extends SaleForm {
  initialState?: SaleForm
  isEdited?: boolean
  drawerEdited?: boolean
  isInitializing?: boolean
  customerCards?: Card[]
  cartTotals?: SyncCartPricesRes
  // wee bit jank but this sale products array tracks only the products we are returning *right now*
  // meaning `quantityReturned` reflects the quantity a user is actively toggling return quantities on
  scrollToBottom?: boolean
}

const initialState: SaleFormState = {}

const checkIsEdited = (state: SaleFormState) => {
  if (state.initialState?.saleNotes !== state.saleNotes) {
    return true
  }
  if (state.initialState?.internalSaleNotes !== state.internalSaleNotes) {
    return true
  }
  if (
    state.initialState?.quoteExpiration?.getTime() !==
    state.quoteExpiration?.getTime()
  ) {
    return true
  }
  if (!UUID.eqFromPB(state.initialState?.location?.id, state.location?.id)) {
    return true
  }
  if (
    !UUID.eqFromPB(
      state.initialState?.purchaseOrder?.id,
      state.purchaseOrder?.id
    )
  ) {
    return true
  }
  if (!Address.eqFromPB(state.initialState?.address, state.address)) {
    return true
  }

  if (
    state.initialState?.customerContact?.idString !==
    state.customerContact?.idString
  ) {
    return true
  }

  const initSaleProductsV2: SaleProduct[] =
    state.initialState?.saleProducts ?? []
  const saleProductsV2: SaleProduct[] = state.saleProducts ?? []

  if (!checkProductArrayEquality(initSaleProductsV2, saleProductsV2)) {
    return true
  }

  if (!UUID.eqFromPB(state.initialState?.customerId, state.customerId)) {
    return true
  }

  // TODO: Replace with initial state check to prevent unexpected behavior
  if (state.searchProduct !== undefined) {
    return true
  }

  // TODO(rkofman): consider making this not hardcoded. See also SaleForm.test.tsx extra setup
  // necessary because of this.
  if (state.addProductQuantity !== 1) {
    return true
  }

  if (!UUID.eqFromPB(state.initialState?.taxRateId, state.taxRateId)) {
    return true
  }

  return false
}

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    setIsInitializing: (state) => {
      state.isInitializing = true
    },
    setSaleInitialState: (state, action: PayloadAction<GetSaleRes>) => {
      const saleProducts =
        action.payload.saleProductViews?.length > 0
          ? action.payload.saleProductViews
          : action.payload.saleProducts ?? []
      state.initialState = {
        location: action.payload.location,
        purchaseOrder: action.payload.customerPurchaseOrder,
        address: action.payload.sale?.address,
        productsById: action.payload.productsById,
        tintColorsById: action.payload.tintColorsById,
        catalogProductsById: action.payload.catalogProductsById,
        saleNotes: action.payload.sale?.saleNotes,
        internalSaleNotes: action.payload.sale?.internalNotes,
        quoteExpiration: action.payload.sale?.quoteExpiresAt
          ? Timestamp.toDate(action.payload.sale?.quoteExpiresAt)
          : undefined,
        customerId: action.payload.sale?.customerId,
        inventoriesByProductId: action.payload.inventoriesByProductId,
        splitPayments: [],
        isSplitPayment: false,
        payment: undefined,
        paymentAmount: undefined,
        paymentMethod: undefined,
        taxRateId: action.payload.sale?.taxRateId,
        saleProducts: saleProducts,
      }
      state.location = action.payload.location
      state.purchaseOrder = action.payload.customerPurchaseOrder
      state.address = action.payload.sale?.address
      state.customerContact = action.payload.customerContact
      state.productsById = action.payload.productsById
      state.tintColorsById = action.payload.tintColorsById
      state.catalogProductsById = action.payload.catalogProductsById
      state.amountRefunded = 0

      state.saleProducts = saleProducts
      state.saleNotes = action.payload.sale?.saleNotes
      state.internalSaleNotes = action.payload.sale?.internalNotes
      state.quoteExpiration = action.payload.sale?.quoteExpiresAt
        ? Timestamp.toDate(action.payload.sale?.quoteExpiresAt)
        : undefined
      state.customerId = action.payload.sale?.customerId
      state.cartTotals = undefined

      state.isEdited = false
      state.isInitializing = false
      state.addProductQuantity = 1
      state.inventoriesByProductId = action.payload.inventoriesByProductId
      state.splitPayments = []
      state.isSplitPayment = false
      state.payment = createPaymentCardDefaults()
      state.paymentAmount = undefined
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.drawerEdited = false
      state.customerCards = []
      state.taxRateId = action.payload.sale?.taxRateId
      state.idempotencyKey = UUID.newRandom().toString()
    },
    resetSaleForm: (state) => {
      for (const key in state) {
        delete state[key as keyof SaleFormState]
      }
      state.addProductQuantity = 1
      state.initialState = {
        addProductQuantity: 1,
      }
      state.payment = createPaymentCardDefaults()
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.isEdited = false
      state.drawerEdited = false
      state.idempotencyKey = UUID.newRandom().toString()
    },
    incrementReturnQuantities: (
      state,
      action: PayloadAction<Record<string, number>>
    ) => {
      if (!state.saleProducts) {
        return
      }

      for (const sp of state.saleProducts) {
        if (!sp.id) {
          continue
        }
        const spID = UUID.fromPB(sp.id).toString()
        if (spID in action.payload) {
          sp.quantityReturned += action.payload[spID]
        }
      }
    },
    setInitialQuoteExpiration: (state, action: PayloadAction<Date>) => {
      state.initialState = {
        ...state.initialState,
        quoteExpiration: action.payload,
      }
      state.quoteExpiration = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setQuoteExpiration: (state, action: PayloadAction<Date>) => {
      state.quoteExpiration = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    customerSelected: (state, action: PayloadAction<CustomerState>) => {
      state.customerId = action.payload?.customerId
      if (!state.drawerEdited) {
        if (
          Money.fromPB(action.payload?.customerCreditLimit).gt(Money.zero())
        ) {
          state.payment = {
            drawer: "charge_account",
          }
          state.paymentMethod = PaymentMethod.UNDEFINED
        } else {
          state.payment = createPaymentCardDefaults()
        }
      }
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    customerCardsSelected: (state, action: PayloadAction<Card[]>) => {
      state.customerCards = action.payload
      if (
        !state.drawerEdited &&
        state.customerCards?.length > 0 &&
        state.payment?.drawer === "card"
      ) {
        state.payment.card = state.customerCards[0]
        state.paymentMethod = PaymentMethod.CARD_SAVED
      }
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    resetCustomer: (state) => {
      delete state.customerId
      delete state.customerContact
      state.customerCards = []
      state.payment = createPaymentCardDefaults()
      state.paymentMethod = PaymentMethod.CARD_PRESENT

      state.isEdited = checkIsEdited(state as SaleFormState)
      state.drawerEdited = false
    },
    removeProductV2: (state, action: PayloadAction<SaleProduct>) => {
      if (
        !state.saleProducts ||
        state.saleProducts.length === 0 ||
        !action.payload.id
      ) {
        return
      }
      const productIdx = findIdxInSaleProducts(
        state.saleProducts,
        action.payload.id
      )

      if (productIdx === -1) {
        return
      }

      state.saleProducts.splice(productIdx, 1)

      // update position values for all products
      state.saleProducts = state.saleProducts.map((product, index) => ({
        ...product,
        position: index + 1,
      }))

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setAddProductQuantity: (state, action: PayloadAction<number>) => {
      state.addProductQuantity = action.payload
      state.isEdited
    },
    setProductQuantityV2: (
      state,
      action: ProductPayloadAction<{ quantity?: number }>
    ) => {
      if (!action.payload.id || !state.saleProducts) {
        return
      }

      const productIdx = findIdxInSaleProducts(
        state.saleProducts,
        action.payload.id
      )

      if (productIdx === -1) {
        return
      }

      state.saleProducts[productIdx] = {
        ...state.saleProducts[productIdx],
        quantity: action.payload.quantity ?? 0,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductPriceV2: (
      state,
      action: ProductPayloadAction<{
        price: Money
      }>
    ) => {
      if (!action.payload.id || !state.saleProducts) {
        return
      }

      const productIdx = findIdxInSaleProducts(
        state.saleProducts,
        action.payload.id
      )

      if (productIdx === -1) {
        return
      }

      if (!action.payload.id || !state.saleProducts) {
        return
      }

      state.saleProducts[productIdx] = {
        ...state.saleProducts[productIdx],
        price: action.payload.price.toPB(),
        isPriceEdited: true,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductCostV2: (
      state,
      action: ProductPayloadAction<{
        cost: FractionalMoney
      }>
    ) => {
      if (!action.payload.id || !state.saleProducts) {
        return
      }

      const productIdx = findIdxInSaleProducts(
        state.saleProducts,
        action.payload.id
      )

      if (productIdx === -1) {
        return
      }

      state.saleProducts[productIdx] = {
        ...state.saleProducts[productIdx],
        costV2: action.payload.cost.toPB(),
        isCostEdited: true,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setAddress: (state, action: PayloadAction<PBAddress | undefined>) => {
      state.address = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setPurchaseOrder: (
      state,
      action: PayloadAction<PurchaseOrder | undefined>
    ) => {
      state.purchaseOrder = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setCustomerContact: (
      state,
      action: PayloadAction<CustomerContact | undefined>
    ) => {
      state.customerContact = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setSaleNotes: (state, action: PayloadAction<string>) => {
      state.saleNotes = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setInternalSaleNotes: (state, action: PayloadAction<string>) => {
      state.internalSaleNotes = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductNotesV2: (
      state,
      action: ProductPayloadAction<{
        notes: string
      }>
    ) => {
      const { notes } = action.payload
      if (!action.payload.id || !state.saleProducts) {
        return
      }

      const productIdx = findIdxInSaleProducts(
        state.saleProducts,
        action.payload.id
      )

      if (productIdx === -1) {
        return
      }

      state.saleProducts[productIdx] = {
        ...state.saleProducts[productIdx],
        notes: notes,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setSpecialOrderProductFields: (
      state,
      action: ProductPayloadAction<{
        data: SpecialOrderProductSaleFormData
      }>
    ) => {
      const { id, data } = action.payload

      if (!id || !state.saleProducts) {
        return
      }

      const productIdx = findIdxInSaleProducts(state.saleProducts, id)

      if (productIdx === -1) {
        return
      }

      state.saleProducts[productIdx] = {
        ...state.saleProducts[productIdx],
        price: data.price,
        deposit: data.deposit,
        notes: data.notes,
        costV2: data.cost.toPB(),
        isPriceEdited: true,
        isCostEdited: true,
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    searchProductSelected: (
      state,
      action: PayloadAction<{
        productResult: TransactionProduct
      }>
    ) => {
      state.searchProduct = {
        ...action.payload.productResult,
      }

      if (
        action.payload.productResult.isScan &&
        action.payload.productResult.quantity > 0
      ) {
        state.addProductQuantity = action.payload.productResult.quantity
      }

      if (
        action.payload.productResult?.isScan &&
        !action.payload.productResult.product?.isTintable &&
        !action.payload.productResult.product?.isSpecialOrderable
      ) {
        salesSlice.caseReducers.searchProductSubmitted(state, {
          type: "searchProductSubmitted",
          payload: {},
        })
      }
    },
    searchProductSubmitted: (
      state,
      action: PayloadAction<{
        notes?: string
        tintColor?: TintColor
        customTintColor?: string
        data?: SpecialOrderProductSaleFormData
      }>
    ) => {
      const searchProduct = state.searchProduct?.product
      const { notes = "", tintColor, customTintColor = "" } = action.payload

      if (!searchProduct?.id || (customTintColor && tintColor)) {
        return
      }

      if (!state.productsById) {
        state.productsById = {}
      }

      if (!state.inventoriesByProductId) {
        state.inventoriesByProductId = {}
      }

      if (!state.catalogProductsById) {
        state.catalogProductsById = {}
      }

      const alwaysAddNew = searchProduct.isSpecialOrderable
      const searchProductIDString = UUID.fromPB(searchProduct.id).toString()

      const {
        price,
        cost,
        deposit,
        retailPrice,
        notes: specialOrderProductNotes,
      } = action.payload.data || {}

      if (!state.saleProducts) {
        state.saleProducts = []
      }

      const idx = getIdxFromSaleProductsForUniqueTint(state.saleProducts, {
        productId: searchProduct.id,
        tintColorId: tintColor?.id,
        customTintColor,
      })
      const lastPositionV2 = state.saleProducts.length
      if (idx != undefined && !alwaysAddNew) {
        state.saleProducts[idx] = {
          ...state.saleProducts[idx],
          quantity:
            state.saleProducts[idx].quantity + (state.addProductQuantity ?? 0),
          tintColorId: tintColor?.id,
          customTintColor,
          notes: notes || state.saleProducts[idx].notes,
          isActive: true,
        }
      } else {
        state.saleProducts = [
          ...(state.saleProducts ?? []),
          newSaleProductV2FromProduct({
            addProductQuantity: state.addProductQuantity ?? 0,
            customTintColor,
            notes:
              specialOrderProductNotes ||
              notes ||
              searchProduct?.templateSaleProductNotes ||
              "",
            position: lastPositionV2 + 1,
            product: state.searchProduct?.product,
            additionalFee: state.searchProduct?.additionalFee,
            tintColor,
            tier1Price: retailPrice,
            price,
            cost,
            deposit,
          }),
        ]
      }

      state.productsById = {
        ...state.productsById,
        [searchProductIDString]: searchProduct,
      }

      if (state.searchProduct?.inventories) {
        state.inventoriesByProductId = {
          ...state.inventoriesByProductId,
          [searchProductIDString]: state.searchProduct.inventories,
        }
      }

      if (state.searchProduct?.catalogProduct) {
        state.catalogProductsById = {
          ...state.catalogProductsById,
          [searchProductIDString]: state.searchProduct.catalogProduct,
        }
      }

      if (tintColor?.id) {
        state.tintColorsById = {
          ...state.tintColorsById,
          [UUID.fromPB(tintColor.id).toString()]: tintColor,
        }
      }
      state.addProductQuantity = 1
      delete state.searchProduct

      state.scrollToBottom = true
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    addColorxTintedProductsToCartV2: (
      state,
      action: PayloadAction<{
        colorxProducts: ColorxTintedProductsResult[]
      }>
    ) => {
      if (!action?.payload?.colorxProducts?.length) {
        return
      }

      if (!state.saleProducts) {
        state.saleProducts = []
      }

      if (!state.productsById) {
        state.productsById = {}
      }

      if (!state.inventoriesByProductId) {
        state.inventoriesByProductId = {}
      }

      if (!state.catalogProductsById) {
        state.catalogProductsById = {}
      }

      for (const colorxProduct of action.payload.colorxProducts) {
        const {
          colorxIdentifier,
          product,
          catalogProduct,
          tintColor,
          customTintColor,
          notes: formula,
          additionalFee,
        } = colorxProduct
        if (!product || !product.id) {
          continue
        }
        let notes = colorxIdentifier
        if (formula) {
          notes += `: ${formula}`
        }

        const idx = getIdxFromSaleProductsForUniqueTint(state.saleProducts, {
          productId: product.id,
          tintColorId: tintColor?.id,
          customTintColor,
        })

        const alwaysAddNew = product.isSpecialOrderable
        const lastPosition: number = state.saleProducts.length ?? 0
        if (idx != undefined && !alwaysAddNew) {
          state.saleProducts[idx] = {
            ...state.saleProducts[idx],
            quantity:
              (state.saleProducts[idx]?.quantity ?? 0) +
              Number(colorxProduct.quantity),
            tintColorId: tintColor?.id ?? state.saleProducts[idx]?.tintColorId,
            customTintColor,
            notes: notes || state.saleProducts[idx].notes,
            colorxTintedProductId: colorxProduct.colorxTintedProductId,
            isActive: true,
          }
        } else {
          state.saleProducts = [
            ...(state.saleProducts ?? []),
            newSaleProductV2FromProduct({
              addProductQuantity: Number(colorxProduct.quantity),
              customTintColor,
              notes,
              position: lastPosition + 1,
              product,
              additionalFee,
              tintColor,
              colorxTintedProductId: colorxProduct.colorxTintedProductId,
            }),
          ]
        }

        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(product.id).toString()]: product,
        }

        if (colorxProduct?.inventories && colorxProduct?.product?.id) {
          state.inventoriesByProductId = {
            ...state.inventoriesByProductId,
            [UUID.fromPB(colorxProduct.product.id).toString()]:
              colorxProduct.inventories,
          }
        }

        if (tintColor?.id) {
          state.tintColorsById = {
            ...state.tintColorsById,
            [UUID.fromPB(tintColor.id).toString()]: tintColor,
          }
        }

        if (catalogProduct) {
          state.catalogProductsById = {
            ...state.catalogProductsById,
            [UUID.fromPB(product.id).toString()]: catalogProduct,
          }
        }
      }

      state.scrollToBottom = true
      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setProductTintAndNotesV2: (
      state,
      action: PayloadAction<{
        productToEdit: SaleProduct
        tintColor?: TintColor
        customTintColor?: string
        notes?: string
      }>
    ) => {
      const {
        productToEdit,
        tintColor,
        customTintColor = "",
        notes = "",
      } = action.payload
      if (
        !productToEdit.id ||
        !state.saleProducts ||
        (tintColor && customTintColor)
      ) {
        return
      }

      const productIdx = findIdxInSaleProducts(
        state.saleProducts,
        productToEdit.id
      )

      if (productIdx === -1) {
        return
      }

      state.saleProducts[productIdx] = {
        ...state.saleProducts[productIdx],
        notes,
        tintColorId: tintColor?.id,
        customTintColor,
      }

      if (tintColor?.id) {
        state.tintColorsById = {
          ...state.tintColorsById,
          [UUID.fromPB(tintColor.id).toString()]: tintColor,
        }
      }

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    paymentSelected(
      state,
      action: PayloadAction<"charge_account" | "check" | "card" | "cash">
    ) {
      switch (action.payload) {
        case "charge_account": {
          state.payment = {
            drawer: "charge_account",
          }
          state.paymentMethod = PaymentMethod.UNDEFINED
          break
        }
        case "cash": {
          state.payment = {
            drawer: "cash",
            cashReceived: Money.zero(),
          }
          state.paymentMethod = PaymentMethod.CASH
          break
        }
        case "check": {
          state.payment = {
            drawer: "check",
            checkNumber: "",
          }
          state.paymentMethod = PaymentMethod.CHECK
          break
        }
        case "card": {
          state.payment = createPaymentCardDefaults()
          if (state.customerCards && state.customerCards.length > 0) {
            state.payment.card = state.customerCards[0]
            state.paymentMethod = PaymentMethod.CARD_SAVED
          } else {
            state.paymentMethod = PaymentMethod.CARD_PRESENT
          }
          break
        }
        default: {
          throw new Error("unexpected payment method")
        }
      }

      state.drawerEdited = true
    },
    setCheckNumber(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "check") {
        return
      }
      state.paymentMethod = PaymentMethod.CHECK
      state.payment.checkNumber = action.payload
    },
    savedCardSelected(state, action: PayloadAction<Card>) {
      if (state.payment?.drawer !== "card") {
        return
      }
      state.paymentMethod = PaymentMethod.CARD_SAVED
      state.payment = {
        ...state.payment,
        card: action.payload,
      }
      state.drawerEdited = true
    },
    cardPresentSelected(state) {
      if (state.payment?.drawer !== "card") {
        return
      }
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment = {
        ...state.payment,
      }
      state.drawerEdited = true
    },
    toggleShouldSaveReadCard(state) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment.shouldSaveCardPresent = !state.payment.shouldSaveCardPresent
    },
    setCardPresentCardNickname(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment.cardPresentCardNickname = action.payload
    },
    manuallyEnterCardSelected(state) {
      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.drawerEdited = true
    },
    setManuallyEnteredCardNumber(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.number = action.payload
    },
    setManuallyEnteredCardExp(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.exp = action.payload
    },
    setManuallyEnteredCardCVC(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.cvc = action.payload
    },
    setManuallyEnteredCardZIP(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.zip = action.payload
    },
    toggleShouldSaveManuallyEnteredCard(state) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.shouldSaveCardManuallyEntered =
        !state.payment.shouldSaveCardManuallyEntered
    },
    setManuallyEnteredCardNickname(state, action: PayloadAction<string>) {
      if (state.payment?.drawer !== "card") {
        return
      }

      state.paymentMethod = PaymentMethod.CARD_MANUALLY_ENTERED
      state.payment.manuallyEnteredCardNickname = action.payload
    },
    setCashReceived(state, action: PayloadAction<Money>) {
      if (state.payment?.drawer !== "cash") {
        return
      }

      state.payment.cashReceived = action.payload
    },
    resetCartTotals: (state) => {
      delete state.cartTotals

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.scrollToBottom = action.payload
    },
    resetSearchProductSelected: (state) => {
      delete state.searchProduct

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    setPaymentAmount(state, action: PayloadAction<Money>) {
      if (action.payload.lte(Money.zero())) {
        return
      }

      const surchargePaid = calculateSurchargeFromPayments(
        state.splitPayments ?? []
      )
      const saleTotal = Money.fromPB(state.cartTotals?.total).add(surchargePaid)
      const amountReadyToPay = sumPayments(state.splitPayments)
      const leftToPay = saleTotal.sub(amountReadyToPay)

      if (action.payload.gt(leftToPay)) {
        state.paymentAmount = leftToPay
      } else {
        state.paymentAmount = action.payload
      }
    },
    addSplitPaymentAndClearCurrent: (
      state,
      action: PayloadAction<PaymentMetadata>
    ) => {
      if (action.payload.paymentMethod === PaymentMethod.UNDEFINED) {
        return
      }

      if (
        !action.payload.amount ||
        Money.fromPB(action.payload.amount).lte(Money.zero())
      ) {
        return
      }

      const updatedPayments = [...(state.splitPayments ?? []), action.payload]

      const surchargePaid = calculateSurchargeFromPayments(updatedPayments)
      const saleTotal = Money.fromPB(state.cartTotals?.total).add(surchargePaid)
      const amountReadyToPay = sumPayments(updatedPayments)

      const isOverPaying = saleTotal.lt(amountReadyToPay)
      if (isOverPaying) {
        return
      }

      state.splitPayments = updatedPayments
      state.payment = undefined
      state.paymentMethod = undefined
      state.paymentAmount = undefined
      state.drawerEdited = false
    },
    toggleIsSplitPayment(state) {
      if (state.paymentMethod == PaymentMethod.UNDEFINED) {
        state.payment = createPaymentCardDefaults()
        if (state.customerCards && state.customerCards.length > 0) {
          state.payment.card = state.customerCards[0]
          state.paymentMethod = PaymentMethod.CARD_SAVED
        } else {
          state.paymentMethod = PaymentMethod.CARD_PRESENT
        }
      }

      state.isSplitPayment = !state.isSplitPayment

      state.paymentAmount = undefined
      state.splitPayments = []
    },
    resetPayments(state) {
      state.isSplitPayment = false
      state.paymentMethod = PaymentMethod.CARD_PRESENT
      state.payment = createPaymentCardDefaults()
      state.paymentAmount = undefined
      state.splitPayments = []
      state.drawerEdited = false
    },
    setTaxRateId(state, action: PayloadAction<PBUUID>) {
      state.taxRateId = action.payload

      state.isEdited = checkIsEdited(state as SaleFormState)
    },
    customerLoaded(state, action: PayloadAction<Customer | undefined>) {
      state.defaultTaxRateId = action.payload?.taxRateId
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      salesApiSlice.endpoints.syncCartPrices.matchFulfilled,
      (state, { payload }) => {
        state.cartTotals = payload
      }
    )
  },
})

export const {
  setIsInitializing,
  setSaleInitialState,
  resetSaleForm,
  setQuoteExpiration,
  setInitialQuoteExpiration,
  customerSelected,
  customerCardsSelected,
  resetCustomer,
  removeProductV2,
  setAddProductQuantity,
  setProductQuantityV2,
  setAddress,
  setPurchaseOrder,
  setCustomerContact,
  setSaleNotes,
  setInternalSaleNotes,
  setProductNotesV2,
  searchProductSubmitted,
  searchProductSelected,
  resetSearchProductSelected,
  setProductTintAndNotesV2,
  setProductPriceV2,
  setProductCostV2,
  paymentSelected,
  setCheckNumber,
  savedCardSelected,
  cardPresentSelected,
  toggleShouldSaveReadCard,
  setCardPresentCardNickname,
  manuallyEnterCardSelected,
  setManuallyEnteredCardNumber,
  setManuallyEnteredCardExp,
  setManuallyEnteredCardCVC,
  setManuallyEnteredCardZIP,
  toggleShouldSaveManuallyEnteredCard,
  setManuallyEnteredCardNickname,
  setCashReceived,
  resetCartTotals,
  incrementReturnQuantities,
  setSpecialOrderProductFields,

  setScrollToBottom,
  addSplitPaymentAndClearCurrent,
  setPaymentAmount,
  toggleIsSplitPayment,
  resetPayments,
  addColorxTintedProductsToCartV2,
  setTaxRateId,
  customerLoaded,
} = salesSlice.actions

export const selectIsEdited = (state: AppState) => !!state.sales.isEdited
export const selectIsInitializing = (state: AppState) =>
  state.sales.isInitializing

export const selectCustomerId = (state: AppState) => state.sales.customerId
export const selectPurchaseOrder = (state: AppState) =>
  state.sales.purchaseOrder
export const selectAddress = (state: AppState) => state.sales.address
export const selectCustomerContact = (state: AppState) =>
  state.sales.customerContact
export const selectSaleNotes = (state: AppState) => state.sales.saleNotes
export const selectInternalSaleNotes = (state: AppState) =>
  state.sales.internalSaleNotes
export const selectSaleProducts = (state: AppState) =>
  state.sales.saleProducts || []
export const selectHasSaleProducts = (state: AppState) =>
  !!state.sales.saleProducts && state.sales.saleProducts.length > 0
export const selectHasSpecialOrderProduct = (state: AppState) =>
  (!!state.sales.saleProducts &&
    state.sales.saleProducts.some((sp) => sp.isSpecialOrder)) ??
  false
export const selectQuoteExpiration = (state: AppState) =>
  state.sales.quoteExpiration
export const selectLocation = (state: AppState) => state.sales.location
export const selectProductsById = (state: AppState) => state.sales.productsById
export const selectCatalogProductsById = (state: AppState) =>
  state.sales.catalogProductsById
export const selectTintColorsById = (state: AppState) =>
  state.sales.tintColorsById
export const selectAmountRefunded = (state: AppState) =>
  state.sales.amountRefunded
export const selectPaymentMethod = (state: AppState) =>
  state.sales.paymentMethod
export const selectCheckNumber = (state: AppState) =>
  state.sales.payment?.drawer === "check" ? state.sales.payment.checkNumber : ""
export const selectCashReceived = createSelector(
  (state: AppState) => state.sales.payment,
  (payment) =>
    payment?.drawer === "cash" ? payment.cashReceived : Money.zero()
)
export const selectSavedCard = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.card : null
export const selectCards = (state: AppState) => state.sales.customerCards ?? []
export const selectManuallyEnteredCardNumber = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.number : ""
export const selectManuallyEnteredCardExp = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.exp : ""
export const selectManuallyEnteredCardCVC = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.cvc : ""
export const selectManuallyEnteredCardZIP = (state: AppState) =>
  state.sales.payment?.drawer === "card" ? state.sales.payment.zip : ""
export const selectShouldSaveManuallyEnteredCard = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.shouldSaveCardManuallyEntered
    : false
export const selectManuallyEnteredCardNickname = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.manuallyEnteredCardNickname
    : ""
export const selectShouldSaveCardPresent = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.shouldSaveCardPresent
    : false
export const selectCardPresentCardNickname = (state: AppState) =>
  state.sales.payment?.drawer === "card"
    ? state.sales.payment.cardPresentCardNickname
    : ""
export const selectCartTotals = (state: AppState) => state.sales.cartTotals
export const selectPayment = (state: AppState) => state.sales.payment
export const selectSplitPayments = createSelector(
  (state: AppState) => state.sales.splitPayments,
  (splitPayments) => splitPayments ?? []
)
export const selectSplitTotal = createSelector(
  (state: AppState) => state.sales.splitPayments,
  (splitPayments) => sumPayments(splitPayments)
)
export const selectIsSplitPayment = (state: AppState) =>
  state.sales.isSplitPayment
export const selectPaymentDrawer = (state: AppState) =>
  state.sales.payment?.drawer
export const selectPaymentAmount = createSelector(
  (state: AppState) => state.sales.paymentAmount,
  (paymentAmount) => (paymentAmount as Money) ?? Money.zero()
)
export const selectScrollToBottom = (state: AppState) =>
  state.sales.scrollToBottom
export const selectSearchProduct = (state: AppState) =>
  state.sales.searchProduct
export const selectAddProductQuantity = (state: AppState) =>
  state.sales.addProductQuantity
export const selectSaleInventoriesByProductId = (state: AppState) =>
  state.sales.inventoriesByProductId
export const selectTaxRateId = (state: AppState) =>
  state.sales.taxRateId || state.sales.defaultTaxRateId
export const selectCartTaxRate = createSelector(
  (state: AppState) => state.sales.cartTotals,
  (cartTotals) =>
    cartTotals?.taxRate?.rate
      ? Decimal.fromPB(cartTotals.taxRate.rate)
      : Decimal.zero()
)
export const selectCardSurchargeRate = createSelector(
  (state: AppState) => state.sales.cartTotals?.cardSurchargeRate,
  (cardSurchargeRate) =>
    cardSurchargeRate ? Decimal.fromPB(cardSurchargeRate) : Decimal.zero()
)
export const selectIsCardPayment = (state: AppState) =>
  state.sales.paymentMethod !== undefined &&
  [
    PaymentMethod.CARD_MANUALLY_ENTERED,
    PaymentMethod.CARD_PRESENT,
    PaymentMethod.CARD_SAVED,
  ].includes(state.sales.paymentMethod)
export const selectSplitTotalSurcharge = createSelector(
  (state: AppState) => state.sales.splitPayments,
  (splitPayments) => calculateSurchargeFromPayments(splitPayments ?? [])
)
export const selectCurrentAmount = (state: AppState) =>
  state.sales.isSplitPayment
    ? state.sales.paymentAmount?.toPB()
    : state.sales.cartTotals?.totalDueToday
export const selectIdempotencyKey = (state: AppState) =>
  state.sales.idempotencyKey

function newSaleProductV2FromProduct(args: {
  addProductQuantity: number
  customTintColor: string
  notes: string
  position: number
  product?: Product
  additionalFee?: AdditionalFee
  tintColor?: TintColor
  colorxTintedProductId?: PBUUID
  tier1Price?: Money
  price?: Money
  cost?: FractionalMoney
  deposit?: Money
}): SaleProduct {
  const {
    addProductQuantity,
    customTintColor,
    notes,
    position,
    product,
    additionalFee,
    tintColor,
    colorxTintedProductId,
    tier1Price,
    price,
    cost,
    deposit,
  } = args
  const sp: SaleProduct = SaleProductView.create({
    id: UUID.newRandom().toPB(),
    isActive: true,
    isCostEdited: false,

    productId: product?.id,
    quantity: addProductQuantity,
    quantityReturned: 0,
    additionalFeeName: additionalFee?.name ?? "",
    additionalFeeV2: additionalFee?.fee
      ? FractionalMoney.fromMoney(Money.fromPB(additionalFee.fee)).toPB()
      : undefined,
    customTintColor,
    tintColorId: tintColor?.id,
    notes,
    isPriceEdited: !!price,
    position,
    isFeeTaxed: !!additionalFee?.isTaxable,
    isTaxed: product?.taxStatus !== Product_TaxStatus.NON_TAXABLE,
    colorxTintedProductId,
    tier1Price,
    price,
    costV2: cost && cost.toPB(),
    deposit,
    isSpecialOrder: product?.isSpecialOrderable,
  })
  return sp
}
