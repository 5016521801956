import { CopyableField } from "components/CopyableField/CopyableField"
import { Step } from "./Step"
import { supportNumber } from "lib/constants"
import { useGetCompanyForCurrentSubdomain } from "./useGetCompanyForCurrentSubdomain"

export function SignInCard() {
  return (
    <div className="surface-primary mx-auto flex w-full max-w-sm flex-col items-stretch gap-y-4 rounded p-6">
      <Logo />
      <h1 className="text-primary w-full text-left font-semibold">Sign in</h1>
      <Step />
      <span className="text-secondary text-center text-xs">
        Call <SupportPhoneLink /> for support
      </span>
    </div>
  )
}

function Logo() {
  const { data: company } = useGetCompanyForCurrentSubdomain()
  const logoURL = company?.logoImageUrl ?? ""

  return (
    <div className="relative h-32 w-full">
      {logoURL && (
        <img
          src={logoURL}
          alt="logo"
          className="h-full w-full object-contain"
        />
      )}
    </div>
  )
}

function SupportPhoneLink() {
  return (
    <CopyableField
      className="text-accent-primary mx-1 inline-flex"
      value={supportNumber()}
      copyLabel="Support number"
    >
      <a href={`tel:${supportNumber("E.164")}`} className="text-accent-primary">
        {supportNumber()}
      </a>
    </CopyableField>
  )
}
