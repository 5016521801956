import { Address as PBAddress } from "gen/proto/address/models_pb"

export class Address {
  private address: PBAddress

  private constructor(pbAddress: PBAddress) {
    this.address = pbAddress
  }

  static fromPB(pbAddress: PBAddress): Address {
    return new Address(pbAddress)
  }

  get format(): string {
    if (this.address.line1 === "") {
      return ""
    }

    if (this.address.line2 === "") {
      return `${this.address.line1}\n${this.address.city}, ${this.address.state} ${this.address.postalCode}`
    }

    return `${this.address.line1} ${this.address.line2}\n${this.address.city}, ${this.address.state} ${this.address.postalCode}`
  }

  get isValid(): boolean {
    return (
      !!this.address.line1 &&
      !!this.address.city &&
      !!this.address.state &&
      !!this.address.postalCode
    )
  }

  // eqFromPB is a helper function to check if two possibly undefined PBAddresses are equal
  static eqFromPB(addressA?: PBAddress, addressB?: PBAddress): boolean {
    if (!addressA && !addressB) {
      return true
    }

    if (!addressA) {
      return false
    } else if (!addressB) {
      return false
    } else {
      return this.fromPB(addressA).eq(addressB)
    }
  }

  eq(other: PBAddress): boolean {
    return (
      this.address.line1 === other.line1 &&
      this.address.line2 === other.line2 &&
      this.address.city === other.city &&
      this.address.state === other.state &&
      this.address.postalCode === other.postalCode
    )
  }
}
