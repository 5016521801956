import { PropsWithChildren } from "react"
import { ReactNode } from "react"

export type LabelProps = {
  label?: ReactNode
  inputId?: string | undefined
  subLabel?: ReactNode[]
  secondaryLabel?: string
  errorMessage?: string
  containerClassName?: string
}

export function Label(props: PropsWithChildren<LabelProps>) {
  const {
    label,
    subLabel,
    secondaryLabel,
    errorMessage,
    inputId,
    containerClassName,
    children,
  } = props

  return (
    <div className={containerClassName}>
      {label && (
        <div className="mb-1 block">
          <label
            htmlFor={inputId}
            className="text-primary text-sm font-semibold"
          >
            {label}
          </label>
          {!!secondaryLabel && (
            <label className="text-secondary ml-1 text-xs font-normal">
              {secondaryLabel}
            </label>
          )}
        </div>
      )}
      {subLabel}
      {children}
      {!!errorMessage && (
        <label
          htmlFor={inputId}
          className="text-accent-error-light dark:text-accent-error-dark mt-1 block text-xs"
        >
          {errorMessage}
        </label>
      )}
    </div>
  )
}
