import { Order_Status as OrderStatus } from "gen/txn/models_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { cleanStatus } from "./cleanStatus"

export function order(id?: PBUUID, status?: OrderStatus) {
  if (!id) {
    return `/pos/purchase-orders/new`
  }
  const statusParam = orderStatusParam(status)
  if (!statusParam) {
    return `/pos/purchase-orders/${UUID.idString(id)}`
  }
  return `/pos/purchase-orders/${UUID.idString(id)}?status=${statusParam}`
}

export function orderStatusParam(status?: OrderStatus): string {
  if (!status) {
    return ""
  }
  return cleanStatus(OrderStatus[status])
}
