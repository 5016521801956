import { HeadTitle } from "components/Head/head.types"
import NextHead from "next/head"

export function RundooHead({ headTitle }: { headTitle: HeadTitle }) {
  return (
    <NextHead>
      <title>{headTitle}</title>
      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  )
}
