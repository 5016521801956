import {
  ComponentPropsWithoutRef,
  ComponentType,
  MouseEventHandler,
  SVGProps,
  forwardRef,
} from "react"

import ButtonLoadingSpinner from "./ButtonLoadingSpinner"
import { classNames } from "lib/classNames"

export const ButtonMode = {
  Primary: "primary",
  PrimaryDarkBackground: "primaryDarkBackground",
  Secondary: "secondary",
  Positive: "positive",
  PositiveDarkBackground: "positiveDarkBackground",
  Negative: "negative",
  Destructive: "destructive",
  SelectedTab: "selectedTab",
  Tab: "tab",
  Input: "input",
} as const
export type ButtonMode = (typeof ButtonMode)[keyof typeof ButtonMode]

export const ButtonSize = {
  Default: "default",
  Small: "small",
} as const
export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize]

export const ButtonFontWeight = {
  Semibold: "semibold",
  Normal: "normal",
} as const
export type ButtonFontWeight =
  (typeof ButtonFontWeight)[keyof typeof ButtonFontWeight]

export type ButtonIconType = ComponentType<SVGProps<SVGSVGElement>>

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  mode: ButtonMode
  size?: ButtonSize
  fontWeight?: ButtonFontWeight
  loading?: boolean
  leftIcon?: ButtonIconType
  rightIcon?: ButtonIconType
  disableHover?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      mode,
      size = ButtonSize.Default,
      fontWeight = ButtonFontWeight.Semibold,
      loading = false,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      onClick,
      disableHover,
      ...otherProps
    } = props

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.stopPropagation()

      if (loading) {
        return
      }

      if (onClick) {
        onClick(event)
      }
    }

    const hasIcon = LeftIcon !== undefined || RightIcon !== undefined

    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          "group relative",
          "inline-flex items-center justify-center",
          "rounded",
          "whitespace-nowrap",
          classNamesForSize(size),
          classNamesForMode(mode, loading, disableHover ?? false),
          classNamesForFontWeight(fontWeight),
          className
        )}
        onClick={handleClick}
        {...otherProps}
      >
        <ButtonLoadingSpinner size={size} loading={loading} />
        <div
          className={classNames(
            loading && "invisible",
            hasIcon && "inline-flex items-center gap-x-1.5"
          )}
        >
          {LeftIcon && <LeftIcon className={iconClassNames(size, mode)} />}
          {children}
          {RightIcon && <RightIcon className={iconClassNames(size, mode)} />}
        </div>
      </button>
    )
  }
)

function classNamesForMode(
  mode: ButtonMode,
  loading: boolean,
  disableHover: boolean
): string {
  const primaryDisabledClassName = classNames(
    "disabled:bg-gray-300 dark:disabled:bg-gray-600",
    "disabled:text-gray-500 dark:disabled:text-gray-400",
    "disabled:bg-gray-300 dark:disabled:bg-gray-600"
  )

  const secondaryDisabledClassName = classNames(
    "disabled:bg-white dark:disabled:bg-gray-800",
    "disabled:text-gray-500 dark:disabled:text-gray-400",
    "disabled:border-gray-300 dark:disabled:border-gray-600"
  )

  switch (mode) {
    case ButtonMode.Primary: {
      return classNames(
        "text-white",
        "bg-cobalt-400 dark:bg-cobalt-300",
        !disableHover && "hover:bg-cobalt-300 dark:hover:bg-cobalt-200",
        "active:bg-cobalt-500 dark:active:bg-cobalt-400",
        !loading && primaryDisabledClassName
      )
    }
    case ButtonMode.PrimaryDarkBackground: {
      return classNames(
        "text-white",
        "bg-cobalt-300",
        !disableHover && "hover:bg-cobalt-200",
        "active:bg-cobalt-400",
        !loading && classNames("disabled:bg-gray-600", "disabled:text-gray-400")
      )
    }
    case ButtonMode.Secondary: {
      return classNames(
        "text-primary",
        "surface-primary",
        "border border-gray-300 dark:border-gray-600",
        !disableHover && "hover:bg-gray-100 dark:hover:bg-gray-700",
        "active:bg-gray-200 dark:active:bg-gray-600",
        !loading && secondaryDisabledClassName
      )
    }
    case ButtonMode.Negative: {
      return classNames(
        "text-red-500 dark:text-red-400",
        "surface-primary",
        "border border-red-500 dark:border-red-400",
        !disableHover && "hover:bg-red-50 dark:hover:bg-red-800",
        "active:bg-red-100 dark:active:bg-red-900",
        !loading && secondaryDisabledClassName
      )
    }
    case ButtonMode.Positive: {
      return classNames(
        "text-accent-primary",
        "surface-primary",
        "border border-cobalt-400 dark:border-cobalt-200",
        !disableHover && "hover:bg-cobalt-50 dark:hover:bg-cobalt-700",
        "active:bg-cobalt-100 dark:active:bg-cobalt-600",
        !loading && secondaryDisabledClassName
      )
    }
    case ButtonMode.PositiveDarkBackground: {
      return classNames(
        "text-cobalt-200",
        "bg-gray-800",
        "border border-cobalt-200",
        !disableHover && "hover:bg-cobalt-700",
        "active:bg-cobalt-600",
        !loading &&
          classNames("disabled:text-gray-400", "disabled:border-gray-600")
      )
    }
    case ButtonMode.Destructive: {
      return classNames(
        "text-white",
        "bg-red-600 dark:bg-red-500",
        !disableHover && "hover:bg-red-500 dark:hover:bg-red-400",
        "active:bg-red-700 dark:active:bg-red-600",
        !loading && primaryDisabledClassName
      )
    }
    case ButtonMode.SelectedTab: {
      return classNames(
        "text-cobalt-400 dark:text-cobalt-700",
        "bg-cobalt-100 dark:bg-cobalt-200",
        !disableHover && "hover:bg-cobalt-200 dark:hover:bg-cobalt-100",
        "active:bg-cobalt-200 dark:active:bg-cobalt-100",
        !loading &&
          classNames(
            "disabled:bg-gray-300 dark:disabled:bg-gray-600",
            "disabled:text-gray-600 dark:disabled:text-gray-300"
          )
      )
    }
    case ButtonMode.Tab: {
      return classNames(
        "text-secondary",
        !disableHover && "hover:bg-cobalt-200 dark:hover:bg-cobalt-100",
        !disableHover && "hover:text-cobalt-400 dark:hover:text-cobalt-700",
        !loading &&
          classNames("disabled:hover:bg-white", "disabled:hover:text-gray-600")
      )
    }
    case ButtonMode.Input: {
      return classNames(
        "text-secondary",
        "surface-primary",
        "border border-primary",
        !disableHover && "hover:surface-accent"
      )
    }
    default: {
      throw new Error("unexpected button mode!")
    }
  }
}

function classNamesForSize(size: ButtonSize): string {
  if (size === ButtonSize.Small) {
    return classNames("h-7", "px-2 py-[0.3125rem]", "text-xs")
  }
  return classNames("h-10", "px-4 py-2.5", "text-sm")
}

function iconClassNames(size: ButtonSize, mode: ButtonMode): string {
  if (size === ButtonSize.Small) {
    return classNames(
      "h-4 w-4",
      mode === ButtonMode.Secondary && "text-gray-500 dark:text-gray-400"
    )
  }
  return "h-5 w-5"
}

function classNamesForFontWeight(fontWeight: ButtonFontWeight): string {
  if (fontWeight === ButtonFontWeight.Normal) {
    return "font-normal"
  }
  return "font-semibold"
}

Button.displayName = "Button"
