import { ButtonProps, ButtonSize } from "./Button"
import { LoadingSpinner, LoadingSpinnerSize } from "components/LoadingSpinner"

type ButtonLoadingSpinnerProps = Pick<Required<ButtonProps>, "size" | "loading">

function ButtonLoadingSpinner({ size, loading }: ButtonLoadingSpinnerProps) {
  if (!loading) return null

  return (
    <LoadingSpinner
      size={spinnerSize(size)}
      pathClassName="opacity-50"
      className="absolute"
    />
  )
}

function spinnerSize(size: ButtonSize): LoadingSpinnerSize {
  if (size === ButtonSize.Small) {
    return LoadingSpinnerSize.ExtraSmall
  }
  return LoadingSpinnerSize.Small
}

export default ButtonLoadingSpinner
