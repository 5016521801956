import {
  BulkExportCustomPriceRulesReq,
  BulkExportCustomPriceRulesRes,
  BulkExportCustomerContactsReq,
  BulkExportCustomerContactsRes,
  BulkExportCustomersReq,
  BulkExportCustomersRes,
  BulkExportInventoryCountProductsReq,
  BulkExportInventoryCountProductsRes,
  BulkExportProductLabelsReq,
  BulkExportProductLabelsRes,
  BulkExportProductsReq,
  BulkExportProductsRes,
  BulkExportVendorsReq,
  BulkExportVendorsRes,
  GetAccountPaymentAdjustmentsCSVReq,
  GetAccountPaymentAdjustmentsPDFReq,
  GetAccountPaymentAdjustmentsReq,
  GetAdditionalFeesCSVReq,
  GetAdditionalFeesPDFReq,
  GetAdditionalFeesReq,
  GetAgingByJobCSVReq,
  GetAgingByJobPDFReq,
  GetAgingByJobReq,
  GetAgingDetailCSVReq,
  GetAgingDetailPDFReq,
  GetAgingDetailReq,
  GetCashRegisterCSVReq,
  GetCashRegisterPDFReq,
  GetCashRegisterReq,
  GetInteractionsByStaffCSVReq,
  GetInteractionsByStaffPDFReq,
  GetInteractionsByStaffReq,
  GetInteractionsCSVReq,
  GetInteractionsPDFReq,
  GetInteractionsReq,
  GetInventoryByDepartmentCSVReq,
  GetInventoryByDepartmentPDFReq,
  GetInventoryByDepartmentReq,
  GetInventoryByProductCSVReq,
  GetInventoryByProductPDFReq,
  GetInventoryByProductReq,
  GetNewAccountsOpenedCSVReq,
  GetNewAccountsOpenedPDFReq,
  GetNewAccountsOpenedReq,
  GetPaymentsCSVReq,
  GetPaymentsPDFReq,
  GetPaymentsReq,
  GetPriceEditsCSVReq,
  GetPriceEditsPDFReq,
  GetPriceEditsReq,
  GetProfitAndLossPDFReq,
  GetProfitAndLossReq,
  GetProfitAndLossRes,
  GetPurchaseJournalCSVReq,
  GetPurchaseJournalPDFReq,
  GetPurchaseJournalReq,
  GetSalesByCustomerCSVReq,
  GetSalesByCustomerPDFReq,
  GetSalesByCustomerReq,
  GetSalesByJobCSVReq,
  GetSalesByJobPDFReq,
  GetSalesByJobReq,
  GetSalesByLocationCSVReq,
  GetSalesByLocationPDFReq,
  GetSalesByLocationReq,
  GetSalesByProductCSVReq,
  GetSalesByProductPDFReq,
  GetSalesByProductReq,
  GetSalesByRepCSVReq,
  GetSalesByRepPDFReq,
  GetSalesByRepReq,
  GetSalesCSVReq,
  GetSalesPDFReq,
  GetSalesReq,
  GetSoldProductsCSVReq,
  GetSoldProductsPDFReq,
  GetSoldProductsReq,
  GetTransferDiscrepanciesCSVReq,
  GetTransferDiscrepanciesPDFReq,
  GetTransferDiscrepanciesReq,
  SearchBillPaymentsRes_Result as SearchBillPaymentResult,
  SearchBillPaymentsReq,
  SearchBillPaymentsRes,
  SearchCashTransactionsRes_Result as SearchCashTransactionResult,
  SearchCashTransactionsReq,
  SearchCashTransactionsRes,
  SearchCustomPriceRulesReq,
  SearchCustomPriceRulesRes,
  SearchCustomPriceRulesRes_Result as SearchCustomPriceRulesResult,
  SearchCustomerContactsReq,
  SearchCustomerContactsRes,
  SearchCustomerContactsRes_Result,
  SearchCustomersV2Req,
  SearchCustomersV2Res,
  SearchCustomersV2Res_Result as SearchCustomersV2Result,
  SearchExcludedInventoryCountProductsReq,
  SearchExcludedInventoryCountProductsRes,
  SearchExcludedInventoryCountProductsRes_Result as SearchExcludedInventoryCountProductsResult,
  SearchExternalTransactionsReq,
  SearchExternalTransactionsRes,
  SearchExternalTransactionsRes_Result as SearchExternalTransactionsResult,
  SearchFinanceChargesReq,
  SearchFinanceChargesRes,
  SearchFinanceChargesRes_Result as SearchFinanceChargesResult,
  SearchFinancialTransactionsReq,
  SearchFinancialTransactionsRes,
  SearchFinancialTransactionsRes_Result as SearchFinancialTransactionsResult,
  SearchInventoryChangesReq,
  SearchInventoryChangesRes,
  SearchInventoryChangesRes_Result as SearchInventoryChangesResult,
  SearchInventoryCountProductsReq,
  SearchInventoryCountProductsRes,
  SearchJobsReq,
  SearchJobsRes,
  SearchJobsRes_Result as SearchJobsResult,
  SearchOrderShipmentsForReconciliationReq,
  SearchOrderShipmentsForReconciliationRes,
  SearchOrderShipmentsForReconciliationRes_Result as SearchOrderShipmentsForReconciliationResult,
  SearchOrderShipmentsReq,
  SearchOrderShipmentsRes,
  SearchOrderShipmentsRes_Result as SearchOrderShipmentsResult,
  SearchOrdersReq,
  SearchOrdersRes,
  SearchOrdersRes_Result as SearchOrdersResult,
  SearchOutstandingSalesReq,
  SearchOutstandingSalesRes,
  SearchOutstandingSalesRes_Result as SearchOutstandingSalesResult,
  SearchPriceTierRulesReq,
  SearchPriceTierRulesRes,
  SearchPriceTierRulesRes_Result as SearchPriceTierRulesResult,
  SearchProductsV2Req,
  SearchProductsV2Res,
  SearchProductsV2Res_Result as SearchProductsV2Result,
  SearchRefundedBillPaymentsRes_Result as SearchRefundedBillPaymentResult,
  SearchRefundedBillPaymentsReq,
  SearchRefundedBillPaymentsRes,
  SearchReturnsReq,
  SearchReturnsRes,
  SearchReturnsRes_Result as SearchReturnsResult,
  SearchSalesV2Req,
  SearchSalesV2Res,
  SearchSalesV2Res_Result as SearchSalesV2Result,
  SearchStagedProductsToOrderReq,
  SearchStagedProductsToOrderRes,
  SearchStagedProductsToOrderRes_Result,
  SearchTagsReq,
  SearchTagsRes,
  SearchTagsRes_Result,
  SearchTintColorsReq,
  SearchTintColorsRes,
  SearchTintColorsRes_Result as SearchTintColorsResult,
  SearchTransfersReq,
  SearchTransfersRes,
  SearchTransfersRes_Result as SearchTransfersResult,
  SearchVendorsReq,
  SearchVendorsRes,
  SearchVendorsRes_Result as SearchVendorsResult,
} from "gen/search/service/service_pb"
import {
  BulkServiceClient,
  SearchServiceClient,
  SearchalyticsServiceClient,
} from "gen/search/service/service_pb.client"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useDeferredValue,
  useMemo,
  useState,
} from "react"
import {
  GetCustomerAgingCSVReq,
  GetCustomerAgingPDFReq,
  GetCustomerAgingReq,
  GetCustomerAgingRes,
  GetSalesByDayCSVReq,
  GetSalesByDayPDFReq,
  GetSalesByDayReq,
} from "gen/api/analytics/service_pb"
import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  UseQueryResult,
  hashKey,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { RpcOptions, UnaryCall } from "@protobuf-ts/runtime-rpc"
import {
  SerializableReq,
  parseReport,
  restoreSerializableReq,
} from "features/pos/analytics/Reports/configuration/helpers"
import {
  selectExternalTransactionKey,
  selectFinancialTransactionKey,
} from "./searchSlice"
import {
  useLazyReportDataRes,
  useLazyReportFileRes,
  useReportDataRes,
} from "features/pos/analytics/Reports/types"

import { ApiError } from "./apiSlice"
import { Report } from "gen/analytics/models_pb"
import { SearchInventoryCountProductsRes_Result as SearchInventoryCountProductsResult } from "gen/search/service/service_pb"
import { TwirpFetchTransport } from "@protobuf-ts/twirp-transport"
import { selectCompanySearchUrl } from "features/auth/authSlice"
import { stringify } from "lib/stringify"
import { useAppSelector } from "app/hooks"
import { useRouter } from "next/router"

interface PageParam {
  cursor: string
  offset: number
}
export type UseSearchQueryHookArg<Request extends { query: string }> =
  Partial<Request>

type UseSearchQueryHookBaseResult<T> = {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  results: T[]
}

type UsePaginatedSearchQueryHookBaseResult<T> =
  UseSearchQueryHookBaseResult<T> & {
    loadNext: () => void
  }

type UsePaginatedSearchQueryHookResult<Response, Result> =
  UseInfiniteQueryResult<InfiniteData<Response, PageParam>, Error> &
    UsePaginatedSearchQueryHookBaseResult<Result>

type UseUnpaginatedSearchQueryHookResult<Response, Result> = UseQueryResult<
  Response,
  Error
> &
  UseSearchQueryHookBaseResult<Result>

type SearchClient<T> = {
  client: T
  isReady: boolean
}

function useServiceClient<
  T extends
    | BulkServiceClient
    | SearchServiceClient
    | SearchalyticsServiceClient,
>(
  ClientConstructor: new (transport: TwirpFetchTransport) => T
): SearchClient<T> {
  const companySearchUrl = useAppSelector(selectCompanySearchUrl)
  const client = new ClientConstructor(
    new TwirpFetchTransport({
      baseUrl: `${companySearchUrl}/twirp`,
      fetchInit: { credentials: "include" },
      sendJson: true,
      jsonOptions: { ignoreUnknownFields: true },
    })
  )

  return {
    client,
    isReady: !!companySearchUrl,
  }
}

function useBulkClient(): SearchClient<BulkServiceClient> {
  return useServiceClient(BulkServiceClient)
}

function useSearchClient(): SearchClient<SearchServiceClient> {
  return useServiceClient(SearchServiceClient)
}

export function useSearchalyticsClient(): SearchClient<SearchalyticsServiceClient> {
  return useServiceClient(SearchalyticsServiceClient)
}

function useBulkMutation<Req extends object, Res extends object>(
  key: keyof BulkServiceClient
) {
  const { client } = useBulkClient()

  const fn = (
    client[key] as (input: Req, options?: RpcOptions) => UnaryCall<Req, Res>
  ).bind(client)

  return useMutation<Res, ApiError, Req>({
    mutationFn: async (req: Req): Promise<Res> => {
      const res = await fn(req)
      return res.response
    },
  })
}

export function useBulkExportCustomers() {
  return useBulkMutation<BulkExportCustomersReq, BulkExportCustomersRes>(
    "bulkExportCustomers"
  )
}

export function useBulkExportCustomerContacts() {
  return useBulkMutation<
    BulkExportCustomerContactsReq,
    BulkExportCustomerContactsRes
  >("bulkExportCustomerContacts")
}

export function useBulkExportCustomPriceRules() {
  return useBulkMutation<
    BulkExportCustomPriceRulesReq,
    BulkExportCustomPriceRulesRes
  >("bulkExportCustomPriceRules")
}

export function useBulkExportInventoryCountProducts() {
  return useBulkMutation<
    BulkExportInventoryCountProductsReq,
    BulkExportInventoryCountProductsRes
  >("bulkExportInventoryCountProducts")
}

export function useBulkExportProducts() {
  return useBulkMutation<BulkExportProductsReq, BulkExportProductsRes>(
    "bulkExportProducts"
  )
}

export function useBulkExportProductLabels() {
  return useBulkMutation<
    BulkExportProductLabelsReq,
    BulkExportProductLabelsRes
  >("bulkExportProductLabels")
}

export function useBulkExportVendors() {
  return useBulkMutation<BulkExportVendorsReq, BulkExportVendorsRes>(
    "bulkExportVendors"
  )
}

function useSearchQueryParam() {
  const router = useRouter()
  return useMemo<string | undefined>(() => {
    if (Array.isArray(router.query.q)) {
      return ""
    }
    return router.query.q
  }, [router.query.q])
}

function usePaginatedSearch<
  SearchReq extends { query: string },
  SearchResult,
  SearchRes extends {
    results: SearchResult[]
    cursor: string
  },
>(
  key: keyof SearchServiceClient,
  queryKey: string,
  SearchReqInstanceType: { create: () => SearchReq },
  arg?: UseSearchQueryHookArg<Omit<SearchReq, "offset" | "cursor">>,
  skip?: boolean,
  reloadKey?: string
): UsePaginatedSearchQueryHookResult<SearchRes, SearchResult> {
  const { client, isReady } = useSearchClient()

  const fn = (
    client[key] as (
      input: SearchReq,
      options?: RpcOptions
    ) => UnaryCall<SearchReq, SearchRes>
  ).bind(client)

  const defaultArgs = useMemo(
    () => SearchReqInstanceType.create(),
    [SearchReqInstanceType]
  )

  const searchQueryParam = useSearchQueryParam()
  const [query, setQuery] = useState<string>(
    searchQueryParam ?? arg?.query ?? ""
  )
  const deferredQuery = useDeferredValue(query)

  const res = useInfiniteQuery<
    SearchRes,
    Error,
    InfiniteData<SearchRes, PageParam>,
    QueryKey,
    PageParam
  >({
    queryKey: ["search", queryKey, defaultArgs, arg, deferredQuery, reloadKey],
    queryKeyHashFn: (queryKey) => {
      const serializable = queryKey.map((key) => stringify(key))
      return hashKey(serializable)
    },
    initialPageParam: {
      cursor: "",
      offset: 0,
    },
    queryFn: async ({ pageParam }) => {
      const req: SearchReq = {
        ...defaultArgs,
        ...arg,
        query: deferredQuery,
        cursor: pageParam.cursor,
        offset: pageParam.offset,
      }
      const { response } = await fn(req)
      return response
    },
    getNextPageParam: (lastPage, allPages) =>
      lastPage.cursor
        ? {
            cursor: lastPage.cursor,
            offset: allPages.flatMap((x) => x.results).length,
          }
        : undefined,
    placeholderData: keepPreviousData,
    enabled: !skip && isReady,
  })

  const { hasNextPage, fetchNextPage } = res
  const loadNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage])

  const results = res.data?.pages.flatMap((page) => page.results) ?? []

  return {
    ...res,
    query,
    setQuery,
    results,
    loadNext,
  }
}

export function useUnpaginatedSearch<
  SearchReq extends { query: string; limit?: number },
  SearchResult,
  SearchRes extends {
    results: SearchResult[]
  },
>(
  key: keyof SearchServiceClient,
  queryKey: string,
  SearchReqInstanceType: { create: () => SearchReq },
  arg?: UseSearchQueryHookArg<Omit<SearchReq, "offset" | "cursor">>,
  skip?: boolean,
  preserveResults?: boolean,
  argQuery?: boolean
): UseUnpaginatedSearchQueryHookResult<SearchRes, SearchResult> {
  const { client, isReady } = useSearchClient()

  const fn = (
    client[key] as (
      input: SearchReq,
      options?: RpcOptions
    ) => UnaryCall<SearchReq, SearchRes>
  ).bind(client)

  const defaultArgs = useMemo(
    () => SearchReqInstanceType.create(),
    [SearchReqInstanceType]
  )
  const [query, setQuery] = useState<string>("")
  const deferredQuery = useDeferredValue(query)

  const res = useQuery({
    queryKey: ["search", queryKey, defaultArgs, arg, deferredQuery],
    queryFn: async () => {
      const req: SearchReq = {
        ...defaultArgs,
        ...arg,
      }
      if (!argQuery) {
        req.query = deferredQuery
      }
      const { response } = await fn(req)
      return response
    },
    placeholderData: preserveResults ? undefined : keepPreviousData,
    enabled: !skip && isReady,
  })

  const results = res.data?.results ?? []

  return {
    ...res,
    query,
    setQuery,
    results,
  }
}

export function useSearchBills(
  arg?: UseSearchQueryHookArg<SearchBillPaymentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchBillPaymentsRes,
  SearchBillPaymentResult
> {
  return usePaginatedSearch(
    "searchBillPayments",
    "bill-payments",
    SearchBillPaymentsReq,
    arg,
    skip
  )
}

export function useSearchRefundedBills(
  arg?: UseSearchQueryHookArg<SearchRefundedBillPaymentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchRefundedBillPaymentsRes,
  SearchRefundedBillPaymentResult
> {
  return usePaginatedSearch(
    "searchRefundedBillPayments",
    "refunded-bill-payments",
    SearchRefundedBillPaymentsReq,
    arg,
    skip
  )
}

export function useSearchCustomersV2(
  arg?: UseSearchQueryHookArg<SearchCustomersV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCustomersV2Res,
  SearchCustomersV2Result
> {
  return usePaginatedSearch(
    "searchCustomersV2",
    "customers",
    SearchCustomersV2Req,
    arg,
    skip
  )
}

export function useSearchCustomerContacts(
  arg?: UseSearchQueryHookArg<SearchCustomerContactsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCustomerContactsRes,
  SearchCustomerContactsRes_Result
> {
  return usePaginatedSearch(
    "searchCustomerContacts",
    "customer-contacts",
    SearchCustomerContactsReq,
    arg,
    skip
  )
}

export function useSearchInventoryCountProducts(
  arg?: UseSearchQueryHookArg<
    Omit<SearchInventoryCountProductsReq, "offset" | "cursor">
  >,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchInventoryCountProductsRes,
  SearchInventoryCountProductsResult
> {
  return usePaginatedSearch(
    "searchInventoryCountProducts",
    "inventory-count-products",
    SearchInventoryCountProductsReq,
    arg,
    skip
  )
}

export function useInvalidateSearchInventoryCountProducts(): () => Promise<void> {
  const queryClient = useQueryClient()
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ["search", "inventory-count-products"],
      }),
    [queryClient]
  )
}

export function useSearchExcludedInventoryCountProducts(
  arg?: UseSearchQueryHookArg<SearchExcludedInventoryCountProductsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchExcludedInventoryCountProductsRes,
  SearchExcludedInventoryCountProductsResult
> {
  return usePaginatedSearch(
    "searchExcludedInventoryCountProducts",
    "excluded-inventory-count-products",
    SearchExcludedInventoryCountProductsReq,
    arg,
    skip
  )
}

export function useSearchOrders(
  arg?: UseSearchQueryHookArg<SearchOrdersReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchOrdersRes, SearchOrdersResult> {
  return usePaginatedSearch(
    "searchOrders",
    "orders",
    SearchOrdersReq,
    arg,
    skip
  )
}

export function useSearchOrderShipments(
  arg?: UseSearchQueryHookArg<SearchOrderShipmentsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchOrderShipmentsRes,
  SearchOrderShipmentsResult
> {
  return usePaginatedSearch(
    "searchOrderShipments",
    "order-shipments",
    SearchOrderShipmentsReq,
    arg,
    skip
  )
}

export function useSearchProductsV2(
  arg?: UseSearchQueryHookArg<SearchProductsV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchProductsV2Res,
  SearchProductsV2Result
> {
  return usePaginatedSearch(
    "searchProductsV2",
    "products",
    SearchProductsV2Req,
    arg,
    skip
  )
}

export function useSearchCustomPriceRules(
  arg?: UseSearchQueryHookArg<SearchCustomPriceRulesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCustomPriceRulesRes,
  SearchCustomPriceRulesResult
> {
  return usePaginatedSearch(
    "searchCustomPriceRules",
    "custom-price-rules",
    SearchCustomPriceRulesReq,
    arg,
    skip
  )
}

export function useSearchPriceTierRules(
  arg?: UseSearchQueryHookArg<SearchPriceTierRulesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchPriceTierRulesRes,
  SearchPriceTierRulesResult
> {
  return usePaginatedSearch(
    "searchPriceTierRules",
    "price-tier-rules",
    SearchPriceTierRulesReq,
    arg,
    skip
  )
}

export function useSearchSalesV2(
  arg?: UseSearchQueryHookArg<SearchSalesV2Req>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchSalesV2Res, SearchSalesV2Result> {
  return usePaginatedSearch(
    "searchSalesV2",
    "sales",
    SearchSalesV2Req,
    arg,
    skip
  )
}

export function useSearchReturns(
  arg?: UseSearchQueryHookArg<SearchReturnsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchReturnsRes, SearchReturnsResult> {
  return usePaginatedSearch(
    "searchReturns",
    "returns",
    SearchReturnsReq,
    arg,
    skip
  )
}

export function useSearchOutstandingSales(
  arg?: UseSearchQueryHookArg<SearchOutstandingSalesReq>,
  skip?: boolean
): UseUnpaginatedSearchQueryHookResult<
  SearchOutstandingSalesRes,
  SearchOutstandingSalesResult
> {
  return useUnpaginatedSearch(
    "searchOutstandingSales",
    "outstanding-sales",
    SearchOutstandingSalesReq,
    arg,
    skip
  )
}

export function useSearchTransfers(
  arg?: UseSearchQueryHookArg<SearchTransfersReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchTransfersRes,
  SearchTransfersResult
> {
  return usePaginatedSearch(
    "searchTransfers",
    "transfers",
    SearchTransfersReq,
    arg,
    skip
  )
}

export function useSearchVendors(
  arg?: UseSearchQueryHookArg<SearchVendorsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchVendorsRes, SearchVendorsResult> {
  return usePaginatedSearch(
    "searchVendors",
    "vendors",
    SearchVendorsReq,
    arg,
    skip
  )
}

export function useSearchTintColors(): UseUnpaginatedSearchQueryHookResult<
  SearchTintColorsRes,
  SearchTintColorsResult
> {
  return useUnpaginatedSearch(
    "searchTintColors",
    "tint-colors",
    SearchTintColorsReq
  )
}

export function useSearchCashTransactions(
  arg?: UseSearchQueryHookArg<SearchCashTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchCashTransactionsRes,
  SearchCashTransactionResult
> {
  return usePaginatedSearch(
    "searchCashTransactions",
    "cash-transactions",
    SearchCashTransactionsReq,
    arg,
    skip
  )
}

export function useSearchFinancialTransactions(
  arg?: UseSearchQueryHookArg<SearchFinancialTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchFinancialTransactionsRes,
  SearchFinancialTransactionsResult
> {
  const reloadKey = useAppSelector(selectFinancialTransactionKey)

  return usePaginatedSearch(
    "searchFinancialTransactions",
    "financial-transactions",
    SearchFinancialTransactionsReq,
    arg,
    skip,
    reloadKey
  )
}

export function useSearchExternalTransactions(
  arg?: UseSearchQueryHookArg<SearchExternalTransactionsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchExternalTransactionsRes,
  SearchExternalTransactionsResult
> {
  const reloadKey = useAppSelector(selectExternalTransactionKey)

  return usePaginatedSearch(
    "searchExternalTransactions",
    "external-transactions",
    SearchExternalTransactionsReq,
    arg,
    skip,
    reloadKey
  )
}

export function useSearchOrderShipmentsForReconciliation(
  arg?: UseSearchQueryHookArg<SearchOrderShipmentsForReconciliationReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchOrderShipmentsForReconciliationRes,
  SearchOrderShipmentsForReconciliationResult
> {
  return usePaginatedSearch(
    "searchOrderShipmentsForReconciliation",
    "order-shipments-for-reconciliation",
    SearchOrderShipmentsForReconciliationReq,
    arg,
    skip
  )
}

export function useSearchInventoryChanges(
  arg?: UseSearchQueryHookArg<SearchInventoryChangesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchInventoryChangesRes,
  SearchInventoryChangesResult
> {
  return usePaginatedSearch(
    "searchInventoryChanges",
    "inventory-changes",
    SearchInventoryChangesReq,
    arg,
    skip
  )
}

export function useSearchJobs(
  arg?: UseSearchQueryHookArg<SearchJobsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchJobsRes, SearchJobsResult> {
  return usePaginatedSearch("searchJobs", "jobs", SearchJobsReq, arg, skip)
}

export function useSearchTags(
  arg?: UseSearchQueryHookArg<SearchTagsReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<SearchTagsRes, SearchTagsRes_Result> {
  return usePaginatedSearch("searchTags", "tags", SearchTagsReq, arg, skip)
}

export function useSearchStagedProductsToOrder(
  arg?: UseSearchQueryHookArg<SearchStagedProductsToOrderReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchStagedProductsToOrderRes,
  SearchStagedProductsToOrderRes_Result
> {
  return usePaginatedSearch(
    "searchStagedProductsToOrder",
    "stagedProductsToOrder",
    SearchStagedProductsToOrderReq,
    arg,
    skip
  )
}

export function useSearchFinanceCharges(
  arg?: UseSearchQueryHookArg<SearchFinanceChargesReq>,
  skip?: boolean
): UsePaginatedSearchQueryHookResult<
  SearchFinanceChargesRes,
  SearchFinanceChargesResult
> {
  return usePaginatedSearch(
    "searchFinanceCharges",
    "financeCharges",
    SearchFinanceChargesReq,
    arg,
    skip
  )
}

const SEARCHALYTICS_STALE_TIME = Infinity
function useSearchalytics<Req extends object, Res extends object>(
  key: keyof SearchalyticsServiceClient,
  arg: SerializableReq<Req>,
  skip?: boolean
): UseQueryResult<Res, Error> {
  const { client, isReady } = useSearchalyticsClient()

  const fn = (
    client[key] as (input: Req, options?: RpcOptions) => UnaryCall<Req, Res>
  ).bind(client)

  const res = useQuery({
    queryKey: ["searchalytics", key, arg],
    queryFn: async ({ signal }) => {
      const { response } = await fn(restoreSerializableReq(arg) as Req, {
        abort: signal,
      })
      return response
    },
    placeholderData: keepPreviousData,
    enabled: !skip && isReady,
    staleTime: SEARCHALYTICS_STALE_TIME,
    refetchOnWindowFocus: false,
  })

  return res
}

function useLazySearchalytics<Req extends object, Res extends object>(
  key: keyof SearchalyticsServiceClient,
  arg: SerializableReq<Req>
): UseQueryResult<Res, Error> {
  return useSearchalytics<Req, Res>(key, arg, true)
}

interface SearchalyticsReportRes {
  report: Report | undefined
}

interface SearchalyticsReportFileRes {
  bytes: Uint8Array
}

function useSearchalyticsReport<
  Req extends object,
  Res extends SearchalyticsReportRes,
>(
  key: keyof SearchalyticsServiceClient,
  arg: SerializableReq<Req>,
  skip?: boolean
): useReportDataRes {
  const res = useSearchalytics<Req, Res>(key, arg, skip)
  return {
    isError: res.isError,
    isFetching: res.isFetching,
    error: res.error,
    report: res.data && parseReport(res.data.report),
  }
}

function useLazySearchalyticsReport<
  Req extends object,
  Res extends SearchalyticsReportRes,
>(
  key: keyof SearchalyticsServiceClient,
  arg: SerializableReq<Req>
): useLazyReportDataRes {
  const res = useSearchalytics<Req, Res>(key, arg, true)
  return {
    report: res.data && parseReport(res.data.report),
    triggerReport: () => res.refetch(),
    isFetching: res.isFetching,
    isError: res.isError,
    error: res.error,
  }
}

function useLazySearchalyticsFile<
  Req extends object,
  Res extends SearchalyticsReportFileRes,
>(
  key: keyof SearchalyticsServiceClient,
  arg: SerializableReq<Req>
): useLazyReportFileRes {
  const fileRes = useSearchalytics<Req, Res>(key, arg, true)
  return {
    getBytes: async () => {
      const res = (await fileRes.refetch()).data
      if (!res) {
        throw new Error("No data")
      }
      return res.bytes
    },
    isFetching: fileRes.isFetching,
  }
}

export function useSearchalyticsGetSalesByDay(
  arg: SerializableReq<GetSalesByDayReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSalesByDay", arg, skip)
}

export function useLazySearchalyticsGetSalesByDay(
  arg: SerializableReq<GetSalesByDayReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSalesByDay", arg)
}

export function useLazySearchalyticsGetSalesByDayPDF(
  arg: SerializableReq<GetSalesByDayPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByDayPDF", arg)
}

export function useLazySearchalyticsGetSalesByDayCSV(
  arg: SerializableReq<GetSalesByDayCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByDayCSV", arg)
}

export function useSearchalyticsGetSalesByLocation(
  arg: SerializableReq<GetSalesByLocationReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSalesByLocation", arg, skip)
}

export function useLazySearchalyticsGetSalesByLocation(
  arg: SerializableReq<GetSalesByLocationReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSalesByLocation", arg)
}

export function useLazySearchalyticsGetSalesByLocationPDF(
  arg: SerializableReq<GetSalesByLocationPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByLocationPDF", arg)
}

export function useLazySearchalyticsGetSalesByLocationCSV(
  arg: SerializableReq<GetSalesByLocationCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByLocationCSV", arg)
}

export function useSearchalyticsGetSalesByRep(
  arg: SerializableReq<GetSalesByRepReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSalesByRep", arg, skip)
}

export function useLazySearchalyticsGetSalesByRep(
  arg: SerializableReq<GetSalesByRepReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSalesByRep", arg)
}

export function useLazySearchalyticsGetSalesByRepPDF(
  arg: SerializableReq<GetSalesByRepPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByRepPDF", arg)
}

export function useLazySearchalyticsGetSalesByRepCSV(
  arg: SerializableReq<GetSalesByRepCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByRepCSV", arg)
}

export function useSearchalyticsGetCashRegister(
  arg: SerializableReq<GetCashRegisterReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getCashRegister", arg, skip)
}

export function useLazySearchalyticsGetCashRegister(
  arg: SerializableReq<GetCashRegisterReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getCashRegister", arg)
}

export function useLazySearchalyticsGetCashRegisterPDF(
  arg: SerializableReq<GetCashRegisterPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getCashRegisterPDF", arg)
}
export function useLazySearchalyticsGetCashRegisterCSV(
  arg: SerializableReq<GetCashRegisterCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getCashRegisterCSV", arg)
}

export function useSearchalyticsGetSales(
  arg: SerializableReq<GetSalesReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSales", arg, skip)
}

export function useLazySearchalyticsGetSales(
  arg: SerializableReq<GetSalesReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSales", arg)
}

export function useLazySearchalyticsGetSalesPDF(
  arg: SerializableReq<GetSalesPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesPDF", arg)
}
export function useLazySearchalyticsGetSalesCSV(
  arg: SerializableReq<GetSalesCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesCSV", arg)
}

export function useSearchalyticsGetPayments(
  arg: SerializableReq<GetPaymentsReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getPayments", arg, skip)
}

export function useLazySearchalyticsGetPayments(
  arg: SerializableReq<GetPaymentsReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getPayments", arg)
}

export function useLazySearchalyticsGetPaymentsPDF(
  arg: SerializableReq<GetPaymentsPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getPaymentsPDF", arg)
}
export function useLazySearchalyticsGetPaymentsCSV(
  arg: SerializableReq<GetPaymentsCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getPaymentsCSV", arg)
}

export function useSearchalyticsGetSoldProducts(
  arg: SerializableReq<GetSoldProductsReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSoldProducts", arg, skip)
}

export function useLazySearchalyticsGetSoldProducts(
  arg: SerializableReq<GetSoldProductsReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSoldProducts", arg)
}

export function useLazySearchalyticsGetSoldProductsPDF(
  arg: SerializableReq<GetSoldProductsPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSoldProductsPDF", arg)
}

export function useLazySearchalyticsGetSoldProductsCSV(
  arg: SerializableReq<GetSoldProductsCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSoldProductsCSV", arg)
}

export function useSearchalyticsGetSalesByCustomer(
  arg: SerializableReq<GetSalesByCustomerReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSalesByCustomer", arg, skip)
}

export function useLazySearchalyticsGetSalesByCustomer(
  arg: SerializableReq<GetSalesByCustomerReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSalesByCustomer", arg)
}

export function useLazySearchalyticsGetSalesByCustomerPDF(
  arg: SerializableReq<GetSalesByCustomerPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByCustomerPDF", arg)
}

export function useLazySearchalyticsGetSalesByCustomerCSV(
  arg: SerializableReq<GetSalesByCustomerCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByCustomerCSV", arg)
}

export function useSearchalyticsGetSalesByJob(
  arg: SerializableReq<GetSalesByJobReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSalesByJob", arg, skip)
}

export function useLazySearchalyticsGetSalesByJob(
  arg: SerializableReq<GetSalesByJobReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSalesByJob", arg)
}

export function useLazySearchalyticsGetSalesByJobPDF(
  arg: SerializableReq<GetSalesByJobPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByJobPDF", arg)
}

export function useLazySearchalyticsGetSalesByJobCSV(
  arg: SerializableReq<GetSalesByJobCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByJobCSV", arg)
}

export function useSearchalyticsGetSalesByProduct(
  arg: SerializableReq<GetSalesByProductReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getSalesByProduct", arg, skip)
}

export function useLazySearchalyticsGetSalesByProduct(
  arg: SerializableReq<GetSalesByProductReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getSalesByProduct", arg)
}

export function useLazySearchalyticsGetSalesByProductPDF(
  arg: SerializableReq<GetSalesByProductPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByProductPDF", arg)
}

export function useLazySearchalyticsGetSalesByProductCSV(
  arg: SerializableReq<GetSalesByProductCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getSalesByProductCSV", arg)
}

export function useSearchalyticsGetProfitAndLoss(
  arg: SerializableReq<GetProfitAndLossReq>,
  skip?: boolean
): UseQueryResult<GetProfitAndLossRes, Error> {
  return useSearchalytics("getProfitAndLoss", arg, skip)
}

export function useLazySearchalyticsGetProfitAndLossPDF(
  arg: SerializableReq<GetProfitAndLossPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getProfitAndLossPDF", arg)
}

export function useSearchalyticsGetCustomerAging(
  arg: SerializableReq<GetCustomerAgingReq>,
  skip?: boolean
): UseQueryResult<GetCustomerAgingRes, Error> {
  return useSearchalytics("getCustomerAging", arg, skip)
}

export function useLazySearchalyticsGetCustomerAging(
  arg: SerializableReq<GetCustomerAgingReq>
): UseQueryResult<GetCustomerAgingRes, Error> {
  return useLazySearchalytics("getCustomerAging", arg)
}

export function useLazySearchalyticsGetCustomerAgingPDF(
  arg: SerializableReq<GetCustomerAgingPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getCustomerAgingPDF", arg)
}

export function useLazySearchalyticsGetCustomerAgingCSV(
  arg: SerializableReq<GetCustomerAgingCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getCustomerAgingCSV", arg)
}

export function useSearchalyticsGetPriceEdits(
  arg: SerializableReq<GetPriceEditsReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getPriceEdits", arg, skip)
}

export function useLazySearchalyticsGetPriceEdits(
  arg: SerializableReq<GetPriceEditsReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getPriceEdits", arg)
}

export function useLazySearchalyticsGetPriceEditsPDF(
  arg: SerializableReq<GetPriceEditsPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getPriceEditsPDF", arg)
}

export function useLazySearchalyticsGetPriceEditsCSV(
  arg: SerializableReq<GetPriceEditsCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getPriceEditsCSV", arg)
}

export function useSearchalyticsGetNewAccountsOpened(
  arg: SerializableReq<GetNewAccountsOpenedReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getNewAccountsOpened", arg, skip)
}

export function useLazySearchalyticsGetNewAccountsOpened(
  arg: SerializableReq<GetNewAccountsOpenedReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getNewAccountsOpened", arg)
}

export function useLazySearchalyticsGetNewAccountsOpenedPDF(
  arg: SerializableReq<GetNewAccountsOpenedPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getNewAccountsOpenedPDF", arg)
}

export function useLazySearchalyticsGetNewAccountsOpenedCSV(
  arg: SerializableReq<GetNewAccountsOpenedCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getNewAccountsOpenedCSV", arg)
}

export function useSearchalyticsGetAgingDetail(
  arg: SerializableReq<GetAgingDetailReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getAgingDetail", arg, skip)
}

export function useLazySearchalyticsGetAgingDetail(
  arg: SerializableReq<GetAgingDetailReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getAgingDetail", arg)
}

export function useLazySearchalyticsGetAgingDetailPDF(
  arg: SerializableReq<GetAgingDetailPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAgingDetailPDF", arg)
}

export function useLazySearchalyticsGetAgingDetailCSV(
  arg: SerializableReq<GetAgingDetailCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAgingDetailCSV", arg)
}

export function useSearchalyticsGetPurchaseJournal(
  arg: SerializableReq<GetPurchaseJournalReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getPurchaseJournal", arg, skip)
}

export function useLazySearchalyticsGetPurchaseJournal(
  arg: SerializableReq<GetPurchaseJournalReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getPurchaseJournal", arg)
}

export function useLazySearchalyticsGetPurchaseJournalPDF(
  arg: SerializableReq<GetPurchaseJournalPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getPurchaseJournalPDF", arg)
}

export function useLazySearchalyticsGetPurchaseJournalCSV(
  arg: SerializableReq<GetPurchaseJournalCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getPurchaseJournalCSV", arg)
}

export function useSearchalyticsGetAccountPaymentAdjustments(
  arg: SerializableReq<GetAccountPaymentAdjustmentsReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getAccountPaymentAdjustments", arg, skip)
}

export function useLazySearchalyticsGetAccountPaymentAdjustments(
  arg: SerializableReq<GetAccountPaymentAdjustmentsReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getAccountPaymentAdjustments", arg)
}

export function useLazySearchalyticsGetAccountPaymentAdjustmentsPDF(
  arg: SerializableReq<GetAccountPaymentAdjustmentsPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAccountPaymentAdjustmentsPDF", arg)
}

export function useLazySearchalyticsGetAccountPaymentAdjustmentsCSV(
  arg: SerializableReq<GetAccountPaymentAdjustmentsCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAccountPaymentAdjustmentsCSV", arg)
}

export function useSearchalyticsGetAdditionalFees(
  arg: SerializableReq<GetAdditionalFeesReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getAdditionalFees", arg, skip)
}

export function useLazySearchalyticsGetAdditionalFees(
  arg: SerializableReq<GetAdditionalFeesReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getAdditionalFees", arg)
}

export function useLazySearchalyticsGetAdditionalFeesPDF(
  arg: SerializableReq<GetAdditionalFeesPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAdditionalFeesPDF", arg)
}

export function useLazySearchalyticsGetAdditionalFeesCSV(
  arg: SerializableReq<GetAdditionalFeesCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAdditionalFeesCSV", arg)
}

export function useSearchalyticsGetAgingByJob(
  arg: SerializableReq<GetAgingByJobReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getAgingByJob", arg, skip)
}

export function useLazySearchalyticsGetAgingByJob(
  arg: SerializableReq<GetAgingByJobReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getAgingByJob", arg)
}

export function useLazySearchalyticsGetAgingByJobPDF(
  arg: SerializableReq<GetAgingByJobPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAgingByJobPDF", arg)
}

export function useLazySearchalyticsGetAgingByJobCSV(
  arg: SerializableReq<GetAgingByJobCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getAgingByJobCSV", arg)
}

export function useSearchalyticsGetInventoryByDepartment(
  arg: SerializableReq<GetInventoryByDepartmentReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getInventoryByDepartment", arg, skip)
}

export function useLazySearchalyticsGetInventoryByDepartment(
  arg: SerializableReq<GetInventoryByDepartmentReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getInventoryByDepartment", arg)
}

export function useLazySearchalyticsGetInventoryByDepartmentPDF(
  arg: SerializableReq<GetInventoryByDepartmentPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInventoryByDepartmentPDF", arg)
}

export function useLazySearchalyticsGetInventoryByDepartmentCSV(
  arg: SerializableReq<GetInventoryByDepartmentCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInventoryByDepartmentCSV", arg)
}

export function useSearchalyticsGetInventoryByProduct(
  arg: SerializableReq<GetInventoryByProductReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getInventoryByProduct", arg, skip)
}

export function useLazySearchalyticsGetInventoryByProduct(
  arg: SerializableReq<GetInventoryByProductReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getInventoryByProduct", arg)
}

export function useLazySearchalyticsGetInventoryByProductPDF(
  arg: SerializableReq<GetInventoryByProductPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInventoryByProductPDF", arg)
}

export function useLazySearchalyticsGetInventoryByProductCSV(
  arg: SerializableReq<GetInventoryByProductCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInventoryByProductCSV", arg)
}

export function useSearchalyticsGetTransferDiscrepancies(
  arg: SerializableReq<GetTransferDiscrepanciesReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getTransferDiscrepancies", arg, skip)
}

export function useLazySearchalyticsGetTransferDiscrepancies(
  arg: SerializableReq<GetTransferDiscrepanciesReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getTransferDiscrepancies", arg)
}

export function useLazySearchalyticsGetTransferDiscrepanciesPDF(
  arg: SerializableReq<GetTransferDiscrepanciesPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getTransferDiscrepanciesPDF", arg)
}

export function useLazySearchalyticsGetTransferDiscrepanciesCSV(
  arg: SerializableReq<GetTransferDiscrepanciesCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getTransferDiscrepanciesCSV", arg)
}

export function useSearchalyticsGetInteractionsByStaff(
  arg: SerializableReq<GetInteractionsByStaffReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getInteractionsByStaff", arg, skip)
}

export function useLazySearchalyticsGetInteractionsByStaff(
  arg: SerializableReq<GetInteractionsByStaffReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getInteractionsByStaff", arg)
}

export function useLazySearchalyticsGetInteractionsByStaffPDF(
  arg: SerializableReq<GetInteractionsByStaffPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInteractionsByStaffPDF", arg)
}

export function useLazySearchalyticsGetInteractionsByStaffCSV(
  arg: SerializableReq<GetInteractionsByStaffCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInteractionsByStaffCSV", arg)
}

export function useSearchalyticsGetInteractions(
  arg: SerializableReq<GetInteractionsReq>,
  skip?: boolean
): useReportDataRes {
  return useSearchalyticsReport("getInteractions", arg, skip)
}

export function useLazySearchalyticsGetInteractions(
  arg: SerializableReq<GetInteractionsReq>
): useLazyReportDataRes {
  return useLazySearchalyticsReport("getInteractions", arg)
}

export function useLazySearchalyticsGetInteractionsPDF(
  arg: SerializableReq<GetInteractionsPDFReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInteractionsPDF", arg)
}

export function useLazySearchalyticsGetInteractionsCSV(
  arg: SerializableReq<GetInteractionsCSVReq>
): useLazyReportFileRes {
  return useLazySearchalyticsFile("getInteractionsCSV", arg)
}
