import {
  BulkUpsertVendorsReq,
  BulkUpsertVendorsRes,
  CreateVendorContactReq,
  CreateVendorContactRes,
  CreateVendorReq,
  CreateVendorRes,
  CreateVendorWithBankAccountReq,
  CreateVendorWithBankAccountRes,
  DeactivateVendorContactReq,
  DeactivateVendorContactRes,
  DeleteVendorBankPaymentMethodReq,
  DeleteVendorBankPaymentMethodRes,
  DuplicateVendorReq,
  DuplicateVendorRes,
  GetVendorReq,
  GetVendorRes,
  ListVendorsForCompanyReq,
  ListVendorsForCompanyRes,
  SaveVendorBankPaymentMethodReq,
  SaveVendorBankPaymentMethodRes,
  UpdateVendorAccountsByLocationReq,
  UpdateVendorAccountsByLocationRes,
  UpdateVendorContactReq,
  UpdateVendorContactRes,
  UpdateVendorInformationReq,
  UpdateVendorInformationRes,
  UpdateVendorIsActiveReq,
  UpdateVendorIsActiveRes,
  UpdateVendorNotesReq,
  UpdateVendorNotesRes,
  UpdateVendorOrderSettingsReq,
  UpdateVendorOrderSettingsRes,
  UpsertVendorBankAccountReq,
  UpsertVendorBankAccountRes,
} from "gen/api/vendors/service_pb"
import { apiSlice, transport } from "./apiSlice"

import { UUID } from "uuid-rd"
import { VendorsServiceClient } from "gen/api/vendors/service_pb.client"

const c = new VendorsServiceClient(transport)

const vendorApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Vendors"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVendor: builder.query<GetVendorRes, GetVendorReq>({
        query: (r) => c.getVendor(r),
        providesTags: (_response, _error, request) => [
          { type: "Vendors", id: UUID.fromPB(request.id!).toString() },
        ],
      }),
      listVendorsForCompany: builder.query<
        ListVendorsForCompanyRes,
        ListVendorsForCompanyReq
      >({
        query: (r) => c.listVendorsForCompany(r),
        providesTags: ["Vendors"],
      }),
      createVendorContact: builder.mutation<
        CreateVendorContactRes,
        CreateVendorContactReq
      >({
        query: (r) => c.createVendorContact(r),
        invalidatesTags: (_response, _error, request) => [
          {
            type: "Vendors",
            id: UUID.fromPB(request.vendorId!).toString(),
          },
        ],
      }),
      createVendor: builder.mutation<CreateVendorRes, CreateVendorReq>({
        query: (r) => c.createVendor(r),
        invalidatesTags: ["Vendors"],
      }),
      updateVendorInformation: builder.mutation<
        UpdateVendorInformationRes,
        UpdateVendorInformationReq
      >({
        query: (r) => c.updateVendorInformation(r),
        invalidatesTags: (_response, _error, request) => [
          { type: "Vendors", id: UUID.fromPB(request.id!).toString() },
        ],
      }),
      updateVendorIsActive: builder.mutation<
        UpdateVendorIsActiveRes,
        UpdateVendorIsActiveReq
      >({
        query: (r) => c.updateVendorIsActive(r),
        invalidatesTags: (_response, _error, request) => [
          { type: "Vendors", id: UUID.fromPB(request.id!).toString() },
        ],
      }),
      updateVendorNotes: builder.mutation<
        UpdateVendorNotesRes,
        UpdateVendorNotesReq
      >({
        query: (r) => c.updateVendorNotes(r),
        invalidatesTags: (_response, _error, request) => [
          { type: "Vendors", id: UUID.fromPB(request.id!).toString() },
        ],
      }),
      updateVendorContact: builder.mutation<
        UpdateVendorContactRes,
        UpdateVendorContactReq
      >({
        query: (r) => c.updateVendorContact(r),
        invalidatesTags: (_response, _error, request) => [
          {
            type: "Vendors",
            id: UUID.fromPB(request.vendorId!).toString(),
          },
        ],
      }),
      deactivateVendorContact: builder.mutation<
        DeactivateVendorContactRes,
        DeactivateVendorContactReq
      >({
        query: (r) => c.deactivateVendorContact(r),
        invalidatesTags: (_response, _error, request) => [
          {
            type: "Vendors",
            id: UUID.fromPB(request.vendorId!).toString(),
          },
        ],
      }),
      saveVendorBankPaymentMethod: builder.mutation<
        SaveVendorBankPaymentMethodRes,
        SaveVendorBankPaymentMethodReq
      >({
        query: (r) => c.saveVendorBankPaymentMethod(r),
        invalidatesTags: (_response, _error, request) =>
          request.vendorId
            ? [
                {
                  type: "Vendors",
                  id: UUID.fromPB(request.vendorId).toString(),
                },
              ]
            : [],
      }),
      deleteVendorBankPaymentMethod: builder.mutation<
        DeleteVendorBankPaymentMethodRes,
        DeleteVendorBankPaymentMethodReq
      >({
        query: (r) => c.deleteVendorBankPaymentMethod(r),
        invalidatesTags: (_response, _error, request) =>
          request.vendorId
            ? [
                {
                  type: "Vendors",
                  id: UUID.fromPB(request.vendorId).toString(),
                },
              ]
            : [],
      }),
      createVendorWithBankAccount: builder.mutation<
        CreateVendorWithBankAccountRes,
        CreateVendorWithBankAccountReq
      >({
        query: (r) => c.createVendorWithBankAccount(r),
        invalidatesTags: ["Vendors"],
      }),
      updateVendorOrderSettings: builder.mutation<
        UpdateVendorOrderSettingsRes,
        UpdateVendorOrderSettingsReq
      >({
        query: (r) => c.updateVendorOrderSettings(r),
        invalidatesTags: (_response, _error, request) => [
          { type: "Vendors", id: UUID.fromPB(request.vendorId).toString() },
        ],
      }),
      upsertVendorBankAccount: builder.mutation<
        UpsertVendorBankAccountRes,
        UpsertVendorBankAccountReq
      >({
        query: (r) => c.upsertVendorBankAccount(r),
        invalidatesTags: (_response, _error, request) => [
          { type: "Vendors", id: UUID.fromPB(request.vendorId!).toString() },
        ],
      }),
      UpdateVendorAccountsByLocation: builder.mutation<
        UpdateVendorAccountsByLocationRes,
        UpdateVendorAccountsByLocationReq
      >({
        query: (r) => c.updateVendorAccountsByLocation(r),
        invalidatesTags: (_response, _error, request) => [
          { type: "Vendors", id: UUID.fromPB(request.id!).toString() },
        ],
      }),
      bulkUpsertVendors: builder.mutation<
        BulkUpsertVendorsRes,
        BulkUpsertVendorsReq
      >({
        query: (r) => c.bulkUpsertVendors(r),
      }),
      duplicateVendor: builder.mutation<DuplicateVendorRes, DuplicateVendorReq>(
        {
          query: (r) => c.duplicateVendor(r),
        }
      ),
    }),
  })

export const {
  useGetVendorQuery,
  useLazyGetVendorQuery,
  useListVendorsForCompanyQuery,
  useLazyListVendorsForCompanyQuery,
  useCreateVendorMutation,
  useCreateVendorContactMutation,
  useUpdateVendorInformationMutation,
  useUpdateVendorIsActiveMutation,
  useUpdateVendorNotesMutation,
  useUpdateVendorContactMutation,
  useUpdateVendorOrderSettingsMutation,
  useDeactivateVendorContactMutation,
  useSaveVendorBankPaymentMethodMutation,
  useDeleteVendorBankPaymentMethodMutation,
  useCreateVendorWithBankAccountMutation,
  useUpsertVendorBankAccountMutation,
  useUpdateVendorAccountsByLocationMutation,
  useBulkUpsertVendorsMutation,
  useDuplicateVendorMutation,
} = vendorApiSlice
