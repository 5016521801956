import { customPrices, product, products, promotions } from "./products"
import { customer, customerContacts, customers } from "./customers"
import { order, orderStatusParam } from "./orders"
import { orderShipment, orderShipmentStatusParam } from "./orderShipments"
import { sale, saleStatusParam } from "./sales"
import { transfer, transferStatusParam } from "./transfers"
import { vendor, vendors } from "./vendors"

import { bill } from "./bills"
import { financeCharge } from "./financeCharges"
import { inventoryCount } from "./inventoryCounts"
import { job } from "./jobs"
import { saleReturn } from "./returns"
import { useDBStatus } from "./useDBStatus"

export const urls = {
  customer,
  customers,
  customerContacts,
  job,
  product,
  products,
  customPrices,
  promotions,
  vendor,
  vendors,
  inventoryCount,
  sale,
  saleStatusParam,
  return: saleReturn,
  transfer,
  transferStatusParam,
  order,
  orderStatusParam,
  orderShipment,
  orderShipmentStatusParam,
  bill,
  financeCharge,
  useDBStatus,
}
