import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Tag } from "gen/tag/models_pb"
import { UUID } from "uuid-rd"
import { encodeQueryParam } from "components/TableV2/useFilter"
import { tierFilterKey } from "features/pos/customers/CustomersV2/useCustomersFilters/useTierFilter"

export function customer(id?: PBUUID, defaultTab?: number): string {
  const base = `/pos/customers/${UUID.idString(id)}`
  if (defaultTab !== undefined) {
    return `${base}?defaultTab=${defaultTab}`
  }
  return base
}

export function customers(): string {
  return "/pos/customers?status=2"
}

export function customerContacts(): string {
  return "/pos/customers/contacts"
}

export function customerTier(pricingTierIdString?: string): string {
  return `${customers()}&${tierFilterKey}=${pricingTierIdString}`
}

export function customersWithTag(tag: Tag) {
  return `/pos/customers?tag=${encodeQueryParam({ key: UUID.idString(tag.id), value: tag.name }, true)}`
}
