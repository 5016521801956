import { useEffect } from "react"
import { useRouter } from "next/router"

export function useDBStatus(dbStatus: string) {
  const router = useRouter()
  const routerStatus = router.query.status

  useEffect(() => {
    if (!dbStatus) {
      return
    }
    if (dbStatus === routerStatus) {
      return
    }
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, status: dbStatus },
      },
      undefined,
      { shallow: true }
    )
  }, [dbStatus, routerStatus, router])
}
